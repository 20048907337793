<div tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class=" modal-dialog" role="document" style="max-width: 50%;">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title pull-left" style="font-size: 15px;font-weight: 600;">
          <span style="padding-left: 5px;">Send SMS</span>
        </div>
        <button type="button" class="close pull-right my_closex" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true" style="font-size: 21px;">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <div class="row">
            <div class="form-group col-sm-4">
              <label for="route">Route</label>
              <span style="color: red">*</span>
              <select class="form-control" [(ngModel)]="sms_type" (change)="getsources($event.target.value, 'null')">
                <option value="" disabled selected hidden>Select Route</option>
                <option *ngFor="let item of sampleData" [value]="item">
                  {{item}}
                </option>
              </select>
            </div>
            <div class="form-group col-sm-4">
              <label for="sourceid">SenderID</label>
              <span style="color: red">*</span>
              <select class="form-control" [(ngModel)]="senderId" (change)="getTemplates($event.target.value, 'null')">
                <option value="" disabled selected hidden>Select SenderID</option>
                <option *ngFor="let item of senderIdsArray" [value]="item">
                  {{item}}
                </option>
              </select>
            </div>
            <div class="form-group col-sm-4">
              <label for="template">Template</label>
              <select class="form-control" [(ngModel)]="templateId" (change)="setdescription($event.target.value)">
                <option value="" selected>Select Template</option>
                <option *ngFor="let item of templatesArray" [value]="item.id">
                  {{item.TemplateName}}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row" style="margin-bottom: 0.2rem;">
            <!-- <label class="col-md-3 col-form-label" for="text-input">Language<span style="color: red">*</span></label> -->
            <div class="col-md-12" style="margin-top: 8px;">
              <div class="form-check form-check-inline mr-1" style="margin-right: 6.5rem !important;">
                <input class="form-check-input" type="radio" [(ngModel)]="language" id="english_radio" name="language" value="English" checked
                  (change)="changeLanguage($event,ind)">
                <label class="form-check-label" for="english_radio">English</label>
              </div>
              <div class="form-check form-check-inline mr-1" style="margin-right: 6rem !important;">
                <input class="form-check-input" type="radio" [(ngModel)]="language" id="unicode_radio" name="language" value="Unicode" (change)="changeLanguage($event,ind)">
                <label class="form-check-label" for="unicode_radio">Unicode</label>
              </div>
              <!-- <span style="margin-right: 7px;">
              <input type="radio" name="language" value="English" checked (change)="changeLanguage($event,ind)"> English</span>
            <span>
              <input type="radio" name="language" value="Unicode" (change)="changeLanguage($event,ind)"> Unicode</span> -->
            </div>
          </div>
          <div *ngIf="englishtext" class="form-group row" style="margin-bottom: 0.2rem;">
            <!-- <label style="padding-right: 0px;" class="col-3 col-form-label" id="label" for="text-input">Description
            <span style="color: red">*</span>
          </label> -->
            <div class="col-12" style="display: inherit;">
              <textarea [disabled]="textareaDisable" name="textarea-input" [(ngModel)]="sendObj.messagee" (ngModelChange)="sample(sendObj.messagee)"
                class="form-control" style="height: 100px;" placeholder="Enter Message here"></textarea>
            </div>

          </div>
          <div *ngIf="unicodetext" class="form-group row" style="margin-bottom: 0.2rem;">
            <!-- <label style="padding-right: 0px;" class="col-3 col-form-label" id="label" for="text-input">Description
            <span style="color: red">*</span>
          </label> -->
            <div class="col-12" style="display: inherit;">
              <textarea [disabled]="textareaDisable" name="textarea-input" [(ngModel)]="sendObj.messagee" (ngModelChange)="sampleUni(sendObj.messagee)"
                class="form-control" style="height: 100px;" placeholder="Enter Message here"></textarea>
            </div>

          </div>
          <div class="row">
            <div class="col-md-6" style="text-align: right;">
              <label for="text-input" style="text-align:right;font-weight:600;">Length:</label>
              <label style="margin-left: 6px;">{{sendObj.length}}</label>
            </div>
            <div class="col-md-6" style="text-align: left;">
              <label for="text-input" style="font-weight:600;">Count:</label>
              <label style="margin-left: 6px;">{{sendObj.count}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sk-double-bounce spinnerStyle sk_double_B" *ngIf="spinner_canavas">
      <div class="sk-child sk-double-bounce1 myspinner_cls"></div>
      <div class="sk-child sk-double-bounce2 myspinner_cls"></div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
<!-- /.modal -->