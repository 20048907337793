import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { CommonModule } from '@angular/common';
import { Http, HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/primeng';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CheckboxModule } from 'primeng/checkbox';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';
import { SignupComponent } from './views/signup/signup.component';
import { ForgetComponent } from './views/forget/forget.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
// Tabs Component
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastModule } from 'primeng/toast';
import { TreeModule } from 'primeng/tree';
import { QuillModule } from 'ngx-quill';
const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { TreeTableModule } from 'primeng/treetable';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { TreeNode } from 'primeng/api';
import { CarouselModule } from 'primeng/carousel';
import { CKEditorModule } from 'ng2-ckeditor';
import { DndModule } from 'ng2-dnd';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { BootstrapModalModule, BuiltInOptions } from 'ngx-bootstrap-modal/index';
import { DragulaModule } from 'ng2-dragula';
import { ExcelService } from './shared/services/excel.service';
import { DataTableModule } from 'angular2-datatable';
import { PaginatorModule } from 'primeng/paginator';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CalendarModule as CalendarModule2, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartModule } from 'angular2-highcharts';
import { HighchartsStatic } from 'angular2-highcharts/dist/HighchartsService';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { SmsCountComponent } from './views/journeywidgets/sms-count/sms-count.component';
import { ProductsComponent } from './views/journeywidgets/products/products.component';
import { OffersComponent } from './views/journeywidgets/offers/offers.component';

import { WaitComponent } from './views/journeywidgets/wait/wait.component';
import { DecisionComponent } from './views/journeywidgets/decision/decision.component';
import { ScheduleComponent } from './views/journeywidgets/schedule/schedule.component';
// import { AudiencePlannerComponent } from './views/journeywidgets/audience-planner/audience-planner.component';
import { AudiencePlannerModule } from './views/journeywidgets/audience-planner/audience-planner.module';
import { ViewcontactModule } from './views/admanager/mobile/mobileaddressbook/contactlist/viewcontact/viewcontact.module';
import { AddfundsComponent } from './views/addfunds/addfunds.component';
import { AdminfundsModule } from './views/admin/manageaccounts/adminfunds/adminfunds.module';
// import {
//   IMqttMessage,
//   MqttModule,
//   IMqttServiceOptions
// } from 'ngx-mqtt';
// export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {

//   hostname: 'broker.mqttdashboard.com',
//   port: 8000,
//   path: '/mqtt'

// hostname: '192.168.9.228',
// // hostname: window.location.protocol + '://' + window.location.hostname,
// // port: 8000,
// path: '/mqtt'

// hostname: 'er.citimedia.com',
// protocol: 'wss',
// // port: 9001,
// path: '/mqtt'
// };
export function highchartsFactory() {
  const hc = require('highcharts/highstock');
  const dd = require('highcharts/modules/exporting');
  dd(hc);
  return hc;
}
import { MessageService } from './shared/services/messageoberve.service';
import { AddprofileComponent } from './views/addprofile/addprofile.component';
import { AddnetworkComponent } from './views/addnetwork/addnetwork.component';
import { OathprofileComponent } from './oathprofile/oathprofile.component';
import { MobileverificationComponent } from './views/signup/mobileverification/mobileverification.component';
import { AudiencemodalComponent } from './views/journeywidgets/audiencemodal/audiencemodal.component';
import { RegistermanageroutesmodalComponent } from './views/settings/registermanageroutes/registermanageroutesmodal/registermanageroutesmodal.component';
import { EmailModalModule } from './views/journeywidgets/emailmodal/emailmodal.module';
import { EmailmodalComponent } from './views/journeywidgets/emailmodal/emailmodal.component';
//import { MetroComponent } from './views/metro/metro.component';
// import { DashboardreportsComponent } from './dashboardreports/dashboardreports.component';
// import { EmailreportsComponent } from './emailreports/emailreports.component';
declare var require: any;
@NgModule({
  imports: [
    ViewcontactModule,
    BrowserModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    CommonModule,
    AppHeaderModule,
    AppSidebarModule,
    HttpModule,
    HttpClientModule,
    FormsModule,
    CalendarModule,
    TooltipModule,
    ChartsModule,
    ReactiveFormsModule,
    TreeTableModule,
    CarouselModule,
    CKEditorModule,
    TreeModule,
    NgxDatatableModule,
    DataTableModule,
    QuillModule,
    EmailModalModule,
    ProgressSpinnerModule,
    AdminfundsModule,
    BrowserAnimationsModule,
    PerfectScrollbarModule,
    CheckboxModule,
    PaginatorModule,
    AudiencePlannerModule,
    ToastModule,
    AngularDateTimePickerModule,
    ChartModule,
    DeviceDetectorModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ToastrModule.forRoot(),
    DndModule.forRoot(),
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    DragulaModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    TimepickerModule.forRoot(),
    BootstrapModalModule.forRoot({
      container: document.body,
      builtInOptions: <BuiltInOptions>{
      }
    }),
    CalendarModule2.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    NgbModalModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    SignupComponent,
    ForgetComponent,
    SmsCountComponent,
    ProductsComponent,
    OffersComponent,
    WaitComponent,
    DecisionComponent,
    ScheduleComponent,
    // AudiencePlannerComponent,
    AddfundsComponent,
    AddprofileComponent,
    AddnetworkComponent,
    OathprofileComponent,
    MobileverificationComponent,
    AudiencemodalComponent,
    RegistermanageroutesmodalComponent,
    //MetroComponent,
    // DashboardreportsComponent
    // EmailreportsComponent
  ],
  providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  }, {
    provide: HighchartsStatic,
    useFactory: highchartsFactory
  },
    ExcelService, MessageService],
  bootstrap: [AppComponent],
  entryComponents: [SignupComponent, EmailmodalComponent, AudiencemodalComponent, AddprofileComponent, AddnetworkComponent, ForgetComponent, SmsCountComponent, ProductsComponent, OffersComponent, WaitComponent, DecisionComponent, ScheduleComponent, AddfundsComponent, OathprofileComponent, MobileverificationComponent, RegistermanageroutesmodalComponent]
})
export class AppModule { }
