import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewcontactComponent } from '../viewcontact/viewcontact.component';
import { DataTableModule } from 'angular2-datatable';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { FormsModule } from '@angular/forms';
@NgModule({
  imports: [
    CommonModule,
    DataTableModule,
    PaginationModule,
    ModalModule.forRoot(),
    ButtonsModule.forRoot(),
    FormsModule],
  declarations: [
    ViewcontactComponent
  ],
  entryComponents: [ViewcontactComponent],
  exports: [ViewcontactComponent]
})
export class ViewcontactModule { }
