<div tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class=" modal-dialog modal-lg" style="max-width: 90%;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title pull-left" style="font-size: 15px;font-weight: 600;">
          <span style="padding-left: 5px;">Registration Details</span>
        </div>
        <button type="button" class="close pull-right my_closex" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true" style="font-size: 21px;padding: 5px;">&times;</span>
        </button>
      </div>
      <div class="modal-body" style='padding:0px;overflow: scroll;'>
<div class="card" style="margin-bottom: 0px;">
  <!-- <div class="card-header">
   <div class="row">Preview</div>
  </div> -->
  <div class="card-body">
    <div class="row">
          <div class="col-md-3 col-form-label" for="text-input" style="text-align: center;margin-top:6px;">
            <!-- <span>
                <input type="file" id="file1" name="image" accept="image/*" capture style="display:none"/> <input type="image" src="assets/img/brand/annoucement.png" alt="Avatar" class="imgstyle">
            </span> -->
            <div class="image-upload">
                <label for="file-input">
                  <span class="bottom-left imgtextlabelcolor"> change</span> 
                  <img [src]="url" (error)="setDefaultPic()"/>
                </label>
                <input id="file-input" (change)="onSelectFile($event)" type="file" accept="image/x-png,image/gif,image/jpeg"/>
            </div>
          </div>

          <div class="col-md-8 col-form-label setborderwidth" for="text-input">
            <!-- <div class="form-group" style="margin: 2px !important;">
              <label class="col-md-3 col-form-label textcolor" for="text-input">Full name</label>
              <input type="text" class="col-md-3 form-control" [(ngModel)]="profile.name" placeholder="Enter Campaign Name" style="display:inline !important;height: 30px !important;width: 200px !important;" />
            </div> -->
            <div class="form-group row" style="margin-bottom: 0.2rem;">
                <label class="col-md-3 col-form-label labeltext" for="text-input">Full Name<span style="color: red">*</span></label>
                <div class="col-md-7">
                    <input type="text" id="text-input" name="text-input" class="form-control" placeholder="Enter Name" [(ngModel)]="profile.name">
                </div>
            </div>
             <div class="form-group row" style="margin-bottom: 0.2rem;">
                <label class="col-md-3 col-form-label labeltext" for="text-input">User Name<span style="color: red">*</span></label>
                <div class="col-md-7">
                    <input type="text" id="text-input" name="text-input" class="form-control" placeholder="User Name" [(ngModel)]="profile.username">
                </div>
            </div>
            <div class="form-group row" style="margin-bottom: 0.2rem;">
                <label class="col-md-3 col-form-label labeltext" for="text-input">Email<span style="color: red">*</span></label>
                <div class="col-md-7">
                    <input type="text" id="text-input" name="text-input" class="form-control" placeholder="Enter Email" [(ngModel)]="profile.email">
                </div>
            </div>
            <div class="form-group row" style="margin-bottom: 0.2rem;">
                <label class="col-md-3 col-form-label labeltext" for="text-input">Mobile<span style="color: red">*</span></label>
                <div class="col-md-7">
                    <input type="text" id="text-input" name="text-input" class="form-control" placeholder="Enter Mobile Number" [(ngModel)]="profile.mobilenumber">
                </div>
            </div>
            <div class="form-group row" style="margin-bottom: 0.2rem;">
                <label class="col-md-3 col-form-label labeltext" for="text-input">Country</label>
                <div class="col-md-7">
                    <select class="form-control" [(ngModel)]="profile.country" (change)="changeCountry($event,countries)">
                         <option value="" disabled selected hidden>Select Country </option>>
                        <option *ngFor="let countries of countrieslist" [value]="countries.countryId" [attr.selected]="countries.countryName == profile.country ? true : null">{{countries.countryName}}</option>
                    </select>
                </div>
              </div>
              <div class="form-group row" style="margin-bottom: 0.2rem;">
                  <label class="col-md-3 col-form-label labeltext" for="text-input">State</label>
                  <div class="col-md-7">
                      <select class="form-control" [(ngModel)]="profile.state" (change)="changeState($event.target.value)">
                            <option value="" disabled selected hidden>Select State </option>
                          <option *ngFor="let states of stateslist" [value]="states.stateId">{{states.stateName}}</option>
                      </select>
                  </div>
                </div>
                <!--<div class="form-group row" style="margin-bottom: 0.2rem;">
                    <label class="col-md-3 col-form-label labeltext" for="text-input">City</label>
                    <div class="col-md-9">
                        <select class="form-control" [(ngModel)]="profile.city">
                             <option value="" disabled selected hidden>Select City </option>
                             <option *ngFor="let cities of citylist" [value]="cities.citiName">{{cities.citiName}}</option>
                        </select>
                    </div>
                  </div>-->
                  <div class="form-group row" style="margin-bottom: 0.2rem;">
                    <label class="col-md-3 col-form-label labeltext" for="text-input">City</label>
                    <div class="col-md-7">
                        <select class="form-control" [(ngModel)]="profile.city" (change)="changeCity($event.target.value)">
                             <option value="" disabled selected hidden>Select City </option>
                             <option *ngFor="let cities of citylist" [value]="cities.citiId">{{cities.citiName}}</option>
                        </select>
                    </div>
                  </div>
                  <div class="form-group row" style="margin-bottom: 0.2rem;" >  
                      <label class="col-md-3 col-form-label labeltext"  id="label" for="text-input">Address</label>
                      <div class="col-md-7" style="display: inherit;">
                        <textarea id="textarea-input" name="textarea-input" [(ngModel)]="profile.address" class="form-control" style="height:100px"  placeholder="Enter Address"></textarea>
                      </div> 
                 </div>
                 <div class="form-group row" style="margin-bottom: 0.2rem;">
                   <label class="col-md-3 col-form-label labeltext" for="text-input" >Zip Code</label>
                   <div class="col-md-7">
                     <input type="text" [(ngModel)]="profile.zipcode" class="form-control" placeholder="Enter Zip Code">
                   </div>
                 </div>
                 <div class="form-group row" style="margin-bottom: 0.2rem;">
                    <label class="col-md-3 col-form-label labeltext" for="text-input">Company</label>
                    <div class="col-md-7">
                        <input type="text" id="text-input" name="text-input" class="form-control" placeholder="Enter Company Name" [(ngModel)]="profile.company">
                      </div>
                </div>
                <div class="form-group row" style="margin-bottom: 0.2rem;">
                    <label class="col-md-3 col-form-label labeltext" for="text-input">Industry Type</label>
                    <div class="col-md-7">
                        <select class="form-control" [(ngModel)]="profile.industrytype">
                             <option value="" disabled selected hidden></option>
                            <option *ngFor="let industrytype of industriestype" [value]="industrytype">{{industrytype}}</option>
                        </select>
                    </div>
                  </div>
                  <div class="form-group row" style="margin-bottom: 0.2rem;">
                    <label class="col-md-3 col-form-label labeltext" for="text-input">Company Url</label>
                    <div class="col-md-7">
                        <input type="text" id="text-input" name="text-input" class="form-control" placeholder="Enter Company Name" [(ngModel)]="profile.companyurl">
                      </div>
                </div>
                  <div class="form-group row" style="margin-bottom: 0.2rem;" >  
                    <label style="padding-right: 0px;" class="col-md-3 col-form-label"  id="label" for="text-input"></label>
                    <div class="col-md-7" style="display: inherit;">
                        <span><button type="button" class="btn  btn-primary" style="border: none;background: #e4e7ea;box-shadow: none;color: green !important;" (click)="updateProfile(profile)"><i style="padding-right: 3px;" class="fa fa-pencil" aria-hidden="true"></i>Register</button></span>
                    </div> 
               </div>
          </div>

          <!--<div class="col-md-4">
           <h5> Deactivate your account</h5>
            Deactivating your account will disable your Profile and remove your name 
            and photo from most things that you've citimedia
            <a (click)="deactiveAccount()" style="color:skyblue">Deactivate Account</a>
            <a style="color:skyblue"><i (click)="deactiveAccount()"><u>Deactivate Account</u></i></a>

          </div>-->
    </div>
   
  </div> 
</div>
</div>
</div>
</div>

</div>
<p-progressSpinner class='prospinner' *ngIf="profileloading" [style]="{width: '35px', height: '35px'}" strokeWidth="4" animationDuration=".5s"></p-progressSpinner>