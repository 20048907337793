import { Injectable } from '@angular/core';
import { Http, Headers, URLSearchParams, Jsonp } from '@angular/http';
@Injectable({
  providedIn: 'root'
})
export class CitiservicesService {
  strdata: any;
  dataarray: any = [];
  datetimeArray: any = [];
  groupArray: any;
  setsArray: any = [];
  dataSetsArray: any = [];
  array: any = [];
  templateIdArray: any;
  constructor(private http: Http) { }

  getSourceId(evt) {
    // console.log(evt)
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    // console.log((<any>window).citiUrl + 'campaigns/sms/registrations?route='+evt)
    return this.http.get((<any>window).citiUrl + 'campaigns/sms/registrations?route=' + evt + '&state=Approved', { headers: headers });

  }

  sendCampaign(sendObj, data, datesetsArray) {
    this.datetimeArray = [];
    this.groupArray = [];
    this.setsArray = [];
    // console.log(sendObj)
    // console.log(data)
    // console.log(datesetsArray)
    // console.log(sendObj.fileObj)
    // console.log(JSON.stringify(sendObj.fileObj))

    if (data) {
      for (let i = 0; i < data.length; i++) {
        this.groupArray.push(data[i].groupId);
      }
    } else {
      this.groupArray = [];
    }

    if (datesetsArray) {
      for (let i = 0; i < datesetsArray.length; i++) {
        this.setsArray.push(datesetsArray[i].item.datasetid);
        // console.log('.....', this.setsArray);
      }
    } else {
      this.setsArray = [];
    }

    if (sendObj.tempId) {
      this.templateIdArray = sendObj.tempId;
    } else {
      this.templateIdArray = '';
    }
    if (sendObj.phonenumber) {
      this.dataarray = [];
      // console.log(sendObj.phonenumber);
      // this.dataarray.push(sendObj.phonenumber)
      // console.log(this.dataarray)
      // console.log("contacts")
      // this.groupsListArray = [];
      this.strdata = sendObj.phonenumber;
      this.dataarray = this.strdata.split(',');
      // console.log(this.dataarray);
      // console.log(this.dataarray)
      //  var newArr = JSON.parse("[" + this.dataarray.join() + "]");
      // console.log(this.dataarray);  //string
      // console.log(newArr);  //number
    } else {
      this.dataarray = [];
    }
    if (sendObj.datetime) {
      this.datetimeArray.push(sendObj.datetime);
      sendObj.isScheduled = 'true';
    } else {
      this.datetimeArray = [];
      sendObj.isScheduled = 'false';
    }
    // console.log(this.datetimeArray)
    // console.log(this.setsArray)
    //  var fd = new URLSearchParams();
    // fd.append('campaignName',"sample")
    // fd.append('message',"fsdfsd")
    // fd.append('length',"3")
    // fd.append('count',"1")
    // fd.append('sms-type',"Promotional")
    // fd.append('language',"english")
    // fd.append('isFlash',"false")
    // fd.append('contactlist',newArr)
    // fd.append('groupidlist',this.dataarray)
    // fd.append('templateId',"1234")
    // fd.append('campaignDescription',"sdfs")
    // fd.append('isScheduled',"false")
    // fd.append('scheduledOn',this.dataarray)
    // fd.append('file',sendObj.fileObj)
    const fd = new FormData();
    if (sendObj.fileObj === undefined) {
      fd.append('campaignName', sendObj.campaignname.trim());
      fd.append('message', sendObj.messagee.trim());
      fd.append('length', sendObj.length);
      fd.append('count', sendObj.count);
      fd.append('sms-type', sendObj.select_Route.trim());
      fd.append('language', sendObj.language.trim());
      fd.append('isFlash', 'false');
      fd.append('contactList', JSON.stringify(this.dataarray));
      fd.append('groupidList', JSON.stringify(this.groupArray));
      fd.append('templateId', this.templateIdArray);
      fd.append('campaignDescription', sendObj.description.trim());
      fd.append('isScheduled', sendObj.isScheduled);
      fd.append('scheduledOn', JSON.stringify(this.datetimeArray));
      fd.append('datasetPlansIds', JSON.stringify(this.setsArray));
    } else {
      fd.append('campaignName', sendObj.campaignname.trim());
      fd.append('message', sendObj.messagee.trim());
      fd.append('length', sendObj.length);
      fd.append('count', sendObj.count);
      fd.append('sms-type', sendObj.select_Route.trim());
      fd.append('language', sendObj.language.trim());
      fd.append('isFlash', 'false');
      fd.append('contactList', JSON.stringify(this.dataarray));
      fd.append('groupidList', JSON.stringify(this.groupArray));
      fd.append('templateId', this.templateIdArray);
      fd.append('campaignDescription', sendObj.description.trim());
      fd.append('isScheduled', sendObj.isScheduled);
      fd.append('scheduledOn', JSON.stringify(this.datetimeArray));
      fd.append('file', sendObj.fileObj);
      fd.append('datasetPlansIds', JSON.stringify(this.setsArray));


    }

    const headers = new Headers();
    // headers.append('Content-Type','application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('sender-id', sendObj.select_Id);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    //  var body =
    //   'campaignName='+sendObj.campaignname+
    //   '&message='+sendObj.messagee+
    //   '&length='+sendObj.length+
    //   '&count='+sendObj.count+
    //   '&sms-type='+sendObj.select_Route+
    //   '&language='+ sendObj.language+
    //   '&isFlash=false'+
    //   '&contactlist='+ JSON.stringify(this.dataarray)+
    //   '&groupidlist='+ JSON.stringify(this.groupArray)+
    //   '&templateId='+
    //   '&campaignDescription='+sendObj.description+
    //   '&isScheduled='+ sendObj.isScheduled+
    //   '&scheduledOn='+ JSON.stringify(this.datetimeArray)+
    //   '&file='+ sendObj.fileObj;
    //  //console.log(body)
    // console.log(fd)
    // console.log(fd.toString())
    return this.http.post((<any>window).citiUrl + 'campaigns/services/sms', fd, { headers: headers });
  }


  getTemplateIds(senderId) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    // console.log((<any>window).citiUrl + 'campaigns/sms/registrations?route=')
    return this.http.get((<any>window).citiUrl + 'campaigns/sms/templates?senderId=' + senderId, { headers: headers });
  }

  getGroups() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    // console.log((<any>window).citiUrl + 'campaigns/sms/registrations?route=')
    return this.http.get((<any>window).citiUrl + 'campaigns/groups?serviceType=sms', { headers: headers });
  }

  getemailGroups() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    // console.log((<any>window).citiUrl + 'campaigns/sms/registrations?route=')
    return this.http.get((<any>window).citiUrl + 'campaigns/groups?serviceType=email', { headers: headers });
  }

  getReports(days, data) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    if (data !== 'social') {
      return this.http.get((<any>window).citiUrl + 'campaigns/info?serviceType=sms&days=' + days, { headers: headers });
    } else {
      return this.http.get((<any>window).citiUrl + 'campaigns/info?serviceType=social&days=' + days, { headers: headers });
    }
  }
  getEmailreports(days, data) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    if (data !== 'social') {
      return this.http.get((<any>window).citiUrl + 'campaigns/info?serviceType=email&days=' + days, { headers: headers });
    } else {
      return this.http.get((<any>window).citiUrl + 'campaigns/info?serviceType=social&days=' + days, { headers: headers });
    }
  }


  individualCampaign(data) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    if (data.campaignId) {
      return this.http.get((<any>window).citiUrl + 'campaigns/' + data.campaignId + '/info', { headers: headers });
    } else {
      return this.http.get((<any>window).citiUrl + 'campaigns/' + data.campaignid + '/info', { headers: headers });
    }
  }

  getSMSReports(data) {
    //console.log(data)
    // console.log(data.campaignId)
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    if (data.campaignId) {
      return this.http.get((<any>window).citiUrl + 'campaigns/' + data.campaignId + '/sms/reports', { headers: headers });
    } else {
      return this.http.get((<any>window).citiUrl + 'campaigns/' + data + '/sms/reports', { headers: headers });
    }
  }
  getEmailReports1(data) {
    // console.log(data);
    // console.log(data.campaignId);
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    if (data.campaignId) {
      return this.http.get((<any>window).citiUrl + 'campaigns/' + data.campaignId + '/email/reports', { headers: headers });
    } else {
      return this.http.get((<any>window).citiUrl + 'campaigns/' + data + '/email/reports', { headers: headers });
    }
  }

  getSMSReports1(data) {
    // console.log(data)
    // console.log(data.campaignId)
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    if (data.campaignId) {
      return this.http.get((<any>window).citiUrl + 'campaigns/' + data.campaignId + '/social/reports', { headers: headers });
    } else {
      return this.http.get((<any>window).citiUrl + 'campaigns/' + data + '/social/reports', { headers: headers });
    }
  }

  // Admin/datasets services for get fields and add fields. start here
  getDatasetFields() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    headers.append('X-Apikey', (<any>window).citikey);
    return this.http.get((<any>window).citiUrl + 'datablocks/fields?', { headers: headers }).map(res => res.json());
  }

  // get categories
  getDatasetCategories() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    headers.append('X-Apikey', (<any>window).citikey);
    return this.http.get((<any>window).citiUrl + 'datablocks/categories', { headers: headers }).map(res => res.json());
  }

  // add dataset field
  addDatasetfield(val) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    headers.append('X-Apikey', (<any>window).citikey);
    // var body = 'fields='+val;
    const addFields = [];
    addFields.push({ 'name': val, 'type': 'string' });
    const urlparams = new URLSearchParams();
    urlparams.append('fields', JSON.stringify(addFields));
    return this.http.post((<any>window).citiUrl + 'datablocks/fields', urlparams, { headers: headers }).map(res => res.json());
  }

  // selected datasets buy with price
  selectedDatasetPrice(data) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    headers.append('X-Apikey', (<any>window).citikey);
    const urlparams = new URLSearchParams();
    urlparams.append('packageName', JSON.stringify(data.packageName));
    urlparams.append('permit', 'private');
    urlparams.append('filter', JSON.stringify(data.geofilter));
    urlparams.append('range', JSON.stringify(data.range));
    return this.http.post((<any>window).citiUrl + '/datablocks/packages', urlparams, { headers: headers }).map(res => res.json());
  }

  // saving dataset
  saveDataset(obj) {
    const headers = new Headers();
    // headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    headers.append('X-Apikey', (<any>window).citikey);
    // console.log(obj);
    const urlparams = new FormData();
    if (obj.permitvisibility === 'public') {
      urlparams.append('name', obj.name);
      urlparams.append('file', obj.file);
      urlparams.append('isFirstRowHeader', 'true');
      urlparams.append('requiredFields', JSON.stringify(obj.fields));
      urlparams.append('permit', obj.permitvisibility);
      urlparams.append('category', obj.categorieyid);
      urlparams.append('pricing', JSON.stringify(obj.dataprice));
    }
    if (obj.permitvisibility === 'private') {
      urlparams.append('name', obj.name);
      urlparams.append('file', obj.file);
      urlparams.append('isFirstRowHeader', 'true');
      urlparams.append('requiredFields', JSON.stringify(obj.fields));
      urlparams.append('permit', obj.permitvisibility);
      urlparams.append('category', obj.categorieyid);
      //  urlparams.append('pricing',JSON.stringify(obj.dataprice));
    }
    return this.http.post((<any>window).citiUrl + 'datablocks/info', urlparams, { headers: headers }).map(res => res.json());
  }

  // campaign Activated and DeActivated service
  activate_de_activated(obj) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    headers.append('X-Apikey', (<any>window).citikey);
    const urlParams = new URLSearchParams();
    urlParams.append('campaignPlannerId', obj.campaignId);
    urlParams.append('state', obj.state);
    return this.http.put((<any>window).citiUrl + 'campaignPlanner/campaignPlannerStateAction', urlParams, { headers: headers })
      .map(res => res.json());
  }

  createCustomDatasets(data) {
    // console.log(data)
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    headers.append('X-Apikey', (<any>window).citikey);
    // // var body = 'fields='+val;
    // const addFields = [];
    const urlparams = new URLSearchParams();
    urlparams.append('cdsName', 'test1');
    urlparams.append('filter', data);
    return this.http.post((<any>window).citiUrl + 'datasets/custom', urlparams, { headers: headers }).map(res => res.json());
  }

  getcountries() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    //headers.append('token', (<any>window).citiToken);
    return this.http.get((<any>window).citiUrl + 'lists/countries', { headers: headers });
  }

  getstates(event) {
    // console.log(event)
    const headers = new Headers();
    headers.append('X-Apikey', (<any>window).citikey);
    //headers.append('X-Citistar-Token', (<any>window).token);
    return this.http.get((<any>window).citiUrl + 'lists/countries/'+ event +'/states', { headers: headers });
  }

  getcities(event) {
    const headers = new Headers();
    headers.append('X-Apikey', (<any>window).citikey);
    //headers.append('X-Citistar-Token', (<any>window).token);
    return this.http.get((<any>window).citiUrl + 'lists/countries/states/' + event +'/cities', { headers: headers });
  }

  getindustrytype() {
    const headers = new Headers();
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).token);
    return this.http.get((<any>window).citiUrl + 'lists/industriestype', { headers: headers });
  }
  // ends here

  getOverallReports() {
    const headers = new Headers();
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    return this.http.get((<any>window).citiUrl + 'campaigns/sms/reports?q=today', { headers: headers });
  }

  getChartReports() {
    const headers = new Headers();
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    return this.http.get((<any>window).citiUrl + 'campaigns/sms/reports?days=7', { headers: headers });
  }
  // email chart related services
  getemailChartReports() {
    const headers = new Headers();
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    return this.http.get((<any>window).citiUrl + 'campaigns/email/reports?days=7', { headers: headers });
  }

  getemailOverallReports() {
    const headers = new Headers();
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    return this.http.get((<any>window).citiUrl + 'campaigns/email/reports?q=today', { headers: headers });
  }
  recenEmailCampaignReports() {
    const headers = new Headers();
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    return this.http.get((<any>window).citiUrl + 'campaigns/email/reports?q=recent', { headers: headers });
  }
  recentEmailCampaignReports() {
    const headers = new Headers();
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    return this.http.get((<any>window).citiUrl + 'campaigns/email/reports?q=recent', { headers: headers });
  }
  // ends here
  recentSMSCampaignReports() {
    const headers = new Headers();
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    return this.http.get((<any>window).citiUrl + 'campaigns/sms/reports?q=recent', { headers: headers });
  }

  logout() {
    const body = 'userName=' + (<any>window).userprofile[0].email;
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    // headers.append('X-Citistar-Token', (<any>window).citiToken);
    // console.log((<any>window).citiToken);
    return this.http.post((<any>window).citiUrl + 'users/logout', body, { headers: headers });
  }

  // journey builder campaign reports
  campaignReports(campaignPlannerId) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    return this.http.get((<any>window).citiUrl + 'campaigns/campaignPlanner/' + campaignPlannerId + '/reports', { headers: headers });
  }

  getManageRoutes() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    return this.http.get((<any>window).citiUrl + 'campaigns/sms/routes?roleName=admin', { headers: headers });
  }

  liveStatusData(data) {
    // console.log(data);
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    return this.http.get((<any>window).citiUrl + 'campaigns/' + data.smsCampaignId + '/sms/status', { headers: headers });
  }

  getDataBlocks(dataBlocksType) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    return this.http.get((<any>window).citiUrl + 'datablocks/info?q=count' + '&' + 'datablockstatus=' + dataBlocksType, { headers: headers });
  }

  // getPaidDataBlocks() {
  //   const headers = new Headers();
  //   headers.append('Content-Type', 'application/x-www-form-urlencoded');
  //   headers.append('X-Apikey', (<any>window).citikey);
  //   headers.append('X-Citistar-Token', (<any>window).citiToken);
  //   return this.http.get((<any>window).citiUrl + 'datablocks/packages', { headers: headers });
  // }

  // create keywordqueries
  createQueries(queryobj, kwsubquery) {
    // console.log(queryobj);
    // console.log(queryobj.queryname);
    // console.log(queryobj.querytype);
    // console.log(JSON.stringify(queryobj.createquery));
    const body = 'queryName=' + queryobj.queryname + '&queryType=' + queryobj.querytype + '&query=' + kwsubquery + '&mentions=' + queryobj.querymentions;
    // console.log(body);
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    return this.http.post((<any>window).citiUrl + 'queries', body, { headers: headers }).map(res => res.json());

  }

  // get queries
  getkwQueries() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    return this.http.get((<any>window).citiUrl + 'queries', { headers: headers });
    // return this.http.get((<any>window).citiUrl + 'queries?' + '&startDate=' + '3/5/2019 ' + '&endDate=' + '3/6/2019' + '&days=' + '2'  , {headers: headers});
  }


  getKeywords() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    return this.http.get((<any>window).citiUrl + 'queries/getKeywords', { headers: headers });

    // return this.http.get((<any>window).citiUrl + 'queries?' + '&startDate=' + '3/5/2019 ' + '&endDate=' + '3/6/2019' + '&days=' + '2'  , {headers: headers});
  }

  postKeywords(keyword) {
    const headers = new Headers();
    const body = 'keyword=' + keyword;
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    return this.http.post((<any>window).citiUrl + 'queries/addKeywords', body, { headers: headers }).map(resp => resp.json());
  }
  getSocialProfiles(data) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    return this.http.get((<any>window).citiUrl + 'users/social/profiles?orgId=' + data, { headers: headers });
  }

  // get all campaign posts
  getAllcampaignPosts() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    return this.http.get((<any>window).citiUrl + 'analytics/campaigns/all/posts', { headers: headers });

    // return this.http.get((<any>window).citiUrl + 'queries?' + '&startDate=' + '3/5/2019 ' + '&endDate=' + '3/6/2019' + '&days=' + '2'  , {headers: headers});
  }
  getAllcampaignPostsBydate(fromdate, todate) {
    //console.log(fromdate);
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    return this.http.get((<any>window).citiUrl + 'analytics/campaigns/all/posts?startdate=' + fromdate + '&enddate=' + todate, { headers: headers });
  }
  getAllcampaignPostsbyDays(days) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    return this.http.get((<any>window).citiUrl + 'analytics/campaigns/all/posts?days=' + days, { headers: headers });
  }
  getAllcampaignPostsbyLast30days() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    return this.http.get((<any>window).citiUrl + 'analytics/campaigns/all/posts?days=30', { headers: headers });
  }
  getschduledcampaignstrue(socialtype) {
    const headers = new Headers();
    const body = 'serviceType=' + socialtype + '&isScheduled=true';
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    return this.http.get((<any>window).citiUrl + 'campaigns/info?' + body, { headers: headers });
  }
  postingRouterequestfromusers(routesvalue, descvalue) {
    console.log(routesvalue);
    console.log(descvalue);
    const fd = new FormData();
    fd.append('route', routesvalue);
    fd.append('description', descvalue);
    const headers = new Headers();
    headers.append('X-Apikey', 'citistar-00e24b550974aa1530f6893ca8fc37');
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    return this.http.post((<any>window).citiUrl + 'users/sms/route/requests', fd, { headers: headers })
  }
  updateadminroute(data, status, i) {
    const val = status;
    const headers = new Headers();
    const body = 'roleName=admin' + '&action=' + val + '&citiUserId=' + i.citiUserId + '&route=' + i.smsRoutes[0] + '&reason=' + 'reason';
    headers.append('X-Apikey', 'citistar-00e24b550974aa1530f6893ca8fc37');
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    return this.http.put((<any>window).citiUrl + 'users/' + data + '/route/requests?', body, { headers: headers });
  }

  getrequestedusersroutes(type, rolename) {
    const headers = new Headers();
    headers.append('X-Apikey', 'citistar-00e24b550974aa1530f6893ca8fc37');
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    return this.http.get((<any>window).citiUrl + 'users/' + type + '/route/requests?', { headers: headers });
  }
}
