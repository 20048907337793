<div class='addProfileContent'>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add Stream</h4>
        <button type="button" class="close pull-right cross-set" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modal-style-profile">
        <div class='row button-adjust'>
           <div class='socialBox' (click)='selectedMedia("twitter")'>
              <div id='twitterButton' class="customSocialButton customSocialButtonTip">
                  <div class="social-button-wrap">
                  <i class="fa fa-twitter font-icon-button" aria-hidden="true"></i>
                  <div class="social-button-caption">Twitter</div>
                  </div>
                </div>
           </div>
           <div class='socialBox'  (click)='selectedMedia("facebook")'>
              <div id='facebookButton' class="customSocialButton ">
                  <div class="social-button-wrap">
                  <i class="fa fa-facebook font-icon-button" aria-hidden="true"></i>
                  <div class="social-button-caption">Facebook</div>
                  </div>
                </div>
           </div>
           <div class='socialBox'  (click)='selectedMedia("linkedin")'>
              <div id='linkedinButton' class="customSocialButton ">
                  <div class="social-button-wrap">
                  <i class="fa fa-linkedin font-icon-button" aria-hidden="true"></i>
                  <div class="social-button-caption">LinkedIn</div>
                  </div>
                </div>
           </div>
           <div class='socialBox'  (click)='selectedMedia("instagram")'>
              <div id='instagramButton' class="customSocialButton ">
                  <div class="social-button-wrap">
                  <i class="fa fa-instagram font-icon-button" aria-hidden="true"></i>
                  <div class="social-button-caption">Instagram</div>
                  </div>
                </div>
           </div>
           <div class='socialBox'  (click)='selectedMedia("foursquare")'>
              <div id='foursquareButton' class="customSocialButton ">
                  <div class="social-button-wrap">
                  <i class="fa fa-foursquare font-icon-button" aria-hidden="true"></i>
                  <div class="social-button-caption">FourSquare</div>
                  </div>
                </div>
           </div>
           <div class='socialBox'  (click)='selectedMedia("pinterest")'>
              <div id='pinterestButton' class="customSocialButton ">
                  <div class="social-button-wrap">
                  <i class="fa fa-pinterest font-icon-button" aria-hidden="true"></i>
                  <div class="social-button-caption">Pinterest</div>
                  </div>
                </div>
           </div>
           <!-- <div class='socialBox'  (click)='selectedMedia("tumblr")'>
              <div id='tumblrButton' class="customSocialButton ">
                  <div class="social-button-wrap">
                  <i class="fa fa-tumblr font-icon-button" aria-hidden="true"></i>
                  <div class="social-button-caption">Tumblr</div>
                  </div>
                </div>
           </div>
           <div class='socialBox'  (click)='selectedMedia("youtube")'>
              <div id='youtubeButton' class="customSocialButton ">
                  <div class="social-button-wrap">
                  <i class="fa fa-youtube font-icon-button" aria-hidden="true"></i>
                  <div class="social-button-caption">Youtube</div>
                  </div>
                </div>
           </div> -->
        </div>
        <div class='row'>
           <div class='streamDetails'>
              <span>
                  <div class='profile-slot'>Add {{convertText(socialMediaSelected)}} Profile</div>
                  <div class='profile-spacing'></div>
                  <div class='profile-slot extended-style'>To allow CitiMedia access to your {{convertText(socialMediaSelected)}} account, you must first give authorization from {{convertText(socialMediaSelected)}}.com</div>
                  <div class='profile-spacing'></div>
                </span>
              <div class='row'>
                <div class='col-lg-6 col-sm-12'>
                    <div class="btn-group btn-group-custom-class" dropdown>
                        <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                                aria-controls="dropdown-basic">
                          {{selectedAccount.userName || 'Select Profile'}}  <span class="caret"></span>
                        </button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                          <li role="menuitem" *ngFor='let profile of allProfiles'><a class="dropdown-item" (click)='mediaAccountSelected(profile)' *ngIf='profile.userName != ""'>{{profile.userName}}</a></li>
                        </ul>
                    </div>
                    <div class="btn-group" dropdown *ngIf='selectedAccount.userName && selectedAccount.socialMediaType === "facebook"'>
                      <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                              aria-controls="dropdown-basic">
                        {{selectedPage.name || 'Select Page'}}  <span class="caret"></span>
                      </button>
                      <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                        <li role="menuitem" *ngFor='let page of FBProfilePages'><a class="dropdown-item" (click)='mediaAccountPageSelected(page)' *ngIf='page.name != ""'>{{page.name}}</a></li>
                      </ul>
                  </div>
                </div>
                <div class='col-lg-6 col-sm-12 profile-option'>
                  <div class='row' *ngIf='socialMediaSelected === "facebook"'>
                    <!-- <div class='col'>
                        <div class="form-check check-style-initial"><input class="form-check-input" id="profile-radio" name="facebookRadio" type="radio"  value="profile" [(ngModel)]="facebookRoleType" (change)="onfbRoleChange()"><label class="form-check-label" for="radio1" > Profile </label></div>
                    </div> -->
                    <!-- <div class='col'>
                        <div class="form-check"><input  class="form-check-input" id="pages-radio" name="facebookRadio" type="radio" value="pages" [(ngModel)]="facebookRoleType" (change)="onfbRoleChange()"><label class="form-check-label" for="radio2"> Pages </label></div>
                    </div> -->
                  </div>
                </div>
              </div>
                 
                  <div  class='row social-row-style'>
                      <div class="card card-social-style" *ngFor='let role of selectedMediaRoles'>
                        <div class="card-body p-0 clearfix" (click)='roleSelected(socialMediaSelected, role)'>
                          <i [id]='socialMediaSelected+"Button"' class="fa fa-{{socialMediaSelected}} p-4 font-2xl mr-3 float-left icon-social-style"></i>
                          <div class="text-muted font-xs font-role-style">{{role}}</div>
                        </div>
                      </div>
                  </div>
                </div>
            
        </div>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="hideModal()">{{closeBtnName}}</button>
      </div> -->
      <div class="sk-double-bounce spinnerStyle" *ngIf='addingProfile' style='position: absolute;top: 40%;z-index: 9999;margin-left: 45%;'>
        <div class="sk-child sk-double-bounce1"></div>
        <div class="sk-child sk-double-bounce2"></div>
      </div>
</div>

