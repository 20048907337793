<div   tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class=" modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title pull-left" style="font-size: 15px;font-weight: 600;">
          <span style="padding-left: 5px;">Forgot Password</span>
        </div>
        <button type="button" class="close pull-right my_closex" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true" style="font-size: 21px;">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="background-color: #f4f4f4;">
        <div class="row">
           <div class="col">
              <div class="input-group mb-2">
                <span style="font-size: 14px;">Enter your Email Address below and we will send you the password</span>
              </div>    
              <div class="input-group mb-2">
                <input type="email" id="email-input"  style="border-radius: 4px;font-size: 13px;" name="email-input" class="form-control" [(ngModel)]="email" placeholder="Enter Email Id">
              </div>
              <!-- <br> -->
              <button type="button"  (click)="forgotPassword()" class="btn btn-default btn-xl signup_btn">Send</button>
          </div>
        </div>
      </div>

      <!--<div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="myModal.hide()">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>-->
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

