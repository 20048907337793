import { Component, OnInit, AfterContentInit, OnChanges } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { AddprofileComponent } from '../addprofile/addprofile.component';
import { AddnetworkComponent } from '../addnetwork/addnetwork.component';
import { ToastrService } from 'ngx-toastr';
import { Http, Response, Headers} from '@angular/http';
import { Router, Event, NavigationEnd } from '@angular/router';
import 'rxjs/add/operator/map';
import { SocialMediaServiceService } from '../../providers/social-media-service/social-media-service.service';

// import { DialogService, BuiltInOptions } from 'ngx-bootstrap-modal/index';
declare var $: any;
@Component({
  selector: 'app-socialmonitor',
  templateUrl: './socialmonitor.component.html',
  styleUrls: ['./socialmonitor.component.scss']
})
export class SocialmonitorComponent implements OnInit, AfterContentInit, OnChanges {
  socialtabs: any = [];
  bsModalRef: BsModalRef;
  tabDisabled: any = false;
  showAddTab: any = false;
  titleEdit = false;
  savedId = 'Home-1';
  editingTab = '';
  selectedTabActive = {
    tabName: 'Home-tab',
    index: 0
  };
  facebookPageId: any;
windowHeight = screen.height;
  constructor(private modalService: BsModalService, private toastr: ToastrService, private http: Http, public socialService: SocialMediaServiceService) {
    this.socialtabs = (<any>window).socialMonitorTabs;
    this.ngOnChanges();
    console.log('(<any>window).socialMonitorTabs', (<any>window).socialMonitorTabs);
    // this.getsavedWidgets();

   }

  getsavedWidgets() {
    for (let i = 0; i < (<any>window).socialMonitorTabs.length; i++) {
      if ((<any>window).socialMonitorTabs[i].active === true) {
        for (let j = 0; j < (<any>window).socialMonitorTabs[i].widgets.length; j++) {
          if (this.facebookPageId === undefined) {
            console.log(this.selectedTabActive);
            console.log('///////////', (<any>window).socialMonitorTabs[i].widgets[j].id);
            this.facebookPageId = (<any>window).socialMonitorTabs[i].widgets[j].id;
          } else {
            console.log('///////////2', (<any>window).socialMonitorTabs[i].widgets[j].id);
            this.facebookPageId = (<any>window).socialMonitorTabs[i].widgets[j].id;
          }
        }

      }

    }
  }

  ngOnChanges() {
    // console.log('changes detected', this.socialtabs.length);
    // this.selectedTabActive = {
    //   tabName: this.socialtabs[this.socialtabs.length - 1].name,
    //   index: this.socialtabs.length - 1
    // };
    // console.log(this.selectedTabActive);
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.showAddTab = true;
    }, 3000);
  }
  ngOnInit() {
    // this.tabDisabled = true;

    // setTimeout(() => {
    //   this.showAddTab = true;
    // }, 500);
    console.log('ngonInit');
    $(() => {
      const tabs = $( '#my-tabs' ).tabs();
      tabs.find( '.ui-tabs-nav' ).sortable({
             axis: 'x',
             stop: () => {
                tabs.tabs( 'refresh' );
            },
            select: (event, ui) => {
                alert('PRESSED TAB!');
            }
       });
       $('.tab').on('dblclick', () => {
           $(this).find('input').toggle().val($(this).find('a').html()).focus();
           $(this).find('a').toggle();
       });

      $('.tab').on('keydown blur dblclick', 'input', (e) => {
          if (e.type === 'keydown') {
             if (e.which === 13) {
                 $(this).toggle();
                 $(this).siblings('a').toggle().html($(this).val());
             }
             if (e.which === 38 || e.which === 40 || e.which === 37 || e.which === 39) {
                 e.stopPropagation();
             }
          } else if (e.type === 'focusout') {
              if ($(this).css('display') === 'inline-block') {
                  $(this).toggle();
                  $(this).siblings('a').toggle().html($(this).val());
              }
          } else {
              e.stopPropagation();
          }
      });
  });

  const classname = document.getElementsByClassName('ng-star-inserted');

  const myFunction = (event) => {
    console.log(event);
    console.log('id before enter', event.target.id);
    // this.titleEdit = true;
    if (event.target.id !== '' && event.target.id !== undefined && event.target.id !== null) {
      const strId =  event.target.id.replace('text', 'input');
      (<HTMLInputElement>document.getElementById(strId)).style.display = '-webkit-inline-box';
      (<HTMLInputElement>document.getElementById(event.target.id)).style.display = 'none';
    }
  };

  const node = document.getElementsByClassName('ng-star-inserted')[0];
  if (node) {
    node.addEventListener('keydown', (event: any) => {
      console.log(event);
      const ev: any = event;
        if (event.code === 'Enter') {
          console.log('id after enter', event.target.id);
          if (event.target.id !== '' && event.target.id !== undefined && event.target.id !== null) {
            const parts = event.target.id.split('-');
            const answer = parts[parts.length - 1];
            console.log('index', answer);
           // const name = this.socialtabs[answer].name;
           const saveValue = (<HTMLInputElement>document.getElementById(event.target.id)).value;
           console.log('edited value', saveValue);
           this.socialtabs[answer].title = saveValue;
           // this.titleEdit = false;
           const strId =  event.target.id.replace('text', 'input');
           (<HTMLInputElement>document.getElementById(strId)).style.display = 'none';
           const txtId =  event.target.id.replace('input', 'text');
           (<HTMLInputElement>document.getElementById(txtId)).style.display = '-webkit-inline-box';
           event.preventDefault();
           // Do more work
          }

        }
    });
  }


  for ( let i = 0; i < classname.length; i++) {
    classname[i].addEventListener('dblclick', myFunction, false);
  }

  /** Code to get id from socialMonitorTabs to get facebook timeline**/


     const tabClass: any = document.getElementsByClassName('social_class');
     console.log(tabClass);
     console.log(this.windowHeight);
     for (let i = 0; i < tabClass.length; i++) {
      tabClass[i].style.height = this.windowHeight +  '!important';
     }
    this.socialService.getSocialWidgets((res) => {
      console.log('widgets data', res);
      this.socialtabs = res;
    });
  }

  /* Using BsModalRef*/

  openAddProfile() {
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
    const initialState = {
      selectedTabData: this.selectedTabActive,
      title: 'Social Profile Modal'
    };
    this.socialService.userProfiles((<any>window).userprofile[0].orgId).subscribe( profiles => {
      console.log(profiles);
      console.log((<any>window).userprofile[0].orgId);
     if (profiles.response) {
       console.log(profiles.response);
       (<any>window).citiUserSocialProfiles = profiles.response;
       this.bsModalRef = this.modalService.show(AddprofileComponent, Object.assign({initialState}, { backdrop: 'static' }, { class: 'gray modal-lg modal-lg-custom' }));
       this.bsModalRef.content.closeBtnName = 'Close';
       this.bsModalRef.content.onClose = (myData) => {
         console.log('Model Closed', myData);
         // this.facebookPageId = myData.pageId;
       };
    }
   });


  }

  addTab(event) {
    this.showAddTab = false;
    console.log(event);
    console.log('addingTab');
    const tabName = 'tab-' + this.socialtabs.length;
    const newTab = {
                    title: tabName,
                    name: tabName + ' - tab',
                    // content: 'this is ' + tabName + ' content',
                    content: '',
                    maintab: true,
                    disabled: false,
                    removable: true,
                    active: true,
                    widgets: []
                  };
    (<any>window).socialMonitorTabs.push(newTab);
    this.showSuccess('Tab with name ' + tabName + ' added successfully.') ;
    this.socialService.SaveSocialWidgets((<any>window).socialMonitorTabs);
    console.log(this.socialtabs);
    setTimeout(() => {
      this.showAddTab = true;
    }, 1000);
  }

  activeTab(tabz, event, index) {
    console.log('selected' + index, tabz);
    tabz.active = true;
    this.selectedTabActive = {
      tabName: tabz.name,
      index: index
    };
    console.log(this.selectedTabActive);
    // this.getsavedWidgets();
  }

  dbclicked() {
    console.log('double clicked');
  }

  singleClick() {
    console.log('single clicked');
  }

  addTab2(event) {
    this.showAddTab = false;
    console.log(event);
    console.log('addingTab');
    const tabName = 'tab-' + this.socialtabs.length;
    const newTab = {
                    title: tabName,
                    name: 'list',
                    content: 'this is ' + tabName + ' content',
                    maintab: true,
                    disabled: false,
                    removable: false,
                    active: true
                  };
    (<any>window).socialMonitorTabs.push(newTab);
    console.log(this.socialtabs);
    setTimeout(() => {
      this.showAddTab = true;
    }, 500);
  }

  removeTabHandler(tab: any): void {
    this.socialtabs.splice(this.socialtabs.indexOf(tab), 1);
    console.log('Remove Tab handler');
    (<any>window).socialMonitorTabs = this.socialtabs;
    this.socialService.SaveSocialWidgets((<any>window).socialMonitorTabs);
  }

  AddProfile() {
    const initialState1 = {
      list: [
        'Open a modal with component',
        'Pass your data',
        'Do something else',
      ],
      title: 'Modal with component'
    };

    this.socialService.userProfiles((<any>window).userprofile[0].orgId).subscribe( profiles => {
      console.log(profiles);
      console.log((<any>window).userprofile[0].orgId);
     if (profiles.response) {
       console.log(profiles.response);
       (<any>window).citiUserSocialProfiles = profiles.response;
       this.bsModalRef = this.modalService.show(AddnetworkComponent, Object.assign({initialState1}, { backdrop: 'static' }, { class: 'gray modal-lg' }));
       this.bsModalRef.content.closeBtnName = 'Close';
       this.bsModalRef.content.onClose = (myData) => {
         console.log('Model Closed', myData);
         // this.facebookPageId = myData.pageId;
       };
    }
   });

  }

  showSuccess(data) {
    this.toastr.success(data, '', {
      timeOut: 1000,
      positionClass: 'toast-bottom-right'
    });
  }

  showError(data) {
    this.toastr.error(data, '', {
      timeOut: 1000,
      positionClass: 'toast-bottom-right'
    });
  }

  closeWidget(socialWidget, index) {
   console.log('socialWidget', socialWidget);
   console.log('index is ' + index );
   for (let k = 0; k < this.socialtabs[this.selectedTabActive.index].widgets.length; k++) {
     if (this.socialtabs[this.selectedTabActive.index].widgets[k].socialMediaType === socialWidget.socialMediaType &&
        this.socialtabs[this.selectedTabActive.index].widgets[k].role === socialWidget.role) {
          this.socialtabs[this.selectedTabActive.index].widgets.splice(k, 1);
          (<any>window).socialMonitorTabs = this.socialtabs;
          this.socialService.SaveSocialWidgets((<any>window).socialMonitorTabs);

        }
   }

  }


  // showTab(tabId) {
  //   console.log(tabId);
  //   document.getElementById(this.savedId).style.display = 'none';
  //   setTimeout(() => {
  //     document.getElementById(tabId).style.display = 'block';
  //     this.savedId = tabId;
  //   }, 300);

  // }

  /* Using DialogService*/

  // openAddProfile() {
  //   const obj = {name: 'modal'};
  //   this.dialogService.addDialog(AddprofileComponent, { obj }, { backdrop: 'static' })
  //   .subscribe((data: any) => {
  //       console.log(data);
  //       if (data.message === undefined) {

  //       } else {

  //       }


  //   });
  // }

}
