import { Component, OnInit, Input } from '@angular/core';
import { DialogComponent } from 'ngx-bootstrap-modal/index';
import { DialogService } from 'ngx-bootstrap-modal/components/dialog.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
// export interface ConfirmModel {
//   title: string;
//   message: string;
// }
@Component({
  selector: 'app-registermanageroutesmodal',
  templateUrl: './registermanageroutesmodal.component.html',
  styleUrls: ['./registermanageroutesmodal.component.scss']
})

export class RegistermanageroutesmodalComponent implements OnInit {
  title: string;
  onClose: any;
  message: string;
  route_description: any;
  routes_status: any = [];
  route_status: any;
  initialState = {
    name1: '', name2: ''
  };
  @Input() name: string;
  somedata: any;

  constructor(public bsModalRef: BsModalRef, private toastr: ToastrService) {
    // super(dialogService);
    // this.routes_status = [{ name: 'Active' }, { name: 'InActive' }];
  }

  hideModal() {
    this.bsModalRef.hide();
  }

  ngOnInit() {
    // this.route_status = 'Active';
    this.route_status = this.name;
  }

  // changeroute(event) {
  //   if ((event === 'InActive') && (this.name === 'Active')) {
  //     this.showError('Already Status Is InActive ');
  //     // this.name = 'Active';
  //   } else {

  //   }
  //   if ((this.name === 'InActive') && (event === 'Active')) {
  //     this.showError('Already Status Is Active ');
  //     // this.name = 'InActive';
  //   } else {

  //   }
  // }

  submitroute() {
    this.initialState.name1 = this.route_status;
    this.initialState.name2 = this.route_description;
    this.bsModalRef.hide();
    this.onClose(this.initialState);
  }

  showSuccess(data) {
    if (this.toastr.currentlyActive === 0) {
      this.toastr.success(data, '', {
        timeOut: 1500,
        positionClass: 'toast-bottom-right'
      });
    }
  }

  showError(data) {
    if (this.toastr.currentlyActive === 0) {
      this.toastr.error(data, '', {
        timeOut: 1500,
        positionClass: 'toast-bottom-right'
      });
    }
  }
}
