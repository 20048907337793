import { HttpUrlEncodingCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';

// @Injectable({ 
//     providedIn: 'root' 
// })

 export class CustomEncoder extends HttpUrlEncodingCodec { 
     encodeKey(key: string): string { 
         return encodeURIComponent(key); 
        } 
    encodeValue(value: string): string {
             return encodeURIComponent(value);
             
            }
         }