import { Component, OnInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DialogComponent } from 'ngx-bootstrap-modal/index';
import { DialogService } from 'ngx-bootstrap-modal/components/dialog.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Http, Response, Headers} from '@angular/http';
import { Router, Event, NavigationEnd } from '@angular/router';
import 'rxjs/add/operator/map';
import { ToastrService } from 'ngx-toastr';

export interface ConfirmModel {
}

@Component({
  selector: 'app-addfunds',
  templateUrl: './addfunds.component.html',
  styleUrls: ['./addfunds.component.scss']
})

export class AddfundsComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel, OnInit {
  public addFundsData = {id: (<any>window).userprofile[0].orgId,
                          name: (<any>window).userprofile[0].fullName,
                          amount: 1,
                          country: (<any>window).userprofile[0].country,
                          state: (<any>window).userprofile[0].state,
                          city: (<any>window).userprofile[0].city,
                          address: (<any>window).userprofile[0].address,
                          mobileNumber: (<any>window).userprofile[0].mobileNumber,
                          zipCode: (<any>window).userprofile[0].pinCode,
                          email: (<any>window).userprofile[0].email,
                          currency: (<any>window).userprofile[0].currency};
  addFundsForm: FormGroup;
  randomNumber: any;
  paymentUrl: any;
  siteHostName = window.location.hostname;
  loggingin = false;
  constructor(dialogService: DialogService, private http: Http, private toastr: ToastrService, private route: Router) {
    super(dialogService);
    this.paymentUrl = (<any>window).citiPaymentUrl;
   }

  ngOnInit() {
    console.log('.............');
    this.ramdomIdGenaration((data) => {
      this.randomNumber = data.key;
      console.log('random', this.randomNumber);
    });
    this.addFundsForm = new FormGroup ({
      email: new FormControl('',
      [Validators.required,
      Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]),
      amount: new FormControl('', [Validators.required, Validators.minLength(1)]),
      name: new FormControl('', [Validators.required, Validators.minLength(3)]),
      country: new FormControl('', [Validators.required, Validators.minLength(3)]),
      state: new FormControl('', [Validators.required, Validators.minLength(3)]),
      city: new FormControl('', [Validators.required, Validators.minLength(3)]),
      address: new FormControl('', Validators.required),
      mobileNumber: new FormControl(''),
      zipCode: new FormControl('', Validators.required)
  });

  this.route.events.subscribe((event: Event) => {

    if (event instanceof NavigationEnd) {
      // const closeobj: any = {
      //   message: undefined
      // };
      // this.close(closeobj);
      this.close();
    }

  });
  }

  closeModal() {
    console.log(',,,,,,,,,,,,,,');
    setTimeout( () => {
      localStorage.removeItem('tabs');
      localStorage.removeItem('font_awesome');
      localStorage.removeItem('citiToken');
      localStorage.removeItem('userprofile');
      localStorage.removeItem('walletDetails');
      localStorage.removeItem('fbCredentials');
      localStorage.removeItem('twCredentials');
      localStorage.removeItem('instaCredentials');
      localStorage.removeItem('pinterestCredentials');
      localStorage.removeItem('linkedinCredentials');
      localStorage.removeItem('foursquareCredentials');
      localStorage.removeItem('tumblrCredentials');
      localStorage.removeItem('youtubeCredentials');
     }, 500);
    const resObj: any = 'modal closed';
    this.close(resObj);
  }

  addFundsToUser() {
    this.loggingin = true;
    const tabs = JSON.stringify((<any>window).tabs);
    const font_awesome = JSON.stringify((<any>window).font_awesome);
    const citiToken = JSON.stringify((<any>window).citiToken);
    const userprofile = JSON.stringify((<any>window).userprofile);
    const walletDetails = JSON.stringify((<any>window).walletDetails);
    const fbCredentials = JSON.stringify((<any>window).fbCredentials);
    const twCredentials = JSON.stringify((<any>window).twCredentials);
    const instaCredentials = JSON.stringify((<any>window).instaCredentials);
    const pinterestCredentials = JSON.stringify((<any>window).pinterestCredentials);
    const linkedinCredentials = JSON.stringify((<any>window).linkedinCredentials);
    const foursquareCredentials = JSON.stringify((<any>window).foursquareCredentials);
    const tumblrCredentials = JSON.stringify((<any>window).tumblrCredentials);
    const youtubeCredentials = JSON.stringify((<any>window).youtubeCredentials);

    localStorage.setItem('tabs', tabs);
    localStorage.setItem('font_awesome', font_awesome);
    localStorage.setItem('citiToken', citiToken);
    localStorage.setItem('userprofile', userprofile);
    localStorage.setItem('walletDetails', walletDetails);
    localStorage.setItem('fbCredentials', fbCredentials);
    localStorage.setItem('twCredentials', twCredentials);
    localStorage.setItem('instaCredentials', instaCredentials);
    localStorage.setItem('pinterestCredentials', pinterestCredentials);
    localStorage.setItem('linkedinCredentials', linkedinCredentials);
    localStorage.setItem('foursquareCredentials', foursquareCredentials);
    localStorage.setItem('tumblrCredentials', tumblrCredentials);
    localStorage.setItem('youtubeCredentials', youtubeCredentials);

    if (this.addFundsForm.valid) {
      const formData = this.addFundsForm.value;
      console.log(formData);
      /* Any API call logic via services goes here */
      this.saveOrderDetails(formData, (data) => {
        if (data.status === 'success') {
          console.log('Saved Data', data);
          // this.makePayment(formData, (makePaymentData) => {
            if (data.status === 'success') {
                console.log('make pay data is', data);
                console.log(data.token);
                this.token = data.token;
                let myWindow: any;
                // myWindow.addEventListener('load', (res) => {
                //     console.log('load', res);
                // });
                //   myWindow.addEventListener('loadstart', (res) => {
                //     console.log('load start', res);
                // });
                //   myWindow.addEventListener('loadstop', (res) => {
                //     console.log('load stop', res);
                // });
                myWindow = window.open(this.paymentUrl + 'token?token=' + data.token, '_self');
            } else {
              this.loggingin = false;
            }
          // });
        }
      });
    } else {
      console.log('Form is Invalid...');
      this.loggingin = false;
    }
  }

  ramdomIdGenaration(callback) {
      const headers: Headers = new Headers();
      headers.append('Content-Type', 'application/x-www-form-urlencoded');
      headers.append('X-Apikey', (<any>window).citikey );
      headers.append('X-Citistar-Token', (<any>window).citiToken);
      console.log((<any>window).citiUrl + 'getRandomId');
      this.http.get((<any>window).citiUrl + 'getRandomId', {headers: headers})
      .map(res => res.json())
      .subscribe(data => {
        console.log(data);
        callback(data);

      }, error => {
        console.log(JSON.stringify(error.json()));
      console.log(error);

      });
  }

  saveOrderDetails(payment, callback) {
    // let webString: any;
    // if (this.siteHostName.includes('citimedia')) {
    //   webString = '/web';
    // } else {
    //   webString = '';
    // }
      const body =    'orderId=' + this.randomNumber +
                      '&citiUserId=' + (<any>window).userprofile[0].citiUserId +
                      '&orgId=' + (<any>window).userprofile[0].orgId +
                      '&currency=' + this.addFundsData.currency +
                      '&amount=' + payment.amount +
                      '&language=' + 'en' +
                      '&billingAddress=' + payment.address +
                      '&billingZip=' + payment.zipCode +
                      '&billingCountry=' + payment.country +
                      '&billingTel=' + payment.mobileNumber +
                      '&billingName=' + payment.name +
                      '&billingCity=' + payment.city +
                      '&billingState=' + payment.state +
                      '&billingEmail=' + payment.email +

                      '&deliveryName=' + payment.name +
                      '&deliveryCity=' + payment.city +
                      '&deliveryState=' + payment.state +
                      '&deliveryCountry=' + payment.country +
                      '&deliveryZip=' + payment.zipCode +
                      '&deliveryTel=' + payment.mobileNumber +
                      '&deliveryAddress=' + payment.address +
                      '&deliveryEmail=' + payment.email +
                      '&merchantName=' + 'Citimedia' +
                      '&merchantAddress=' + 'Above Axis Bank, Pydiparru Y Junction,Tanuku' +
                      '&merchantCountry=' + 'India' +
                      '&merchantState=' + 'Andhra Pradesh' +
                      '&merchantZip=' + '534211' +
                      '&isMobile=' + 'false' +
                      '&redirectUrl=' + window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/#/dashboard' +
                      '&cancelUrl=' + (<any>window).citiUrl + 'payments/search';


            console.log(body);
            const orgid = (<any>window).userprofile[0].orgId;
            const headers: Headers = new Headers();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            headers.append('X-Apikey', (<any>window).citikey );
            headers.append('X-Citistar-Token', (<any>window).citiToken);
            // this.http.post((<any>window).citiUrl + 'payments/organisations/' + orgid + '/transactions', body, {headers: headers})
            this.http.post((<any>window).citiUrl + 'payments/orders/info', body, {headers: headers})
            .map(res => res.json())
            .subscribe(data => {
            console.log(data);
            if (data.status === 'success') {
              callback(data);
            } else {
              this.showError(data.message);
              this.loggingin = false;
            }
            }, error => {
              // console.log(JSON.stringify(error.json()));
              console.log(error);
              this.showError(error);
              this.loggingin = false;
            });
        }


makePayment(payment, callback) {
  // let webString: any;
  // if (this.siteHostName.includes('citimedia')) {
  //   webString = '/web';
  // } else {
  //   webString = '';
  // }
const body =  'order_id=' + this.randomNumber +
              '&currency=' + this.addFundsData.currency +
              '&amount=' + payment.amount +
              '&language=' + 'en' +
              '&billing_name=' + payment.name +
              '&billing_address=' + payment.address +
              '&billing_city=' + payment.city +
              '&billing_state=' + payment.state +
              '&billing_zip=' + payment.zipCode +
              '&billing_country=' + payment.country +
              '&billing_tel=' + payment.mobileNumber +
              '&billing_email=' + payment.email +

              '&delivery_name=' + payment.name +
              '&delivery_address=' + payment.address +
              '&delivery_city=' + payment.city +
              '&delivery_state=' + payment.state +
              '&delivery_country=' + payment.country +
              '&delivery_tel=' + payment.mobileNumber +
              '&delivery_zip=' + payment.zipCode +

              '&redirect_url=' + (<any>window).citiUrl + 'creditsMngt/makePayment' +
              '&cancel_url=' + (<any>window).citiUrl + 'payments/makePayment' +

              '&merchant_param1=' + '' +
              '&merchant_param2=' + '' +
              '&merchant_param3=' + '' +
              '&merchant_param4=' + '' +
              '&merchant_param5=' + '';
              const headers: Headers = new Headers();
              headers.append('Content-Type', 'application/x-www-form-urlencoded');
              // headers.append('X-Apikey', (<any>window).citikey );
              // headers.append('X-Citistar-Token', (<any>window).citiToken);
              console.log('body', body);
            this.http.post(this.paymentUrl + 'getOrderId', body, {headers: headers})
            .map(res => res.json())
            .subscribe(data => {
                console.log(data);
                if (data.status === 'success') {
                  callback(data);
                } else {
                  this.showError(data.message);
                }
          }, error => {
              console.log(JSON.stringify(error.json()));
              console.log(error);
              this.showError(error);

        });
      }

    showSuccess(data) {
      this.toastr.success(data, '', {
        timeOut: 1000,
        positionClass: 'toast-bottom-right'
      });
    }
    showError(data) {
      this.toastr.error(data, '', {
        timeOut: 1000,
        positionClass: 'toast-bottom-right'
      });
    }

}

