import { Component, OnInit } from '@angular/core';
import { DialogComponent } from 'ngx-bootstrap-modal/index';
import { DialogService } from 'ngx-bootstrap-modal/components/dialog.service';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationEnd, Event } from '@angular/router';
export interface ConfirmModel {
  email: string;
  mobile: string;
}
@Component({
  selector: 'app-mobileverification',
  templateUrl: './mobileverification.component.html',
  styleUrls: ['./mobileverification.component.scss']
})
export class MobileverificationComponent extends DialogComponent<ConfirmModel, string> implements ConfirmModel, OnInit {
  email: string;
  mobile: string;
  otpNumber: any;
  constructor(dialogService: DialogService, public http: Http, public toastr: ToastrService, public router: Router) {
    super(dialogService);
   }

  ngOnInit() {
    this.router.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {
      this.close();
      }
    });
    console.log(this.email);
     console.log(this.mobile);
  }

  submitBtn(otpNumber) {
    const fd = new FormData();
    // fd.append('email', this.email);
    fd.append('mobile', this.mobile);
    fd.append('verificationCode', this.otpNumber);
    const headers = new Headers();
    console.log(fd);
    // console.log(body)
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    // headers.append('Content-Type','application/x-www-form-urlencoded');
    // console.log(this.currentdate)
    this.http.post((<any>window).citiUrl + 'users/verifications', fd, {headers: headers})
    .map(res => res.json())
    .subscribe(data => {
      // console.log(data)
      if (data.status === 'success') {
      // console.log(data)
      // this.showSuccess(data.message)
      this.close(data);

      } else {
      this.showError(data.message);
      console.log(data);
      this.otpNumber = '';
      }
    }, error => {
      this.otpNumber = '';
      this.showError('Server is busy, Please try again');
      console.log(error);
    });
  }

  closeModal() {
    this.close();
  }

  resendOtp(){
    console.log('resend otp clicked');
    const headers = new Headers();
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    this.http.get((<any>window).citiUrl + 'users/services?mobile='+this.mobile, {headers: headers})
    .map(res => res.json())
    .subscribe(data => {
      console.log(data)
      if (data.status === 'success') {
        this.showSuccess('otp sent successfully!')
      } else {
      this.showError(data.message);
      console.log(data);
      this.otpNumber = '';
      }
    }, error => {
      this.otpNumber = '';
      this.showError('Server is busy, Please try again');
      console.log(error);
    });
  }

  showSuccess(data) {
    if (this.toastr.currentlyActive === 0) {
      this.toastr.success(data, '', {
        timeOut: 1500,
        positionClass: 'toast-bottom-right'
      });
    }
  }


  showError(data) {
    if (this.toastr.currentlyActive === 0) {
      this.toastr.error(data, '', {
        timeOut: 1500,
        positionClass: 'toast-bottom-right'
      });
    }
  }

}
