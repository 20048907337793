import { Component, OnInit } from '@angular/core';
import { Http } from '@angular/http';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { LoginComponent } from '../login/login.component';
import 'rxjs/add/operator/map';
import { Router, Event, NavigationEnd } from '@angular/router';
import { DialogComponent } from 'ngx-bootstrap-modal/index';
import { DialogService } from 'ngx-bootstrap-modal/components/dialog.service';
import { BsModalService } from 'ngx-bootstrap/modal';
export interface ConfirmModel {
  title: string;
  message: string;
}
declare var $: any;
declare var FB: any;
@Component({
  selector: 'app-addnetwork',
  templateUrl: './addnetwork.component.html',
  styleUrls: ['./addnetwork.component.scss'],
  providers: [LoginComponent]
})
export class AddnetworkComponent extends DialogComponent<ConfirmModel, string> implements ConfirmModel, OnInit  {
  twitternotification = true;
  title: string;
  message: string;
  facebook: any;
  instagram: any;
  foursquare: any;
  linkedin: any;
  pinterest: any;
  conditionShow: boolean;
  tumblr: any;
  twitter: any;
  youtube: any;
  facebooknotification = false;
  linkedinnotification = false;
  instagramnotification = false;
  foursquarenotification = false;
  pinterestnotification = false;
  tumblrnotification = false;
  youtubenotification = false;
  userProfiles: any = [];

  constructor(public bsModalRef: BsModalRef, dialogService: DialogService, public networkSocial: LoginComponent, public http: Http, private route: Router) {
    super(dialogService);
    this.twitter = [{ title: 'Citimedia needs permission to access and publish content to Twitter on your behalf. Add your Twitter profile to monitor activity and send tweets.', note: 'Please logout your twitter account before adding new profile otherwise already loggedin account will be added automatically' }];
    this.facebook = [{ title: 'Citimedia needs permission to access and publish content to Facebook on your behalf. To grant permission, you must be an admin for your brand’s Facebook page.', note: ' Please logout your facebook account before adding new profile otherwise already loggedin account will be added automatically' }];
    this.linkedin = [{ title: 'Citimedia needs permission to access and publish content to LinkedIn on your behalf. To grant permission, you must be an admin for your brand’s company page.', note: 'Please logout your linkedin account before adding new profile otherwise already loggedin account will be added automatically' }];
    this.instagram = [{ title: 'Citimedia needs permission to access and publish comments to Instagram on your behalf. Add your Instagram account to manage your profile.', note: 'Please logout your instagram account before adding new profile otherwise already loggedin account will be added automatically' }];
    this.foursquare = [{ title: 'Citimedia needs permission to access and publish content to Foursquare on your behalf. Once this is taken care of, you can choose which pages to connect to Sprout.', note: 'Please logout your foursquare account before adding new profile otherwise already loggedin account will be added automatically' }];
    this.pinterest = [{ title: 'Citimedia needs permission to access and publish content to Pinterest on your behalf. Once this is taken care of, you can choose which pages to connect to Sprout.', note: 'Please logout your pintrest account before adding new profile otherwise already loggedin account will be added automatically' }];
    this.tumblr = [{ title: 'Citimedia needs permission to access and publish content to Tumblr on your behalf. Once this is taken care of, you can choose which pages to connect to Sprout.', note: 'Please logout your tumblr account before adding new profile otherwise already loggedin account will be added automatically' }];
    this.youtube = [{ title: 'Citimedia needs permission to access and publish content to Youtube on your behalf. Once this is taken care of, you can choose which pages to connect to Sprout.', note: 'Please logout your gmail account before adding new profile otherwise already loggedin account will be added automatically' }];
    this.conditionShow = true;
    if ((<any>window).citiUserSocialProfiles !== []) {
      this.userProfiles = (<any>window).citiUserSocialProfiles;
      this.checkIfSocialMediaExists(this.userProfiles);
    }
  }

  checkIfSocialMediaExists(mediaProfiles) {
    for (let i = 0; i < mediaProfiles.length; i++) {
      if (mediaProfiles[i].socialMediaType === 'facebook') {
        (<any>window).socialLoginStatus.facebook = true;
      } else if (mediaProfiles[i].socialMediaType === 'twitter') {
        (<any>window).socialLoginStatus.twitter = true;
      } else if (mediaProfiles[i].socialMediaType === 'instagram') {
        (<any>window).socialLoginStatus.instagram = true;
      } else if (mediaProfiles[i].socialMediaType === 'pinterest') {
        (<any>window).socialLoginStatus.pinterest = true;
      } else if (mediaProfiles[i].socialMediaType === 'foursquare') {
        (<any>window).socialLoginStatus.foursquare = true;
      } else if (mediaProfiles[i].socialMediaType === 'tumblr') {
        (<any>window).socialLoginStatus.tumblr = true;
      } else if (mediaProfiles[i].socialMediaType === 'linkedin') {
        (<any>window).socialLoginStatus.linkedin = true;
      } else if (mediaProfiles[i].socialMediaType === 'youtube') {
        (<any>window).socialLoginStatus.youtube = true;
      } else {
        console.log('Unable to detect social media...');
      }
    }
  }

  ngOnInit() {
    this.twitterfunction();
    $('a').click(function () {
      $('a').removeClass('arrow_box');
      $(this).addClass('arrow_box');
    });

    this.route.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {
        // const closeobj: any = {
        //   message: undefined
        // };
        // this.close(closeobj);
        // this.close();
        this.bsModalRef.hide();
      }

    });
  }

  hideModal() {
    this.bsModalRef.hide();
  }

  twitterfunction() {
    $('#a1').addClass('arrow_box');
    this.twitternotification = true;
    this.facebooknotification = false;
    this.linkedinnotification = false;
    this.instagramnotification = false;
    this.foursquarenotification = false;
    this.pinterestnotification = false;
    this.tumblrnotification = false;
    this.youtubenotification = false;
  }

  facebookfunction() {
    this.facebooknotification = true;
    this.twitternotification = false;
    this.linkedinnotification = false;
    this.instagramnotification = false;
    this.foursquarenotification = false;
    this.pinterestnotification = false;
    this.tumblrnotification = false;
    this.youtubenotification = false;
  }

  linkedinfunction() {
    this.facebooknotification = false;
    this.linkedinnotification = true;
    this.twitternotification = false;
    this.instagramnotification = false;
    this.foursquarenotification = false;
    this.pinterestnotification = false;
    this.tumblrnotification = false;
    this.youtubenotification = false;
  }

  instagramfunction() {
    this.facebooknotification = false;
    this.linkedinnotification = false;
    this.twitternotification = false;
    this.instagramnotification = true;
    this.foursquarenotification = false;
    this.pinterestnotification = false;
    this.tumblrnotification = false;
    this.youtubenotification = false;
  }

  pinterestfunction() {
    this.facebooknotification = false;
    this.linkedinnotification = false;
    this.twitternotification = false;
    this.instagramnotification = false;
    this.foursquarenotification = false;
    this.pinterestnotification = true;
    this.tumblrnotification = false;
    this.youtubenotification = false;
  }

  foursquarefunction() {
    this.facebooknotification = false;
    this.linkedinnotification = false;
    this.twitternotification = false;
    this.instagramnotification = false;
    this.foursquarenotification = true;
    this.pinterestnotification = false;
    this.tumblrnotification = false;
    this.youtubenotification = false;
  }

  tumblrfunction() {
    this.facebooknotification = false;
    this.linkedinnotification = false;
    this.twitternotification = false;
    this.instagramnotification = false;
    this.foursquarenotification = false;
    this.tumblrnotification = true;
    this.pinterestnotification = false;
    this.youtubenotification = false;
  }


  youtubefunction() {
    this.facebooknotification = false;
    this.linkedinnotification = false;
    this.twitternotification = false;
    this.instagramnotification = false;
    this.foursquarenotification = false;
    this.tumblrnotification = false;
    this.youtubenotification = true;
    this.pinterestnotification = false;
  }

  addFacebookNetwork() {
    console.log('accessing facebook');
    const value = JSON.stringify((<any>window).fbCredentials);
    console.log(value);

    // if (value !== '{}') {
    //   console.log('Logged into facebook.');
    // } else {
    //   console.log('Not logged into facebook');
    this.socialLoginFB();
    // }
  }

  loginViaFacebook() {
    // will try to login to fb
    // this.fbLogout();
    FB.login((response) => {
      console.log(FB);
      if (response.authResponse) {
        console.log(response.authResponse);
        (<any>window).fbCredentials = response.authResponse;
        console.log('Welcome!  Fetching your information.... ');
        this.getUserDetailsFb();
      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    }, { scope: 'publish_pages,manage_pages,user_posts,user_photos,user_likes,user_friends,user_gender,business_management,email,public_profile,user_events,user_videos,user_birthday,' });
  }

  getUserDetailsFb() {
    const params = { fields: 'id,name,first_name,gender,location,feed{attachments},posts{id,from,name,picture,story,attachments{url,subattachments,title},to{id,name,username,pic},description,message,comments{id,from,can_comment,like_count,message,likes{id,name},attachment,permalink_url},permalink_url,shares,likes{id,name,pic,link}},friendlists{id,name}' };

    FB.api('/me', 'get', params, (res) => {
      console.log('FB Login Res', res);
      console.log(JSON.stringify(res));
      console.log(res.headers.get('X-App-Usage'));
      console.log(res.headers.get('X-Page-Usage'));
      const fbUserData = { name: (<any>window).userprofile[0].userName, timeLineData: res };
      (<any>window).fbUserData[0].timelines.push(fbUserData);
      console.log('fbuserdata', (<any>window).fbUserData);
      console.log('Good to see you, ' + res.name + '.');
      this.hideModal();
    });
  }

  socialLoginFB() {

    // FB.getLoginStatus(function(response) {
    //   statusChangeCallback(response);
    // });

    // will first check for login status
    if (FB.getLoginStatus() != null) {
      FB.getLoginStatus((response) => {
        console.log(response);
        if (response.status === 'connected') {
          console.log('connected');
          // The user is logged in and has authenticated your
          // app, and response.authResponse supplies
          // the user's ID, a valid access token, a signed
          // request, and the time the access token
          // and signed request each expire.
          const uid = response.authResponse.userID;
          const accessToken = response.authResponse.accessToken;
          (<any>window).fbCredentials = response.authResponse;
          console.log('(<any>window).fbCredentials', (<any>window).fbCredentials);
          this.getUserDetailsFb();

        } else if (response.status === 'authorization_expired') {
          console.log('authorization_expired');
          // The user has signed into your application with
          // Facebook Login but must go through the login flow
          // again to renew data authorization. You might remind
          // the user they've used Facebook, or hide other options
          // to avoid duplicate account creation, but you should
          // collect a user gesture (e.g. click/touch) to launch the
          // login dialog so popup blocking is not triggered.
          this.loginViaFacebook();

        } else if (response.status === 'not_authorized') {
          console.log('not_authorized');
          // The user hasn't authorized your application.  They
          // must click the Login button, or you must call FB.login
          // in response to a user gesture, to launch a login dialog.
          this.loginViaFacebook();
        } else {
          // The user isn't logged in to Facebook. You can launch a
          // login dialog with a user gesture, but the user may have
          // to log in to Facebook before authorizing your application.
          this.loginViaFacebook();
        }
      },
        true);
    } else {
      this.loginViaFacebook();
    }

  }

  fbLogout() {
    try {
      FB.logout((response) => {
        // Person is now logged out
        console.log(response);
      });
    } catch (err) {
      console.log(err);
    }

  }

  postToFacebook() {
    const body = 'Reading JS SDK documentation';

    FB.api('/me/feed', 'post', { message: body }, (response) => {
      if (!response || response.error) {
        alert('Error occured');
      } else {
        alert('Post ID: ' + response.id);
      }
    });
  }

  deletePostFacebook() {
    const postId = '1234567890';
    FB.api(postId, 'delete', function (response) {
      if (!response || response.error) {
        alert('Error occured');
      } else {
        alert('Post was deleted');
      }
    });
  }


  twitterLoginApi() {
    // const headers: Headers = new Headers();
    // headers.append('Content-Type', 'application/x-www-form-urlencoded');
    // this.http.get('http://localhost:3000/auth/twitter', {headers: headers})
    // .map(res => res.json())
    // .subscribe(data => {
    //   console.log(data);

    // }, error => {
    //   console.log(error);

    // });
    this.setLocalStorage();
    window.location.href = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/auth/twitter';
  }

  facebookLoginApi() {
    // const headers: Headers = new Headers();
    // headers.append('Content-Type', 'application/x-www-form-urlencoded');
    // this.http.get('http://localhost:3000/auth/facebook', {headers: headers})
    // .map(res => res.json())
    // .subscribe(data => {
    //   console.log(data);

    // }, error => {
    //   console.log(error);

    // });
    this.setLocalStorage();
    window.location.href = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/auth/facebook';
  }

  instagramLoginApi() {
    this.setLocalStorage();
    window.location.href = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/auth/instagram';
  }


  pinterestLoginApi() {
    this.setLocalStorage();
    window.location.href = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/auth/pinterest';
  }

  linkedinLoginApi() {
    this.setLocalStorage();
    window.location.href = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/auth/linkedin';
  }

  fourSquareLoginApi() {
    this.setLocalStorage();
    window.location.href = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/auth/foursquare';
  }

  tumblrLoginApi() {
    this.setLocalStorage();
    window.location.href = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/auth/tumblr';
  }

  youtubeLoginApi() {
    this.setLocalStorage();
    window.location.href = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/auth/youtube';
  }
  setLocalStorage() {
    const tabs = JSON.stringify((<any>window).tabs);
    const font_awesome = JSON.stringify((<any>window).font_awesome);
    const citiToken = JSON.stringify((<any>window).citiToken);
    const userprofile = JSON.stringify((<any>window).userprofile);
    const walletDetails = JSON.stringify((<any>window).walletDetails);
    const fbCredentials = JSON.stringify((<any>window).fbCredentials);
    const twCredentials = JSON.stringify((<any>window).twCredentials);
    const instaCredentials = JSON.stringify((<any>window).instaCredentials);
    const pinterestCredentials = JSON.stringify((<any>window).pinterestCredentials);
    const linkedinCredentials = JSON.stringify((<any>window).linkedinCredentials);
    const foursquareCredentials = JSON.stringify((<any>window).foursquareCredentials);
    const tumblrCredentials = JSON.stringify((<any>window).tumblrCredentials);
    const youtubeCredentials = JSON.stringify((<any>window).youtubeCredentials);

    localStorage.setItem('tabs', tabs);
    localStorage.setItem('font_awesome', font_awesome);
    localStorage.setItem('citiToken', citiToken);
    localStorage.setItem('userprofile', userprofile);
    localStorage.setItem('walletDetails', walletDetails);
    localStorage.setItem('fbCredentials', fbCredentials);
    localStorage.setItem('twCredentials', twCredentials);
    localStorage.setItem('instaCredentials', instaCredentials);
    localStorage.setItem('pinterestCredentials', pinterestCredentials);
    localStorage.setItem('linkedinCredentials', linkedinCredentials);
    localStorage.setItem('foursquareCredentials', foursquareCredentials);
    localStorage.setItem('tumblrCredentials', tumblrCredentials);
    localStorage.setItem('youtubeCredentials', youtubeCredentials);
  }

  checktokenExistance(socialPlatform) {
    console.log(socialPlatform);
    if (socialPlatform === 'facebook') {
      if ((<any>window).fbCredentials.fbToken) {
        return true;
      } else {
        return false;
      }
    }
    if (socialPlatform === 'twitter') {
      if ((<any>window).twCredentials.twToken) {
        return true;
      } else {
        return false;
      }
    }
    if (socialPlatform === 'instagram') {
      if ((<any>window).instaCredentials.instaccessToken) {
        return true;
      } else {
        return false;
      }
    }
    if (socialPlatform === 'pinterest') {
      if ((<any>window).pinterestCredentials.pinaccessToken) {
        return true;
      } else {
        return false;
      }
    }
    if (socialPlatform === 'linkedin') {
      if ((<any>window).linkedinCredentials.linkdtoken) {
        return true;
      } else {
        return false;
      }
    }
    if (socialPlatform === 'foursquare') {
      if ((<any>window).foursquareCredentials.fqaccesstoken) {
        return true;
      } else {
        return false;
      }
    }
    if (socialPlatform === 'tumblr') {
      if ((<any>window).tumblrCredentials.tblrtoken) {
        return true;
      } else {
        return false;
      }
    }
    if (socialPlatform === 'youtube') {
      if ((<any>window).youtubeCredentials.ytbaccesstoken) {
        return true;
      } else {
        return false;
      }
    }
  }

  checkSocialMediaExistance(socialPlatform) {
    // console.log(socialPlatform);
    if (socialPlatform === 'facebook') {
      if ((<any>window).socialLoginStatus.facebook === true) {
        return true;
      } else {
        return false;
      }
    }
    if (socialPlatform === 'twitter') {
      if ((<any>window).socialLoginStatus.twitter === true) {
        return true;
      } else {
        return false;
      }
    }
    if (socialPlatform === 'instagram') {
      if ((<any>window).socialLoginStatus.instagram === true) {
        return true;
      } else {
        return false;
      }
    }
    if (socialPlatform === 'pinterest') {
      if ((<any>window).socialLoginStatus.pinterest === true) {
        return true;
      } else {
        return false;
      }
    }
    if (socialPlatform === 'linkedin') {
      if ((<any>window).socialLoginStatus.linkedin === true) {
        return true;
      } else {
        return false;
      }
    }
    if (socialPlatform === 'foursquare') {
      if ((<any>window).socialLoginStatus.foursquare === true) {
        return true;
      } else {
        return false;
      }
    }
    if (socialPlatform === 'tumblr') {
      if ((<any>window).socialLoginStatus.tumblr === true) {
        return true;
      } else {
        return false;
      }
    }
    if (socialPlatform === 'youtube') {
      if ((<any>window).socialLoginStatus.youtube === true) {
        return true;
      } else {
        return false;
      }
    }
  }

}
