import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { navItems } from './../../_nav';
import { Router } from '@angular/router';
import { DialogService, BuiltInOptions } from 'ngx-bootstrap-modal/index';
import { AddfundsComponent } from '../../views/addfunds/addfunds.component';
import { Http, Response, Headers} from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import 'rxjs/add/operator/map';
import {AppComponent} from '../../app.component';
import { SocialMediaServiceService } from '../../providers/social-media-service/social-media-service.service';

// import { ChatComponent } from '../../views/communication/chat/chat.component';
@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit, OnChanges {
  public pushNav: any = [];
  public navItems = navItems;
  public sidebarMinimized = true;
  public changes: MutationObserver;
  public element: HTMLElement = document.body;
  public displayaside = false;
  public backupData = 'Home';
  profilePic: any;
  profileName: any;
  isAdmin: any = false;
  public walletbalance: any = 0;
  public userProfile: any = [];
  public screenheight: any;
  public screenwidth: any;
  directionToShift = 'right';
  positionButton = '15px';
  positionUI = '100px';
  notificationData = {count: 0, messages: []};
  emailAddress: any;

  constructor(public router: Router, private dialogService: DialogService, private http: Http, private toastr: ToastrService, private appconf: AppComponent, private socialMedaiService: SocialMediaServiceService) {

    this.backupData = (<any>window).backupStyleData;
    this.screenheight = window.innerHeight;
    this.screenwidth = window.innerWidth;

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });

    this.changes.observe(<Element>this.element, {
      attributes: true
    });
    this.notificationData = (<any>window).notificationCount;
    this.getUsersProfile();

    window.onresize = (event) => {
      //console.log(event);
      this.screenwidth = window.innerWidth;
      //console.log(this.screenwidth);
      if (this.screenwidth < 991) {
        this.directionToShift = 'left';
        this.positionButton = '0px';
        this.positionUI = '0px';
      }
      if (this.screenwidth > 991) {
        this.directionToShift = 'right';
        this.positionButton = '15px';
        this.positionUI = '100px';
      }
    };
  }

  ngOnInit() {
    setTimeout(() => {
      document.getElementById('sideMenu-' + this.backupData).style.backgroundColor = '#3a434d';
      document.getElementById('sideMenu-' + this.backupData).style.color = 'white';
      document.getElementById('sideMenu-' + this.backupData).style.fontWeight = '500';
      document.getElementById('menuicon-' + this.backupData).style.color = '#1cabe6';
    }, 1000);

  }

  ngOnChanges() {

  }

  showAside() {
    this.displayaside = !this.displayaside;
  }

  activeButton(obj) {

    document.getElementById('sideMenu-' + obj).style.backgroundColor = '#3a434d';
    document.getElementById('sideMenu-' + obj).style.color = 'white';
    document.getElementById('sideMenu-' + obj).style.fontWeight = '500';
    document.getElementById('menuicon-' + obj).style.color = '#1cabe6';
    setTimeout(() => {
      if (obj === (<any>window).backupStyleData) {
         // console.log('same root');
      } else {
        document.getElementById('sideMenu-' + (<any>window).backupStyleData).removeAttribute('style');
        document.getElementById('menuicon-' + (<any>window).backupStyleData).removeAttribute('style');
      }
      this.backupData = obj;
      (<any>window).backupStyleData = obj;
      const getStatus = document.getElementById('navbarSupportedContent').classList;
      if (getStatus.contains('show')) {
        document.getElementById('buttonToogler').classList.add('collapsed');
        getStatus.remove('show');
      }
    }, 100);
}

  logout() {
//    this.router.navigate(['/login'], { skipLocationChange: true});
//    (<any>window).tabs = [];
//    (<any>window).backupStyleData = 'Dashboard';
//    (<any>window).font_awesome = [];
//    (<any>window).citiUrl = 'https://api.citimedia.com/';
//    (<any>window).citiToken = '';
//    (<any>window).userprofile = [];
//    (<any>window).walletDetails  = [];
//    (<any>window).tabs = [{
//      title: 'List of Journeys',
//      name: 'list of journeys',
//      content: `journeyList`,
//      maintab: true,
//      disabled: false,
//      removable: false,
//      active: true
//    }];
//    this.logoutsession();
    this.appconf.clearSession();
     setTimeout( () => {
         this.appconf.logout();
     }, 400);
  }

  toogleMenu() {
    //console.log('...');
    const getStatus = document.getElementById('navbarSupportedContent').classList;
    //console.log(getStatus);
    if (getStatus.contains('collapse')) {
      getStatus.remove('collapse');
    } else {
      getStatus.add('collapse');
    }
  }

  clearData(e, n) {
   //('Clearing Notifications..');
   //console.log(n);
   this.notificationData = {count: 0, messages: []};
   (<any>window).notificationCount.count = 0;
   (<any>window).notificationCount.messages = [];
   (<any>window).notifyActiveUser = n.from_name;
   this.showAside();
   this.activeButton('Chat');
   this.router.navigate(['/chat'], { skipLocationChange: true});

   // (<any>window).notifyActiveUser = n.message;
  }

  openFunds() {
    // this.bsModalRef = this.modalService.show(AddfundsComponent, {});
    this.dialogService.addDialog(AddfundsComponent, {}, { backdrop: 'static' })
    .subscribe((data: any) => {
     //console.log(data);
    });
  }

  getUsersProfile() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    return this.http.get((<any>window).citiUrl  + 'users/profile', { headers: headers })
     .map((res: Response) => res.json())
     .subscribe(data => {
      if (data.status === 'success') {
        //console.log('profile data...', data);
        (<any>window).userprofile = this.userProfile = data.response;
        this.profilePic = (<any>window).citiUrl + (<any>window).userprofile[0].profilePic;
        this.profileName = (<any>window).userprofile[0].userName;
        this.isAdmin = (<any>window).userprofile[0].isAdmin;
        this.emailAddress = (<any>window).userprofile[0].email;
       setTimeout(() => {
        this.getUserWalletData();  // getting wallet data;
        this.getSocialProfiles(); // get socailProfiles
       }, 500);
      } else {
        this.showError(data.message);
      }
     }, error => {
       this.showError('Server is busy, Please try again');
     });

  }


  getSocialProfiles() {
    this.socialMedaiService.userProfiles((<any>window).userprofile[0].orgId).subscribe( data => {
      console.log(data);
      //console.log((<any>window).userprofile[0].orgId);
      if (data.status === 'success' && data.response) {

        (<any>window).citiUserSocialProfiles = data.response;
        //console.log('(<any>window).citiUserSocialProfiles', (<any>window).citiUserSocialProfiles );
     } else {
       //console.log('error');
        this.showError(data.message);
     }
   }, error => {
      this.showError('Server is busy, Please try again');
    });
  }


  getData() {
    const body = 'orgId=' + (<any>window).userprofile[0].orgId;
    //console.log('body', body);
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    return this.http.get((<any>window).citiUrl  + 'users/payments?' + body, { headers: headers })
     .map((res: Response) => res.json());
  }

  getUserWalletData() {
    this.getData().subscribe(data => {
      //console.log(data);
      if (data.status === 'success') {

        const totalcount = data.message;
        //console.log('Wallet Balance Check : ' + data.response[0].balance);
        (<any>window).walletDetails = this.walletbalance = data.response;
       // console.log('Wallet:' + this.walletbalance);
        //console.log(data);
       // return;
      } else {
        this.showError(data.message);
      }
     }, error => {
      this.showError('Server is busy, Please try again');
     });
  }

  // Logout Service
  logoutsession() {

 const headers = new Headers();
     headers.append('Content-Type', 'application/x-www-form-urlencoded');
     headers.append('X-Apikey', (<any>window).citikey);
     headers.append('X-Citistar-Token', (<any>window).citiToken);
     //console.log((<any>window).citiToken);
    //console.log((<any>window).userprofile);
    const body = 'userName=' + (<any>window).userprofile[0].email;
   // console.log(body);
    // console.log(localStorage.getItem('EtihadSerUrl') + 'users/loginUser?userName=' + obj.userName + '&password=' + obj.pwd );
    this.http.post((<any>window).citiUrl + 'users/logout', body, {headers: headers})
    .map(res => res.json())
    .subscribe(data => {
     // console.log('*****', data);
      if (data.status === 'success') {
            (<any>window).tabs = [];
            (<any>window).backupStyleData = 'Dashboard';
            (<any>window).font_awesome = [];
            (<any>window).citiUrl = 'https://api.citimedia.com/';
            (<any>window).citiToken = '';
            (<any>window).userprofile = [];
            (<any>window).walletDetails  = [];
            (<any>window).tabs = [{
              title: 'List of Journeys',
              name: 'list of journeys',
              content: `journeyList`,
              maintab: true,
              disabled: false,
              removable: false,
              active: true
            }];
            this.showSuccess('successfully logged out');
        this.router.navigate(['/login'], { skipLocationChange: true});
       (<any>window).tabs = [];
      } else {
        this.showError(data.message);
      }
      }, error => {

      this.showError('Server is busy, Please try again');
   });
  }

  showSuccess(data) {
    if (this.toastr.currentlyActive === 0) {
      this.toastr.success(data, '', {
        timeOut: 2000,
        positionClass: 'toast-bottom-right'
      });
    }
  }

  showError(data) {
    if (this.toastr.currentlyActive === 0) {
      this.toastr.error(data, '', {
        timeOut: 2000,
        positionClass: 'toast-bottom-right'
      });
    }
  }

}
