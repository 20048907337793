import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { DialogComponent } from 'ngx-bootstrap-modal/index';
import { DialogService } from 'ngx-bootstrap-modal/components/dialog.service';
import { EmailComponent } from '../../admanager/email/email.component';
import { EmailsendcampaignsComponent } from '../../admanager/email/emailsendcampaigns/emailsendcampaigns.component';
import { DatePipe } from '@angular/common';
import { DefaultLayoutComponent } from '../../../containers/default-layout/default-layout.component';
import { AppComponent } from '../../../app.component';


export interface AudienceModel {
  title: string;
  message: string;
}

@Component({
  selector: 'app-emailmodal',
  templateUrl: './emailmodal.component.html',
  styleUrls: ['./emailmodal.component.scss'],
  providers: [EmailComponent, EmailsendcampaignsComponent, DatePipe, DefaultLayoutComponent, AppComponent]
})
export class EmailmodalComponent extends DialogComponent<AudienceModel, boolean> implements AudienceModel, OnInit {
  title: string;
  message: any;
  constructor(dialogService: DialogService, private emailsendcampaigns: EmailsendcampaignsComponent, private route: Router) {
    super(dialogService);
  }

  ngOnInit() {
    this.route.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        const myobj: any = {
          'emailtype': ''
        };
        this.close(myobj);
      }

    });
  }

  closeModal() {
    $('.myClick_from_modalBox').click();
    this.close();
  }


}
