import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
// import { Forgetservice } from './firget.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DialogComponent } from 'ngx-bootstrap-modal/index';
import { DialogService } from 'ngx-bootstrap-modal/components/dialog.service';

export interface ConfirmModel {
  title: string;
  message: string;
}


@Component({
  selector: 'app-forget',
  templateUrl: './forget.component.html',
  styleUrls: ['./forget.component.scss']
})
export class ForgetComponent extends DialogComponent<ConfirmModel, string> implements ConfirmModel, OnInit {
  title: string;
  message: string;
  obj: any = {};
  email: any = '';
  constructor(private http: Http, private router: Router, private toastr: ToastrService, dialogService: DialogService) {
    super(dialogService);
  }

  ngOnInit() {

  }

  closeModal() {
    //   console.log(obj);
    //   obj.label = 'sms';
    //  // localStorage.setItem('obj_value' , JSON.stringify(obj));
    this.close();
    // this.smsservice.closeconfirm(this.obj);
  }

  forgotPassword() {
    const emailregex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    if (this.email === '' || this.email === null || this.email === undefined) {
      this.showError('Please Enter Email Address');
    } else if (!this.email.match(emailregex)) {
      this.showError('Please enter valid Email Address');
    } else {
      const headers = new Headers();
      headers.append('Content-Type', 'application/x-www-form-urlencoded');
      headers.append('X-Apikey', (<any>window).citikey);
      const body = 'email=' + this.email;
      // console.log((<any>window).citiUrl + 'users/password='+ body);
      this.http.get((<any>window).citiUrl + 'users/password?' + body, { headers: headers })
        .map(res => res.json())
        .subscribe(data => {
          if (data.status === 'success') {
            // console.log('data success');
            this.email = '';
            this.showSuccess(data.message);
            this.close();
          } else {
            // this.email = '';
            this.showError(data.message);
          }
        }, error => {
          this.email = '';
          this.showError('Server is busy, Please try again');
        });
    }
  }

  showSuccess(data) {
    if (this.toastr.currentlyActive === 0) {
      this.toastr.success(data, '', {
        timeOut: 1500,
        positionClass: 'toast-bottom-right'
      });
    }
  }

  showError(data) {
    if (this.toastr.currentlyActive === 0) {
      // console.log("active toasts = "+ this.toastr.currentlyActive +" In if");
      this.toastr.error(data, '', {
        timeOut: 1500,
        positionClass: 'toast-bottom-right'
      });
    }
  }
}
