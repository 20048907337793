import { Component, OnInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Http, Headers } from '@angular/http';
import { DialogComponent } from 'ngx-bootstrap-modal/index';
import { DialogService } from 'ngx-bootstrap-modal/components/dialog.service';
import { Router, Event, NavigationEnd } from '@angular/router';

export interface ConfirmModel {
  title: string;
  message: string;
}

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss']
})
export class OffersComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel, OnInit {
  title: string;
  message: string;
  offer_val = '';
  worth_val: any;
  percentage_val: any;
  offertypes: any;
  minorder_val: any;
  startdate_val: any;
  enddate_val: any;
  maxamount_val: any;
  obj: any;
  date: Date = new Date();
    settings = {
        bigBanner: true,
        timePicker: true,
        format: 'dd-MM-yyyy h:mm a',
        defaultOpen: false
    };
  percentage_input = true;
  percentage_input1 = true;
  worth_input = false;
  offer_type = 'Percentage';
  constructor(private http: Http, dialogService: DialogService,  private route: Router) {
    super(dialogService);
  }

  ngOnInit() {
    this.route.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        const myobj: any = {
          'offertype': ''
        };
        this.close(myobj);
      }

    });

    // this.display();
    if (this.obj === undefined) {

    } else {
      // let obj_value = JSON.parse(localStorage.getItem('obj_value'));
      // console.log(obj_value);
      if (this.obj.hasOwnProperty('percentage')) {
        this.offer_val = this.obj.offertype;
        this.percentage_val = this.obj.percentage;
        this.minorder_val = this.obj.minimumorder;
        this.startdate_val = this.obj.startdate;
        this.enddate_val = this.obj.enddate;
        this.maxamount_val = this.obj.maxamount;
        this.offer_type = 'Percentage';
        this.percentage_input = true;
        this.percentage_input1 = true;
        this.worth_input = false;
      } else {

        this.offer_val = this.obj.offertype;
        this.worth_val = this.obj.worth;
        this.minorder_val = this.obj.minimumorder;
        this.startdate_val = this.obj.startdate;
        this.enddate_val = this.obj.enddate;
        this.maxamount_val = this.obj.maxamount;
        this.offer_type = 'Worth';
        this.percentage_input = false;
        this.percentage_input1 = false;
        this.worth_input = true;

      }

      localStorage.removeItem('obj_value');
    }
  }

  closeModal() {
    console.log(this.offer_type);
    if (this.offer_type === 'Percentage') {
      this.obj = {
        'offertype': this.offer_val,
        'percentage': this.percentage_val,
        'minimumorder': this.minorder_val,
        'startdate': this.startdate_val,
        'enddate': this.enddate_val,
        'maxamount': this.maxamount_val
      };
    } else {
      this.obj = {
        'offertype': this.offer_val,
        'worth': this.worth_val,
        'minimumorder': this.minorder_val,
        'startdate': this.startdate_val,
        'enddate': this.enddate_val,
        'maxamount': this.maxamount_val
      };
      // localStorage.setItem('obj_value', JSON.stringify(this.data_object));
    }
    this.close(this.obj);
    // this.smsservice.closeconfirm(this.obj);
  }

  display(value) {
    this.percentage_input = true;
    this.percentage_input1 = true;
    this.worth_input = false;
    this.radio_value = value;
  }


  display1(data) {
    this.percentage_input = false;
    this.percentage_input1 = false;
    this.worth_input = true;
  }

}
