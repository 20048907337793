import { DatePipe } from '@angular/common';
import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CitiservicesService } from '../../../../citiservices.service';

@Component({
  selector: 'app-emailcampaigndetails',
  templateUrl: './emailcampaigndetails.component.html',
  styleUrls: ['./emailcampaigndetails.component.scss'],
  providers: [DatePipe]
})
export class EmailcampaigndetailsComponent implements OnInit, AfterContentChecked {
  emailcampaigndetailstabs: any = [];
  exist: any = { contains: false, index: 0 };
  containsTab: any = { contains: false, index: 0 };
  viewReports = true;
    noviewReports = false;
    emailReportsData: any;
    barChartData: any;
    pieChartData: any;
    activestatus = true;
    imagepath: any;
    mobileNumbersCount: any;
    groupsCount: any;
  fileName: any;
  fileAttachements: any;
 dataSetsCount: any;
 dataSetsData: any;
 campaignId: any;
 gridData: any;
   dataSetsArray: any;
 groupsInfoArrayData: any;
 contactsList: any;
  showManualEmails = true;
  showFileName = false;
  showDataSets = false;
  showGroupdDeatails = false;
    fileNumbersCount: any;
  public newTabIndex: any;
  manualNumbersArray: any;
  emailDetailsloader = true;
  sent: any;
  delivered: any;
  opens: any;
  clicks: any;
  bounces: any;
  constructor(private cdref: ChangeDetectorRef, private myServ: CitiservicesService,
    private datePipe: DatePipe, private toastr: ToastrService) {
    this.emailcampaigndetailstabs = (<any>window).emailcampaigndetailstabs;
  }

  ngOnInit() {
   }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
  
  selectedManualEmails() {
        this.showManualEmails = !this.showManualEmails;
    }

    selectedFileName() {
        this.showFileName = !this.showFileName;
    }

    selectedDataSets() {
        this.showDataSets = !this.showDataSets;
    }

    selectedGruop() {
        this.showGroupdDeatails = !this.showGroupdDeatails;
    }

  removeTabHandler(tab: any): void {
    this.emailcampaigndetailstabs.splice(this.emailcampaigndetailstabs.indexOf(tab), 1);
  }

  getIndividualemailcamopaign(dataa) {
    console.log(dataa);
    this.emailcampaigndetailstabs.push({
      title: dataa.title.slice(48),
      content: dataa.campaignid,
      content1: dataa.campaignstatus,
      content2: dataa.description,
      createdOn: dataa.schdate,
      isScheduled: dataa.isschdule,
      maintab: false,
      disabled: false,
      removable: true,
      active: true
  });
    this.myServ.individualCampaign(dataa).subscribe(resp => {
      if (resp.json().status === 'success') {
        console.log(resp.json().result.campaignId);
        // this.liveStatusReport(resp.json().result);
        const indivialCampData = resp.json();
        const data = indivialCampData.result;
        console.log(data);
        console.log(this.emailcampaigndetailstabs.content)
        for (let d = 0; d < this.emailcampaigndetailstabs.length; d++) {
          if (this.emailcampaigndetailstabs[d].content === data.campaignKey) {
              this.emailcampaigndetailstabs.splice(d, 1);
              this.manualNumbersArray = [];
              this.groupsInfoArrayData = [];
              this.dataSetsArray = [];
              // if (data.manualPhNumbers[0]) {
              //     this.mobileNumbersCount = data.manualPhNumbers[0].count;
              //     for (let i = 0; i < data.manualPhNumbers[0].manualPhNumbers.length; i++) {
              //         // console.log(",,,,,",data.manualPhNumbers[0].manualPhNumbers[i])
              //         this.manualNumbersArray.push(data.manualPhNumbers[0].manualPhNumbers[i]);
              //     }
              // } else {
              //     this.mobileNumbersCount = 0;
              //     this.manualNumbersArray = [];
              // }
              // if (data.fileInfo[0]) {
              //     this.fileNumbersCount = data.fileInfo[0].count;
              //     this.fileName = data.fileInfo[0].fileName;

              // } else {
              //     this.fileNumbersCount = 0;
              //     this.fileName = '';
              // }

              // if (data.dataSetInfo[0]) {
              //     this.dataSetsCount = data.dataSetInfo[0].count;
              //     // this.dataSetsName = data.dataSetInfo[0].dataSetName;
              //     for (let i = 0; i < data.dataSetInfo.length; i++) {
              //         this.dataSetsArray.push(data.dataSetInfo[i]);
              //     }
              // } else {
              //     this.dataSetsCount = 0;
              //     this.dataSetsArray = '';
              // }

              // if (data.groupInfo[0]) {
              //     this.groupsCount = data.groupInfo[0].count;

              //     for (let i = 0; i < data.groupInfo.length; i++) {
              //         this.groupsInfoArrayData.push(data.groupInfo[i]);
              //     }
              // } else {
              //     this.groupsCount = 0;
              //     this.groupsInfoArrayData = [];
              // }
              const mytime = this.datePipe.transform(data.scheduledTime, 'HH:mm:ss');
              const mydate = this.datePipe.transform(data.scheduledTime, 'yyyy-MM-dd');
              this.imagepath = (<any>window).citiUrl + data.imageFilePath;
              this.emailcampaigndetailstabs.push({
                  title: data.campaignName,
                  content: data.campaignKey,
                  content1: data.msgCampaignStatus,
                  content2: data.campaignDescription,
                  createdOn: mydate,
                  isScheduled: data.isScheduled,
                  // imagepath: this.imagepath,
                  scheduledTime: mytime,
                  // Amount: data.campaignExpenses,
                  // quantity: data.contactsList.length,
                  // manualNumbersCount: this.mobileNumbersCount,
                  // groupsCount: this.groupsCount,
                  // fileCount: this.fileNumbersCount,
                  // total: this.mobileNumbersCount + this.fileNumbersCount + this.groupsCount + this.dataSetsCount,
                  // mobileNumbers: this.manualNumbersArray.toString(),
                  // fileName: this.fileName,
                  // datasetsCount: this.dataSetsCount,
                  // datasetName: this.dataSetsArray,
                  // groupsArray: this.groupsInfoArrayData,
                  maintab: false,
                  disabled: false,
                  removable: true,
                  active: true
              });
          }
      }
        // this.selectedData(data);
      }
    });
  }


  selectedData(data) {
    console.log('My selected data for email--->>>'+JSON.stringify(data));
    const schTime = this.datePipe.transform(data.scheduledTime, 'HH:mm:ss');
    const schDate = this.datePipe.transform(data.scheduledTime, 'yyyy-MM-dd');
    if (this.emailcampaigndetailstabs.length === 0) {
      this.newTabIndex = this.emailcampaigndetailstabs.length + 1;
      this.emailcampaigndetailstabs.push({
                      title: data.campaignName,
                      content: data.emailCampaignId,
                      content1: data.campaignStatus,
                      content2: data.campaignDescription,
                      createdOn: schDate,
                      subject: data.subject,
                      route: data.smsType, 
                      isScheduled: data.isScheduled,
                      imagepath: this.imagepath,
                      scheduledTime: data.scheduledTime,
                      Amount: data.creditsUsed,
                      quantity: data.contactsList.length,
                      manualNumbersCount: this.mobileNumbersCount,
                      groupsCount: this.groupsCount,
                      fileCount: this.fileNumbersCount,
                      total: this.mobileNumbersCount + this.fileNumbersCount + this.groupsCount + this.dataSetsData,
                //mobileNumbers: this.manualNumbersArray.toString(),
                fileName: this.fileAttachements,
                datasetsCount: this.dataSetsData,
                datasetName: this.dataSetsData,
                groupsArray: this.contactsList,
                maintab: false,
                disabled: false,
                removable: true,
                active: true
      });
    } else {
      //console.log(data.campaignName);
      for (let i = 0; i < this.emailcampaigndetailstabs.length; i++) {
        if (this.emailcampaigndetailstabs[i].content === data.emailCampaignId) {
          this.exist.contains = true;
          this.exist.index = i;
        }
        if (i === (this.emailcampaigndetailstabs.length - 1)) {
          if (this.exist.contains === false) {
            this.emailcampaigndetailstabs.push({
                      title: data.campaignName,
                      content: data.emailCampaignId,
                      content1: data.campaignStatus,
                      content2: data.campaignDescription,
                      subject: data.subject,
                      createdOn: schDate,
                      route: data.smsType, 
                      isScheduled: data.isScheduled,
                      imagepath: this.imagepath,
                      scheduledTime: data.scheduledTime,
                      Amount: data.creditsUsed,
                      quantity: data.smsCount,
                      manualNumbersCount: this.mobileNumbersCount,
                      groupsCount: this.groupsCount,
                      fileCount: this.fileNumbersCount,
                      total: this.mobileNumbersCount + this.fileNumbersCount + this.groupsCount + this.dataSetsData,
                //mobileNumbers: this.manualNumbersArray.toString(),
                fileName: this.fileAttachements,
                datasetsCount: this.dataSetsData,
                datasetName: this.dataSetsData,
                groupsArray: this.contactsList,
                maintab: false,
                disabled: false,
                removable: true,
                active: true
            });
          } else {
            this.exist.contains = false;
            (<any>window).emailcampaigndetailstabs[this.exist.index].active = true;
          }
        }
      }
    }
  }



  // selectedData(data) {
  //   const schtime = this.datePipe.transform(data.schduletime, 'HH:mm:ss');
  //   const schdate = this.datePipe.transform(data.schduletime, 'yyyy-MM-dd');
  //   if (data.channelId === undefined) {
  //       if (this.emailcampaigndetailstabs.length === 0) {
  //           this.newTabIndex = this.emailcampaigndetailstabs.length + 1;
  //           this.emailcampaigndetailstabs.push({
  //               title: data.title,
  //               content: data.campaignid,
  //               content1: data.campaignstatus,
  //               content2: data.description,
  //               createdOn: schdate,
  //               isScheduled: data.isschdule,
  //               imagepath: this.imagepath,
  //               scheduledTime: schtime,
  //               Amount: data.creditsUsed,
  //               quantity: data.smsCount,
  //               manualNumbersCount: this.mobileNumbersCount,
  //               groupsCount: this.groupsCount,
  //               fileCount: this.fileNumbersCount,
  //               total: this.mobileNumbersCount + this.fileNumbersCount + this.groupsCount + this.dataSetsCount,
  //               mobileNumbers: this.manualNumbersArray.toString(),
  //               fileName: this.fileName,
  //               datasetsCount: this.dataSetsCount,
  //               datasetName: this.dataSetsArray,
  //               groupsArray: this.groupsInfoArrayData,
  //               maintab: false,
  //               disabled: false,
  //               removable: true,
  //               active: true
  //           });
  //       } else {
  //           for (let i = 0; i < this.emailcampaigndetailstabs.length; i++) {
  //               if (this.emailcampaigndetailstabs[i].content === data.campaignid) {
  //                   this.exist.contains = true;
  //                   this.exist.index = i;
  //               }
  //               if (i === (this.emailcampaigndetailstabs.length - 1)) {
  //                   if (this.exist.contains === false) {
  //                       this.emailcampaigndetailstabs.push({
  //                           title: data.title.slice(51),
  //                           content: data.campaignid,
  //                           content1: data.campaignstatus,
  //                           content2: data.description,
  //                           createdOn: schdate,
  //                           isScheduled: data.isschdule,
  //                           imagepath: this.imagepath,
  //                           scheduledTime: schtime,
  //                           Amount: data.creditsUsed,
  //                           quantity: data.smsCount,
  //                           manualNumbersCount: this.mobileNumbersCount,
  //                           groupsCount: this.groupsCount,
  //                           fileCount: this.fileNumbersCount,
  //                           total: this.mobileNumbersCount + this.fileNumbersCount + this.groupsCount + this.dataSetsCount,

  //                           mobileNumbers: this.manualNumbersArray.toString(),
  //                           fileName: this.fileName,
  //                           datasetsCount: this.dataSetsCount,
  //                           datasetName: this.dataSetsArray,
  //                           groupsArray: this.groupsInfoArrayData,
  //                           maintab: false,
  //                           disabled: false,
  //                           removable: true,
  //                           active: true
  //                       });
  //                   } else {
  //                       this.exist.contains = false;
  //                       (<any>window).campaigndetailstabs[this.exist.index].active = true;
  //                   }
  //               }
  //           }
  //       }
  //   } else {
  //       // console.log(data.manualPhNumbers[0].manualPhNumbers[0])
  //       // console.log(data.usedCredits)
  //       this.gridData = true;
  //       this.manualNumbersArray = [];
  //       this.groupsInfoArrayData = [];
  //       this.dataSetsArray = [];
  //       if (data.manualPhNumbers[0]) {
  //           this.mobileNumbersCount = data.manualPhNumbers[0].count;
  //           for (let i = 0; i < data.manualPhNumbers[0].manualPhNumbers.length; i++) {
  //               // console.log(",,,,,",data.manualPhNumbers[0].manualPhNumbers[i])
  //               this.manualNumbersArray.push(data.manualPhNumbers[0].manualPhNumbers[i]);
  //           }
  //       } else {
  //           this.mobileNumbersCount = 0;
  //           this.manualNumbersArray = [];
  //       }
  //       if (data.fileInfo[0]) {
  //           this.fileNumbersCount = data.fileInfo[0].count;
  //           this.fileName = data.fileInfo[0].fileName;

  //       } else {
  //           this.fileNumbersCount = 0;
  //           this.fileName = '';
  //       }

  //       if (data.dataSetInfo[0]) {
  //           this.dataSetsCount = data.dataSetInfo[0].count;
  //           // this.dataSetsName = data.dataSetInfo[0].dataSetName;
  //           for (let i = 0; i < data.dataSetInfo.length; i++) {
  //               this.dataSetsArray.push(data.dataSetInfo[i]);
  //           }
  //       } else {
  //           this.dataSetsCount = 0;
  //           this.dataSetsArray = '';
  //       }

  //       if (data.groupInfo[0]) {
  //           this.groupsCount = data.groupInfo[0].count;

  //           for (let i = 0; i < data.groupInfo.length; i++) {
  //               this.groupsInfoArrayData.push(data.groupInfo[i]);
  //           }
  //       } else {
  //           this.groupsCount = 0;
  //           this.groupsInfoArrayData = [];
  //       }
  //       const mytime = this.datePipe.transform(data.scheduledTime, 'HH:mm:ss');
  //       const mydate = this.datePipe.transform(data.scheduledTime, 'yyyy-MM-dd');
  //       this.imagepath = (<any>window).citiUrl + data.imageFilePath;
  //       if (this.emailcampaigndetailstabs.length === 0) {
  //           this.newTabIndex = this.emailcampaigndetailstabs.length + 1;
  //           this.emailcampaigndetailstabs.push({
  //               title: data.campaignName,
  //               content: data.campaignId,
  //               content1: data.msgCampaignStatus,
  //               content2: data.campaignDescription,
  //               createdOn: mydate,
  //               isScheduled: data.isScheduled,
  //               imagepath: this.imagepath,
  //               scheduledTime: mytime,
  //               Amount: data.creditsUsed,
  //               quantity: data.smsCount,
  //               manualNumbersCount: this.mobileNumbersCount,
  //               groupsCount: this.groupsCount,
  //               fileCount: this.fileNumbersCount,
  //               total: this.mobileNumbersCount + this.fileNumbersCount + this.groupsCount + this.dataSetsCount,
  //               mobileNumbers: this.manualNumbersArray.toString(),
  //               fileName: this.fileName,
  //               datasetsCount: this.dataSetsCount,
  //               datasetName: this.dataSetsArray,
  //               groupsArray: this.groupsInfoArrayData,
  //               maintab: false,
  //               disabled: false,
  //               removable: true,
  //               active: true
  //           });
  //       } else {
  //           for (let i = 0; i < this.emailcampaigndetailstabs.length; i++) {
  //               if (this.emailcampaigndetailstabs[i].content === data.campaignId) {
  //                   this.exist.contains = true;
  //                   this.exist.index = i;
  //               }
  //               if (i === (this.emailcampaigndetailstabs.length - 1)) {
  //                   if (this.exist.contains === false) {
  //                       this.emailcampaigndetailstabs.push({
  //                           title: data.campaignName,
  //                           content: data.campaignId,
  //                           content1: data.msgCampaignStatus,
  //                           content2: data.campaignDescription,
  //                           createdOn: mydate,
  //                           isScheduled: data.isScheduled,
  //                           imagepath: this.imagepath,
  //                           scheduledTime: mytime,
  //                           Amount: data.creditsUsed,
  //                           quantity: data.smsCount,
  //                           manualNumbersCount: this.mobileNumbersCount,
  //                           groupsCount: this.groupsCount,
  //                           fileCount: this.fileNumbersCount,
  //                           total: this.mobileNumbersCount + this.fileNumbersCount + this.groupsCount + this.dataSetsCount,

  //                           mobileNumbers: this.manualNumbersArray.toString(),
  //                           fileName: this.fileName,
  //                           datasetsCount: this.dataSetsCount,
  //                           datasetName: this.dataSetsArray,
  //                           groupsArray: this.groupsInfoArrayData,
  //                           maintab: false,
  //                           disabled: false,
  //                           removable: true,
  //                           active: true
  //                       });
  //                   } else {
  //                       this.exist.contains = false;
  //                       (<any>window).campaigndetailstabs[this.exist.index].active = true;
  //                   }
  //               }
  //           }
  //       }
  //       //   this.newTabIndex = this.tabs.length + 1;
  //       //   console.log(this.newTabIndex);
  //       //  (<any>window).tabs.push({
  //       //     title: data.campaignName,
  //       //      content: data.campaignId,
  //       //     content1: data.campaignState,
  //       //     content2: data.campaignDescription,
  //       //     createdOn: data.createdOn,
  //       //     updatedOn: data.updatedOn,
  //       //     runningStatus: data.runningStatus,
  //       //     disabled: false,
  //       //     removable: true,
  //       //     active: true
  //       //   });
  //       //   console.log(this.tabs)
  //   }
  // }
  tabChange(changeData, tabData) {
     //console.log('My change data for reports'+JSON.stringify(changeData));
    // console.log(changeData.json());
    this.myServ.getEmailReports1(changeData.content).subscribe(data => {
      //console.log(data.json());
//console.log(changeData.content);
        if (data.json().status === 'success') {
          //console.log('My data for reports'+JSON.stringify(data));
            this.emailReportsData = data.json().response[0];
            if (data.json().response.length === 0) {
                this.viewReports = false;
                this.noviewReports = true;
                return;
            } else {
                this.viewReports = true;
                this.noviewReports = false;
                this.selectedEmailData(this.emailReportsData);
            }
        } else {
            // console.log("failed")
            // this.showError(data.json().message)
            this.viewReports = false;
            this.noviewReports = true;
        }

    }, error => {
        this.showError('Server is busy, Please try again');
    });
}



selectedEmailData(event) {
  if (event === undefined) {
      return;
  } else {
    //console.log('Length of email camapaigndetails tab-->'+this.emailcampaigndetailstabs.length);
    //console.log('email camapaigndetails tab contents -->'+JSON.stringify(this.emailcampaigndetailstabs));
      for (let i = 0; i < this.emailcampaigndetailstabs.length; i++) {
          if (this.emailcampaigndetailstabs[i].active === true) {
            //console.log('In email if loop');
               this.sent = event.totalSent;
               this.delivered = event.delivered;
               this.opens = event.opens;
               this.clicks = event.clicks;
               this.bounces = event.bounce;
              console.log('My Pie chart data---->'+this.pieChartData);
               this.pieChartData = {
                  chart: {
                      plotBackgroundColor: null,
                      plotBorderWidth: null,
                      plotShadow: false,
                      type: 'pie'
                  },
                  title: {
                      text: ''
                  },
                  credits: {
                      enabled: false
                  },
                  exporting: { enabled: false },
                  tooltip: {
                      pointFormat: '<b>{point.name}</b>: {point.y}'
                  },
                  plotOptions: {
                      pie: {
                          allowPointSelect: true,
                          cursor: 'pointer',
                          colorByPoint: true,
                          dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.y}',
                      }
                    }
                  },
                  colors: ['#52e094', '#59a6f3', 'yellow', '#f9dd51', '#f9a6f5', '#59a6f3'],
                  series: [{
                      name: 'Brands',
                      colorByPoint: true,
                      data: [{
                                            name: 'Sent',
                                            y: event.totalSent
                                        }, {
                                            name: 'Delivered',
                                            y: event.delivered
            
                                        }, {
                                            name: 'Opens',
                                            y: event.opens
                                        }, {
                                            name: 'Clicks',
                                            y: event.clicks
                                        }, {
                                            name: 'Bounces',
                                            y: event.bounce
                                        }]
                  }]
                };
            
    this.barChartData = {
      chart: {
        type: 'bar'
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: ['Sent', 'Delivered', 'Opens', 'Clicks', 'Bounces'],
        title: {
               text: null
        }
      },
      tooltip: {
             pointFormat: '<b>{point.name}</b>: {point.y}'
                },
     colors: ['#52e094', '#59a6f3', 'yellow', '#f9dd51', '#f9a6f5', '#59a6f3'],
       yAxis: {
            min: 0,
            labels: {
                  overflow: 'justify'
                  }
           },
      exporting: { enabled: false },
      legend: {
         enabled: false
      },
      credits: {
        enabled: false
    },
      series: [{
         colorByPoint: true,
         data: [event.totalSent, event.delivered, event.opens,
                  event.clicks, event.bounce]
      }]
    };


          }
      }
  }

}
showSuccess(data) {
  if (this.toastr.currentlyActive === 0) {
      this.toastr.success(data, '', {
          timeOut: 1500,
          positionClass: 'toast-bottom-right'
      });
  }
}

showError(data) {
  if (this.toastr.currentlyActive === 0) {
      //// console.log("active toasts = "+ this.toastr.currentlyActive +" In if");
      this.toastr.error(data, '', {
          timeOut: 1500,
          positionClass: 'toast-bottom-right'
      });
  }
}
}

