<div tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
 			<div class="modal-header">
				<div class="modal-title pull-left" style="font-size: 15px;font-weight: 600;">
					<span style="padding-left: 5px;">Offers</span>
				</div>
				<button type="button" class="close pull-right my_closex" aria-label="Close" (click)="closeModal()">
					<span aria-hidden="true" style="font-size: 21px;">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-group row" style="margin-bottom: 0.2rem;">
					<label class="col-md-4 col-form-label" id="label" for="text-input">Offer Type</label>
					<div class="col-md-6">
						<select class="form-control" [(ngModel)]="offer_val">
							<option value="" disabled selected hidden>Choose Offer Type</option>
							<option value="Flat">Flat</option>
							<option value="Cashback">Cashback</option>
						</select>
					</div>
				</div>
				<div class="form-group row" style="margin-bottom: -0.2rem;">
					<label class="col-md-4 col-form-label" for="inline-radios">Offers</label>
					<div class="col-md-6 col-form-label">
						<div class="form-check form-check-inline mr-1" id="inline-radios">
							<input class="form-check-input" type="radio" (click)="display('Percentage')" name="inline-radios" id="inlineRadio1" [(ngModel)]="offer_type" value="Percentage" checked>
							<label class="form-check-label" for="inlineRadio1">Percentage</label>
						</div>
						<div class="form-check form-check-inline mr-1">
							<input class="form-check-input" type="radio" (click)="display1('Worth')" name="inline-radios" id="inlineRadio2" [(ngModel)]="offer_type" value="Worth">
							<label class="form-check-label" for="inlineRadio2">Worth</label>
						</div>
					</div>
				</div>
				<div class="form-group row" style="margin-bottom:0.2rem;">
						<div class="col-md-4"></div>
						<span class="col-md-6" *ngIf="percentage_input" style="display: inline-flex">
							<input type="text" class="form-control" placeholder="Please Enter Percentage" [(ngModel)]="percentage_val">
	
						</span>
						<span class="col-md-2" *ngIf="percentage_input1" style="padding-left: 0px;padding-top: 8px">%</span>
					<div class="col-md-6" *ngIf="worth_input">
							<input type="text" class="form-control" placeholder="Please Enter Amount" [(ngModel)]="worth_val">
					</div>
					</div>
				<div class="form-group row" style="margin-bottom: 0.2rem;">
					<label class="col-md-4 col-form-label" for="text-input">Minimum Order</label>
					<div class="col-md-6">
						<input type="number" id="text-input" name="text-input" class="form-control" placeholder="Minimum Order" [(ngModel)]="minorder_val">
					</div>
				</div>
				<div class="form-group row" style="margin-bottom: 0.2rem;">
					<label class="col-md-4 col-form-label">Offer Start Date</label>
					<div class="col-md-6">
						<angular2-date-picker (onDateSelect)="onDateSelect($event)" [(ngModel)]="startdate_val" [settings]="settings"></angular2-date-picker>
					</div>
				</div>
				<div class="form-group row" style="margin-bottom: 0.2rem;">
					<label class="col-md-4 col-form-label">Offer End Date</label>
					<div class="col-md-6">
						<angular2-date-picker (onDateSelect)="onDateSelect($event)" [(ngModel)]="enddate_val" [settings]="settings"></angular2-date-picker>
					</div>
				</div>
				<div class="form-group row" style="margin-bottom: 0.2rem;">
					<label class="col-md-4 col-form-label" for="text-input">Maximum Discount</label>
					<div class="col-md-6">
						<input type="text" id="text-input" name="text-input" class="form-control" placeholder="Maximum Discount" [(ngModel)]="maxamount_val">
					</div>
				</div>
			</div>
		</div>
		<!-- /.modal-content -->
	</div>
	<!-- /.modal-dialog -->
</div>
<!-- /.modal -->