<div class="app-body" style="margin-top: 0px !important;display: grid !important;background-image: url(assets/img/brand/background.jpg) !important;">
    <!--<div class="row" style="width: 100% !important;height: 40px !important;margin-right: 0px !important;margin-left: 0px !important;">
      <div class="col" style="background: url('assets/img/brand/glossybg2a.png');background-size: contain;">
        <img src="assets/img/brand/logo.png" alt="Social Enterprise" style="width: 135px;margin-top: 4px !important;" />
      </div>
    </div>-->
    <!-- <div class="row" style="width: 100% !important;height: 40px !important;margin-right: 0px !important;margin-left: 0px !important;margin-top: 2px !important;">
      <div class="col" style="background: #ecf0f5;">
        <p style="margin-top: 9px !important;font-weight: 500;">PLEASE SELECT YOUR LANGUAGE: &nbsp; <i class="flag-icon flag-icon-gb h1" title="gb" id="gb" style="font-size: 1.3rem !important;vertical-align: sub;border-radius: 6px;"></i>&nbsp;English &nbsp; <i class="flag-icon flag-icon-ae h1" title="ae" id="ae" style="font-size: 1.3rem !important;vertical-align: sub;border-radius: 6px;"></i>&nbsp;Arabic </p>
      </div>
    </div> -->

    <div style="display: flex;height: -webkit-fill-available">
 <div style="margin: auto;">
    <div class="row">
      <!--<div class="col">
        <h3 style="color: #3276ad;">Welcome to Citimedia platform</h3>
        <p style="margin-bottom: 2rem;">Citimedia is a state-of-the-art marketing platform, that provides seamless access to all online marketing channels. It can help you make the best marketing decisions when launching any products and services. It can also assist in campaign planning as well as help you understand the best possible ways to promote your product and services in an impactful way. As a comprehensive platform, it provides innovative solutions that are integrated and easy to implement.</p>
        <h5 style="color: #3276ad;">About Us</h5>
        <p>Marketing – the most complicated aspect in the most competitive environment. In today’s evolving environment, where the needs are continuously evolving; there is a need for progressive marketing. Gone are the days of traditional marketing. Now, the more innovative and creative you make your marketing efforts, the higher the chances of it becoming a success.</p>
        <p>With such fast evolving times and amplified competition, it is imperative that you act fast, are innovative and think “out of the box”. Customers have become the most powerful decision makers so it is integral to reach and exceed their expectations. Targeting the right audience and making an impact is essential when it comes to marketing. Impactful marketing is the key to success; Citimedia can help you attain it.</p>
      </div>-->
      
      <div class="col" id='loginState'>
          <div class="col">
              <div class="card-group">
                <div class="card p-4" style="border: 1px solid #c8ced3 !important;padding: 0px !important;">
                  <div class="card-body" style="padding: 25px;">
                    <img src="assets/img/brand/logo.png" alt="Citimedia" style="width: 160px;display: block;margin-left: auto;margin-right: auto;margin-bottom: 5px;"/>
                    
                  <div class="row">
                  <div class="col-md-6">
                    <p class="labelStyles" >Sign in to your account</p>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" style="background: none;"><i class="fa fa-user-o"></i></span>
                      </div>
                      <input type="text" style="border-left: none;" class="form-control" id='loginUser' pTooltip="Username" placeholder="Username" [(ngModel)]="signinObj.userName">
                    </div>
                    <div class="input-group mb-4">
                      <div class="input-group-prepend">
                        <span class="input-group-text" style="background: none;"><i class="fa fa-lock"></i></span>
                      </div>
                      <input type="password" style="border-left: none;" class="form-control" id='loginPass' pTooltip="Password" placeholder="Password" [(ngModel)]="signinObj.pwd">
                    </div>
                    <div class="row">
                      <div class="col">
                        <button type="button" class="btn btn-block btn-primary px-4" style="background: #2c9720;border: none !important;" (click)="submit(signinObj)">Login</button>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col text-left">
                          <input class="form-check-input" type="checkbox" value="option1" id="checkbox1" style="margin-top: 0.8rem !important;margin-left: 0rem !important;">
                          <label class="form-check-label" for="checkbox1" style="margin-top: 9px !important;margin-left: 18px !important;font-size: 13px; color: #23282c !important;">
                            Remember me
                          </label>
                      </div>
                      <div class="col text-right">
                        <button type="button" class="btn btn-link px-0" (click)="forgotPassword()" style="font-size: 13px !important;margin-top: 2px !important; color: steelblue;">Forgot password?</button>
                      </div>
                    </div>
                    <div>
                      <p style="font-size: 13px !important; color: #23282c !important;margin-bottom: 0px;">Don't have an account? <a class="btn btn-link px-0" style="font-size: 13px !important;     margin-top: -3px !important; cursor: pointer;color: steelblue" (click)="signUp()">Signup here</a></p>
                    </div>
                    </div>
                    <!---->
                    <div class="col-md-1" style="margin-top: 71px;padding-left: 0px;padding-right: 0px;">
                    <div class="social-divider fontStyles" >(OR)</div>
                    </div>
                    <div class="col-md-5" style="margin-top: 38px;padding-left: 0px !important;padding-right: 0px !important;">
                    <div class="row">
                      <div class="col oAuthStyles" > 
                        <button type="button" class="btn btn-brand-1 btn-facebook" (click)="FacebookNodeLogin()" style="text-align: left;width: 87%;">
                          <i class="fa fa-facebook brand-icon-fb" style="font-size: 18px;"></i><span style="margin-left: 25px;font-size: 13px;">Login via Facebook</span>
                        </button>
                     
                        <button type="button" class="btn btn-brand-1 btn-twitter" (click)="TwitterNodeLogin()" style="margin-top: 10px;text-align: left;width: 87%;">
                          <i class="fa fa-twitter brand-icon-twtr" style="font-size: 18px;"></i><span style="margin-left: 25px;font-size: 13px;">Login via Twitter</span>
                        </button>
                      </div>
                    </div>
                    <!---->
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
      
    </div>
    </div>
    </div>
    <!--<div class="row" style="margin-right: 0px !important;margin-left: 0px !important;margin-top: 0px !important;background: #d2dcec;padding: 10px;position: fixed;bottom: 0;width: 100% !important;">
       
      <div class="col" style="display: flex;">
        <div><img src="assets/img/brand/dashboard.png" width="80" /></div>
        <div>
        <h5 style="color: #ff9100;margin-left: 1.3rem;">Dashboard</h5>
        <ul>
          <li>Citimedia dashboard brings all reports covering different digital marketing channels.</li>
        </ul>
        </div>
      </div>
      <div class="col" style="display: flex;">
        <div><img src="assets/img/brand/social.png" width="80" /></div>
        <div>
        <h5 style="color: #1cabe6;margin-left: 1.3rem;">Social Monitor</h5>
        <ul>
          <li>Publish, monitor and engage all your social profiles and pages in this module.</li>
        </ul>
        </div>
      </div>
      <div class="col" style="display: flex;">
        <div><img src="assets/img/brand/campaign.png" width="80" /></div>
        <div>
        <h5 style="color: #e93e2e;margin-left: 1.3rem;">Ad Manager</h5>
        <ul>
          <li>This module enables to plan, execute and monitor paid campaigns across channels.</li>
        </ul>
        </div>
      </div>
  
    </div>-->
    <div class="sk-double-bounce spinnerStyle" *ngIf='loggingin'>
      <div class="sk-child sk-double-bounce1"></div>
      <div class="sk-child sk-double-bounce2"></div>
    </div>
  </div>