<div tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class=" modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title pull-left" style="font-size: 15px;font-weight: 600;">
          <span style="padding-left: 5px;">Schedule</span>
        </div>
        <button type="button" class="close pull-right my_closex" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true" style="font-size: 21px;">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <div class="secondcontainer" style="margin-left: 5px;">
            <span>Choose how long contacts should wait in this step
                  <div class="form-group row" style="margin-bottom: 0.2rem">
                    <div class="col-md-12 col-form-label">
                      <div class="form-check form-check-inline mr-1" id="inline-radios">
                        <input class="form-check-input" type="radio" name="selector" id="inlineRadio1" value="period" checked (click)="radio_change('period')">
                        <label class="form-check-label" for="inlineRadio1">Wait for a set amount of time:</label>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-bottom: 0.2rem">
                    <div class="col-3">
                        <input type="text" id="text-input" name="text-input" class="form-control" [(ngModel)]="numberDays" >
                    </div>
                    <div class="col-4">
                        <select class="form-control" [(ngModel)]="selection_period">
                            <option value="" disabled selected hidden>Please Select</option>
                            <option value="Week(s)">Week(s)</option>
                            <option value="Month(s)">Month(s)</option>
                            <option value="Year(s)">Year(s)</option>
                          </select>
                    </div>
                  </div>
            </span>
            <div class="form-group row" style="margin-bottom: -0.2rem">
              <div class="col-md-12 col-form-label">
                <div class="form-check form-check-inline mr-1" id="inline-radios">
                  <input class="form-check-input" type="radio" name="selector" value="specific" (click)="radio_change('specific')" id="inlineRadio2"  >
                  <label class="form-check-label" for="inlineRadio2">Wait for a specific Date and time</label>
                </div>
              </div>
            </div>
            <div class="form-group row" style="margin-bottom: 0.2rem">
              <div class="col-md-6">
                <angular2-date-picker (onDateSelect)="onDateSelect($event)" [(ngModel)]="datetime" [settings]="settings"></angular2-date-picker>
              </div>
            </div>
            <span style="font-size:14px; font-family: 'Roboto';">Time Zone </span>
            <div class="form-group row" style="margin-bottom: 0.2rem;">
              <div class="col-md-12">
                <select class="form-control" [(ngModel)]="time_zonee">
                  <option value="" disabled selected hidden>Choose TimeZone</option>
                  <option value="UTC" > UTC +5.30 (IST)-Indian Standard Time (Kolkata)</option>
                  <option  value="WET">UTC (WET) — Canary Islands UTC+01:00 (CET) — main territory of Spain</option>
                  <option  value="EET">UTC+02:00 (EET) — most of the country UTC+03:00 (MSK) — Crimea</option>
    
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
<!-- /.modal -->