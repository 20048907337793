
<!-- <nav class="navbar navbar-expand-lg navbar-dark" style='position:fixed; top: 39px; width:100%; z-index:999;background: url("assets/img/brand/strip.png");background-size: contain;padding: 0rem 0.5rem !important;'> -->
  <nav class="navbar navbar-expand-lg navbar-dark" style='position:fixed; top: 0px; width:100%; z-index:999;background-image: linear-gradient(to left,#2C2D2F 0%, #2C2D2F 9px);color: #D8D8D8;background-size: contain;padding: 0rem 0.5rem !important;'>
    <!-- <a class="navbar-brand" href="#">Navbar</a> -->
    <button id='buttonToogler' class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon" (click)='toogleMenu()'></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <!-- <li class="nav-item active">
          <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
        </li> -->
        <li class="nav-item">
          <img src="assets/img/brand/citistarlogo.png" alt="" style="width: 30px;margin-right: 5px;margin-top: 3px;" />
        </li>
        <span *ngFor='let items of navItems'>

            <!-- For menu without drop downs-->
            
            <li class="nav-item" >
              <a id='sideMenu-{{items.name}}' *ngIf='items.name != "Admin"' class="nav-link topmenu text-custom-link" [routerLink]="['/'+items.url]" skipLocationChange (click)="activeButton(items.name)"><i class="{{items.icon}}" id='menuicon-{{items.name}}'></i> {{items.name}}</a>
              <a id='sideMenu-{{items.name}}' *ngIf='items.name == "Admin" && emailAddress == "noreply@iton.com"' class="nav-link topmenu text-custom-link" [routerLink]="['/'+items.url]" skipLocationChange (click)="activeButton(items.name)"><i class="{{items.icon}}" id='menuicon-{{items.name}}'></i> {{items.name}}</a>
            </li>

            
 
            <!-- For menu with drop downs-->

           <!--  <li class="nav-item" *ngIf='!items.children'>
                <a class="nav-link" href="{{'#'+items.url}}">{{items.name}}</a>
            </li>

            <li class="nav-item dropdown" *ngIf='items.children'>
              <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{items.name}}
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <a class="dropdown-item" href="{{'#'+item.url}}"  *ngFor='let item of items'>{{item.name}}</a>
              </div>
            </li> -->

        </span>
        <ul class="navbar-nav mr-auto" style="right: 10px;position: absolute;">
        <span style='margin-top: 8px;'>
          <li class="nav-item">
            <i class="fas fa-wallet" style="font-size: 22px !important;"></i>&nbsp;&nbsp;<span style="vertical-align: text-bottom !important;"><i class="fa fa-inr"></i>&nbsp;<span><b *ngIf='walletbalance.length > 0' style="font-size: 15px;">{{walletbalance[0].balance || 0}}</b></span></span>
          </li>
        </span>
      <span>
          <li class="nav-item" [style.marginLeft]="positionButton" style="margin-right: 15px;margin-top: 7px;" >
              <button type="button" class="btn btn-block btn-primary" style="padding: 0.15rem 0.5rem;font-size: 0.75rem;font-weight: 500;margin-bottom: 3px;max-width: 75px;" (click)='openFunds()'>Add Funds</button>
          </li>
        </span>
      <span>
          <li class="nav-item dropdown" dropdown placement="bottom right" style="margin-right: 5px;margin-top: 4px;">
              <a class="nav-link" role="button" aria-haspopup="true" aria-expanded="true" dropdownToggle style="padding: 0px !important;">
                <img src="{{profilePic}}" class="img-avatar" alt="citiuser" style="border: 2px solid #e3e3e3;width: 30px;height: 30px;object-fit: cover !important;margin-right: 5px;" onerror="this.src='assets/img/brand/user.png'"/>
                <!-- <span style="font-size: 13px;font-weight: 500;color: #fff;" class='text-custom-link'>{{profileName || 'citimedia'}}</span> -->
              </a>
              <div class="dropdown-menu dropdown-menu-right menu-drop-style" *dropdownMenu aria-labelledby="simple-dropdown">
                <div class="dropdown-header text-center"><strong style="color: #4e4e4e;">{{profileName || 'citimedia'}}</strong></div>
                <a class="dropdown-item" [routerLink]="['/yourprofile']" skipLocationChange><i class="fa fa-user user_ic"></i> Profile</a>
                <a class="dropdown-item" [routerLink]="['/changepassword']" skipLocationChange><i class="fa fa-wrench password_ic"></i> Change Password</a>
                <a class="dropdown-item" [routerLink]="['/settings']" skipLocationChange><i class="fa fa-cog sett_ic"></i> Settings</a>
                <div class="divider"></div>
                <a class="dropdown-item" (click)='logout()'><i class="fa fa-power-off logout_ic"></i> Logout</a>
              </div>
            </li>
          </span>
        <span style='margin-top: 8px;'>
            <li class="nav-item" [style.float]="directionToShift">
              <button class="navbar-toggler-2" type="button" ng-reflect-breakpoint="lg" (click)='logout()' style="background: transparent;border: none;"><span><i class="fa fa-power-off logout_btn"></i></span></button>
            </li>
        </span>
      </ul>
      </ul>
      <!-- <ul class="nav navbar-nav ml-auto" style="align-items: flex-end;">
        <li class="nav-item">
          <i class="fas fa-wallet" style="font-size: 22px !important;"></i>&nbsp;&nbsp;<span style="vertical-align: text-bottom !important;"><i class="fa fa-inr"></i>&nbsp;<span><b *ngIf='walletbalance.length > 0' style="font-size: 15px;">{{walletbalance[0].balance || 0}}</b></span></span>
        </li>
        <li class="nav-item d-md-down-none" style="margin-left: 15px;margin-right: 15px;">
            <button type="button" class="btn btn-block btn-primary" style="padding: 0.15rem 0.5rem;font-size: 0.75rem;font-weight: 500;margin-bottom: 3px;" (click)='openFunds()'>Add Funds</button>
        </li>
        <li class="nav-item dropdown" dropdown placement="bottom right" style="margin-right: 5px;">
            <a class="nav-link" role="button" aria-haspopup="true" aria-expanded="true" dropdownToggle style="padding: 0px !important;">
              <img src="{{profilePic}}" class="img-avatar" alt="citiuser" style="border: 2px solid #e3e3e3;width: 30px;height: 30px;object-fit: cover !important;margin-right: 5px;" onerror="this.src='assets/img/brand/user.png'"/>
              <span style="font-size: 13px;font-weight: 500;color: #fff;">{{profileName || 'citimedia'}}</span>
            </a>
            <div class="dropdown-menu dropdown-menu-right menu-drop-style" *dropdownMenu aria-labelledby="simple-dropdown">
              <div class="dropdown-header text-center"><strong style="color: #4e4e4e;">Account</strong></div>
              <a class="dropdown-item" [routerLink]="['/yourprofile']" skipLocationChange><i class="fa fa-user user_ic"></i> Profile</a>
              <a class="dropdown-item" [routerLink]="['/changepassword']" skipLocationChange><i class="fa fa-wrench password_ic"></i> Change Password</a>
              <a class="dropdown-item" [routerLink]="['/settings']" skipLocationChange><i class="fa fa-cog sett_ic"></i> Settings</a>
              <div class="divider"></div>
              <a class="dropdown-item" (click)='logout()'><i class="fa fa-power-off logout_ic"></i> Logout</a>
            </div>
          </li>
          <li class="nav-item">
            <button class="navbar-toggler-2 d-md-down-none" type="button" ng-reflect-breakpoint="lg" (click)='logout()' style="background: transparent;border: none;"><span><i class="fa fa-power-off logout_btn"></i></span></button>
          </li>
      </ul> -->

    </div>
  </nav>
<div class="app-body" style='margin-top:36px;background: #fff;'>

  <!-- Main content -->
  <main class="main">
    <div class="container-fluid" style="padding: 3px; height:100%">
      <router-outlet></router-outlet>
    </div><!-- /.container-fluid -->
  </main>
  <div *ngIf='displayaside' id='sidebarShow' class='aside-menu' style='position: fixed;height: 100%;right:0; margin: auto;box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)!important;'>
    <tabset>
        <tab>
            <ng-template tabHeading><i class="icon-speech"></i></ng-template>
            <!-- <div class="p-3" *ngIf='notificationData.messages.length > 0'>
    
              <div class="message" *ngFor='let notify of notificationData.messages' (click)="clearData($event, notify)">
                <div class="py-3 pb-5 mr-3 float-left" style='padding-top: 0rem !important;'>
                  <div class="avatar">
                    <img src="assets/img/avatars/7.jpg" class="img-avatar" alt="{{notify.from_name}}" onError="this.onerror=null;this.src='assets/images/user.png';">
                    <span class="avatar-status badge-success"></span>
                  </div>
                </div>
                <div>
                  <small class="text-muted">{{notify.from_name}}</small>
                  <small class="text-muted float-right mt-1">{{notify.time}}</small>
                </div>
                <small class="text-muted">{{notify.msg}}</small>
              </div>
              <hr>
            </div> -->
            <!-- <div class="p-3"  *ngIf='notificationData.messages.length === 0'>
               <div>No Messages..</div>
            </div> -->
          </tab>
      <tab>
        <ng-template tabHeading><i class="icon-list"></i></ng-template>
        <!-- <div class="list-group list-group-accent">
          <div class="list-group-item list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small">Today</div>
          <div class="list-group-item list-group-item-accent-warning list-group-item-divider">
            <div class="avatar float-right">
              <img class="img-avatar" src="{{profilePic}}" alt="admin@citimedia.com">
            </div>
            <div>Meeting with
              <strong>Lucas</strong>
            </div>
            <small class="text-muted mr-3">
              <i class="icon-calendar"></i>  1 - 3pm</small>
            <small class="text-muted">
              <i class="icon-location-pin"></i>  Palo Alto, CA</small>
          </div>
          <div class="list-group-item list-group-item-accent-info">
            <div class="avatar float-right">
              <img class="img-avatar" src="{{profilePic}}" alt="admin@citimedia.com">
            </div>
            <div>Skype with
              <strong>Megan</strong>
            </div>
            <small class="text-muted mr-3">
              <i class="icon-calendar"></i>  4 - 5pm</small>
            <small class="text-muted">
              <i class="icon-social-skype"></i>  On-line</small>
          </div>
          <div class="list-group-item list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small">Tomorrow</div>
          <div class="list-group-item list-group-item-accent-danger list-group-item-divider">
            <div>New UI Project -
              <strong>deadline</strong>
            </div>
            <small class="text-muted mr-3">
              <i class="icon-calendar"></i>  10 - 11pm</small>
            <small class="text-muted">
              <i class="icon-home"></i>  creativeLabs HQ</small>
            <div class="avatars-stack mt-2">
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="{{profilePic}}" alt="admin@citimedia.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="{{profilePic}}" alt="admin@citimedia.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="{{profilePic}}" alt="admin@citimedia.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="{{profilePic}}" alt="admin@citimedia.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="{{profilePic}}" alt="admin@citimedia.com">
              </div>
            </div>
          </div>
          <div class="list-group-item list-group-item-accent-success list-group-item-divider">
            <div>
              <strong>#10 Startups.Garden</strong> Meetup</div>
            <small class="text-muted mr-3">
              <i class="icon-calendar"></i>  1 - 3pm</small>
            <small class="text-muted">
              <i class="icon-location-pin"></i>  Palo Alto, CA</small>
          </div>
          <div class="list-group-item list-group-item-accent-primary list-group-item-divider">
            <div>
              <strong>Team meeting</strong>
            </div>
            <small class="text-muted mr-3">
              <i class="icon-calendar"></i>  4 - 6pm</small>
            <small class="text-muted">
              <i class="icon-home"></i>  creativeLabs HQ</small>
            <div class="avatars-stack mt-2">
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="{{profilePic}}" alt="admin@citimedia.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="{{profilePic}}" alt="admin@citimedia.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="{{profilePic}}" alt="admin@citimedia.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="{{profilePic}}" alt="admin@citimedia.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="{{profilePic}}" alt="admin@citimedia.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="{{profilePic}}" alt="admin@citimedia.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="{{profilePic}}" alt="admin@citimedia.com">
              </div>
            </div>
          </div>
        </div> -->
      </tab>

      <tab>
        <ng-template tabHeading><i class="icon-settings"></i></ng-template>
        <!-- <div class="p-3">
          <h6>Settings</h6>
          <div class="aside-options">
            <div class="clearfix mt-4">
              <small><b>Option 1</b></small>
              <label class="switch switch-label switch-pill switch-success switch-sm float-right">
                <input type="checkbox" class="switch-input" checked>
                <span class="switch-slider" data-checked="On" data-unchecked="Off"></span>
              </label>
            </div>
            <div>
              <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</small>
            </div>
          </div>
          <div class="aside-options">
            <div class="clearfix mt-3">
              <small><b>Option 2</b></small>
              <label class="switch switch-label switch-pill switch-success switch-sm float-right">
                <input type="checkbox" class="switch-input">
                <span class="switch-slider" data-checked="On" data-unchecked="Off"></span>
              </label>
            </div>
            <div>
              <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</small>
            </div>
          </div>
          <div class="aside-options">
            <div class="clearfix mt-3">
              <small><b>Option 3</b></small>
              <label class="switch switch-label switch-pill switch-success switch-sm float-right">
                <input type="checkbox" class="switch-input">
                <span class="switch-slider" data-checked="On" data-unchecked="Off"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
          </div>
          <div class="aside-options">
            <div class="clearfix mt-3">
              <small><b>Option 4</b></small>
              <label class="switch switch-label switch-pill switch-success switch-sm float-right">
                <input type="checkbox" class="switch-input" checked>
                <span class="switch-slider" data-checked="On" data-unchecked="Off"></span>
              </label>
            </div>
          </div>
          <hr>
          <h6>System Utilization</h6>
          <div class="text-uppercase mb-1 mt-4"><small><b>CPU Usage</b></small></div>
          <div class="progress progress-xs">
            <div class="progress-bar bg-info" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <small class="text-muted">348 Processes. 1/4 Cores.</small>
          <div class="text-uppercase mb-1 mt-2"><small><b>Memory Usage</b></small></div>
          <div class="progress progress-xs">
            <div class="progress-bar bg-warning" role="progressbar" style="width: 70%" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <small class="text-muted">11444GB/16384MB</small>
          <div class="text-uppercase mb-1 mt-2"><small><b>SSD 1 Usage</b></small></div>
          <div class="progress progress-xs">
            <div class="progress-bar bg-danger" role="progressbar" style="width: 95%" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <small class="text-muted">243GB/256GB</small>
          <div class="text-uppercase mb-1 mt-2"><small><b>SSD 2 Usage</b></small></div>
          <div class="progress progress-xs">
            <div class="progress-bar bg-success" role="progressbar" style="width: 10%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <small class="text-muted">25GB/256GB</small>
        </div> -->
      </tab>
    </tabset>
  </div>
</div>
<app-footer>
  <!-- <span><a href="http://iton.com/" target="_blank">ITON</a> &copy; 2018</span> -->
  <span style="text-align: center;"><a href="https://www.citimedia.com/" target="_blank">More Citimedia</a>
  <span style="padding: 20px;">Terms & Conditions</span><span>Help</span>
  </span>
  <span class="ml-auto">Copyright &copy; 2018 <a href="https://www.citimedia.com/" target="_blank">Citimedia</a></span>
</app-footer>

<div class="sidebar-backdrop visible" id="video_backdrop" style="display:none;">
  <div class="sidebar-panel" style="background: transparent;width:100%">
  
  
  
  
  <div id="localVideo"  style=" width: 250px;height: 150px;position: absolute;bottom: 5px;right: 5px;"></div> 
  <div class="timer-time" id="timerunner" style="bottom: 10px;position: absolute;left: 10px;">
    <span style="display:block !important;color:white !important;">00:00:00</span>
  </div>
  <span class="absolute-count" style="display:none !important;">0</span>
  <button id="endACall" class="endcall" style="bottom: 14px;position: absolute;left: 30%;"></button> 
  <div class="video-thumb-scroll" style="width: 0%;"></div> 
  <div class="guest-main-video" style="width:100%;" [style.height.px]="screenheight"></div>
  
  <audio id="soundPlayer"></audio>
  
  </div>
  </div>
  <audio id="myNotification">
    <source src="assets/notifications/notify.mp3" type="audio/mpeg">
  </audio>
  <audio id="sentNotification">
    <source src="assets/notifications/senttone.mp3" type="audio/mpeg">
  </audio>
