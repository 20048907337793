<div tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title pull-left" style="font-size: 15px;font-weight: 600;">
          <span style="padding-left: 5px;">Email</span>
        </div>
        <button type="button" class="close pull-right my_closex" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true" style="font-size: 21px;padding: 5px;">&times;</span>
        </button>
      </div>
      <div class="modal-body" style='padding:0px;overflow: scroll;background: #f1f3f5;'>
        <app-emailsendcampaigns></app-emailsendcampaigns>
      </div>
    </div>
  </div>
</div>