import { Component, OnInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Http, Headers } from '@angular/http';
import { Smsservice } from './sms.service';
import { DialogComponent } from 'ngx-bootstrap-modal/index';
import { DialogService } from 'ngx-bootstrap-modal/components/dialog.service';
import { CitiservicesService } from '../../../citiservices.service';
import { Router, Event, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
export interface ConfirmModel {
  title: string;
  message: string;
}

@Component({
  selector: 'app-sms-count',
  templateUrl: './sms-count.component.html',
  styleUrls: ['./sms-count.component.scss']
})
export class SmsCountComponent extends DialogComponent<ConfirmModel, string> implements ConfirmModel, OnInit {
  public myModal;
  title: string;
  sampleData: any = [];
  sampleData1: any = [];
  sampleData2: any = [];
  sms_type = '';
  public largeModal;
  public smallModal;
  public primaryModal;
  public successModal;
  public warningModal;
  public dangerModal;
  public infoModal;
  obj_value: any;
  obj: any = {};
  count: any;
  senderId = '';
  templateId = '';
  language = 'English';
  message: string;
  messagee: any;
  length: any;
  senderIdsArray: any = [];
  templatesArray: any = [];
  unicodetext: boolean;
  sendObj: any = {};
  englishtext = true;
  textareaDisable = false;
  spinner_canavas = false;
  constructor(private http: Http, dialogService: DialogService, private citiservice: CitiservicesService, private route: Router, private toastr: ToastrService) {
    super(dialogService);


  }

  ngOnInit() {
    this.route.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        const closeobj: any = {
          message: undefined
        };
        this.close(closeobj);
      }

    });

    if (this.obj === undefined) {
      if ((<any>window).userprofile[0].smsRoutes.length === 0) {
        this.sampleData = [];
        this.showError('Server is busy, Please try again');
      } else {
        for (let i = 0; i < (<any>window).userprofile[0].smsRoutes.length; i++) {
          this.sampleData.push((<any>window).userprofile[0].smsRoutes[i]);
        }
      }
    } else {
      if ((<any>window).userprofile[0].smsRoutes.length === 0) {
        this.sampleData = [];
        this.showError('Server is busy, Please try again');
      } else {
        for (let i = 0; i < (<any>window).userprofile[0].smsRoutes.length; i++) {
          this.sampleData.push((<any>window).userprofile[0].smsRoutes[i]);
        }
      }
      this.getsources(this.obj.sms_type, 'obj');
      this.getTemplates(this.obj.senderId, 'obj');
      this.sms_type = this.obj.sms_type;
      this.senderId = this.obj.senderId;
      this.templateId = this.obj.templateId;
      this.language = this.obj.language;
      this.sendObj.messagee = this.obj.message;
      this.sendObj.length = this.obj.length;
      this.sendObj.count = this.obj.count;
      const evt = { target: { value: this.language } };
      this.changeLanguage(evt, '');
      if (this.obj.templateId === undefined || this.obj.templateId === '') {
        this.textareaDisable = false;
      } else {
        this.textareaDisable = true;
      }
    }

  }


  getsources(evt, val) {
    this.spinner_canavas = true;
    if (val === 'obj') {
      this.citiservice.getSourceId(evt).subscribe(data => {
        const res_data = data.json();
        if (res_data.status === 'success') {
          this.spinner_canavas = false;
          const respdata = data.json().response;
          this.senderIdsArray = [];
          for (let i = 0; i < respdata.length; i++) {
            this.senderIdsArray.push(respdata[i].SenderId);
          }

        } else {
          this.showError(res_data.message);
          // console.log("failed")
          this.spinner_canavas = false;
//          //// console.log(data.json().message.toLowerCase())
//          if (res_data.message.toLowerCase() === 'token mismatch') {
//            this.citiservice.logout().subscribe(data_logout => {
//              if (data_logout.json().status === 'success') {
//                // console.log('successfully logged out');
//                this.showError('Session Expired');
//                window.location.reload();
//                // this.router.navigate(['/login'], { skipLocationChange: true});
//                (<any>window).tabs = [];
//                (<any>window).font_awesome = [];
//                (<any>window).citiToken = '';
//                (<any>window).userprofile = [];
//                (<any>window).walletDetails = [];
//              } else {
//                this.showError(data_logout.json().message);
//                window.location.reload();
//              }
//            });
//          } else 
//            this.showError(res_data.message);
          
        }


      },
        error => {
          this.showError('Server is busy, Please try again');
          // console.log(JSON.stringify(error.json()));
          this.spinner_canavas = false;
        }
      );
    } else {
      this.citiservice.getSourceId(evt).subscribe(data => {
        const res_data = data.json();
        if (res_data.status === 'success') {
          this.spinner_canavas = false;
          const respdata = data.json().response;
          this.senderIdsArray = [];
          this.templatesArray = [];
          this.senderId = '';
          this.sendObj.messagee = '';
          this.textareaDisable = false;
          for (let i = 0; i < respdata.length; i++) {
            this.senderIdsArray.push(respdata[i].SenderId);
          }

        } else {
          this.showError(res_data.message);
          // console.log("failed")
          this.spinner_canavas = false;
//          //// console.log(data.json().message.toLowerCase())
//          if (res_data.message.toLowerCase() === 'token mismatch') {
//            this.citiservice.logout().subscribe(data_logout => {
//              if (data_logout.json().status === 'success') {
//                // console.log('successfully logged out');
//                this.showError('Session Expired');
//                window.location.reload();
//                // this.router.navigate(['/login'], { skipLocationChange: true});
//                (<any>window).tabs = [];
//                (<any>window).font_awesome = [];
//                (<any>window).citiToken = '';
//                (<any>window).userprofile = [];
//                (<any>window).walletDetails = [];
//              } else {
//                this.showError(data_logout.json().message);
//                window.location.reload();
//              }
//            });
//          } else {
//            this.showError(res_data.message);
//          }
        }
      },
        error => {
          this.showError('Server is busy, Please try again');
          // console.log(JSON.stringify(error.json()));
          this.spinner_canavas = false;
        }
      );
    }
  }


  sample(data) {

    this.sendObj.count = 1;
    this.sendObj.length = data.length;
    if (this.sendObj.length <= 160) {
      this.sendObj.count = 1;
    } else {
      const templenght = this.sendObj.length - 160;
      const tempcount = templenght / 153;
      const finalLength = Math.ceil(tempcount);
      this.sendObj.count = this.sendObj.count + finalLength;

    }

  }


  sampleUni(data) {

    this.sendObj.count = 1;

    this.sendObj.length = data.length;
    if (this.sendObj.length <= 70) {
      this.sendObj.count = 1;
    } else {
      const templenght = this.sendObj.length - 70;
      const tempcount = templenght / 67;
      const finalLength = Math.ceil(tempcount);
      this.sendObj.count = this.sendObj.count + finalLength;

    }

  }

  changeLanguage(evt, ind) {

    this.sendObj.language = evt.target.value;
    if (evt.target.value === 'Unicode') {
      this.unicodetext = true;
      this.englishtext = false;
      if (this.sendObj.messagee !== undefined && this.sendObj.messagee.length > 0) {
        // console.log("}}}}}}}}}}}}}}}]", this.sendObj.messagee)
        this.sampleUni(this.sendObj);
      } else {
        // console.log("second")
      }
    }
    if (evt.target.value === 'English') {
      this.englishtext = true;
      this.unicodetext = false;
      if (this.sendObj.messagee !== undefined && this.sendObj.messagee.length > 0) {
        this.sample(this.sendObj);
      } else {
        // console.log("initial")
      }
    }
  }

  closeModal() {
    if (this.sms_type === '' || this.sms_type === undefined || this.sms_type === null) {
      this.showError('Please Select Route');
    } else if (this.senderId === '' || this.senderId === undefined || this.senderId === null) {
      this.showError('Please Select Sender');
    } else if (this.sendObj.messagee === '' || this.sendObj.messagee === undefined || this.sendObj.messagee === null) {
      this.showError('Please Enter Message');
    } else {
      this.obj = {
        sms_type: this.sms_type,
        senderId: this.senderId,
        templateId: this.templateId,
        language: this.language,
        message: this.sendObj.messagee,
        length: this.sendObj.length,
        count: this.sendObj.count,
        isFlash: false,
      };
      this.close(this.obj);
    }

    // this.smsservice.closeconfirm(this.obj);
  }


  getTemplates(templateid, val) {
    if (val === 'obj') {
      this.citiservice.getTemplateIds(templateid).subscribe(data => {
        const res_data = data.json();
        if (res_data.status === 'success') {
          this.spinner_canavas = false;
          const myresponse = data.json();
          this.templatesArray = myresponse.response;
        } else {
          // console.log("failed")
          this.spinner_canavas = false;
          this.showError(res_data.message);
//          //// console.log(data.json().message.toLowerCase())
//          if (res_data.message.toLowerCase() === 'token mismatch') {
//            this.citiservice.logout().subscribe(data_logout => {
//              if (data_logout.json().status === 'success') {
//                // console.log('successfully logged out');
//                this.showError('Session Expired');
//                window.location.reload();
//                // this.router.navigate(['/login'], { skipLocationChange: true});
//                (<any>window).tabs = [];
//                (<any>window).font_awesome = [];
//                (<any>window).citiToken = '';
//                (<any>window).userprofile = [];
//                (<any>window).walletDetails = [];
//              } else {
//                this.showError(data_logout.json().message);
//                window.location.reload();
//              }
//            });
//          } else {
//            this.showError(res_data.message);
//          }
        }
      },
        error => {
          this.showError('Server is busy, Please try again');
          // console.log(JSON.stringify(error.json()));
          this.spinner_canavas = false;
        }
      );
    } else {
      this.citiservice.getTemplateIds(templateid).subscribe(data => {
        const res_data = data.json();
        if (res_data.status === 'success') {
          this.spinner_canavas = false;
          const myresponse = data.json();
          this.templatesArray = [];
          this.templateId = '';
          this.sendObj.messagee = '';
          this.textareaDisable = false;
          this.templatesArray = myresponse.response;
        } else {
          // console.log("failed")
          this.spinner_canavas = false;
          this.showError(res_data.message);
          //// console.log(data.json().message.toLowerCase())
//          if (res_data.message.toLowerCase() === 'token mismatch') {
//            this.citiservice.logout().subscribe(data_logout => {
//              if (data_logout.json().status === 'success') {
//                // console.log('successfully logged out');
//                this.showError('Session Expired');
//                window.location.reload();
//                // this.router.navigate(['/login'], { skipLocationChange: true});
//                (<any>window).tabs = [];
//                (<any>window).font_awesome = [];
//                (<any>window).citiToken = '';
//                (<any>window).userprofile = [];
//                (<any>window).walletDetails = [];
//              } else {
//                this.showError(data_logout.json().message);
//                window.location.reload();
//              }
//            });
//          } else {
//            this.showError(res_data.message);
//          }
        }
      },
        error => {
          this.showError('Server is busy, Please try again');
          // console.log(JSON.stringify(error.json()));
          this.spinner_canavas = false;
        }
      );
    }

  }

  setdescription(id) {
    if (id === '' || id === undefined || id === null) {
      this.textareaDisable = false;
      this.sendObj.messagee = '';
      this.sendObj.length = 0;
      this.sendObj.count = 0;
    } else {
      for (let i = 0; i < this.templatesArray.length; i++) {
        if (id === this.templatesArray[i].id) {

          this.textareaDisable = true;
          this.templateId = id;
          this.sendObj.messagee = '';
          this.sendObj.messagee = this.templatesArray[i].MessageBody;
          if (this.language === 'English') {
            this.sample(this.sendObj.messagee);
          } else {
            this.sampleUni(this.sendObj.messagee);
          }

        }
      }
    }

  }


  showError(data) {
    if (this.toastr.currentlyActive === 0) {
      //// console.log("active toasts = "+ this.toastr.currentlyActive +" In if");
      this.toastr.error(data, '', {
        timeOut: 1500,
        positionClass: 'toast-bottom-right'
      });
    }
  }


}
