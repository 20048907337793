import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { PreloadAllModules } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Dashboard'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'socialmonitor',
        loadChildren: './views/socialmonitor/socialmonitor.module#SocialmonitorModule'
      },
      {
        path: 'admanager',
        loadChildren: './views/admanager/admanager.module#AdmanagerModule'
      },
      {
        path: 'keywordanalytics',
        loadChildren: './views/keywordanalytics/keywordanalytics.module#KeywordanalyticsModule'
      },
      {
        path: 'journeybuilder',
        loadChildren: './views/journeybuilder/journeybuilder.module#JourneybuilderModule'
      },
      {
        path: 'datasets',
        loadChildren: './views/datasets/datasets.module#DatasetsModule'
      },
      {
        path: 'contentmanager',
        loadChildren: './views/contentmanager/contentmanager.module#ContentmanagerModule'
      },
      {
        path: 'metro',
        loadChildren: './views/metro/metro.module#MetroModule'
      },
      {
        path: 'admin',
        loadChildren: './views/admin/admin.module#AdminModule'
      },
      {
        path: 'settings',
        loadChildren: './views/settings/settings.module#SettingsModule'
      },
      {
        path: 'yourprofile',
        loadChildren: './views/settings/yourprofile/yourprofile.module#YourprofileModule'
      },
      {
        path: 'changepassword',
        loadChildren: './views/settings/changepassword/changepassword.module#ChangepasswordModule'
      }
    ]
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes,
    {
      enableTracing: false, // <-- debugging purposes only
      preloadingStrategy: PreloadAllModules
    }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
