<div tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class=" modal-dialog" role="document">
    <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title pull-left" style="font-size: 15px;font-weight: 600;">
            <span style="padding-left: 5px;">Wait</span>
          </div>
          <button type="button" class="close pull-right my_closex" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true" style="font-size: 21px;">&times;</span>
          </button>
        </div>
      <div class="modal-body">
        <div class="form-group row" style="margin-bottom: 0.2rem;">
          <label class="col-md-4 col-form-label"> Start Time</label>
          <div class="col-md-8">
            <angular2-date-picker 
            [(ngModel)]="start_time" 
              [settings]="settings" >
            </angular2-date-picker>
          </div>
        </div>
        <div class="form-group row" style="margin-bottom: 0.2rem;">
          <label class="col-md-4 col-form-label"> End Time</label>
          <div class="col-md-8">
            <angular2-date-picker 
            [(ngModel)]="end_time" 
              [settings]="settings" >
            </angular2-date-picker>
          </div>
        </div>
        <!-- <div class="form-group row" style="margin-bottom: 0.2rem;">
					<label  class="col-md-4 col-form-label" id="label" for="text-input">Time Zone</label>
					<div class="col-md-8">
						<select class="form-control" [(ngModel)]="time_zone"  >
							<option value="melbourne" selected >(UTC+10.00) Canberra,melbourne,sydney</option>
							<option value="Tanuku">(UTC+10.00) Tanuku,India </option>
							<option value="Afganistan">Afganistan timings</option>
						</select>
					</div>
        </div> -->
        <div class="form-group row" style="margin-bottom: 0.2rem;">
					<label  class="col-md-4 col-form-label" id="label" for="text-input">Days Of the Week</label>
					<div class="col-md-8">
					<button type="button" class="btn btn-sm btn-primary">Sun</button>
          <button type="button" class="btn btn-sm btn-primary">Mon</button>
          <button type="button" class="btn btn-sm btn-primary">Tue</button>
          <button type="button" class="btn btn-sm btn-primary">Wed</button>
          <button type="button" class="btn btn-sm btn-primary">Thu</button>
          <button type="button" class="btn btn-sm btn-primary">Fri</button>
          <button type="button" class="btn btn-sm btn-primary">Sat</button>
					</div>
        </div>




      </div>

     
    </div>
   
  </div>
 
</div>
