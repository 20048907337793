<div tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class=" modal-dialog" role="document" style="max-width: 60%;">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title pull-left" style="font-size: 15px;font-weight: 600;">
          <span style="padding-left: 5px;">Decision</span>
        </div>
        <button type="button" class="close pull-right my_closex" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true" style="font-size: 21px;">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #userlogin="ngForm">
          <div class="form-group row" style="margin-bottom: 0.2rem;">
            <div class="col-md-2">
              <label>Decision Name</label>
              <span style="color: red">*</span>
            </div>
            <div class="col-md-10">
              <input type="text" [(ngModel)]="decision_name" name="decision" class="form-control" placeholder="Decision Name">
            </div>
          </div>
          <div class="form-group row" style="margin-bottom: 0.2rem;">
            <div class="col-md-2">
              <label for="text-input">Choose for</label>
              <span style="color: red">*</span>
            </div>
            <div class="col-md-10">
              <select name="type" class="form-control" [(ngModel)]="type_message" (change)="selectType(type_message)" >
                <option value="">Please Type</option>
                <option value="SMS">SMS</option>
                <option value="EMAIL">EMAIL</option>
                <!-- <option value="2">Undelivered</option>
                <option value="3">Failed</option> -->
              </select>
            </div>
          </div>

          <div class="form-group row" style="margin-bottom: 0.2rem;">
            <div class="col-md-2">
              <label for="text-input">SendFor</label>
              <span style="color: red">*</span>
            </div>
            <div class="col-md-10">
              <select name="select1" class="form-control" [(ngModel)]="send_type">
                <option value="">Select Condition</option>
                <option value="all">All</option>
                <option value="delivrd">Delivered</option>
                <option value="failed">Failed</option>
              </select>
            </div>
          </div>
          <div class="form-group row" style="margin-bottom: 0.2rem;">
            <div class="col-md-2">
              <label for="text-input">Check Condition</label>
              <span style="color: red">*</span>
            </div>
            <div class="col-md-4">
              <select name="select2" class="form-control" [(ngModel)]="condition_type">
                <option *ngFor="let opt of conditionTypeOptions" [value]="opt.value">{{opt.name}}
                <!-- <option value="">Select Condition</option>
                <option value="delivrd">Delivered</option>
                <option value="failed">Failed</option> -->
              </select>
            </div>
            <div class="col-md-4">
              <select name="select3" class="form-control" [(ngModel)]="relation_type">
                <option value="">Select Relation</option>
                <option value=">">></option>
                <option value=">=">>=</option>
                <option value="<">
                  <</option>
                    <option value="<=">
                      <=</option>
                        <option value="="> = </option>
              </select>
            </div>
            <div class="col-md-2">
              <input type="number" name="number1" [(ngModel)]="relation_number" class="form-control" placeholder="Number">

            </div>
          </div>
          <div class="form-group row" style="margin-bottom: 0.2rem;">
            <div class="col-md-2">
              <label for="text-input">If Yes</label>
              <span style="color: red">*</span>
            </div>
            <div class="col-md-10">
              {{yes_text}}
            </div>
          </div>
          <div class="form-group row" style="margin-bottom: 0.2rem;">
            <div class="col-md-2">
              <label for="text-input">If No</label>
              <span style="color: red">*</span>
            </div>
            <div class="col-md-10">
              {{no_text}}
            </div>
          </div>
          <!-- <div class="form-group row" style="margin-bottom: 0.2rem;">
            <label class="col-md-4 col-form-label" for="text-input">Relation</label>
           
            
          </div> -->
        </form>
      </div>
    </div>
  </div>
</div>