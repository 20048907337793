import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { EmailsendcampaignsModule } from '../../admanager/email/emailsendcampaigns/emailsendcampaigns.module';
import { AudiencePlannerModule } from '../audience-planner/audience-planner.module';
import { EmailmodalComponent } from './emailmodal.component';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        EmailsendcampaignsModule,
        AudiencePlannerModule,

    ],
    declarations: [ EmailmodalComponent
    ],
    exports: [EmailmodalComponent],
})
export class EmailModalModule { }
