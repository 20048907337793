import { Injectable } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
@Injectable({
  providedIn: 'root'
})
export class UpdateyourprofileService {
  orgid: any;
  constructor(public http: Http) {
// this.orgid=(<any>window).orgId
   }

   // get all user profiles//
  getAllUsersprofile() {
   // console.log(evt)
  const headers = new Headers();
   headers.append('Content-Type', 'application/x-www-form-urlencoded');
   headers.append('X-Apikey', (<any>window).citikey);
   headers.append('X-Citistar-Token', (<any>window).citiToken);
   // console.log((<any>window).citiUrl + 'campaigns/sms/registrations?route='+evt)
   return this.http.get((<any>window).citiUrl + '/users/profile?orgId=' + '5a619afe4bd4645394a36325', {headers: headers});
 }

//  get single user info//
 getSingleUser() {
  // console.log(evt)
  const headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('X-Apikey', (<any>window).citikey);
  headers.append('X-Citistar-Token', (<any>window).citiToken);
  // console.log((<any>window).citiUrl + 'campaigns/sms/registrations?route='+evt)
  return this.http.get((<any>window).citiUrl + 'users/profile', {headers: headers});
}

// update user profile//
//  updateUsersprofileInfo(profile,orgid){
//   console.log(profile)
//   // var body='fullname='+profile.name+'&country='+profile.country+'&company='+profile.company+'&companyurl='+profile.companyurl+'&industry='+profile.industrytype+'&mobileNumber='+profile.mobilenumber+'&email='+profile.email+'&orgId='+orgid+'&timezone='+profile.timezone;
//   var fd = new URLSearchParams();
//     fd.append('fullname',profile.name);
//     fd.append('country',profile.country);
//     fd.append('company',profile.company)
//     fd.append('companyurl',profile.companyurl)
//     fd.append('industry',profile.industrytype)
//     fd.append('mobileNumber',profile.mobilenumber)
//     fd.append('email',profile.email)
//     fd.append('orgId',orgid)
//     fd.append('timezone',profile.timezone)
//   console.log(fd)
//   var headers = new Headers();
//   //headers.append('Content-Type','application/x-www-form-urlencoded');
//   headers.append('X-Apikey',(<any>window).citikey);
//   headers.append('X-Citistar-Token', (<any>window).citiToken);
//   //console.log((<any>window).citiUrl + 'campaigns/sms/registrations?route='+evt)
//   return this.http.put((<any>window).citiUrl + 'users/profile?',fd ,{headers: headers})
//    //fullname,country,company,companyurl,industry,mobileNumber,orgId
// }

// service for get countries list//
getCountries() {
  // console.log(evt)
  const headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('X-Apikey', (<any>window).citikey);
  //headers.append('X-Citistar-Token', (<any>window).citiToken);
  // console.log((<any>window).citiUrl + 'campaigns/sms/registrations?route='+evt)
  return this.http.get((<any>window).citiUrl + 'lists/countries'
   , {headers: headers});
}

// service for get states list//
getStates(countryid) {
  //console.log(countryid);
  const headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('X-Apikey', (<any>window).citikey);
   return this.http.get((<any>window).citiUrl + 'lists/countries/'+ countryid +'/states' , {headers: headers});
}

// service for get cities list//
getCities(stateid) {
  //console.log(stateid);
  const headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('X-Apikey', (<any>window).citikey);
   return this.http.get((<any>window).citiUrl + 'lists/countries/states/' + stateid +'/cities', {headers: headers});
}

// service for get industries list//
getIndustriesTypes() {
  const headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('X-Apikey', (<any>window).citikey);
  headers.append('X-Citistar-Token', (<any>window).citiToken);
   return this.http.get((<any>window).citiUrl + 'lists/industriestype', {headers: headers});
}

// service for deactivate account//
deactivateAccount(orgid) {
  console.log(orgid);
  const headers = new Headers();
  headers.append('Content-Type','application/x-www-form-urlencoded');
  headers.append('X-Apikey',(<any>window).citikey);
  headers.append('X-Citistar-Token', (<any>window).citiToken);
  return this.http.delete((<any>window).citiUrl + '/users/profile?orgId=' + orgid);
}

}
