import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { DialogComponent } from 'ngx-bootstrap-modal/index';
import { DialogService } from 'ngx-bootstrap-modal/components/dialog.service';
import { CitiservicesService } from '../../citiservices.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { Router, Event, NavigationEnd } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { v4 as uuid } from 'uuid';
import { MobileverificationComponent } from './mobileverification/mobileverification.component';

export interface ConfirmModel {
  title: string;
  message: string;
}

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  providers: [DatePipe]
})
export class SignupComponent extends DialogComponent<ConfirmModel, string> implements ConfirmModel, OnInit {
  @ViewChild('myUserName') userNameElement: ElementRef;
  @ViewChild('myEmail') emailElement: ElementRef;
  @ViewChild('myMobileNum') mobileElement: ElementRef;
  title: string;
  message: string;
  countrieslist: any = [];
  statesList: any = [];
  citieslist: any = [];
  months: any = [];
  selectedcountryname: any;
  selectedStateName: any;
  selectedindustry: any;
  years: any = [];
  flags: any;
  mydate: any;
  industriestypelist: any = [];
  username: any;
  email: any;
  password: any;
  select_country: any;
  select_state: any;
  select_city: any;
  pincode: any;
  mobilenum: any;
  phonecode: any;
  address: any;
  companyname: any;
  industry: any;
  companyUrl: any;
  cardname: any;
  cardnum: any;
  cvv: any;
  month: any;
  year: any;
  deviceInfo: any;
  latitude: any;
  longitude: any;
  uuidParam: any;
  deviceType: any;
  deviceOs: any;
  initLocationArray: any = [];
  closeData: any;
  todaydate: any;
  newdate: any;
  yearsvalue: any;
  monthvalue: any;
  constructor(public deviceService: DeviceDetectorService, public router: Router, dialogService: DialogService, public http: Http, public myServ: CitiservicesService, public toastr: ToastrService, public datePipe: DatePipe) {
    super(dialogService);
    this.getcountries();
    this.getindustrytype();
    this.months = [{ value: '01', name: 'January' }, { value: '02', name: 'Febuary' }, { value: '03', name: 'March' }, { value: '04', name: 'April' },
    { value: '05', name: 'May' }, { value: '06', name: 'June' }, { value: '07', name: 'July' }, { value: '08', name: 'August' }, { value: '09', name: 'September' }, { value: '10', name: 'October' }, { value: '11', name: 'November' }, { value: '12', name: 'December' }];
    this.years = [{ value: '2033' }, { value: '2032' }, { value: '2031' }, { value: '2030' }, { value: '2029' }, { value: '2028' }, { value: '2027' }, { value: '2026' }, { value: '2025' }, { value: '2024' }, { value: '2023' }, { value: '2022' }, { value: '2021' }, { value: '2020' }, { value: '2019' }, { value: '2018' }];
    this.cardname = '';
    this.cardnum = '';
    this.cvv = '';
    this.month = '';
    this.year = '';
    // this.monthvalue = [];
    for (let i = 0; i < this.years.length; i++) {
      this.yearsvalue = this.years[i].value - 1;
      this.todaydate = new Date().getFullYear();
      this.today = new Date().getMonth();
      if (this.yearsvalue === this.todaydate) {
        for (let j = 0; j < this.months.length; j++) {
          if (this.months[j].value - 1 === this.today) {
            this.monthvalue = this.months.slice(j);
          }
        }
      } else {
        for (let k = 0; k < this.years.length; k++) {
          if (this.todaydate > this.years[k].value) {
            this.years.pop(this.years[k].value);
          }
        }
      }
    }
  }

  ngOnInit() {
    this.router.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {
      this.close();
      }
    });
    // this.initLocationArray = [];
    navigator.geolocation.getCurrentPosition((position) => {
      this.initLocationArray.push(position.coords.latitude);
      this.initLocationArray.push(position.coords.longitude);
    });
    //console.log(this.initLocationArray);
    this.uuidParam = uuid();
   // console.log('//////////', this.initLocationArray);
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.deviceType = this.deviceInfo.browser;
    this.deviceOs = this.deviceInfo.os;
    this.select_country = '';
    this.select_state = '';
    this.select_city = '';
    this.industry = '';
    this.month = '';
    this.year = '';
  }

  closeModal() {
    this.close();
  }

  selectcountry(event) {
    document.getElementById('copyright').style.display = 'block';
    for (let i = 0; i < this.countrieslist.length; i++) {
      if (event === this.countrieslist[i].countryId) {
        this.phonecode = this.countrieslist[i].phoneCode;
        this.selectedcountryname = this.countrieslist[i].countryName;
        this.flags = (<any>window).citiUrl + this.countrieslist[i].flag;
      }
    }
    // this.statesList=[];
    this.citieslist = [];
    this.select_state = '';
    this.select_city = '';
    this.getstates(event);

  }

  selectstate(event) {
    for (let i = 0; i < this.statesList.length; i++) {
      if (event === this.statesList[i].stateId) {
        this.selectedStateName = this.statesList[i].stateName;
      }
    }

    this.getcities(event);
  }

  selectindustry(event) {
    this.selectedindustry = event;
  }

  selectyear(event) {
    this.todaydate = new Date().getFullYear();
    if (event > this.todaydate) {
      this.monthvalue = this.months;
    } else {
      this.monthvalue = [];
      for (let i = 0; i < this.years.length; i++) {
        this.yearsvalue = this.years[i].value - 1;
        this.todaydate = new Date().getFullYear();
        this.today = new Date().getMonth();
        if (this.yearsvalue === this.todaydate) {
          for (let j = 0; j < this.months.length; j++) {
            if (this.months[j].value - 1 === this.today) {
              this.monthvalue = this.months.slice(j);
            }
          }
        }
      }
    }
  }
  selectmonth(event) {
    //console.log(event);
  }

  getcountries() {
    this.myServ.getcountries().subscribe(data => {
      if (data.json().status === 'success') {
        this.countrieslist = data.json().countryList;
      }else {
        //console.log(data.json());
        this.showError(data.json().message);
      }
    }, error => {
      this.showError('Server is busy, Please try again');
      // console.log(JSON.stringify(error.json()));
      //console.log(error);
    });
  }


  getstates(event) {
    this.myServ.getstates(event).subscribe(data => {
      if (data.json().status === 'success') {
        this.statesList = [];
        if (data.json().stateList.length === 0) {
          this.showSuccess('No States found for the selected country');
        }
        else {
          this.statesList = data.json().stateList;
        }
      }else {
        this.showError(data.json().message);
        //console.log(data.json());
      }
    }, error => {
        this.showError('Server is busy, Please try again');
    });
  }


  getcities(event) {
    this.myServ.getcities(event).subscribe(data => {
      if (data.json().status === 'success') {
        this.citieslist = [];
        if (data.json().cityList.length === 0) {
          this.showSuccess('No cities found for the selected state');
        }
        else {
          this.citieslist = data.json().cityList;
        }
      }else {
        this.showError(data.json().message);
        //console.log(data.json());
      }
    }, error => {
      this.showError('Server is busy, Please try again');
    });
  }


  getindustrytype() {
    this.myServ.getindustrytype().subscribe(data => {
      if (data.json().status === 'success') {
        this.industriestypelist = data.json().industriesList;
      }
    });
  }

  userDetailsCheck(fieldName, type) {
    const usernameregex = /^[a-zA-Z]+$/;
    const mobileregex = /^[0-9]+$/;
    const usernamefirstletterregex = /^[A-Z][a-zA-Z0-9]+$/;
    const emailregex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    let isCheckFieldValue = false;
    if (!(fieldName === undefined || fieldName === '')) {
      let body = '';
      if (type === 'username' && fieldName.match(usernameregex) && fieldName.match(usernamefirstletterregex)) {
        isCheckFieldValue = true;
        body = 'userName=' + fieldName;
      }
      if (type === 'email' && fieldName.match(emailregex)) {
        isCheckFieldValue = true;
        body = 'email=' + fieldName;
      }
      if (type === 'mobile' && fieldName.match(mobileregex)) {
        isCheckFieldValue = true;
        body = 'mobile=' + fieldName;
      }
      if (isCheckFieldValue) {
        const headers = new Headers();
        headers.append('X-Apikey', (<any>window).citikey);
        headers.append('X-Citistar-Token', (<any>window).citiToken);
        this.http.get((<any>window).citiUrl + 'users/details/exists?' + body, { headers: headers })
          .map(res => res.json())
          .subscribe(data => {
            if (data.status === 'success') {
              this.showError(data.message);
              if (type === 'username') {
                this.userNameElement.nativeElement.focus();
              }
              if (type === 'email') {
                this.emailElement.nativeElement.focus();
              }
              if (type === 'mobile') {
                this.mobileElement.nativeElement.focus();
              }
            }
          }, error => {
            this.showError('Server is busy, Please try again');
          });
      }
    }
  }


  signupbutton() {
    const emailregex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    const usernameregex = /^[a-zA-Z]+$/;
    const mobileregex = /^[0-9]+$/;
    const cardnumregex = /^[0-9]+$/;
    const cvvnumregex = /^[0-9]+$/;
    const usernamefirstletterregex = /^[A-Z][a-zA-Z0-9]+$/;
    // var passwordregex="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
    const passwordregex = /^(?=^.{6,12}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/;
    if (this.username === undefined || this.username === '') {
      this.showError('Please Enter User Name');
    } else if (!this.username.match(usernameregex)) {
      this.showError('UserName must contain only alphabets');
    } else if (!this.username.match(usernamefirstletterregex)) {
      this.showError('UserName must start with capital letter');

    } else if (this.username.length < 3 || this.username.length > 30) {
      this.showError('UserName must contain atleast 3 characters');

    } else if (this.email === undefined || this.email === '') {
      this.showError('Please Enter Email');

    } else if (!this.email.match(emailregex)) {
      this.showError('Please Enter Valid Email');

    } else if (this.password === undefined || this.password === '') {
      this.showError('Please Enter Password');

    } else if (this.password.length < 6 || this.password.length > 12) {
      this.showError('Password must be 6-12 Characters');

    } else if (!this.password.match(passwordregex)) {
      this.showError('Password should have atleast one capital, one Lower,  one Number & one special characters');

    } else if (this.select_country === undefined || this.select_country === '') {
      this.showError('Please Select Country');

    } else if (this.select_state === undefined || this.select_state === '') {
      this.showError('Please Select State');

    } else if (this.select_city === undefined || this.select_city === '') {
      this.showError('Please Select City');

    } else if (this.pincode === undefined || this.pincode === '') {
      this.showError('Please Enter Pincode');

    } else if (this.mobilenum === undefined || this.mobilenum === '') {
      this.showError('Please Enter Mobile number');

    } else if (!this.mobilenum.match(mobileregex)) {
      this.showError('Please Enter valid Mobile number');

    } else if (this.address === undefined || this.address === '') {
      this.showError('Please Enter Address');

    } else if (this.companyname === undefined || this.companyname === '') {
      this.showError('Please Enter CompanyName');

    } else if (this.industry === undefined || this.industry === '') {
      this.showError('Please Select IndustryType');

    } else if (this.companyUrl === undefined || this.companyUrl === '') {
      this.showError('Please Enter CompanyUrl');
      } else if (this.initLocationArray.length === 0) {
        this.initLocationArray = [0.0, 0.0];

      // } else if(this.cardnum === undefined || this.cardnum === ''){
      //   this.showError('Please enter card number');

      // } else if(!this.cardnum.match(cardnumregex)){
      //   this.showError('Please enter valid card number');


      // } else if(this.cvv === undefined || this.cvv === ''){
      //   this.showError('Please enter cvv');

      // } else if(!this.cvv.match(cvvnumregex)){
      //   this.showError('Please enter valid cvv');

      // } else if(this.month === undefined || this.month === ''){
      //   this.showError('Please select month');

      // } else if(this.year === undefined || this.year === ''){
      //   this.showError('Please select year');

    }  else {
     //console.log(this.initLocationArray);
      this.mydate = new Date();
      const fd = new FormData();
      fd.append('country', this.selectedcountryname);
      fd.append('state', this.selectedStateName);
      fd.append('city', this.select_city);
      fd.append('password', this.password);
      fd.append('timezone', this.mydate);
      fd.append('industry', this.selectedindustry);
      fd.append('cardNumber', this.cardnum);
      fd.append('cardName', this.cardname);
      fd.append('cardYear', this.year);
      fd.append('cardMonth', this.month);
      fd.append('cardCvv', this.cvv);
      fd.append('userName', this.username);
      // fd.append('phoneCode',this.phonecode)
      fd.append('mobile', this.phonecode + this.mobilenum);
      fd.append('email', this.email);
      fd.append('pinCode', this.pincode);
      fd.append('address', this.address);
      fd.append('company', this.companyname);
      fd.append('companyurl', this.companyUrl);
      fd.append('deviceType', this.deviceType);
      fd.append('deviceToken', this.uuidParam);
      fd.append('deviceOS', this.deviceOs);
      fd.append('initialLocation', JSON.stringify(this.initLocationArray));
      const headers = new Headers();
      headers.append('X-Apikey', (<any>window).citikey);
      headers.append('X-Citistar-Token', (<any>window).citiToken);
      // headers.append('Content-Type','application/x-www-form-urlencoded');
      this.http.post((<any>window).citiUrl + 'users', fd, { headers: headers })
        .map(res => res.json())
        .subscribe(data => {
          if (data.status === 'success') {
            const myvar = this;
            myvar.dialogService.addDialog(MobileverificationComponent, {
              email: this.email,
              mobile: this.phonecode + this.mobilenum
            }, { backdrop: 'static' })
              .subscribe((Mdata) => {
                // console.log(Mdata);
                this.closeData = Mdata;
                // console.log(this.closeData);
                if (this.closeData === undefined) {
                  return;
                } else {
                  if (this.closeData.status === 'success') {
                    this.close();
                    this.showSuccess('User Registration Successfully');

                  } else {
                    //console.log('failed');
                    this.showError(this.closeData.message);
                  }
                }
              });
          } else {
            this.showError(data.message);
            //console.log('data failed');
            // this.close();
            // this.router.navigate(['/login'], { skipLocationChange: true});
          }
        }, error => {
          this.showError('Server is busy, Please try again');
        });
    }
  }

  cancelbutton() {
    this.username = '';
    this.email = '';
    this.password = '';
    this.select_country = '';
    this.select_state = '';
    this.select_city = '';
    this.pincode = '';
    this.mobilenum = '';
    this.address = '';
    this.companyname = '';
    this.industry = '';
    this.companyUrl = '';
    this.cardname = '';
    this.cardnum = '';
    this.cvv = '';
    this.month = '';
    this.year = '';
    this.citieslist = [];
    this.statesList = [];
    this.phonecode = '';
    document.getElementById('copyright').style.display = 'none';
  }

  showSuccess(data) {
    if (this.toastr.currentlyActive === 0) {
      this.toastr.success(data, '', {
        timeOut: 2000,
        positionClass: 'toast-bottom-right'
      });
    }
  }

  showError(data) {
    if (this.toastr.currentlyActive === 0) {
      // console.log('active toasts = '+ this.toastr.currentlyActive +' In if');
      this.toastr.error(data, '', {
        timeOut: 2000,
        positionClass: 'toast-bottom-right'
      });
    }
  }
}
