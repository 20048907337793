import { Component, OnInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Http, Headers } from '@angular/http';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { SocialMediaServiceService } from '../../providers/social-media-service/social-media-service.service';
import { ToastrService } from 'ngx-toastr';
import { Router, Event, NavigationEnd } from '@angular/router';
import { DialogComponent } from 'ngx-bootstrap-modal/index';
import { DialogService } from 'ngx-bootstrap-modal/components/dialog.service';
import {SocialmonitorComponent} from '../socialmonitor/socialmonitor.component';
export interface ConfirmModel {
  title: string;
  message: string;
}
@Component({
  selector: 'app-addprofile',
  templateUrl: './addprofile.component.html',
  styleUrls: ['./addprofile.component.scss'],
  providers: [SocialmonitorComponent]
})
export class AddprofileComponent extends DialogComponent<ConfirmModel, string> implements ConfirmModel, OnInit {
  list: any[] = [];
  title: string;
  message: string;
  selectedTabData: any = '';
  selectedSocialMedia: any = 'twitterButton';
  socialMediaSelected: any = 'twitter';
  selectedMediaRoles: any = [];
  allProfiles: any = [];
  addingProfile = <any> false;
  sortedProfiles: any = {};
  selectedAccount: any = {};
  selectedPage: any = {};
  FBProfilePages: any = [];
  twitterRoles: any = ['Home', 'Followers', 'Following', 'My Tweets', 'Favorites'];
  facebookRoles: any = ['User Timeline', 'User Events', 'Friends', 'Liked Pages', 'Get Albums', 'User Profile'];
  facebookPagesRoles: any = ['Home'];
  linkedinRoles: any = ['Home'];
  youtubeRoles: any = ['Home', 'Liked Videos', 'Get Subscriptions', 'Search Channels'];
  pinterestRoles: any = ['Boards', 'Pins'];
  instagramRoles: any = ['My Posts'];
  foresquareRoles: any = ['Checkins', 'Tips', 'Lists'];
  tumblrRoles: any = ['Home'];
  facebookRoleType: any = 'pages';
  userProfiles: any = [];
  onClose: any;
  selectedIndex: any;
  constructor(public bsModalRef: BsModalRef, dialogService: DialogService, private socialMedaiService: SocialMediaServiceService, private toastr: ToastrService, private route: Router, public socialmonitor: SocialmonitorComponent) {
    super(dialogService);
    //   this.socialMedaiService.userProfiles((<any>window).userprofile[0].orgId).subscribe( data => {
    //     console.log(data);
    //     console.log((<any>window).userprofile[0].orgId);
    //     if (data.status === 'success' && data.response) {
    //       console.log('success');
    //       this.userProfiles = data.response;
    //       console.log('userprofiles', this.userProfiles);
    //    } else {
    //      console.log('error');
    //    }
    //  });
    if ( (<any>window).citiUserSocialProfiles !== []) {
      this.userProfiles = (<any>window).citiUserSocialProfiles;
    }

   }

  ngOnInit() {
     console.log(this.list);
     console.log(this.selectedTabData);
     this.selectedIndex = this.selectedTabData;
     console.log('this.selectedIndex', this.selectedIndex);
     this.selectedMedia(this.socialMediaSelected);
     setTimeout(() => {
      this.getMediaProfiles('twitter');
     }, 2000);

     this.route.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {
        // const closeobj: any = {
        //   message: undefined
        // };
        // this.close(closeobj);
        // this.close();
        this.bsModalRef.hide();
      }

    });
  }

  hideModal() {
    this.bsModalRef.hide();
    this.onClose({ pageId: this.selectedAccount.socialId });
  }

  selectedMedia(media) {
    this.addingProfile = true;
    this.socialMediaSelected = media;
    const mediaSelected = media + 'Button';
    console.log(this.selectedSocialMedia);
    try {
      const containsClass = document.getElementById(this.selectedSocialMedia).classList.contains('customSocialButtonTip');
      console.log(containsClass);
      if (containsClass === true) {
        document.getElementById(this.selectedSocialMedia).classList.remove('customSocialButtonTip');
        document.getElementById(mediaSelected).classList.add('customSocialButtonTip');
        this.selectedSocialMedia = media + 'Button';
        console.log('selected', media);
        if (this.socialMediaSelected === 'twitter') {
            this.selectedMediaRoles = this.twitterRoles;
            this.loaderTimeout();
        }
        if (this.socialMediaSelected === 'facebook') {
          if (this.facebookRoleType === 'profile') {
              this.selectedMediaRoles = this.facebookRoles;
              this.loaderTimeout();
          }
          if (this.facebookRoleType === 'pages') {
              this.selectedMediaRoles = this.facebookPagesRoles;
              this.loaderTimeout();
          }
          console.log(this.selectedMediaRoles);
        }
        if (this.socialMediaSelected === 'linkedin') {
            this.selectedMediaRoles = this.linkedinRoles;
            this.loaderTimeout();
        }
        if (this.socialMediaSelected === 'youtube') {
            this.selectedMediaRoles = this.youtubeRoles;
            this.loaderTimeout();
        }
        if (this.socialMediaSelected === 'instagram') {
            this.selectedMediaRoles = this.instagramRoles;
            this.loaderTimeout();
        }
        if (this.socialMediaSelected === 'pinterest') {
            this.selectedMediaRoles = this. pinterestRoles;
            this.loaderTimeout();
        }
        if (this.socialMediaSelected === 'tumblr') {
            this.selectedMediaRoles = this.tumblrRoles;
            this.loaderTimeout();
        }
        if (this.socialMediaSelected === 'foursquare') {
            this.selectedMediaRoles = this.foresquareRoles;
            this.loaderTimeout();

        }

      }
    } catch (res) {
        console.log('contains no class');
        this.loaderTimeout();
    }
    setTimeout(() => {
      this.getMediaProfiles(media);
      this.loaderTimeout();
    }, 500);
  }

  loaderTimeout() {
    setTimeout(() => {
      this.addingProfile = false;
    }, 2000);
  }

  getMediaProfiles(mediaType) {
    this.allProfiles = [];
    this.selectedAccount = {};
    console.log('selected media', mediaType);
    for (let i = 0; i < this.userProfiles.length; i++) {
      if (this.userProfiles[i].socialMediaType === mediaType) {
        this.allProfiles.push(this.userProfiles[i]);
        console.log(this.allProfiles);
      }
    }
  }

  onfbRoleChange() {
    console.log(this.facebookRoleType);
    if (this.facebookRoleType === 'profile') {
      this.selectedMediaRoles = this.facebookRoles;
    }
    if (this.facebookRoleType === 'pages') {
      this.selectedMediaRoles = this.facebookPagesRoles;
    }
  }

  convertText(socialMediaSelected) {
    return socialMediaSelected.charAt(0).toUpperCase() + socialMediaSelected.substr(1);
  }

  roleSelected(media, role) {
    console.log('selectedAccount', this.selectedAccount);
    console.log('media', media + ' & role', role);
    const mediaTopush: any = {
      role: role,
      socialMediaType: this.selectedAccount.socialMediaType,
      id: this.selectedAccount.socialId,
      // socialInfo: this.selectedAccount
    };
    if ( this.selectedAccount.socialMediaType === 'facebook' ) {
      mediaTopush.pageId = this.selectedPage.id;
    }

    if (this.selectedAccount.userName && this.selectedAccount.socialMediaType) {
      if (this.selectedAccount.socialMediaType === 'twitter') {
        if (role === 'Home') {

          this.mediaWidgetsAddition(mediaTopush, role);

        } else if (role === 'Followers') {

            this.mediaWidgetsAddition(mediaTopush, role);

        } else if (role === 'Following') {

            this.mediaWidgetsAddition(mediaTopush, role);

        } else if (role === 'My Tweets') {

            this.mediaWidgetsAddition(mediaTopush, role);

        } else if (role === 'Favorites') {

            this.mediaWidgetsAddition(mediaTopush, role);

        } else {
            console.log('Unable to detect ' + this.selectedAccount.socialMediaType + ' Role');
        }

      } else if (this.selectedAccount.socialMediaType === 'facebook') {
        if (this.selectedAccount.pagesInfo && role === 'Home') {
            if (this.selectedPage.name) {
              this.mediaWidgetsAddition(mediaTopush, role);
            } else {
              this.showError('Please Select Page');
            }

        } else {
          console.log('Unable to detect ' + this.selectedAccount.socialMediaType + ' Role');
        }
      } else if (this.selectedAccount.socialMediaType === 'instagram') {
        if (role === 'Home') {

            this.mediaWidgetsAddition(mediaTopush, role);

        } else if (role === 'My Posts') {

            this.mediaWidgetsAddition(mediaTopush, role);

        } else if (role === 'Followers') {

            this.mediaWidgetsAddition(mediaTopush, role);

        } else if (role === 'Followings') {

            this.mediaWidgetsAddition(mediaTopush, role);

        } else {
            console.log('Unable to detect ' + this.selectedAccount.socialMediaType + ' Role');
        }
      } else if (this.selectedAccount.socialMediaType === 'linkedin') {
        if (role === 'Post Message') {

            this.mediaWidgetsAddition(mediaTopush, role);

        } else if (role === 'Linkedin Pages') {

            this.mediaWidgetsAddition(mediaTopush, role);

        } else if (role === 'Home') {

            this.mediaWidgetsAddition(mediaTopush, role);

        } else {
            console.log('Unable to detect ' + this.selectedAccount.socialMediaType + ' Role');
        }
      } else if (this.selectedAccount.socialMediaType === 'foursquare') {
        if (role === 'Checkins') {

            this.mediaWidgetsAddition(mediaTopush, role);

        } else if (role === 'Tips') {

            this.mediaWidgetsAddition(mediaTopush, role);

        } else if (role === 'Lists') {

            this.mediaWidgetsAddition(mediaTopush, role);

        } else {
            console.log('Unable to detect ' + this.selectedAccount.socialMediaType + ' Role');
        }
      } else if (this.selectedAccount.socialMediaType === 'youtube') {
        if (role === 'Home') {

            this.mediaWidgetsAddition(mediaTopush, role);

        } else if (role === 'Liked Videos') {

            this.mediaWidgetsAddition(mediaTopush, role);

        } else if (role === 'Get Subscriptions') {

            this.mediaWidgetsAddition(mediaTopush, role);

        } else if (role === 'Search Channels') {

            this.mediaWidgetsAddition(mediaTopush, role);

        } else {
            console.log('Unable to detect ' + this.selectedAccount.socialMediaType + ' Role');
        }
      } else if (this.selectedAccount.socialMediaType === 'pinterest') {
        if (role === 'Boards') {

            this.mediaWidgetsAddition(mediaTopush, role);

        } else if (role === 'Pins') {

            this.mediaWidgetsAddition(mediaTopush, role);

        } else {
            console.log('Unable to detect ' + this.selectedAccount.socialMediaType + ' Role');
        }
      } else if (this.selectedAccount.socialMediaType === 'tumblr') {
        if (role === 'Home') {

            this.mediaWidgetsAddition(mediaTopush, role);

        } else {
            console.log('Unable to detect ' + this.selectedAccount.socialMediaType + ' Role');
        }
      } else {
          console.log('Unable to detect social media!');
      }

    } else {
         console.log('No social profile selected..');
    }
  }

  mediaAccountSelected(account) {
   // console.log(account);
   this.selectedAccount = account;
   if (account.socialMediaType === 'facebook') {
     this.FBProfilePages = account.pagesInfo;
   }
  }

  mediaAccountPageSelected(page) {
    console.log(page);
    this.selectedPage = page;
  }

  checkForDublicateRole(mediaType, role, callback) {
    let exists: any = false;
    console.log('selected tab -------', this.selectedIndex);
    console.log('tabs', (<any>window).socialMonitorTabs);
    if ((<any>window).socialMonitorTabs[this.selectedIndex.index].widgets.length > 0) {
      console.log('1');
      for (let i = 0; i < (<any>window).socialMonitorTabs[this.selectedIndex.index].widgets.length; i++) {
        console.log(i);
        console.log((<any>window).socialMonitorTabs[this.selectedIndex.index].widgets[i].role , role);
        console.log((<any>window).socialMonitorTabs[this.selectedIndex.index].widgets[i].socialMediaType, mediaType);
        if ((<any>window).socialMonitorTabs[this.selectedIndex.index].widgets[i].socialMediaType === mediaType && (<any>window).socialMonitorTabs[this.selectedIndex.index].widgets[i].role === role) {
          exists = true;
          console.log('3');
        }
        if ( i === (<any>window).socialMonitorTabs[this.selectedIndex.index].widgets.length - 1 ) {
          console.log('4');
          callback(exists);
          return;
        }

      }
    } else {
      console.log('2');
      exists = false;
      callback(exists);
    }
  }

  mediaWidgetsAddition(mediaTopush, role) {
    // console.log('mediaTopush', mediaTopush);
    this.checkForDublicateRole(this.selectedAccount.socialMediaType, role, (exists) => {
      console.log('exists', exists);
      if (exists === true) {
        this.showError(this.selectedAccount.socialMediaType + ' ' + role + ' already exists in selected tab.');
        return; // to check if it resolves the toast issue
      } else {
        (<any>window).socialMonitorTabs[this.selectedTabData.index].widgets.push(mediaTopush);
        console.log('socialMonitorTabs', JSON.stringify((<any>window).socialMonitorTabs ));
        this.showSuccess(this.selectedAccount.socialMediaType + ' ' + role + ' added successfully to selected tab.');
        this.socialMedaiService.SaveSocialWidgets((<any>window).socialMonitorTabs);
        this.hideModal();
        return; // to check if it resolves the toast issue
      }
    });
  }

  ts_abort(){
      throw new Error('This is not an error. This is just to abort javascript');
  }

  showSuccess(data) {
    this.toastr.success(data, '', {
      timeOut: 1000,
      positionClass: 'toast-bottom-right'
    });
  }

  showError(data) {
    this.toastr.error(data, '', {
      timeOut: 1000,
      positionClass: 'toast-bottom-right'
    });
  }

}
