import { Component, OnInit, HostListener  } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MessageService } from './shared/services/messageoberve.service';
import { Http, Response, Headers} from '@angular/http';
import { DialogService, BuiltInOptions } from 'ngx-bootstrap-modal/index';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { OathprofileComponent } from './oathprofile/oathprofile.component';
import 'rxjs/add/operator/map';
import { ToastrService } from 'ngx-toastr';
import { SocialMediaServiceService } from './providers/social-media-service/social-media-service.service';
(<any>window).tabs = [];
(<any>window).sessionInactiveTime = 0;
(<any>window).backupStyleData = 'Dashboard';
(<any>window).font_awesome = [];
(<any>window).citiUrl = 'https://api.citimedia.com/v1/' ;
 //(<any>window).citiUrl = 'http://192.168.1.52:8001/';
// (<any>window).citiUrl = 'http://192.168.9.65:8001/';

(<any>window).citiPaymentUrl = 'https://www.citimedia.in/';
(<any>window).citiPaymentGlobalUrl = 'https://www.citimedia.com/';
(<any>window).citiNodeApi = 'https://cloud.citimedia.com/';
(<any>window).citiToken = '';
(<any>window).userprofile = [];
(<any>window).walletDetails  = [];
(<any>window).fbCredentials  = {};
(<any>window).twCredentials  = {};
(<any>window).instaCredentials  = {};
(<any>window).pinterestCredentials  = {};
(<any>window).linkedinCredentials  = {};
(<any>window).foursquareCredentials  = {};
(<any>window).tumblrCredentials  = {};
(<any>window).youtubeCredentials  = {};
(<any>window).audienceData_Journey  = {};
(<any>window).audienceDataForCells = {};
(<any>window).emailDataFromModal = {};
(<any>window).journey_email_modal_tabs_fields = {};
(<any>window).fbFriendsCount = 0;
(<any>window).twfollowersCount = 0;
(<any>window).twitterUserLookups = [];
(<any>window).fbUserData = [{timelines: []}];
(<any>window).citikey = 'citistar-00e24b550974aa1530f6893ca8fc37';
(<any>window).tabs = [{
  title: 'List of Journeys',
  name: 'list of journeys',
  content: `journeyList`,
  maintab: true,
  disabled: false,
  removable: false,
  active: true,

}

// {
//   title: 'Create Journey',
//   name: 'Create Journey',
//   content: `createJourney`,
//   maintab: true,
//   disabled: false,
//   removable: true,
//   active: false
// }
];

(<any>window).socialMonitorTabs = [
  {
    title: 'Home',
    name: 'Home-tab',
    content: '',
    maintab: true,
    disabled: false,
    removable: false,
    active: true,
    widgets: []
  }
];

(<any>window).campaigndetailstabs = [{
  title: 'SMS Campaign Report',
  name: 'sms campaign Report',
  content: `campaignDatails`,
  maintab: true,
  disabled: false,
  removable: false,
  active: true
}
];
(<any>window).emailcampaigndetailstabs = [{
  title: 'Email Campaign Report',
  name: 'email campaign Report',
  content: `emailcampaignDatails`,
  maintab: true,
  disabled: false,
  removable: false,
  active: true
}
];
(<any>window).manageaccounttabs = [{
  title: 'Manage Accounts',
  name: 'manage accounts',
  content: `AccountDetails`,
  maintab: true,
  disabled: false,
  removable: false,
  active: true
}];
(<any>window).socialcampaigndetailstabs = [{
  title: 'Socail Campaign Report',
  name: 'socail campaign Report',
  content: `socialcampaignDatails`,
  maintab: true,
  disabled: false,
  removable: false,
  active: true
}];
declare var FB: any;
 (<any>window).citiUserSocialProfiles = [];

(<any>window).socialLoginStatus = {};
(<any>window).fourquaresClientId = 'RX4TU2JZ2XLDC5OTNVKA1LRE4BYZJEU3KQUBW0MQWND2PC2X';
(<any>window).foursquareClientSecret = '0AEFTFN1PRWKLZ2WKN0SISP0YYFGSS4L51DNXV2ZZ3N0UR0O';
(<any>window).facebookAppId = '227873237548250';
(<any>window).facebookAppSecret = '9b5193dd19b41374b4d128238cc10cea';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
 js: any;
  loggedin_user: any;
  loggedIn_user_profilePic: any;
  refreshInterval: any;
  bsModalRef: BsModalRef;
  loaded = false;
  closeData: any;
  // @HostListener('window:beforeunload') onBeforeUnload() {
  //   alert('unloading...');
  // }
  constructor(public modalService: BsModalService, private toastr: ToastrService, public dialogService: DialogService, public router: Router, private messageService: MessageService, private http: Http, private socialMedaiService: SocialMediaServiceService) {
    if (localStorage.getItem('tabs') || localStorage.getItem('font_awesome') || localStorage.getItem('citiToken') || localStorage.getItem('userprofile') || localStorage.getItem('walletDetails')) {
      // console.log(localStorage.getItem('tabs'));
      // console.log(localStorage.getItem('font_awesome'));
      // console.log(localStorage.getItem('citiToken'));
      // console.log(localStorage.getItem('userprofile'));
      // console.log(localStorage.getItem('walletDetails'));
      (<any>window).tabs = JSON.parse(localStorage.getItem('tabs'));
      (<any>window).font_awesome = JSON.parse(localStorage.getItem('font_awesome'));
      (<any>window).citiToken = JSON.parse(localStorage.getItem('citiToken'));
      (<any>window).userprofile = JSON.parse(localStorage.getItem('userprofile'));
      (<any>window).walletDetails  = JSON.parse(localStorage.getItem('walletDetails'));

      (<any>window).fbCredentials = JSON.parse(localStorage.getItem('fbCredentials'));
      (<any>window).twCredentials = JSON.parse(localStorage.getItem('twCredentials'));
      (<any>window).instaCredentials = JSON.parse(localStorage.getItem('instaCredentials'));
      (<any>window).pinterestCredentials = JSON.parse(localStorage.getItem('pinterestCredentials'));
      (<any>window).linkedinCredentials = JSON.parse(localStorage.getItem('linkedinCredentials'));
      (<any>window).foursquareCredentials = JSON.parse(localStorage.getItem('foursquareCredentials'));
      (<any>window).tumblrCredentials = JSON.parse(localStorage.getItem('tumblrCredentials'));
      (<any>window).youtubeCredentials = JSON.parse(localStorage.getItem('youtubeCredentials'));
     setTimeout( () => {
      localStorage.removeItem('tabs');
      localStorage.removeItem('font_awesome');
      localStorage.removeItem('citiToken');
      localStorage.removeItem('userprofile');
      localStorage.removeItem('walletDetails');
      localStorage.removeItem('fbCredentials');
      localStorage.removeItem('twCredentials');
      localStorage.removeItem('instaCredentials');
      localStorage.removeItem('pinterestCredentials');
      localStorage.removeItem('linkedinCredentials');
      localStorage.removeItem('foursquareCredentials');
      localStorage.removeItem('tumblrCredentials');
      localStorage.removeItem('youtubeCredentials');
     }, 500);

    }

    (<any>window).addEventListener('load', () => {
      //  this.fbLogout();
    });

    (<any>window).addEventListener('beforeunload', (event) => {
      // call the widgets save servicce here...
      // call logout service here
      // console.log.log(event);
      this.socialMedaiService.SaveSocialWidgets((<any>window).socialMonitorTabs);
      // this.logout();
      // event.returnValue = 'Do you want to close..';
    });

  }

  getUrlVars() {
    const vars = {};
    const parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value): any => {
        vars[key] = value;
    });
    return vars;
 }

 ngOnInit() {
  // setTimeout(()=> {
    // console.log('(<any>window).citiToken', (<any>window).citiToken);
    if ((<any>window).citiToken !== '') {
      // console.log('entered If.......')
        if (window.location.href.indexOf('fbToken') !== -1) {
          // console.log('contains fbToken');
          // console.log((<any>window).location.href);
          // console.log(this.getUrlVars()['fbToken']);
          (<any>window).fbCredentials.fbToken = this.getUrlVars()['fbToken'];
          (<any>window).fbCredentials.fbrefreshToken = this.getUrlVars()['fbrefreshToken'];
          (<any>window).fbCredentials.permenentToken = this.getUrlVars()['fbpermenentToken'];
          // console.log('(<any>window).fbCredentials', (<any>window).fbCredentials);
          // this.socialMedaiService.getFbPermenentAccessToken((<any>window).fbCredentials).subscribe(fbData => {
          //   console.log('fbData', fbData);
          //   (<any>window).fbCredentials.permenentToken = fbData.access_token;
          //   this.socialMedaiService.getFacebookUserAccounts((<any>window).fbCredentials.permenentToken).subscribe(data => {
          //     if (data.data[0].access_token) {
          //       console.log('success', data.data);
          //       (<any>window).fbCredentials.userAccounts = data.data;
          //       console.log((<any>window).fbCredentials.userAccounts);
          //    } else {
          //      console.log('error');
          //    }
          //   });
          //   console.log('(<any>window).fbCredentials', (<any>window).fbCredentials);
          // });
          /** To get Facebook User Accounts**/
          this.socialMedaiService.getfbUserAccounts((<any>window).fbCredentials.permenentToken).subscribe(data => {
              // if (data.data[0].access_token) {
                if(data.data.length > 0) {
                  // if(data.data[0].hasOwnProperty("access_token")){
                // console.log('success', data.data);
                (<any>window).fbCredentials.userAccounts = data.data;
                // console.log((<any>window).fbCredentials.userAccounts);
                /** To get Facebook User Details**/
                this.socialMedaiService.getFbUserDetails((<any>window).fbCredentials.permenentToken).subscribe(fbDetails => {
                  if(fbDetails.name){
                  // console.log.log('fbDetails', fbDetails);
                  (<any>window).fbCredentials.userProfileData = fbDetails;
                  // // console.log.log((<any>window).fbCredentials);
                  this.socialMedaiService.saveFacebookAccess((<any>window).fbCredentials).subscribe(dataResp => {
                    // console.log.log('saveFacebookAccess', dataResp);
                    if (dataResp.status === 'success') {
                      this.socialMedaiService.SaveFacebookPages(data.data, fbDetails.id ).subscribe(accountSaveResp => {
                        // console.log.log('accountSaveResp', accountSaveResp);
                        this.socialMedaiService.userProfiles((<any>window).userprofile[0].orgId).subscribe( profiles => {
                         // console.log.log(profiles);
                         // console.log.log((<any>window).userprofile[0].orgId);
                        if (data.status === 'success' && profiles.response) {

                          (<any>window).citiUserSocialProfiles = profiles.response;
                       }
                      });
                     });
                    }
                  });
                  /** To Post Facebook Pages Data**/
                  // for (let k = 0; k < data.data.length; k++) {
                  // this.socialMedaiService.SaveFacebookPages(data.data, fbDetails.id ).subscribe(accountSaveResp => {
                  //    console.log('accountSaveResp', accountSaveResp);
                  //    this.socialMedaiService.userProfiles((<any>window).userprofile[0].orgId).subscribe( profiles => {
                  //     console.log(profiles);
                  //     console.log((<any>window).userprofile[0].orgId);
                  //    if (data.status === 'success' && profiles.response) {

                  //      (<any>window).citiUserSocialProfiles = profiles.response;
                  //   }
                  //  });
                  // });
                  // if ( k === data.data.length - 1 ) {
                    // this.socialMedaiService.userProfiles((<any>window).userprofile[0].orgId).subscribe( profiles => {
                    //    console.log(profiles);
                    //    console.log((<any>window).userprofile[0].orgId);
                    //   if (data.status === 'success' && profiles.response) {

                    //     (<any>window).citiUserSocialProfiles = profiles.response;
                    //  }
                    // });
                  // }
                  // }
                  } else {
                    console.log('error');
                    this.showError('Something went wrong from Facebook.');
                  }

                });

             } else {
                console.log('error');
                this.showError('Something went wrong from Facebook.');
             }
            });

          setTimeout(() => {
            window.history.replaceState(null, null, window.location.pathname);
          }, 1000);


        } else if (window.location.href.indexOf('twToken') !== -1) {
          // console.log('contains twToken');
          // console.log((<any>window).location.href);
          // console.log(this.getUrlVars()['twToken']);
          (<any>window).twCredentials.twToken = this.getUrlVars()['twToken'];
          (<any>window).twCredentials.twTokenSecret = this.getUrlVars()['twTokenSecret'];
          (<any>window).twCredentials.profile = {id: this.getUrlVars()['twid'], name: this.getUrlVars()['twName'], screenName: this.getUrlVars()['twScreenName']};

          // console.log('(<any>window).twCredentials', (<any>window).twCredentials);
          this.socialMedaiService.saveTwitterAccess((<any>window).twCredentials).subscribe(data => {
            // console.log.log('saveTwitterAccess', data);
          });
          setTimeout(() => {
            window.history.replaceState(null, null, window.location.pathname);
          }, 1000);

        } else if (window.location.href.indexOf('instaccessToken') !== -1) {
          // console.log('contains instaccessToken');
          // console.log((<any>window).location.href);
          // console.log(this.getUrlVars()['instaccessToken']);
          (<any>window).instaCredentials.instaccessToken = this.getUrlVars()['instaccessToken'];
          (<any>window).instaCredentials.instarefreshToken = this.getUrlVars()['instarefreshToken'];
          this.socialMedaiService.getInstagramProfile((<any>window).instaCredentials.instaccessToken).subscribe(res => {
            this.socialMedaiService.saveInstagramAccess((<any>window).instaCredentials, res.data).subscribe(data => {
              // console.log.log('saveInstagramAccess', data);

            });
            setTimeout(() => {
              window.history.replaceState(null, null, window.location.pathname);
            }, 1000);
          });
          // console.log('(<any>window).instaCredentials', (<any>window).instaCredentials);


        } else if (window.location.href.indexOf('pinaccessToken') !== -1) {
          // console.log('contains pinaccessToken');
          // console.log((<any>window).location.href);
          // console.log(this.getUrlVars()['pinaccessToken']);
          (<any>window).pinterestCredentials.pinaccessToken = this.getUrlVars()['pinaccessToken'];
          (<any>window).pinterestCredentials.pinrefreshToken = this.getUrlVars()['pinrefreshToken'];
          this.socialMedaiService.getPinterestProfile((<any>window).pinterestCredentials.pinaccessToken).subscribe(res => {
            this.socialMedaiService.savePinterestAccess((<any>window).pinterestCredentials, res.data).subscribe(data => {
              // console.log.log('savePinterestAccess', data);

            });
            setTimeout(() => {
              window.history.replaceState(null, null, window.location.pathname);
            }, 1000);
          });
          // console.log('(<any>window).pinterestCredentials', (<any>window).pinterestCredentials);



        } else if (window.location.href.indexOf('linkdtoken') !== -1) {
          (<any>window).linkedinCredentials.linkdtoken = this.getUrlVars()['linkdtoken'];
          (<any>window).linkedinCredentials.linkdtokenSecret = this.getUrlVars()['linkdtokenSecret'];
          (<any>window).linkedinCredentials.linkedUserId = this.getUrlVars()['linkedUserId'];
          (<any>window).linkedinCredentials.linkedUserName = this.getUrlVars()['linkedUserName'];

          this.socialMedaiService.saveLinkedInAccess((<any>window).linkedinCredentials).subscribe(data => {
          });
          setTimeout(() => {
            window.history.replaceState(null, null, window.location.pathname);
          }, 1000);

        } else if (window.location.href.indexOf('fqaccesstoken') !== -1) {
          (<any>window).foursquareCredentials.fqaccesstoken = this.getUrlVars()['fqaccesstoken'];
          (<any>window).foursquareCredentials.fqrefreshToken = this.getUrlVars()['fqrefreshToken'];
          this.socialMedaiService.getForeSquareProfile((<any>window).foursquareCredentials.fqaccesstoken ).subscribe(res => {
            // console.log.log(res);
            this.socialMedaiService.saveFourSquareAccess((<any>window).foursquareCredentials, res.response.user).subscribe(data => {
              // console.log.log(data);
            });
            setTimeout(() => {
              window.history.replaceState(null, null, window.location.pathname);
            }, 1000);
          });


        } else if (window.location.href.indexOf('tblrtoken') !== -1) {
          (<any>window).tumblrCredentials.tblrtoken = this.getUrlVars()['tblrtoken'];
          (<any>window).tumblrCredentials.tblrtokenSecret = this.getUrlVars()['tblrtokenSecret'];

          // console.log('(<any>window).tumblrCredentials', (<any>window).tumblrCredentials);
          this.socialMedaiService.saveTumblrAccess((<any>window).tumblrCredentials).subscribe(data => {
            // console.log.log('saveTumblrAccess', data);

          });
          setTimeout(() => {
            window.history.replaceState(null, null, window.location.pathname);
          }, 1000);

        } else if (window.location.href.indexOf('ytbaccesstoken') !== -1) {
          // console.log('contains ytbaccesstoken');
          // console.log((<any>window).location.href);
          // console.log(this.getUrlVars()['ytbaccesstoken']);
          (<any>window).youtubeCredentials.ytbaccesstoken = this.getUrlVars()['ytbaccesstoken'];
          (<any>window).youtubeCredentials.ytbrefreshToken = this.getUrlVars()['ytbrefreshToken'];

          // console.log('(<any>window).youtubeCredentials', (<any>window).youtubeCredentials);
          this.socialMedaiService.saveYoutubeAccess((<any>window).youtubeCredentials).subscribe(data => {
            // console.log.log('saveYoutubeAccess', data);

          });
          setTimeout(() => {
            window.history.replaceState(null, null, window.location.pathname);
          }, 1000);

        } else {
          this.router.navigate(['/dashboard'], { skipLocationChange: true });

        }

    } else {
      // console.log('entered else.......');
      this.router.navigate(['/login'], { skipLocationChange: true });
      document.getElementById('example').style.display = 'block';
      setTimeout(() => {
        if (window.location.href.indexOf('fbToken') !== -1) {
          // this.router.navigate(['/dashboard'], { skipLocationChange: true });
          // console.log('contains fbToken');
          // console.log((<any>window).location.href);
          // console.log(this.getUrlVars()['fbToken']);
          (<any>window).fbCredentials.fbToken = this.getUrlVars()['fbToken'];
          (<any>window).fbCredentials.fbrefreshToken = this.getUrlVars()['fbrefreshToken'];
          (<any>window).fbCredentials.permenentToken = this.getUrlVars()['fbpermenentToken'];
          // this.socialMedaiService.getFbPermenentAccessToken((<any>window).fbCredentials).subscribe(fbData => {
          //   console.log('fbData', fbData);
          //   (<any>window).fbCredentials.permenentToken = fbData.access_token;
          //   this.socialMedaiService.getFacebookUserAccounts((<any>window).fbCredentials.permenentToken).subscribe(data => {
          //     if (data.data[0].access_token) {
          //       console.log('success', data.data);
          //       (<any>window).fbCredentials.userAccounts = data.data;
          //       console.log((<any>window).fbCredentials.userAccounts);
          //    } else {
          //      console.log('error');
          //    }
          //   });
          //   console.log('(<any>window).fbCredentials', (<any>window).fbCredentials);
          //   this.socialMedaiService.saveFbAccess((<any>window).fbCredentials.permenentToken)
          //   .subscribe(data => {
          //     console.log('*****', data);
          //     if (data.status === 'success') {
          //        console.log('success');
          //       (<any>window).citiToken = data.token;
          //       console.log('(<any>window).citiToken', (<any>window).citiToken);
          //       this.router.navigate(['/dashboard'], { skipLocationChange: true});
          //     } else {
          //       console.log('error');
          //     }
          //     });
          // });
          this.socialMedaiService.getfbUserAccounts((<any>window).fbCredentials.permenentToken).subscribe(data => {
              // if (data.data[0].access_token) {
              if(data.data.length > 0) {
              // if(data.data[0].hasOwnProperty("access_token")){
              // console.log('success', data.data);
              (<any>window).fbCredentials.userAccounts = data.data;
              // console.log.log((<any>window).fbCredentials.userAccounts);
              /** To get Facebook User Details**/
              this.socialMedaiService.getFbUserDetails((<any>window).fbCredentials.permenentToken).subscribe(fbDetails => {
                if(fbDetails.name){
                // console.log.log('fbDetails', fbDetails);
                (<any>window).fbCredentials.userProfileData = fbDetails;
                // console.log.log((<any>window).fbCredentials);
                /**To save fb credentials**/
                this.socialMedaiService.saveFbAccess((<any>window).fbCredentials)
                .subscribe(datas => {
                  // console.log.log('*****', datas);
                  if (datas.status === 'success') {
                    (<any>window).citiToken = datas.token;

                    // this.socialMedaiService.SaveFacebookPages(data.data, fbDetails.id ).subscribe(accountSaveResp => {
                    //   // console.log.log('accountSaveResp', accountSaveResp);
                    // if (accountSaveResp.status === 'success') {
                     // // console.log.log('success');
                    //  (<any>window).citiToken = datas.token;
                     // console.log.log('(<any>window).citiToken', (<any>window).citiToken);

                     this.getUsersProfile((respData) => {
                      if (respData === 'success') {
                        // open dialog to save user data
                        document.getElementById('example').style.display = 'none';
                        if((<any>window).userprofile[0].isSmsVerified === true){
                            this.router.navigate(['/dashboard'], { skipLocationChange: true});
                        } else {
                          this.dialogService.addDialog(OathprofileComponent, { backdrop: 'static' })
                          .subscribe((resps: any) => {
                              // console.log(res)
                              this.closeData = resps;
                              // console.log(this.closeData)
                                if (this.closeData === undefined) {
                                  return;
                                } else {
                                  if (this.closeData.status === 'success') {
                                    /** To Post Facebook Pages Data**/
                                    this.socialMedaiService.SaveFacebookPages(data.data, fbDetails.id ).subscribe(accountSaveResp => {
                                      // console.log.log('accountSaveResp', accountSaveResp);
                                    if (accountSaveResp.status === 'success') {
                                      // console.log.log('successfully added pages data');
                                    } else {
                                      // console.log.log('error saving pages data');
                                    }
                                  });
                                  /** navigating user to Dashboard **/
                                  this.router.navigate(['/dashboard'], { skipLocationChange: true});
                                } else {
                                    console.log('failed');
                                }
                              }
                            });
                        }


                       /** To get social data of user**/
                          this.socialMedaiService.userProfiles((<any>window).userprofile[0].orgId).subscribe( profiles => {
                            // console.log.log(profiles);
                            // console.log.log((<any>window).userprofile[0].orgId);
                            if (profiles.status === 'success' && profiles.response) {
                              (<any>window).citiUserSocialProfiles = profiles.response;
                            }
                          });

                      } else {
                        console.log('unable to proceed..');
                      }
                    });

                  //   }
                  //  });
                    // this.router.navigate(['/YourProfile'], { skipLocationChange: true});
                  } else {
                      console.log('error');
                  }
                });


                } else {
                  this.showError('Something went wrong from Facebook.');
                }

              });
           } else {
               console.log('error');
               this.showError('Something went wrong from Facebook.');
           }
          });
          // console.log('(<any>window).fbCredentials', (<any>window).fbCredentials);


          setTimeout(() => {
            window.history.replaceState(null, null, window.location.pathname);
          }, 1000);
        } else if (window.location.href.indexOf('twToken') !== -1) {
          // console.log('contains twToken');
          // console.log((<any>window).location.href);
          // console.log(this.getUrlVars()['twToken']);
          (<any>window).twCredentials.twToken = this.getUrlVars()['twToken'];
          (<any>window).twCredentials.twTokenSecret = this.getUrlVars()['twTokenSecret'];
          (<any>window).twCredentials.profile = {id: this.getUrlVars()['twid'], name: this.getUrlVars()['twName'], screenName: this.getUrlVars()['twScreenName']};
          this.socialMedaiService.saveTwttrAccess((<any>window).twCredentials)
          .subscribe(data => {
            // console.log.log('*****', data);
            if (data.status === 'success') {
               // console.log('success');
              (<any>window).citiToken = data.token;
              // console.log.log('(<any>window).citiToken', (<any>window).citiToken);
              this.getUsersProfile((respData) => {
                if (respData === 'success') {
                  if((<any>window).userprofile[0].isSmsVerified === true){
                    this.router.navigate(['/dashboard'], { skipLocationChange: true});
                  } else {
                    this.dialogService.addDialog(OathprofileComponent, { backdrop: 'static' })
                    .subscribe((res: any) => {
                        // console.log(res)
                        this.closeData = res;
                        // console.log(this.closeData)
                          if (this.closeData === undefined) {
                            return;
                          } else {
                            if (this.closeData.status === 'success') {
                              this.router.navigate(['/dashboard'], { skipLocationChange: true});
                          } else {
                              // console.log.log('failed');
                          }
                        }
                      });
                  }

                }
              })

             // this.router.navigate(['/dashboard'], { skipLocationChange: true});
            } else {
              // console.log.log('error');
            }
            });
          setTimeout(() => {
            window.history.replaceState(null, null, window.location.pathname);
          }, 1000);
        } else {
          //console.log('other social media...');
        }

      }, 1000);
    }
  // }, 2000)
  this.router.events.subscribe((evt) => {
    if (!(evt instanceof NavigationEnd)) {
      return;
    }
    window.scrollTo(0, 0);
  });

  window.addEventListener('click', () => {
    // console.log.log('refreshing session');
    if (this.refreshInterval !== undefined) {
      this.clearSession();
    }
   this.loadSession();
  });
   this.loaded = true;

   if (document.getElementById('example')) {
    document.getElementById('example').style.display = 'none';
    }
}

  loadSession() {
    // console.log('(<any>window).citiToken', (<any>window).citiToken);
    if ((<any>window).citiToken !== '') {
        // console.log('session time resetting', (<any>window).sessionInactiveTime);
      (<any>window).sessionInactiveTime = 0;
       this.refreshInterval = setInterval(() => {
        (<any>window).sessionInactiveTime += 1;
        // console.log('session elapsed time', (<any>window).sessionInactiveTime );
        if ((<any>window).sessionInactiveTime === 300 && (<any>window).citiToken !== '') {

          this.clearSession();
          // console.log.log('Session Expired...');
          this.logout();
          return;
        }
      }, 1000);
    }
}
  clearSession() {
    clearInterval(this.refreshInterval);
  }

logout() {
  const body = 'username=' + (<any>window).userprofile[0].email;
  const headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('X-Apikey', (<any>window).citikey);
  headers.append('X-Citistar-Token', (<any>window).citiToken);
  // console.log((<any>window).citiToken);
  this.http.post((<any>window).citiUrl + 'users/logout', body, {headers: headers})
  .map(res => res.json())
  .subscribe(data => {
    if (data.status === 'success') {
      // console.log('successfully logged out');
      // console.log.log('logout response.......', data);
      this.setWindowObjects();
      // window.location.reload();
      this.router.navigate(['/login'], { skipLocationChange: true});

    } else {
      // console.log.log('Error Logging Out...');
      // console.log.log('Error : ', data);
      if (data.message === 'token mismatch') {
        this.setWindowObjects();
        // window.location.reload();
        this.router.navigate(['/login'], { skipLocationChange: true});

      } else {
        this.setWindowObjects();
        // window.location.reload();
        this.router.navigate(['/login'], { skipLocationChange: true});
      }
    }
  }, error => {
this.showError('Server is busy, Please try again');
    // console.log(JSON.stringify(error.json()));
    // console.log(error);

  });

}

// fbLogout() {
//   try {
//     if (FB.getAccessToken() != null) {
//       FB.logout(function(response) {
//         // Person is now logged out
//         // console.log(response);
//      });
//     }

//   } catch (err) {
//    console.log(err);
//   }

// }

setWindowObjects() {
    (<any>window).tabs = [];
  (<any>window).sessionInactiveTime = 0;
  (<any>window).backupStyleData = 'Dashboard';
  (<any>window).font_awesome = [];
  (<any>window).citiUrl = 'https://api.citimedia.com/v1/';
  // (<any>window).citiUrl = 'http://192.168.3.119:8002/';
  (<any>window).citiPaymentUrl = 'https://www.citimedia.in/';
  (<any>window).citiPaymentGlobalUrl = 'https://www.citimedia.com/';
  (<any>window).citiNodeApi = 'https://cloud.citimedia.com/';

  (<any>window).citiToken = '';
  (<any>window).userprofile = [];
  (<any>window).walletDetails  = [];
  (<any>window).fbCredentials  = {};
  (<any>window).twCredentials  = {};
  (<any>window).instaCredentials  = {};
  (<any>window).pinterestCredentials  = {};
  (<any>window).linkedinCredentials  = {};
  (<any>window).foursquareCredentials  = {};
  (<any>window).tumblrCredentials  = {};
  (<any>window).youtubeCredentials  = {};

  (<any>window).fbUserData = [{timelines: []}];
  (<any>window).citikey = 'citistar-00e24b550974aa1530f6893ca8fc37';
  (<any>window).tabs = [{
    title: 'List of Journeys',
    name: 'list of journeys',
    content: `journeyList`,
    maintab: true,
    disabled: false,
    removable: false,
    active: true
  }
  // {
  //   title: 'Create Journey',
  //   name: 'Create Journey',
  //   content: `createJourney`,
  //   maintab: true,
  //   disabled: false,
  //   removable: true,
  //   active: false
  // }
  ];

  (<any>window).socialMonitorTabs = [
    {
      title: 'Home',
      name: 'Home-tab',
      content: '',
      maintab: true,
      disabled: false,
      removable: false,
      active: true,
      widgets: []
    }
  ];

  (<any>window).campaigndetailstabs = [{
    title: 'SMS Campaign Report',
    name: 'sms campaign Report',
    content: `campaignDatails`,
    maintab: true,
    disabled: false,
    removable: false,
    active: true
  }
  ];
  (<any>window).emailcampaigndetailstabs = [{
    title: 'Email Campaign Report',
    name: 'email campaign Report',
    content: `emailcampaignDatails`,
    maintab: true,
    disabled: false,
    removable: false,
    active: true
  }
  ];
  (<any>window).manageaccounttabs = [{
    title: 'Manage Accounts',
    name: 'manage accounts',
    content: `AccountDetails`,
    maintab: true,
    disabled: false,
    removable: false,
    active: true
  }];
  (<any>window).socialcampaigndetailstabs = [{
    title: 'Socail Campaign Report',
    name: 'socail campaign Report',
    content: `socialcampaignDatails`,
    maintab: true,
    disabled: false,
    removable: false,
    active: true
  }];
  (<any>window).citiUserSocialProfiles = [];
  (<any>window).socialLoginStatus = {};
  (<any>window).fourquaresClientId = 'RX4TU2JZ2XLDC5OTNVKA1LRE4BYZJEU3KQUBW0MQWND2PC2X';
  (<any>window).foursquareClientSecret = '0AEFTFN1PRWKLZ2WKN0SISP0YYFGSS4L51DNXV2ZZ3N0UR0O';
  (<any>window).facebookAppId = '227873237548250';
  (<any>window).facebookAppSecret = '9b5193dd19b41374b4d128238cc10cea';
  }

  getUsersProfile(callback) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    return this.http.get((<any>window).citiUrl  + 'users/profile', { headers: headers })
     .map((res: Response) => res.json())
     .subscribe(data => {
      if (data.status === 'success') {
       // console.log('profile data...', data);
        (<any>window).userprofile = data.response;
        callback('success');
      } else if (data.status === 'failed') {
        //console.log('failed');
        callback('failed');
      } else {
        //console.log('Error');
        callback('failed');
      }
     }, error => {
        callback('failed');
        this.showError('Server is busy, Please try again');
     });

  }

  showSuccess(data) {
    if (this.toastr.currentlyActive === 0) {
      this.toastr.success(data, '', {
        timeOut: 2000,
        positionClass: 'toast-bottom-right'
      });
    }
  }

  showError(data) {
    console.log('this.toastr.currentlyActive', this.toastr.currentlyActive);
    if (this.toastr.currentlyActive === 0) {
      this.toastr.error(data, '', {
        timeOut: 2000,
        positionClass: 'toast-bottom-right'
      });
    }
  }
}
