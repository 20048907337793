import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'ngx-bootstrap-modal/index';
import { AudiencemodalComponent } from '../../../journeywidgets/audiencemodal/audiencemodal.component';
import { Http, Headers } from '@angular/http';
import { DatePipe } from '@angular/common';
import { CitiservicesService } from '../../../../citiservices.service';
import { EmailComponent} from '../email.component';
import {DefaultLayoutComponent} from '../../../../containers/default-layout/default-layout.component';
// import {
//   IpEmailBuilderService,
//   IPDefaultEmail,
//   Structure,
//   TextBlock,
//   ImageBlock
// } from 'ip-email-builder';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-emailsendcampaigns',
  templateUrl: './emailsendcampaigns.component.html',
  styleUrls: ['./emailsendcampaigns.component.scss'],
  providers: [DatePipe]
})
export class EmailsendcampaignsComponent implements OnInit {

  isLoading: BehaviorSubject<boolean>;
  emailgroupsArray: any = [];
  templateimages: any = [];
  emailfiledata: any = {};
  selectedemail: any = [];
  deselectedemail: any = [];
  emailselectedItems: any = [];
  current_tab = 'Campaign Details';
  campaign_detailsview = true;
  email_listview = false;
  template_view = false;
  emailcontent_view = false;
  emailspamreport = false;
  emailpreviewanssend = false;
  emailsendObj: any = {};
  campaignname: any;
  sendername: any;
  camdetailssubject: any;
  emails: any;
  ckeditorcontent: any;
  replyname: any;
  reply_email: any;
  sendbtn = false;
  nextbtn = true;
  contentcampaignname: any;
  schduledtime: any = [];
  schdulevalue: any = [];
  schdule_time: any;
  scheduledOn: any;
  fileName: any;
  desabledScheduleButton: any = true;
  groupsArray: any = [];
  profileloading: any;
  datasetsArray: any = [];
  datasetDataArray: any = [];
  array: any = [];
  showDatasets: any = false;
  settings = {
    bigBanner: true,
    timePicker: true,
    // format: 'yyyy-MM-dd HH:mm:ss',
    format: 'dd-MM-yyyy h:mm a',
    defaultOpen: false
  };
  schduledatetime: any = [];
  useremails: any = [];
  emails_coma: any;
  journey_email = true;
  journey_email_preview = false;
  journey_email_sendBtn = false;
  campaigndetails_label: any;
  recepients_label: any;
  template_label: any;
  content_label: any;
  previewsend_label: any;
  // ckEditorConfig: {};
  constructor(private emailcomp: EmailComponent, private toastr: ToastrService, private defaultLayout: DefaultLayoutComponent, private dialogService: DialogService, private http: Http, public datepipe: DatePipe, private myServ: CitiservicesService,
    // private _ngb: IpEmailBuilderService
  ) {

    // console.log('Email NGB:' , _ngb);
    // this.isLoading = _ngb.isLoading;

    this.templateimages = [{ image: 'assets/images/blankTemplate.png', content: 'template' },
    { image: 'assets/images/twolayer.png', content: 'Twolayer' }, { image: 'assets/images/threelayer.png' }, { image: 'assets/images/singlelayerTemplate.png' }];
    this.emailsendObj.ckeditorcontent = '<p>Some html</p>';
    // this.ckEditorConfig = {
    //   'uiColor': '#f4f4f4',
    //   'toolbarGroups': [
    //     { name: 'clipboard', groups: ['clipboard', 'undo'] },
    //     { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
    //     { name: 'insert' },
    //     { name: 'forms' },
    //     { name: 'tools' },
    //     // { name: 'document',	   groups: [ 'mode', 'document', 'doctools' ] },
    //     { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    //     // { name: 'paragraph',   groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ] },
    //     { name: 'styles' },
    //     { name: 'colors' },
    //     { name: 'about' }
    //   ],
    //   'removeButtons': 'Source,Save,Templates,Find,Replace,Scayt,SelectAll'
    // };
    this.schdule_time = new Date();
  }

  next(emailsendObj) {
    if ((<any>window).journey_email_modal_tabs_fields.tab_fields) {
      if (this.current_tab === 'Campaign Details') {
        if (this.emailsendObj.campaignname === '' || this.emailsendObj.campaignname === undefined) {
          this.showError('Please Enter CampaignName');
        } else if (this.emailsendObj.sendername === '' || this.emailsendObj.sendername === undefined) {
          this.showError('Please Enter SenderName');
        } else if (this.emailsendObj.senderemail === '' || this.emailsendObj.senderemail === undefined) {
          this.showError('Please Enter SenderEmail');
        } else if (this.emailsendObj.camdetailssubject === '' || this.emailsendObj.camdetailssubject === undefined) {
          this.showError('Please Enter Subject');
        } else {
          this.current_tab = 'Recepients';
          this.campaign_detailsview = false;
          this.email_listview = true;
          this.template_view = false;
          this.emailcontent_view = false;
          // this.emailspamreport = false;
          this.emailpreviewanssend = false;
          this.nextbtn = true;
          document.getElementById('nav2').classList.add('active');
          document.getElementById('nav1').classList.remove('active');
          document.getElementById('cam_details').classList.remove('activeText');
          document.getElementById('email_recipients').classList.add('activeText');
        }
      } else if (this.current_tab === 'Recepients') {
        console.log(this.emailsendObj.emails);
        // if ((this.emailsendObj.emails === undefined || this.emailsendObj.emails === '') && (this.emailfiledata === undefined || this.emailfiledata === '')) {
        //   this.showError('Please Enter Emails Or Select File');
        // } else {
        //   this.current_tab = 'Template';
        //   this.campaign_detailsview = false;
        //   this.email_listview = false;
        //   this.template_view = true;
        //   this.emailcontent_view = false;
        //   // this.emailspamreport = false;
        //   this.emailpreviewanssend = false;
        //   this.nextbtn = true;
        //   document.getElementById('nav3').classList.add('active');
        //   document.getElementById('nav2').classList.remove('active');
        //   document.getElementById('email_recipients').classList.remove('activeText');
        //   document.getElementById('email_template').classList.add('activeText');
        // }
        const emailregex = '[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\\.+[a-z._-]+';
  if ((this.emailsendObj.emails === undefined || this.emailsendObj.emails === '')) {
        if (this.emailselectedItems.length !== 0) {
          this.current_tab = 'Template';
            this.campaign_detailsview = false;
            this.email_listview = false;
            this.template_view = true;
            this.emailcontent_view = false;
            // this.emailspamreport = false;
            this.emailpreviewanssend = false;
            this.nextbtn = true;
            document.getElementById('nav3').classList.add('active');
            document.getElementById('nav2').classList.remove('active');
            document.getElementById('email_recipients').classList.remove('activeText');
            document.getElementById('email_template').classList.add('activeText');
          return;
        }

        if (this.datasetsArray.length !== 0) {
          this.current_tab = 'Template';
          this.campaign_detailsview = false;
          this.email_listview = false;
          this.template_view = true;
          this.emailcontent_view = false;
          // this.emailspamreport = false;
          this.emailpreviewanssend = false;
          this.nextbtn = true;
          document.getElementById('nav3').classList.add('active');
          document.getElementById('nav2').classList.remove('active');
          document.getElementById('email_recipients').classList.remove('activeText');
          document.getElementById('email_template').classList.add('activeText');
          return;
        }
        this.showError('Please enter atleast one mobile number to submit Campaign');
        return;
      } else if ((!this.emailsendObj.emails.match(emailregex))) {
        this.showError('Only valid email allowed');
       return;
      } else {
        this.current_tab = 'Template';
          this.campaign_detailsview = false;
          this.email_listview = false;
          this.template_view = true;
          this.emailcontent_view = false;
          // this.emailspamreport = false;
          this.emailpreviewanssend = false;
          this.nextbtn = true;
          document.getElementById('nav3').classList.add('active');
          document.getElementById('nav2').classList.remove('active');
          document.getElementById('email_recipients').classList.remove('activeText');
          document.getElementById('email_template').classList.add('activeText');
    }
      } else if (this.current_tab === 'Template') {
        this.current_tab = 'Content';
        this.campaign_detailsview = false;
        this.email_listview = false;
        this.template_view = false;
        this.emailcontent_view = true;
        // this.emailspamreport = false;
        this.emailpreviewanssend = false;
        this.nextbtn = true;
        document.getElementById('nav4').classList.add('active');
        document.getElementById('nav3').classList.remove('active');
        document.getElementById('email_template').classList.remove('activeText');
        document.getElementById('email_content').classList.add('activeText');
      } else if (this.current_tab === 'Content') {
          this.current_tab = 'Preview';
          this.campaign_detailsview = false;
          this.email_listview = false;
          this.template_view = false;
          this.emailcontent_view = false;
          // this.emailspamreport = false;
          this.emailpreviewanssend = true;
          this.nextbtn = false;
          this.sendbtn = true;
          this.journey_email_sendBtn = true;
          document.getElementById('nav6').classList.add('active');
          document.getElementById('nav4').classList.remove('active');
          document.getElementById('email_content').classList.remove('activeText');
          document.getElementById('email_presend').classList.add('activeText');
      }
    } else {
      if (this.current_tab === 'Campaign Details') {
        this.campaign_detailsview = false;
        this.email_listview = false;
        this.template_view = true;
        this.emailcontent_view = false;
        this.emailpreviewanssend = false;
        this.journey_email_preview = false;
        this.current_tab = 'Template';
        document.getElementById('nav3').classList.add('active');
          document.getElementById('nav1').classList.remove('active');
          document.getElementById('cam_details').classList.remove('activeText');
          document.getElementById('email_template').classList.add('activeText');
      } else if (this.current_tab === 'Template') {
        this.campaign_detailsview = false;
        this.email_listview = false;
        this.template_view = false;
        this.emailcontent_view = true;
        this.emailpreviewanssend = false;
        this.journey_email_preview = false;
        this.current_tab = 'Content';
        document.getElementById('nav4').classList.add('active');
        document.getElementById('nav3').classList.remove('active');
        document.getElementById('email_template').classList.remove('activeText');
        document.getElementById('email_content').classList.add('activeText');
      } else if (this.current_tab === 'Content') {
        this.campaign_detailsview = false;
        this.email_listview = false;
        this.template_view = false;
        this.emailcontent_view = false;
        this.emailpreviewanssend = false;
        this.journey_email_preview = true;
        this.current_tab = 'Preview';
        document.getElementById('nav6').classList.add('active');
        document.getElementById('nav4').classList.remove('active');
        document.getElementById('email_content').classList.remove('activeText');
        document.getElementById('email_presend').classList.add('activeText');
      }
    }

  }

  previous() {
    if ((<any>window).journey_email_modal_tabs_fields.tab_fields) {
      if (this.current_tab === 'Recepients') {
        this.current_tab = 'Campaign Details';
        this.campaign_detailsview = true;
        this.email_listview = false;
        this.template_view = false;
        this.emailcontent_view = false;
        // this.emailspamreport = false;
        this.emailpreviewanssend = false;
        this.sendbtn = false;
        this.nextbtn = true;
        this.journey_email_sendBtn = false;
        document.getElementById('nav1').classList.add('active');
        document.getElementById('nav2').classList.remove('active');
        document.getElementById('cam_details').classList.add('activeText');
        document.getElementById('email_recipients').classList.remove('activeText');
      } else if (this.current_tab === 'Template') {
        this.current_tab = 'Recepients';
        this.campaign_detailsview = false;
        this.journey_email_sendBtn = false;
        this.email_listview = true;
        this.template_view = false;
        this.emailcontent_view = false;
        // this.emailspamreport = false;
        this.emailpreviewanssend = false;
        this.sendbtn = false;
        this.nextbtn = true;
        document.getElementById('nav2').classList.add('active');
        document.getElementById('nav3').classList.remove('active');
        document.getElementById('email_recipients').classList.add('activeText');
        document.getElementById('email_template').classList.remove('activeText');
      } else if (this.current_tab === 'Content') {
        this.current_tab = 'Template';
        this.campaign_detailsview = false;
        this.email_listview = false;
        this.template_view = true;
        this.emailcontent_view = false;
        // this.emailspamreport = false;
        this.emailpreviewanssend = false;
        this.sendbtn = false;
        this.nextbtn = true;
        this.journey_email_sendBtn = false;
        document.getElementById('nav3').classList.add('active');
        document.getElementById('nav4').classList.remove('active');
        document.getElementById('email_template').classList.add('activeText');
        document.getElementById('email_content').classList.remove('activeText');
      } else if (this.current_tab === 'Preview') {
        this.current_tab = 'Content';
        this.campaign_detailsview = false;
        this.email_listview = false;
        this.template_view = false;
        this.emailcontent_view = true;
        // this.emailspamreport = true;
        this.emailpreviewanssend = false;
        this.sendbtn = false;
        this.nextbtn = true;
        this.journey_email_sendBtn = false;
        document.getElementById('nav4').classList.add('active');
        document.getElementById('nav6').classList.remove('active');
        document.getElementById('email_content').classList.add('activeText');
        document.getElementById('email_presend').classList.remove('activeText');
      }
    } else {
      if (this.current_tab === 'Preview') {
        this.campaign_detailsview = false;
        this.email_listview = false;
        this.template_view = false;
        this.emailcontent_view = true;
        this.emailpreviewanssend = false;
        this.journey_email_preview = false;
        this.current_tab = 'Content';
        document.getElementById('nav4').classList.add('active');
        document.getElementById('nav6').classList.remove('active');
        document.getElementById('email_content').classList.add('activeText');
        document.getElementById('email_presend').classList.remove('activeText');
      } else if (this.current_tab === 'Content') {
        this.campaign_detailsview = false;
        this.email_listview = false;
        this.template_view = true;
        this.emailcontent_view = false;
        this.emailpreviewanssend = false;
        this.journey_email_preview = false;
        this.current_tab = 'Template';
        document.getElementById('nav3').classList.add('active');
        document.getElementById('nav4').classList.remove('active');
        document.getElementById('email_template').classList.add('activeText');
        document.getElementById('email_content').classList.remove('activeText');
      } else if (this.current_tab === 'Template') {
        this.campaign_detailsview = true;
        this.email_listview = false;
        this.template_view = false;
        this.emailcontent_view = false;
        this.emailpreviewanssend = false;
        this.journey_email_preview = false;
        this.current_tab = 'Campaign Details';
        document.getElementById('nav1').classList.add('active');
        document.getElementById('nav3').classList.remove('active');
        document.getElementById('cam_details').classList.add('activeText');
        document.getElementById('email_template').classList.remove('activeText');
      }
    }
  }

  clear() {
    document.getElementById('nav1').classList.add('active');
    document.getElementById('nav2').classList.remove('active');
    document.getElementById('nav3').classList.remove('active');
    document.getElementById('nav4').classList.remove('active');
    document.getElementById('nav6').classList.remove('active');
    document.getElementById('cam_details').classList.add('activeText');
    document.getElementById('email_recipients').classList.remove('activeText');
    document.getElementById('email_template').classList.remove('activeText');
    document.getElementById('email_content').classList.remove('activeText');
    document.getElementById('email_presend').classList.remove('activeText');
    this.emailsendObj.contentcampaignname = '';
    this.emailsendObj.campaignname = '';
    this.emailsendObj.sendername = '';
    this.emailsendObj.camdetailssubject = '';
    this.emailsendObj.emails = '';
    this.emailsendObj.ckeditorcontent = '';
    this.emailsendObj.senderemail = '';
    this.emailsendObj.replyname = '';
    this.emailsendObj.reply_email = '';
    this.emailfiledata = '';
    this.schduledtime = [];
    this.desabledScheduleButton = true;
    this.datasetsArray = [];
    this.emailselectedItems = [];
    this.showDatasets = false;
    this.getemailgrps();
    this.emailsendObj.senderemail = ((<any>window).userprofile[0].email);
      this.emailsendObj.sendername = ((<any>window).userprofile[0].fullName);
      this.emailsendObj.replyname = ((<any>window).userprofile[0].fullName);
    if (this.current_tab === 'Preview' || this.current_tab === 'Content' || this.current_tab === 'Template' || this.current_tab === 'Recepients' || this.current_tab === 'Campaign Details') {
      this.current_tab = 'Campaign Details';
      if (this.current_tab === 'Campaign Details') {
        this.campaign_detailsview = true;
        this.email_listview = false;
        this.template_view = false;
        this.emailcontent_view = false;
        // this.emailspamreport = false;
        this.emailpreviewanssend = false;
        this.sendbtn = false;
        this.nextbtn = true;
        this.previous();
      }
    }
  }

  schduleddate(data) {
    this.schduledtime.push(this.datepipe.transform(data, 'yyyy-MM-dd HH:mm:ss'));
    console.log(this.schduledtime);
  }


  onDateSelect(evt) {
    this.desabledScheduleButton = false;
  }

  changeemailFile(event) {
    this.emailfiledata = event.target.files[0];
  }

  // uploademailFile() {
  //   if (this.emailfiledata === undefined || this.emailfiledata === '') {
  //     this.showError('Please Select Emails List File');
  //     return;
  //   } else {
  //         console.log(this.emailfiledata);
  //         console.log(this.emailfiledata.target.files[0]);
  //         // let fileName = selected[0].name;
  //         this.fileName = this.emailfiledata.target.files[0].name;
  //         this.emailsendObj.fileObj = this.emailfiledata.target.files[0];
  //         console.log(this.fileName);
  //         // console.log(this.fileObj)
  //         const fd = new FormData();
  //         fd.append('file', this.emailfiledata.target.files[0], this.emailfiledata.target.files[0].name);
  //         console.log('sdasd', fd);
  //         const headers = new Headers();
  //         headers.append('X-Citistar-Token', (<any>window).citiToken);
  //         headers.append('X-Apikey', 'citistar-00e24b550974aa1530f6893ca8fc37');
  //         this.http.post((<any>window).citiUrl + 'campaigns/email/validations', fd, { headers: headers })
  //           .map(res => res.json())
  //           .subscribe(data => {
  //             console.log(data);
  //             if (data.status === 'success') {
  //               this.emailfiledata.target.value = '';
  //             } else {
  //               console.log('failed');
  //               // console.log(data.json().message.toLowerCase())
  //               // if (data.json().message.toLowerCase() === 'token mismatch') {
  //               //    this.myServ.logout().subscribe(data => {
  //               //       if (data.json().status === 'success') {
  //               //          console.log('successfully logged out');
  //               //           window.location.reload();
  //               //           // this.router.navigate(['/login'], { skipLocationChange: true});
  //               //           (<any>window).tabs = [];
  //               //           (<any>window).font_awesome = [];
  //               //           (<any>window).citiToken = '';
  //               //           (<any>window).userprofile = [];
  //               //           (<any>window).walletDetails  = [];
  //               //       this.showError('Session Expired');
  //               //       } else {
  //               //       this.showError(data.json().message);
  //               //         window.location.reload();
  //               //       }
  //               //     });
  //               //   } else {
  //               //     this.showError(data.json().message);
  //               //   }
  //               this.showError(data.json().message);
  //             }
  //           }, error => {
  //             this.showError('Server is busy, Please try again');
  //             console.log(JSON.stringify(error.json()));
  //             console.log(error);

  //           });

  //       }
  //     }

      chosenemailData(cars) {
      this.selectedemail = cars;
    }

    chosenemailDataToRemove(cars) {
      this.deselectedemail = cars;
    }

    emailsassigne() {
      this.emailselectedItems = this.emailselectedItems.concat(this.selectedemail);
      this.emailgroupsArray = this.emailgroupsArray.filter(car => {
        return this.emailselectedItems.indexOf(car) < 0;
      });
      this.selectedemail = [];
    }

    emailsunassigne() {
      this.emailgroupsArray = this.emailgroupsArray.concat(this.deselectedemail);
      this.emailselectedItems = this.emailselectedItems.filter(selectedCar => {
        return this.emailgroupsArray.indexOf(selectedCar) < 0;
      });
      this.deselectedemail = [];
    }

    openemailDatasets() {
      this.datasetsArray = [];
  const obj = {
    type: 'sendCampaign'
  };
  this.dialogService.addDialog(AudiencemodalComponent, { obj }, { backdrop: 'static' })
  .subscribe((data: any) => {
    setTimeout(() => {
    console.log((<any>window).audienceDataForCells.data.dataSets);
    this.array = (<any>window).audienceDataForCells.data.dataSets;
  if (this.array.length === 0) {
    this.showDatasets = false;
    return;
  } else {
    this.showDatasets = true;
    for (let i = 0; i < this.array.length; i++) {
      this.datasetsArray.push(this.array[i]);
    }
  }
    }, 1000);
});
  }

    showSuccess(data) {
      if (this.toastr.currentlyActive === 0) {
        this.toastr.success(data, '', {
          timeOut: 1500,
          positionClass: 'toast-bottom-right'
        });
      }
    }
    showError(data) {
      if (this.toastr.currentlyActive === 0) {
        // console.log('active toasts = '+ this.toastr.currentlyActive +' In if');
        this.toastr.error(data, '', {
          timeOut: 1500,
          positionClass: 'toast-bottom-right'
        });
      }
    }

    sendTestMail() {
      // this._ngb.send(emailsendObj, date);
      const to = prompt('Where to send?');
      if (!to) {
        return;
      }
      console.log('XXXXXXXXXXXX', to);
      // this._ngb.sendTestEmail({ to });
    }

    send(emailsendObj, date) {
      this.profileloading = true;
      this.groupsArray = [];
      this.datasetDataArray = [];
      if (this.schduledtime.length === 0) {
        this.scheduledOn = 'false';
        this.schduledatetime = [];
      } else {
        this.scheduledOn = 'true';
        this.schduledatetime = this.schduledtime;

      }

    if (this.emailselectedItems === 0) {
      this.groupsArray = [];
    } else {
      for (let i = 0; i < this.emailselectedItems.length; i++) {
        this.groupsArray.push(this.emailselectedItems[i].groupId);
      }
    }

    if (this.datasetsArray.length === 0) {
      this.datasetDataArray = [];
    } else {
      for (let i = 0; i < this.datasetsArray.length; i++) {
        this.datasetDataArray.push(this.datasetsArray[i].item.datasetid);
      }
    }

    console.log(this.datasetDataArray);

      if (emailsendObj.emails) {
        this.useremails = [];
        this.emails_coma = emailsendObj.emails;
        this.useremails = this.emails_coma.split(',');
        console.log(this.useremails);
      } else {
        this.useremails = [];
      }
      const fd = new FormData();
      fd.append('campaignName', this.emailsendObj.campaignname);
      fd.append('campaignDescription', 'test desc');
      fd.append('subject', this.emailsendObj.camdetailssubject);
      fd.append('senderEmail', this.emailsendObj.senderemail);
      fd.append('senderName', this.emailsendObj.sendername);
      fd.append('replyEmail', this.emailsendObj.reply_email);
      fd.append('replyName', this.emailsendObj.replyname);
      fd.append('trackableLink', 'testlink');
      fd.append('textVersion', 'test version');
      fd.append('unsubscribeList', '');
      fd.append('precedence', '');
      fd.append('contentBody', this.emailsendObj.ckeditorcontent);
      fd.append('isScheduled', this.scheduledOn);
      fd.append('scheduledOn', JSON.stringify(this.schduledatetime));
      fd.append('contactsList', JSON.stringify(this.useremails));
      fd.append('groupIdsList', JSON.stringify(this.groupsArray));
      fd.append('datasetPlansIds', JSON.stringify(this.datasetDataArray));

      if (this.journey_email) {
        const headers = new Headers();
        headers.append('X-Apikey', (<any>window).citikey);
        headers.append('X-Citistar-Token', (<any>window).citiToken);
        this.http.post((<any>window).citiUrl + 'campaigns/services/email', fd, { headers: headers })
          .map(res => res.json())
          .subscribe(data => {
            if (data.status === 'success') {
              this.profileloading = false;
              this.showSuccess(data.message);
              this.clear();
              this.emailcomp.applyFilter('emailcampaigndetails');
              this.defaultLayout.getUserWalletData();
            } else {
              this.profileloading = false;
              this.showError(data.message);
            }
          }, error => {
            this.profileloading = false;
            this.showError('Server is busy, Please try again');
          });
      } else {
        // For only journey builder use only this
        const emailobj = {
          subject: this.emailsendObj.camdetailssubject,
          senderEmail: this.emailsendObj.senderemail,
          senderName: this.emailsendObj.sendername,
          replyName: this.emailsendObj.reply_email,
          contentBody: this.emailsendObj.ckeditorcontent,
        };
        console.log(emailobj);
        console.log((<any>window).emailDataFromModal);
        (<any>window).emailDataFromModal = emailobj;
        // ends here
      }
    }

    getemailgrps() {
      this.emailgroupsArray = [];
      this.myServ.getemailGroups().subscribe(data => {
        if (data.json().status === 'success') {
          const emailgroup = data.json().response;
          for (let i = 0; i < emailgroup.length; i++) {
            this.emailgroupsArray.push(emailgroup[i]);
          }
        } else {
          this.showError(data.json().message);
        }
      }, error => {
        this.showError('Server is busy, Please try again');
      });
    }

    ngOnInit() {
      this.getemailgrps();
      document.getElementById('nav1').classList.add('active');
      document.getElementById('cam_details').classList.add('activeText');
      this.emailsendObj.senderemail = ((<any>window).userprofile[0].email);
      this.emailsendObj.sendername = ((<any>window).userprofile[0].fullName);
      this.emailsendObj.replyname = ((<any>window).userprofile[0].fullName);
      console.log((<any>window).journey_email_modal_tabs_fields.tab_fields);
      if ((<any>window).journey_email_modal_tabs_fields.tab_fields) {
        this.journey_email = true;
        this.campaigndetails_label = '1. Campaign Details';
        this.recepients_label = '2. Recipients';
        this.template_label = '3. Template';
        this.content_label = '4. Content';
        this.previewsend_label = '5. Preview & Send';
        document.getElementsByClassName('myemail_dialog_footer_cls')[0].classList.add('myemail_dialog_footer_margin');
      } else {
        this.journey_email = false;
        this.campaigndetails_label = 'Campaign Details';
        this.recepients_label = 'Recipients';
        this.template_label = 'Template';
        this.content_label = 'Content';
        this.previewsend_label = 'Preview';
        document.getElementsByClassName('myemail_dialog_footer_cls')[0].classList.add('myemail_dialog_footer_padding');
        document.getElementsByClassName('myemail_dialog_cls')[0].classList.add('myemail_dialog_clss');
      }

      // this._ngb.Email = new IPDefaultEmail({
      //   structures: [
      //     new Structure('cols_1', [
      //       [
      //         // new TextBlock(
      //         //   `<p class="ql-align-center">This text and Image are injected from <strong>Root Module</strong>.
      //         //   It's an example of dynamic Email, which allow you to create many awesome things, like Newsletter!</p>`,
      //         //   {
      //         //     lineHeight: {
      //         //       value: 22,
      //         //       unit: 'px'
      //         //     }
      //         //   }
      //         // ),
      //         // new TextBlock(
      //         //   `<h2 class="ql-align-center">It looks like this!</h2>`
      //         // ),
      //         // new ImageBlock(
      //         //   'https://image.ibb.co/iXV3S9/Screen_Shot_2018_09_14_at_17_15_38.png'
      //         // )
      //       ]
      //     ])
      //   ]
      // });
    }
  }
