<div class="modal-content" style="background: #fff;padding: 0.2rem;">
	<div class="modal-header">
		<div class="modal-title pull-left list_contacts">
			<span style="padding-left: 5px;color: #00b7ff;">Add/Subtract Credits</span>
		</div>
		<button type="button" class="close" aria-label="Close" (click)="closeModal()">
			<span aria-hidden="true" style="font-size: 21px;">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="form-group row" style="margin-bottom: 14px;margin-top: 10px;">
			<label class="col-md-3 col-form-label" style="text-align: right;" for="text-input">Credits</label>
			<div class="col-md-9">
				<input type="text" id="text-input" name="text-input" class="form-control" style="padding: 10px;width:70%;margin-left: 6%;"
				 placeholder="Enter Credits here" [(ngModel)]="credits">
			</div>
		</div>
		<div class="form-group row" style="margin-bottom: 0.2rem;">
			<label style="padding-right: 0px;" class="col-3 col-form-label" style="text-align: right;" id="label" for="text-input">Description</label>
			<div class="col-9" style="display: inherit;">
				<textarea id="textarea-input" cols="4" rows="5" name="textarea-input" class="form-control" style="height: 100px;border:none;width:70%;margin-left: 6%;border: 1px solid lightgrey;"
				 placeholder="Enter Description here" [(ngModel)]="admindesc"></textarea>
			</div>
		</div>
		<div class="row" style="padding-top: 10px;">
			<div class="col-12" style="text-align: center">
				<span>
					<button type="button" class="btn btn-primary groupalign" (click)="addsubtractCredits()">
						Add
					</button>
				</span>
				<span>
					<button type="button" class="btn btn-primary" style="margin-left: 8px;" (click)="addsubtractCredits()">
						Subract
					</button>
				</span>
			</div>
		</div>
		<p style="margin-top:41px;font-weight: 600;float:right;margin-right:5px;">Total Credits : <span>{{this.sum}}</span></p>
		<div style="padding-top: 5px;margin-bottom: 75px">
			<table class="table table-striped jobtracker" [mfData]="addfundsData" #mf="mfDataTable" [mfRowsOnPage]="10" style=" overflow:scroll;">
				<thead style="background: #d9e0e6;">
					<tr>

						<th>
							<mfDefaultSorter by="Date">Date</mfDefaultSorter>
						</th>
						<th>
							<mfDefaultSorter by="Credits">Credits</mfDefaultSorter>
						</th>
						<th>
							<mfDefaultSorter by="Description">Description</mfDefaultSorter>
						</th>
						<th>
							<mfDefaultSorter by="TransactionType">Transaction Type</mfDefaultSorter>
						</th>
						<th>
							<mfDefaultSorter by="Edit">Edit</mfDefaultSorter>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of mf.data" (click)="rowdata(item)">
						<td>{{item.date}}</td>
						<td>{{item.amount}}</td>
						<td>{{item.description}}</td>
						<td>{{item.transactionType}}</td>
						<td><i class='fa fa-edit change_ico' (click)=edit(item)></i></td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<td colspan="4">
							<mfBootstrapPaginator [rowsOnPageSet]="[5,10,25]"></mfBootstrapPaginator>
							<span class="input-group-text" style="background-color: transparent;border: none;font-size: 12px;;position: absolute;left: 50%;">{{addfundsData.length}}
								total</span>
						</td>
					</tr>
				</tfoot>
			</table>

		</div>
	</div>
</div>