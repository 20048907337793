import { Component, OnInit } from '@angular/core';
import { DialogComponent } from 'ngx-bootstrap-modal/index';
import { DialogService } from 'ngx-bootstrap-modal/components/dialog.service';
import { Router, Event, NavigationEnd } from '@angular/router';
export interface ConfirmModel {
  title: string;
  message: string;
}

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel, OnInit {

  title: string;
  message: any;
  time_number: any;
  time_period: any;
  datetime: Date = new Date();
  // specific_date: Date = new Date();
  time_zonee = 'UTC';
  radio_value_change: any;
  numberDays: any;
  selection_period: any;
  date: Date = new Date();
  settings = {
    bigBanner: true,
    timePicker: true,
    format: 'dd-MM-yyyy h:mm a',
    defaultOpen: false
  };
  constructor(dialogService: DialogService, private route: Router) {
    super(dialogService);
    if (localStorage.getItem('obj_value') == null) {
    } else {
      const obj_value = JSON.parse(localStorage.getItem('obj_value'));
      console.log(obj_value);
      if (obj_value.hasOwnProperty('specific_date')) {
        this.datetime = obj_value.datetime;
        /// this.specific_time = obj_value.specific_time;
        this.time_zonee = obj_value.time_zonee;
      } else {
        this.time_number = obj_value.time_number;
        this.time_period = obj_value.time_period;
        this.time_zonee = obj_value.time_zonee;
      }
      localStorage.removeItem('obj_value');
    }
  }

  ngOnInit() {
    this.route.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {
        // Hide loading indicator

        this.close();
      }

    });
  }

  closeModal() {
    console.log(this.datetime);
    // let radio_active = $("input[name='selector']:checked").val();
    if (this.radio_value_change === 'specific') {
      const obj = {
        'specific': this.datetime
      };
      localStorage.setItem('obj_value', JSON.stringify(obj));
    } else {
      const obj = {
        'numberDays': this.numberDays + ':' + this.selection_period
      };
      localStorage.setItem('obj_value', JSON.stringify(obj));
    }
    // console.log(radio_active);

    this.close();
  }

  radio_change(data) {
    this.radio_value_change = data;
  }

}
