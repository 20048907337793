import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule  } from '@angular/common';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { AudiencePlannerComponent } from './audience-planner.component';
import { AudiencePlannerRoutingModule } from './audience-planner-routing.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
@NgModule({
  imports: [
    FormsModule,
    ChartsModule,
    AudiencePlannerRoutingModule,
    BsDropdownModule,
    CommonModule ,
    ButtonsModule.forRoot(),
    PopoverModule.forRoot(),
    TabsModule,
    ProgressSpinnerModule

  ],
  declarations: [ AudiencePlannerComponent ],
  exports: [ AudiencePlannerComponent ]
})
export class AudiencePlannerModule { }
