<div   tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class=" modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title pull-left" style="font-size: 15px;font-weight: 600;">
          <span style="padding-left: 5px;">Create <font color="#f44336">Citimedia</font> account</span>
        </div>
        <button type="button" class="close pull-right my_closex" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true" style="font-size: 21px;">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="background-color: #f4f4f4;">
        <div class="row">
          <div class="col">
            <div class="input-group mb-2">
              <div class="top_strip">Personal Details</div>
            </div>
            <div class="input-group mb-2">
              <input type="text" class="form-control sample" style="border-radius: 5px;" placeholder="User Name" [(ngModel)]="username" maxlength="30" #myUserName (focusout)="userDetailsCheck(username,'username')">
            </div>
            <div class="input-group mb-2">
              <input type="text" class="form-control" style="border-radius: 5px;" placeholder="Email Address" [(ngModel)]="email" #myEmail (focusout)="userDetailsCheck(email,'email')">
            </div>
            <div class="input-group mb-2">
              <input type="password" class="form-control" style="border-radius: 5px;" placeholder="Password" [(ngModel)]="password">
            </div>
            <div class="row">
              <div class="col" style="padding-right: 2px;">
                <div class="input-group mb-2">
                  <select class="form-control" style="height: 31px;border-radius: 5px;font-size: 13px;" [(ngModel)]="select_country" (change)="selectcountry($event.target.value)"  >
                    <option value="" disabled selected hidden>Select Country</option>
                    <option *ngFor="let singlecountry of countrieslist"  [value]="singlecountry.countryId">{{singlecountry.countryName}}</option>
                  </select>
                </div>
              </div>
              <div class="col" style="padding-left: 2px;">
                <select class="form-control" style="height: 31px;border-radius: 5px;font-size: 13px;" [(ngModel)]="select_state" (change)="selectstate($event.target.value)" >
                  <option value="" disabled selected hidden>Select State</option>
                  <option *ngFor="let singlestate of statesList" [value]="singlestate.stateId">{{singlestate.stateName}}</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col"  style="padding-right: 2px;">
                <div class="input-group mb-2">
                  <select class="form-control"  style="height: 31px;border-radius: 5px;font-size: 13px;" [(ngModel)]="select_city" >
                    <option value="" disabled selected hidden>Select City</option>
                    <option *ngFor="let singlecity of citieslist" [value]="singlecity.citiName">{{singlecity.citiName}}</option>
                  </select>
                </div>
              </div>
              <div class="col" style="padding-left: 2px;">
                <div class="input-group mb-2">
                  <input type="text" class="form-control" style="border-radius: 5px;height: 30px;" placeholder="Pincode" [(ngModel)]="pincode">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-4" style="padding-right: 2px;">
                <div class="input-group mb-2">
                  <input type="text" class="form-control" style="border-radius: 5px;" placeholder="PhoneCode" [(ngModel)]="phonecode">
                </div>
              </div>
              <div class="col" style="padding-left: 2px;">
                <div class="input-group mb-2">
                  <input type="text" class="form-control" style="border-radius: 5px;" placeholder="Mobile Number" [(ngModel)]="mobilenum" maxlength="15" #myMobileNum (focusout)="userDetailsCheck(mobilenum,'mobile')">
                </div>
              </div>
            </div>
            <div class="input-group mb-2">
              <input type="text" class="form-control" style="border-radius: 5px;" placeholder=" Address" [(ngModel)]="address">
            </div>
            <div class="input-group mb-2">
              <input type="text" class="form-control" style="border-radius: 5px;" placeholder="Company Name" [(ngModel)]="companyname">
            </div>
            <div class="input-group mb-2">
              <select class="form-control" style="height: 31px;border-radius: 5px;font-size: 13px;" [(ngModel)]="industry" (change)="selectindustry($event.target.value)" >
                <option value="" disabled selected hidden>Select Industry Type</option>
                <option *ngFor="let singleindustry of industriestypelist" [value]="singleindustry">{{singleindustry}}</option>
              </select>
            </div>
            <div class="input-group mb-2">
              <input type="text" class="form-control" style="border-radius: 5px;" placeholder="Company URL" [(ngModel)]="companyUrl">
            </div>
          </div>
          <div class="col">
            <div class="input-group mb-2">
              <div class="top_strip">Payment Details</div>
            </div>
            <div class="input-group mb-2">
              <input type="text" class="form-control" style="border-radius: 5px;" placeholder="Credit Card Name" [(ngModel)]="cardname">
            </div>
            <div class="row">
              <div class="col-9" style="padding-right: 2px;">
                <div class="input-group mb-2">
                  <input type="text" class="form-control" style="border-radius: 5px;" placeholder="Credit Card Number" [(ngModel)]="cardnum" maxlength="16" oninput="this.value=this.value.replace(/[^0-9]/g,'');">
                </div>
              </div>
              <div class="col-3" style="padding-left: 2px;">
                <div class="input-group mb-2">
                  <input type="password" class="form-control" style="border-radius: 5px;" placeholder="CVV" [(ngModel)]="cvv" maxlength="3" oninput="this.value=this.value.replace(/[^0-9]/g,'');">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-4" style="padding-right: 2px;">
                <div class="input-group mb-2">
                  <select class="form-control" style="height: 31px;border-radius: 5px;font-size: 13px;" [(ngModel)]="month" (change)="selectmonth($event.target.value)">
                      <option value="" disabled selected hidden>Month</option>
                      <option  *ngFor="let i of monthvalue" [value]="i.value">{{i.value}}</option>
                  </select>
                </div>
              </div>
              <div class="col-4"  style="padding-left: 2px;">
                <div class="input-group mb-2">
                  <select class="form-control"  style="height: 31px;border-radius: 5px;font-size: 13px;" [(ngModel)]="year" (change)="selectyear($event.target.value)">
                      <option value="" disabled selected hidden>Year</option>
                      <option *ngFor="let i of years" [value]="i.value">{{i.value}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="input-group mb-2" >

               <img src="./assets/images/visa-curved.png" style="height: 30px;"/>
               <img src="./assets/images/mastercard.png" style="margin-top: -8px;height: 47px;"/>
               <img src="./assets/images/jcb_card_payment-48.png" style="height: 32px;margin-top:0px;"/>
               <img src="./assets/images/payment_method_paypal-48.png" style="height: 30px;margin-top: 2px;"/>
               <img src="./assets/images/rupay_payment_card_bank-64.png" style="height: 30px;margin-top: 2px;"/>
            </div>
            <div class="input-group mb-2">
              <div><span style="color: red ;font-size: 12px">Note: All Fields are mandatory</span>
              </div>
            </div> <img src="{{flags}}" id="copyright" style="display: none;">

          </div>

        </div>
        <div class="row" style="text-align: center;">
          <div class="col">
            <button type="button" class="btn btn-default btn-xl signup_btn" (click)="signupbutton()">Signup</button>
            <button type="button" class="btn btn-default btn-xl cancel_btn" (click)="cancelbutton()">Clear</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
