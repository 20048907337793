import { Component, OnInit } from '@angular/core';
import { DialogComponent } from 'ngx-bootstrap-modal/index';
import { DialogService } from 'ngx-bootstrap-modal/components/dialog.service';
import { Router, Event, NavigationEnd } from '@angular/router';
export interface ConfirmModel {
  title: string;
  message: string;
}

@Component({
  selector: 'app-decision',
  templateUrl: './decision.component.html',
  styleUrls: ['./decision.component.scss']
})
export class DecisionComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel, OnInit {

  title: string;
  message: any;
  decision_name: any;
  type_message = '';
  condition_type = '';
  obj: any = {};
  relation_type = '';
  relation_number: any;
  send_type = '';
  yes_id: any;
  no_id: any;
  yes_text: any;
  no_text: any;
  conditionTypeOptions: any[];
  constructor(dialogService: DialogService, private route: Router) {
    super(dialogService);

  }

  ngOnInit() {
    this.route.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        const myobj: any = {
          'offertype': ''
        };
        this.close(myobj);
      }

    });
    console.log(this.obj);
    if (this.obj === undefined) {

    } else {

      this.decision_name = this.obj.decision;
      this.type_message = this.obj.type,
        this.condition_type = this.obj.condition;
      this.relation_type = this.obj.relation;
      this.relation_number = this.obj.range;
      this.send_type = this.obj.sendfor;
      this.yes_id = this.obj.yes_id;
      this.no_id = this.obj.no_id;
      this.yes_text = this.obj.yes_text;
      this.no_text = this.obj.no_text;
    }
  }

  closeModal() {
    this.obj = {
      decision: this.decision_name,
      type: this.type_message,
      condition: this.condition_type,
      relation: this.relation_type,
      range: this.relation_number,
      sendfor: this.send_type,
      yes_id: this.yes_id,
      no_id: this.no_id,
      yes_text: this.yes_text,
      no_text: this.no_text
    };
    this.close(this.obj);
  }

  display() {
    this.sms_input = true;
    this.email_input = false;
    // document.getElementById('div2').style.display = "none";
    // document.getElementById('div1').style.display = "block";
  }

  display1() {
    this.sms_input = false;
    this.email_input = true;
    // document.getElementById('div1').style.display = "none";
    // document.getElementById('div2').style.display = "block";
  }

  selectType(datavalue) {
    if (datavalue === 'SMS') {
      this.conditionTypeOptions = [
        { 'name': 'Select Condition', value: '' },
        { 'name': 'Delivered', value: 'delivrd' },
        { 'name': 'Failed', value: 'failed' }
      ];
    } else {
      this.conditionTypeOptions = [
        { 'name': 'Select Condition', value: '' },
        { 'name': 'Delivered', value: 'DELIVERRED' },
        { 'name': 'Opened', value: 'OPENED' },
        { 'name': 'Clicked', value: 'CLICKED' },
        { 'name': 'All', value: 'ALL' }
      ];
    }
  }

}
