import { Component, OnInit } from '@angular/core';
import { DialogComponent } from 'ngx-bootstrap-modal/index';
import { DialogService } from 'ngx-bootstrap-modal/components/dialog.service';
import { UpdateyourprofileService } from '../views/settings/updateyourprofile.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomEncoder } from '../views/settings/yourprofile/CustomEncoder';
import { DeviceDetectorService } from 'ngx-device-detector';
import { v4 as uuid } from 'uuid';
import { Router, NavigationEnd, Event } from '@angular/router';
import { MobileverificationComponent } from '../views/signup/mobileverification/mobileverification.component';
export interface AudienceModel {
  title: string;
  message: string;
}
@Component({
  selector: 'app-oathprofile',
  templateUrl: './oathprofile.component.html',
  styleUrls: ['./oathprofile.component.scss']
})
export class OathprofileComponent extends DialogComponent<AudienceModel, boolean> implements AudienceModel, OnInit {
  title: string;
  message: any;
  countrieslist: any = [];
  stateslist: any = [];
  citylist: any = [];
  profile: any = {};
  url: any;
  orgidis: any;
  countryid: any;
  stateid: any;
  filePath: any = [];
  selected: any = [];
  industriestype: any = [];
  orgData: any;
  profileloading: any;
  deviceInfo: any;
  latitude: any;
  longitude: any;
  uuidParam: any;
  deviceType: any;
  deviceOs: any;
  initLocationArray: any = [];
  selectedStateName: any;
  selectedCityName: any;
  selectedcountryname: any;
  desabledTextbox: any;
  closeData: any;
  constructor(private deviceService: DeviceDetectorService, dialogService: DialogService, private getuserinfoservice: UpdateyourprofileService, private http: HttpClient, private toastr: ToastrService, public router: Router) {
    super(dialogService);
    this.getUserInfo();
    this.getCountriesList();
    this.getIndustries();
  }

  ngOnInit() {
    this.router.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {
      this.close();
      }
    });
    this.getDeviceDetails();
    document.getElementById('sideMenu-Dashboard').removeAttribute('style');
    document.getElementById('sideMenu-Social Monitor').removeAttribute('style');
    document.getElementById('sideMenu-Ad Manager').removeAttribute('style');
    document.getElementById('sideMenu-Keyword Analytics').removeAttribute('style');
    document.getElementById('sideMenu-Journey Builder').removeAttribute('style');
    document.getElementById('sideMenu-Admin').removeAttribute('style');
  }
  setDefaultPic() {
    this.url = 'assets/img/brand/user.png';
  }

  getDeviceDetails() {
    this.initLocationArray = [];
    console.log('uuid---->' + uuid());
    this.uuidParam = uuid();
    // navigator.geolocation.watchPosition((position) => {
    //   console.log('i m tracking you!');
    //   console.log(position);
    //   this.initLocationArray.push(position.coords.latitude);
    //   this.initLocationArray.push(position.coords.longitude);
    //   console.log(this.initLocationArray);
    // },
    // error => {
    //   if (error.code === error.PERMISSION_DENIED) {
    //     console.log('you denied me :-(');
    //   }
    // });
    setTimeout(() => {
      navigator.geolocation.getCurrentPosition((position) => {
        this.initLocationArray.push(position.coords.latitude);
        this.initLocationArray.push(position.coords.longitude);
        console.log(this.initLocationArray);
      },
      error => {
          switch (error.code) {
              case 1:
                  console.log('Permission Denied');
                  break;
              case 2:
                  console.log('Position Unavailable');
                  break;
              case 3:
                  console.log('Timeout');
                  break;
          }
      });
    }, 100);

    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.deviceType = this.deviceInfo.browser;
    this.deviceOs = this.deviceInfo.os;
  }

  getUserInfo() {
    this.profileloading = true;
    this.getuserinfoservice.getSingleUser().subscribe(data => {
      console.log(data.json());
      if (data.json().status === 'success') {
        this.profileloading = false;
        const userdata = data.json().response[0];
        this.orgidis = userdata.orgId;
        this.profile.name = userdata.fullName;
        this.profile.username = userdata.userName;
        this.profile.email = userdata.email;
        this.profile.mobilenumber = userdata.mobileNumber;
        this.profile.country = userdata.country;
        this.profile.state = userdata.state;
        this.profile.city = userdata.city;
        this.profile.address = userdata.address;
        this.profile.zipcode = userdata.pinCode;
        this.profile.company = userdata.company;
        this.profile.industrytype = userdata.industry;
        this.profile.companyurl = userdata.companyUrl;
        this.url = userdata.profilePic;
        this.profile.timezone = new Date();
      } else {
        this.profileloading = false;
        this.showError(data.json().message);
        console.log(data.json());
      }
    }, error => {
      this.profileloading = false;
      this.showError('Server is busy, Please try again');
      // console.log(JSON.stringify(error.json()));
      console.log(error);
    });
  }
  showSuccess(data) {
    if (this.toastr.currentlyActive === 0) {
      // this.showSuccess(data);
      this.toastr.success(data, '', {
        timeOut: 1500,
        positionClass: 'toast-bottom-right'
        });
    }
  }
  showError(data) {
    if (this.toastr.currentlyActive === 0) {
      this.toastr.error(data, '', {
        timeOut: 1500,
        positionClass: 'toast-bottom-right'
      });
    }
  }
  updateProfile(evt) {
    this.profileloading = true;
    console.log(evt);
    // var body='fullname='+evt.name+'&country='+evt.country+'&company='+evt.company+'&companyurl='+evt.companyurl+'&industry='+evt.industrytype+'&mobileNumber='+evt.mobilenumber+'&email='+evt.email+'&orgId='+this.orgidis+'&timezone='+evt.timezone;
    for (let i = 0; i < this.countrieslist.length; i++) {
      if (evt.country === this.countrieslist[i].countryId) {
        // this.phonecode=this.countrieslist[i].phoneCode;
        this.selectedcountryname = this.countrieslist[i].countryName;
        // this.flags= (<any>window).citiUrl + this.countrieslist[i].flag;
        console.log(this.selectedcountryname);
      }
    }
    for (let i = 0; i < this.stateslist.length; i++) {
      if (evt.state === this.stateslist[i].stateId) {
        this.selectedStateName = this.stateslist[i].stateName;
        // this.flags= (<any>window).citiUrl + this.countrieslist[i].flag;
        console.log(this.selectedStateName);
      }
    }
    for (let i = 0; i < this.citylist.length; i++) {
      if (evt.city === this.citylist[i].citiId) {
        this.selectedCityName = this.citylist[i].citiName;
        // this.flags= (<any>window).citiUrl + this.countrieslist[i].flag;
        console.log(this.selectedCityName);
      }
    }
    if (this.initLocationArray.length === 0) {
      this.getDeviceDetails();
    }
    const fd = new HttpParams({ encoder: new CustomEncoder() })
      .set('fullname', evt.name)
      .set('userName', evt.username)
      .set('country', this.selectedcountryname)
      .set('company', evt.company)
      .set('companyurl', evt.companyurl)
      .set('industry', evt.industrytype)
      .set('mobileNumber', evt.mobilenumber)
      .set('email', evt.email)
      .set('timezone', evt.timezone)
      .set('deviceType', this.deviceType)
      .set('deviceToken', this.uuidParam)
      .set('deviceOS', this.deviceOs)
      .set('initialLocation', JSON.stringify(this.initLocationArray))
      .set('state', this.selectedStateName)
      .set('pinCode', evt.zipcode)
      .set('city', this.selectedCityName)
      .set('address', evt.address);
    console.log(fd);
    const headers = new HttpHeaders({ 'X-Apikey': (<any>window).citikey, 'X-Citistar-Token': (<any>window).citiToken });
    console.log(headers);
    // console.log((<any>window).citiUrl + 'campaigns/sms/registrations?route='+evt)
    this.http.post((<any>window).citiUrl + 'users/profile?', fd, { headers: headers })
      .subscribe((resp) => {
        console.log(resp);
        this.orgData = resp;
        if (this.orgData.status === 'success') {
          this.profileloading = false;
          const myvar = this;
          myvar.dialogService.addDialog(MobileverificationComponent, {
            email: evt.email,
            mobile: evt.mobilenumber
          }, { backdrop: 'static' })
            .subscribe((data) => {
              console.log(data);
              this.closeData = data;
              console.log(this.closeData);
              if (this.closeData === undefined) {
                return;
              } else {
                if (this.closeData.status === 'success') {
                  this.showSuccess(this.closeData.message);
                  this.close(this.closeData);
                } else {
                  console.log('failed');
                  this.showError(this.closeData.message);
                }
              }
            });
        } else {
          this.profileloading = false;
          this.showError(this.orgData.message);
          this.getDeviceDetails();
        }
      }, error => {
        this.profileloading = false;
        this.showError('Server is busy, Please try again');
      });
    // fullname,country,company,companyurl,industry,mobileNumber,orgId
  }

  getCountriesList() {
    // console.log()
    this.getuserinfoservice.getCountries().subscribe(data => {
      console.log(data.json());
      if (data.json().status === 'success') {
        this.countrieslist = data.json().countryList;
        console.log(this.countrieslist.length);
        for (let i = 0; i < this.countrieslist.length; i++) {
          if (this.profile.country === this.countrieslist[i].countryName) {
            console.log('countryid is' + this.countrieslist[i].countryId);
            // console.log(this.countrieslist)
            this.profile.country = this.countrieslist[i].countryId;
            console.log(this.profile.country);
            this.getStatesList(this.countrieslist[i].countryId);
          }
        }
      } else {
        console.log(data.json());
      }
    }, error => {
      console.log(JSON.stringify(error.json()));
      console.log(error);
    });
  }

  // function for calling ststelist service//
  getStatesList(countryid) {
    console.log(this.profile.state);
    this.getuserinfoservice.getStates(countryid).subscribe(data => {
      console.log(data.json());
      if (data.json().status === 'success') {
        this.stateslist = data.json().stateList;
        for (let i = 0; i < this.stateslist.length; i++) {
          if (this.profile.state === this.stateslist[i].stateName) {
            console.log('this.stateslist[i].stateName' + this.stateslist[i].stateName);
            this.profile.state = this.stateslist[i].stateId;
            console.log('my stateid is' + this.profile.state);
            this.getCitiesList(this.stateslist[i].stateId);
          }
        }
      } else {
        console.log(data.json());
      }
      console.log(this.stateslist);
    }, error => {
      console.log(error);
    });
  }

  getCitiesList(stateid) {
    this.getuserinfoservice.getCities(stateid).subscribe(data => {
      console.log(data.json());
      if (data.json().status === 'success') {
        this.citylist = data.json().cityList;
        for (let i = 0; i < this.citylist.length; i++) {
          if (this.profile.city === this.citylist[i].citiName) {
            console.log('this.stateslist[i].citiName' + this.citylist[i].citiName);
            this.profile.city = this.citylist[i].citiId;
          }
        }
      } else {
        console.log(data.json());
      }
      // console.log(this.countrieslist)
    });
  }

  // function for change event when country change//
  changeCountry(event, data) {
    console.log(data);
    console.log(event.target.value);
    this.countryid = event.target.value;
    this.getStatesList(this.countryid);
    // this.State(states);
  }

  changeCity(ev){
   console.log(ev);
  }

  // function for change event when state change//
  changeState(stateid) {
    // console.log(event.target.value)
    // this.stateid=event.target.value
    this.getCitiesList(stateid);
  }

  getIndustries() {
    console.log('industry typessss');
    this.getuserinfoservice.getIndustriesTypes().subscribe(data => {
      console.log(data.json());
      if (data.json().status === 'success') {
        this.industriestype = data.json().industriesList;
      } else {
        console.log('failed data');
      }
    }, error => {
      console.log(error);
    });
  }

  // function for file upload//
  onSelectFile(event) {
    this.filePath = [];
    this.selected = event.target.files;
    if (this.selected) {
      this.upload();
      this.profileloading = true;
    }
    console.log(event);
  }
  // service for file upload //
  upload() {
    const fd: FormData = new FormData();
    for (let i = 0; i < this.selected.length; i++) {
      fd.append('avatar', this.selected[i], this.selected[i].name);
      // fd.append('orgId',(<any>window).org)
    }
    const headers = new HttpHeaders({ 'X-Apikey': (<any>window).citikey, 'X-Citistar-Token': (<any>window).citiToken });
    // headers.append('X-Apikey', (<any>window).citikey);
    // headers.append('X-Citistar-Token', (<any>window).citiToken);
    this.http.post((<any>window).citiUrl + 'users/avatar', fd, { headers: headers })
      .subscribe((data) => {
        console.log(data);
        this.orgData = data;
        if (this.orgData.status === 'success') {
          // this.getassets();
          this.profileloading = false;
          this.getUserInfo();
          this.getCountriesList();
          // this.manageDefault.getUsersProfile();
          this.showSuccess('Uploaded successfully');
        } else {
          this.profileloading = false;
          this.showError(this.orgData.message);
        }
      }, error => {
        this.profileloading = false;
        this.showError('Server is busy, Please try again');
        console.log(JSON.stringify(error.json()));
        console.log(error);
      });
  }

  // function for deactivating account//
  deactiveAccount() {
    console.log('deactivated');
  }

  closeModal() {
    this.close();
  }

}
