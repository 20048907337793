<tabset class="audiencepalnner_tabs">
  <tab style="padding: 0px !important;">
    <ng-template tabHeading>
      <span class="badge iconStyle">
        <i class="fa fa-map-marker" aria-hidden="true"></i>
      </span>
      Audience
    </ng-template>
    <div>
      <div class="card" style='margin-bottom: -0.5rem !important;background: #f0f3f5;border: none;height: 90vh;'>
        <!-- <div class="card-header">
                  <i class="icon-drop"></i> Theme colors
                </div> -->
        <!-- <div class="card-body" style="padding-top: 5px; " [style.height.px]='windowHeight'> -->
        <div class="card-body" style="padding-top: 5px;">

          <div class="row">
            <div class={{columnForMap}} style="padding: 5px;background: #f0f3f5;">
              <!-- <div class="card-header">
                      <i class="icon-map"></i> Map
                    </div> -->

              <div style="width:inherit;" *ngIf="searchedPlaces.length>0">
                <div *ngFor="let item of searchedPlaces; let i = index" [id]='"place-"+i' class="localeStyle" style="height: 34px;margin-top: 2px;    margin-bottom: 5px;">
                  <div style="margin-top: 5px;font-size: 15px;" (click)="getIndex(item, i)">
                    <strong style='font-weight: 500;font-size: 13px;'>{{getLocationDetails(item)}}</strong>
                  </div>
                  <div class="placeClose" data-dismiss="alert" aria-label="close" (click)="removePlace(item, i)" style="opacity: 1;">&times;</div>

                </div>
              </div>
              <input (click)="resizeColumns()" id="pac-input" class="controls mySearchControl" onfocus="this.value=''" type="text" placeholder="">

              <!-- <span style="float:right;z-index:999;font-weight:600;position: relative;font-size:20px;padding:4px;color:#009688;" (click)="clearSearch()">X</span> -->
              <div id="map" [style.height.px]='mapHeight'></div>
              <div class="row" style="padding: 2px 15px;">

                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div style="margin-left: -15px!important;" class="row">
                    <strong style='font-weight:500;'>Gender</strong>
                  </div>
                  <div class="row" style='margin-top: 5px;'>
                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 form-check" style=' max-width: 63px;'>
                      <input class="form-check-input" type="radio" name="radios" id="radio-male" value="male" [checked]='genderSelected === "male"'
                        (change)="getGender($event)" [disabled]='searchedPlaces.length === 0'>
                      <label class="form-check-label" for="radio1">
                        Male
                      </label>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 form-check" style=' max-width: 75px;'>
                      <input class="form-check-input" type="radio" name="radios" id="radio-female" value="female" [checked]='genderSelected === "female"'
                        (change)="getGender($event)" [disabled]='searchedPlaces.length === 0'>
                      <label class="form-check-label" for="radio2">
                        Female
                      </label>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 form-check" style=' max-width: 75px;'>
                      <input class="form-check-input" type="radio" name="radios" id="radio-other" value="other" [checked]='genderSelected === "other"'
                        (change)="getGender($event)" [disabled]='searchedPlaces.length === 0'>
                      <label class="form-check-label" for="radio3">
                        Other
                      </label>
                    </div>
                  </div>

                </div>
                <div class='col-sm-12 col-md-4 col-lg-4 col-xl-4'>
                  <div style="margin-left: -15px!important;margin-bottom: 7px;" class="row">
                    <strong style='font-weight:500;'>Age</strong>
                  </div>
                  <div class="row" style="    margin-left: -29px;">
                    <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <!-- <label for="ccmonth">Month</label> -->
                      <select class="form-control" id="age-from" (change)="updateAge($event)" [disabled]='searchedPlaces.length === 0'>
                        <!-- <option value="0" selected>Please select</option> -->
                        <option *ngFor="let itemFrom of ageArray" [value]="itemFrom" [attr.selected]='itemFrom == ageRange.fromAge ? true : null'>{{itemFrom}}</option>
                      </select>
                    </div>
                    <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <!-- <label for="ccyear">Year</label> -->
                      <select class="form-control" id="age-to" (change)="updateAge($event)" [disabled]='searchedPlaces.length === 0'>
                        <!-- <option value="0">Please select</option> -->
                        <option *ngFor="let itemTo of ageArray" [value]="itemTo" [attr.selected]='itemTo == ageRange.toAge ? true : null'>{{itemTo}}</option>
                      </select>
                    </div>

                  </div>
                </div>

                <!-- <div class="col-6" >
                          <div style="text-align: right;">
                            <input type="range" min="5" max="100" [value]='searchRadius/1000' class="slider" id="myRange" (change)="radiusChage($event)" [disabled]='this.searchedPlaces.length == 0'>
                            <span style="font-weight:500;margin-left:10px;">{{searchRadius/1000}}</span> km
                          </div>
                        </div> -->
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div style="text-align: center;">
                    <input type="range" min="1" max="100" [value]='searchRadius/1000' class="slider" id="myRange" (change)="radiusChage($event)"
                      [disabled]='this.searchedPlaces.length == 0'>
                    <span style="font-weight:500;margin-left:10px;">{{searchRadius/1000}}</span> km
                  </div>
                </div>
              </div>
            </div>
            <div class={{columnForDatasets}} style="padding: 0px 5px 0px 5px;background: #f1f4f5;">
              <div class="card-header card-header-custom" style="padding: 0px !important;background: #f1f4f5">
                <!-- <i class="icon-layers"></i> Data Sets -->
                <!-- <div class="row">
                            <div class="col" style=" ">
                               <label for="role">Role</label>
                               <select class="form-control" id="dataset_role"  (change)="updaterole($event, dataset_role)" >
                                 <option *ngFor="let itemRole of datasetRole" [value]="itemRole.roleType"  [attr.selected]='itemRole.roleType == "All" ? true : null'>{{itemRole.roleType}}</option>
                               </select>
                             </div>
                             <div class="col">
                               <label for="type">Type</label>
                               <select class="form-control" id="dataset_type"  (change)="updatetype($event)">
                                 <option *ngFor="let itemType of datasetType" [value]="itemType.type"  [attr.selected]='itemType.type == "All" ? true : null'>{{itemType.type}}</option>
                               </select>
                             </div>
            
                           </div> -->
                <!-- <label for="type" style='padding-left: 0px;margin-bottom: 3px;margin-left: -15px;'>Selected: {{globalDataSets.length}}</label> -->
                <div class='row'>
                  <div class='col'>
                    <label for="role" *ngIf='dataSets'>Total:
                      <b>{{datasetsArray.length}}</b>
                    </label>
                  </div>
                  <div class='col' style="text-align: right">
                    <label for="type" *ngIf='globalDataSets'>Selected: {{smsEmail.length}}</label>
                  </div>
                </div>
                <div class="btn-group btn-group-sm" role="group" aria-label="Small button group" style='width:100%;    height: 30px;'>

                  <button type="button" class="btn btn-secondary" style='width:50%; padding: 0;right: 2px;' id="dataset_sms" (click)="updatetype($event)">
                    <i class="fa fa-mobile" id="dataset_sms" style="font-size:17px;margin-right: 5px;" (click)="updatetype($event)"></i>SMS</button>
                  <button type="button" class="btn btn-secondary" style='width:50%; padding: 0;' id="dataset_email" (click)="updatetype($event)">
                    <i class="fa fa-envelope" id="dataset_email" style="margin-right: 5px;" (click)="updatetype($event)"></i>Email</button>
                  <button type="button" class="btn btn-secondary" style='width:50%; padding: 0;left: 2px;' id="dataset_social" (click)="updatetype($event)">
                    <i class="fa fa-user" id="dataset_social" style="margin-right: 5px;" (click)="updatetype($event)"></i>Social</button>

                </div>

                <!-- <div class="btn-group btn-group-sm" role="group" aria-label="Small button group">
                                <button class="btn btn-secondary" type="button">Left</button>
                                <button class="btn btn-secondary" type="button">Middle</button>
                                <button class="btn btn-secondary" type="button">Right</button>
                            </div> -->


              </div>
              <div class="animated fadeIn">

                <div *ngIf='!noDataSetsFound' class="row" style="padding-left: 2px;padding-right: 5px;    margin-right: 2px;overflow-y: scroll;height: inherit;    margin: auto;"
                  [style.maxHeight.px]='datasetHeight'>
                  <div class="col" style="margin-top: 5px;padding: 5px;height: fit-content;width: 100px;max-width: 105px;margin-right: 2px;"
                    *ngFor="let item of dataSets">
                    <div class="card bg-custom" style="" id="dataset-{{item.datasetid}}-{{item.type}}">
                      <div class="card-body pb-0 data-set-pointer" style="padding: 5px!important;" *ngIf="item.type == 'email'" (click)='selectDataSet($event,item, "dataset-"+item.datasetid +"-"+item.type)'>
                        <div class="row">
                          <div class="col-3">
                            <div class="btn-group" dropdown *ngIf="item.type == 'email'">
                              <i class="fa fa-envelope faemail" id='fa-{{item.datasetid}}'></i>
                            </div>
                            <div class="btn-group" dropdown *ngIf="item.type == 'email'">
                              <div class="form-check" style='padding-left: 1.45rem;'>
                                <input class="form-check-input" style='visibility: hidden;' type="checkbox" value="option1" id="checkbox-{{item.datasetid}}"
                                  (click)='selectDataSet($event,item, "dataset-"+item.datasetid +"-"+item.type)'>
                              </div>
                            </div>
                          </div>
                          <div class="col-9" style="text-align: right;">
                            <div style='font-weight: 700;font-size: 12px; text-overflow: ellipsis;overflow: hidden;white-space: nowrap;'>{{item.name}}</div>
                            <div style='font-size: 11px;'>{{item.count}}</div>
                            <div style='font-size: 11px;'>M: {{item.maleCount}} F: {{item.femaleCount}}</div>
                          </div>
                        </div>
                      </div>

                      <div class="card-body pb-0 data-set-pointer" style="padding: 5px!important;" *ngIf="item.type == 'sms'" (click)='selectDataSet($event,item, "dataset-"+item.datasetid +"-"+item.type)'>
                        <div class="row">
                          <div class="col-3">

                            <div class="btn-group" dropdown *ngIf="item.type == 'sms'">
                              <i class="fa fa-mobile fasms" alt="sms" id='fa-{{item.datasetid}}' style=""></i>
                            </div>
                            <div class="btn-group" dropdown *ngIf="item.type == 'sms'">
                              <div class="form-check" style='padding-left: 1.45rem;'>
                                <input class="form-check-input" style='visibility: hidden;' type="checkbox" value="option1" id="checkbox-{{item.datasetid}}"
                                  (click)='selectDataSet($event,item, "dataset-"+item.datasetid +"-"+item.type)'>
                              </div>
                            </div>
                          </div>
                          <div class="col-9" style="text-align: right;">
                            <div style='font-weight: 700;font-size: 12px; text-overflow: ellipsis;overflow: hidden;white-space: nowrap;'>{{item.name}}</div>
                            <div style='font-size: 11px;'>{{item.count}}</div>
                            <div style='font-size: 11px;'>M: {{item.maleCount}} F: {{item.femaleCount}}</div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <!-- <div class="col-sm-12 col-lg-6" style="margin-top: 5px;padding: 5px;">
                                <div class="card text-white bg-info">
                                  <div class="card-body pb-0">
                                    <button type="button" class="btn btn-transparent p-0 float-right">
                                      <i class="icon-location-pin"></i>
                                    </button>
                                    <div class="text-value">9.823</div>
                                    <div>Members online</div>
                                  </div>
                  
                                </div>
                              </div> -->

                </div>
              </div>
              <div style="padding-bottom: 3px;background: #e6e6e6;">
                <div class="row" (click)="customsetshow()" style="text-align: center;margin: 0px;background: #f9f9f9;height: 28px;line-height: 2;">
                  <div class="col-10" style="text-align: left;padding-left: 6px;">
                    <i class="fa fa-database" aria-hidden="true" style="color: #36aed4"></i> Custom Datasets
                  </div>
                  <div class="col-2" style="right: -10px;">
                    <i class='{{myfontclass_custom}} font_class_for_rightdown' aria-hidden="true"></i>
                  </div>
                </div>
                <!-- <div *ngIf='loadSpinner' style='width:100%; text-align:center;'>Please Wait Loading...</div>
                        <div *ngIf='ErrorLoadingData' style='width:100%; text-align:center;'>Error While Loading Data...</div>
                        <div *ngIf='noDataSetsFound' style='width:100%; text-align:center;'>No DataSets Found...</div> -->
                <div *ngIf='datasets_lengthcustomsms' style='width:100%; text-align:center;position: relative;top: 15vh;'>Please add atleast one Place.</div>
                <div *ngIf='datasets_lengthcustomemail' style='width:100%; text-align:center;position: relative;top: 15vh;'>Please add atleast one Place.</div>
                <div *ngIf='custompurchaseDataNOsms' style='width:100%; text-align:center;position: relative;top: 15vh;'>No DataSets Available</div>
                <div class="sk-double-bounce spinnerStyledatasets" *ngIf='customedatasets_spinner'>
                  <div class="sk-child sk-double-bounce1"></div>
                  <div class="sk-child sk-double-bounce2"></div>
                </div>
                <div [hidden]="!customsetsDIV_sms" class="row datasetsdiv_commonHidden">
                  <div class="card afterDatasetsHidden">
                    <div class="card-body afterDatasetsHiddenBG">
                      <div class="row Datasets_bgcolor">
                        <div *ngFor="let item of customPurchased_sms, let i=index" style="padding-left:5px !important;padding-right:5px !important;margin-left: -7px;">
                          <div class="card card-block card-widget subcards" (click)="customsOwn(item,i)">
                            <div id={{item.datasetid+item.type}} class="customDataBG">
                              <p style="margin-bottom: 0px; overflow:hidden;white-space:nowrap;text-overflow: ellipsis;width: 90px;">
                                <i id={{item.datasetid+i}} class="fa fa-mobile font_classMobileB" style="font-size: 16px;"></i>
                                {{item.name}}
                              </p>
                              <p style="text-align: left;margin-bottom: 0px;font-size: 13px;">{{item.count}}</p>
                              <div class="row">
                                <div class="col" style="padding: 0;text-align: center;">
                                  M: {{item.maleCount}}
                                </div>
                                <div class="col" style="padding: 0;text-align: center;">
                                  F: {{item.femaleCount}}
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf='custompurchaseDataNOemail' style='width:100%; text-align:center;position: relative;top: 15vh;'>No DataSets Available</div>
                <div [hidden]="!customsetsDIV_email" class="row datasetsdiv_commonHidden">
                  <div class="card afterDatasetsHidden">
                    <div class="card-body afterDatasetsHiddenBG">
                      <div class="row Datasets_bgcolor">
                        <div *ngFor="let item of customPurchased_email, let i=index" style="padding-left:5px !important;padding-right:5px !important;margin-left: -7px;">
                          <div class="card card-block card-widget subcards" (click)="customsOwn(item,i)">
                            <div id={{item.datasetid+item.type}} class="customDataBG">
                              <p style="margin-bottom: 0px; overflow:hidden;white-space:nowrap;text-overflow: ellipsis;width: 90px;">
                                <i id={{item.datasetid+i}} class="fa fa-envelope font_classMobileB" style="font-size: 13px;"></i>
                                {{item.name}}
                              </p>
                              <p style="text-align: left;margin-bottom: 0px;font-size: 13px;">{{item.count}}</p>
                              <div class="row">
                                <div class="col" style="padding: 0;text-align: center;">
                                  M: {{item.maleCount}}
                                </div>
                                <div class="col" style="padding: 0;text-align: center;">
                                  F: {{item.femaleCount}}
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="background: #e6e6e6">
                <div class="row" style="text-align: center;margin: 0px;background: #f9f9f9;padding: 4px;">
                  <div class="col-7" (click)="subscribeset()" style="text-align: left;padding-left: 5px;">
                    <i class="fa fa-inr" aria-hidden="true" style="color: #36aed4"></i> Subscribe Datasets
                  </div>
                  <div class="col-3" style="text-align: center;">
                    <div>
                      <button type="button" class="btn" style='color: #fff;
                        background: #36aed4;padding: 0px 3px;' (click)="buyselectedDatasets(datasetsDailogBox)">
                        <i class="fa fa-shopping-cart" style="font-size:14px;margin-right: 5px;"></i>Subscribe</button>
                    </div>
                  </div>
                  <div class="col-2" style="right: -13px;" (click)="subscribeset()">
                    <i class='{{myfontclass_accordain}} font_class_for_rightdown' aria-hidden="true"></i>
                  </div>
                </div>
                <!-- <div *ngIf='loadSpinner' style='width:100%; text-align:center;'>Please Wait Loading...</div>
                        <div *ngIf='ErrorLoadingData' style='width:100%; text-align:center;'>Error While Loading Data...</div>
                        <div *ngIf='noDataSetsFound' style='width:100%; text-align:center;'>No DataSets Found...</div> -->
                <div *ngIf='datasets_lengthsubscribesms' style='width:100%; text-align:center;position: relative;top: 15vh;'>Please add atleast one Place.</div>
                <div *ngIf='datasets_lengthsubscribeemail' style='width:100%; text-align:center;position: relative;top: 15vh;'>Please add atleast one Place.</div>
                <div *ngIf='subscribeDataNOsms' style='width:100%; text-align:center;position: relative;top: 15vh;'>No DataSets Available</div>
                <div class="sk-double-bounce spinnerStyledatasets" *ngIf='subscribe_datasetsspinner'>
                  <div class="sk-child sk-double-bounce1"></div>
                  <div class="sk-child sk-double-bounce2"></div>
                </div>
                <div [hidden]="!subscribeDIV_sms" class="row datasetsdiv_commonHidden">
                  <div class="card afterDatasetsHidden">
                    <div class="card-body afterDatasetsHiddenBG">
                      <div class="row Datasets_bgcolor">
                        <div *ngFor="let item of subscribePublic_sms, let i=index" style="padding-left:5px !important;padding-right:5px !important;margin-left: -7px;">
                          <div class="card card-block card-widget subcards" (click)="selectsubcribe(item,i)">
                            <div id={{item.datasetid+item.type}} class="subscribeDataBG">
                              <p style="margin-bottom: 0px; overflow:hidden;white-space:nowrap;text-overflow: ellipsis;width: 90px;">
                                <i id={{item.type+i}} class="fa fa-mobile font_classMobileB" style="font-size: 16px;"></i>
                                {{item.name}}
                              </p>
                              <p style="text-align: left;margin-bottom: 0px;font-size: 13px;">{{item.count}}</p>
                              <div class="row">
                                <div class="col" style="padding: 0;text-align: center;">
                                  M: {{item.maleCount}}
                                </div>
                                <div class="col" style="padding: 0;text-align: center;">
                                  F: {{item.femaleCount}}
                                </div>
                              </div>

                            </div>
                            <div id={{item.datasetid+i+item.type}} class="card-footer subcardfooter">
                              <div class="row">
                                <div class="col-4">
                                  <i id={{item.datasetid+item.type+i}} class="fa fa-check-circle font_check_classtransparent"></i>
                                </div>
                                <div class="col-4">
                                  <p class=''></p>
                                </div>
                                <div class="col-4">
                                  <p class=''>₹</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div *ngIf='subscribeDataNOemail' style='width:100%; text-align:center;position: relative;top: 15vh;'>No DataSets Available</div>
                <div [hidden]="!subscribeDIV_email" class="row datasetsdiv_commonHidden">
                  <div class="card afterDatasetsHidden">
                    <div class="card-body afterDatasetsHiddenBG">
                      <div class="row Datasets_bgcolor">
                        <div *ngFor="let item of subscribePublic_email, let i=index" style="padding-left:5px !important;padding-right:5px !important;margin-left: -7px;">
                          <div class="card card-block card-widget subcards" (click)="selectsubcribe(item,i)">
                            <div id={{item.datasetid+item.type}} class="subscribeDataBG">
                              <p style="margin-bottom: 0px;overflow:hidden;white-space:nowrap;text-overflow: ellipsis;width: 90px;">
                                <i id={{item.type+i}} class="fa fa-envelope font_classMobileB" style="font-size: 13px;"></i>
                                {{item.name}}
                              </p>
                              <p style="text-align: left;margin-bottom: 0px;font-size: 13px;">{{item.count}}</p>
                              <div class="row">
                                <div class="col" style="padding: 0;text-align: center;">
                                  M: {{item.maleCount}}
                                </div>
                                <div class="col" style="padding: 0;text-align: center;">
                                  F: {{item.femaleCount}}
                                </div>
                              </div>
                            </div>
                            <div id={{item.datasetid+i+item.type}} class="card-footer subcardfooter">
                              <div class="row">
                                <div class="col-4">
                                  <i id={{item.datasetid+item.type+i}} class="fa fa-check-circle font_check_classtransparent"></i>
                                </div>
                                <div class="col-4">
                                  <p class=''></p>
                                </div>
                                <div class="col-4">
                                  <p class=''>₹</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>


              </div>
            </div>

          </div>
        </div>
        <!-- 
              <div class="card">
                <div class="card-header">
                  <i class="icon-drop"></i> Additional colors
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-6">
                      <h6>Blue Color</h6>
                    </div>
                    <div class="col-6">
                      <h6>Light Blue Color</h6>
                    </div>
                  </div>
                </div>
              </div> -->
      </div>
    </div>
  </tab>
  <tab *ngIf="showManualTab" style="background: #f1f3f5">
    <ng-template tabHeading>
      <span class="badge iconStyle">
        <i class="fa fa-pencil" aria-hidden="true"></i>
      </span>
      Manual
    </ng-template>
    <tabset>
      <tab>
        <ng-template tabHeading>
          <span class="badge iconStyle">
            <i class="fa fa-pencil" aria-hidden="true"></i>
          </span>
          SMS
        </ng-template>
        <div style="padding: 18px;margin-left: 15%;height: 80vh;">
          <div class="form-group row" style="margin-bottom: 0.2rem;">
            <label class="col-md-2 col-form-label text_align_right" for="text-input">Mobile Number
              <span style="color: red">*</span>
            </label>
            <div class="col-md-5">
              <textarea [(ngModel)]="sendObj.phonenumber" name="text-input" class="form-control" style="margin-bottom: 6px;"
                placeholder="Mobile Number"></textarea>
            </div>
          </div>
          <div class="form-group row" style="margin-bottom: 0px;">
            <label class="col-md-2 col-form-label text_align_right" for="text-input">From file
              <span style="color: red">*</span>
            </label>
            <div class="col-md-5">
              <div style="background: #24ca71;border-radius: 5px;">
                <div class="row">
                  <div class="col">
                    <input type="file" id="file" style="padding: 2px" (change)="changeFile($event)">
                  </div>
                  <div class="col" style="text-align: right;right: 3px;">
                    <button type="button" style="height: 27px;line-height: 1;background: #e0e0e0;margin-top: 2px;border-color: #e0e0e0;color: #000;"
                      class="btn  btn-primary" (click)="uploadFile()">
                      <i style="padding-right: 3px;" class="fas fa-file-upload" aria-hidden="true"></i>Upload</button>
                  </div>
                </div>


              </div>

            </div>
          </div>

          <div class="form-group row" style="margin-bottom: 0px;">
            <div class="col-md-2">

            </div>
            <div class="col-md-10" *ngIf="validFormat">
              <div style="background: #e2e5e6;">
                <span style="padding: 5px">{{fileName}}(Total - {{total}}, Valid - {{valid}}, InValid - {{invalid}}, DND - {{dnd}})</span>
                <i (click)="closeData()" style="float: right;margin-right: 5px;margin-top: 3px;color:red" class="fa fa-times" aria-hidden="true"></i>
              </div>

            </div>
          </div>

          <div class="form-group row group_style">
            <label class="col-md-2 col-form-label text_align_right" for="text-input">Groups
              <span style="color: red">*</span>
            </label>
            <div class="col-md-2">
              <select class="form-control groupSelectBoxStyle" [ngModel]="selectedToAdd" multiple name="myselecttsms2" (ngModelChange)="chosenData($event)"
                style="height: 50vh;background: #dcdee1;padding: 2px">
                <option *ngFor="let item of groupsArray" class="sms_options" [ngValue]="item">{{item.groupName}}</option>
              </select>
            </div>
            <div class="col-md-1">
              <div style="position: relative;top: 35%;">
                <div class="row assing_unassign">
                  <div class="col">
                    <button (click)="assigne()" class="btn  btn-primary" style="margin-bottom: 3px;width: 39px;height: 39px;background: #24ca71;">
                      <i class="fa fa-angle-double-right"></i>
                    </button>
                  </div>
                </div>
                <div class="row assing_unassign">
                  <div class="col">
                    <button (click)="unassigne()" class="btn  btn-primary" style="margin-bottom: 3px;width: 39px;height: 39px;background: #24ca71;">
                      <i class="fa fa-angle-double-left"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <select class="form-control groupSelectBoxStyle" [ngModel]="selectedToRemove" multiple name="myselecttsms" (ngModelChange)="chosenDataToRemove($event)"
                style="height: 50vh;background: #dcdee1;padding: 2px">
                <option *ngFor="let item of selectedItems" class="sms_options" [ngValue]="item">{{item.groupName}}</option>
              </select>
            </div>
          </div>
        </div>
      </tab>
      <tab>
        <ng-template tabHeading>
          <span class="badge iconStyle">
            <i class="fa fa-pencil" aria-hidden="true"></i>
          </span>
          Email
        </ng-template>
        <div style="padding: 18px;margin-left: 15%;height: 80vh;">
          <div class="form-group row" style="margin-bottom: 0.2rem;">
            <label class="col-md-3 col-form-label labelSize" for="text-input">Emails
              <span style="color: red">*</span>
            </label>
            <div class="col-md-9">
              <textarea type="email" [(ngModel)]="journey_emails_value" name="text-input" class="form-control" style="margin-bottom: 6px;width:78%;height: 77px;"
                placeholder="Please Enter Emails separated with comma"></textarea>
            </div>
          </div>
          <div class="form-group row" style="margin-bottom: 5px;">
            <label class="col-md-3 col-form-label labelSize" for="text-input">From file
              <span style="color: red"></span>
            </label>
            <div class="col-md-7" style="background: #e7505a;border-radius: 5px;padding-left: 0px !important;padding-right: 0px !important;">
              <input type="file" id="file" accept=".xls,.xlsx,.csv" style="margin-top: 4px;margin-left: 12px;color: white;" (change)="changeemailFile($event)">
              <!-- <button type="button" class="btn  btn-primary uploadBtnStyle" (click)="uploademailFile()"><i style="padding-right: 3px;" class="fas fa-file-upload" aria-hidden="true"></i>Upload</button> -->
            </div>
          </div>
          <div class="form-group row" style="margin-bottom: 10px;">
            <div class="col-md-3"></div>
            <div class="col-md-7" *ngIf="validFormat" style="background: #e2e5e6;">
              <span style="padding: 10px;">{{fileName}}(Total - {{total}}, Valid - {{valid}}, InValid - {{invalid}}, DND - {{dnd}})</span>
              <i (click)="closeData()" style="float: right;margin-right: 5px;margin-top: 3px;color:red" class="fa fa-times" aria-hidden="true"></i>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-form-label labelSize" for="text-input">Groups
              <span style="color: red"></span>
            </label>
            <div class="col-md-3">
              <select class="form-control emailgroupSelectBoxStyle" [ngModel]="selectedemail" multiple (ngModelChange)="chosenemailData($event)" style="height: 50vh;background: #dcdee1;padding: 2px">
                <option *ngFor="let item of emailgroupsArray" style="text-align: center;border-radius: 2px;background: #fff;margin-bottom: 2px;padding: 2px;"
                  [ngValue]="item">{{item.groupName}}</option>
              </select>
            </div>
            <div class="col-md-1" style="margin-top: 35px;">
              <div class="row">
                <div class="col-12 assing_unassign">
                  <button (click)="emailsassigne()" class="btn  btn-primary" style="margin-bottom: 3px;width: 39px;height: 39px;background: #e7505a;border: none;">
                    <i class="fa fa-angle-double-right"></i>
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-12 assing_unassign">
                  <button (click)="emailsunassigne()" class="btn  btn-primary" style="margin-bottom: 3px;width: 39px;height: 39px;background: #e7505a;border: none;">
                    <i class="fa fa-angle-double-left"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <select class="form-control emailgroupSelectBoxStyle" [ngModel]="deselectedemail" multiple (ngModelChange)="chosenemailDataToRemove($event)" style="height: 50vh;background: #dcdee1;padding: 2px">
                <option *ngFor="let item of emailselectedItems" style="text-align: center;border-radius: 2px;background: #fff;margin-bottom: 2px;padding: 2px;"
                  [ngValue]="item">{{item.groupName}}</option>
              </select>
            </div>
          </div>
        </div>
      </tab>
    </tabset>
  </tab>
</tabset>



<div class="sk-double-bounce spinnerStyle" *ngIf='fetchingCache'>
  <div class="sk-child sk-double-bounce1"></div>
  <div class="sk-child sk-double-bounce2"></div>
</div>
<!-- <p-progressSpinner *ngIf='fetchingCache' class='prospinner' [style]="{width: '30px', height: '30px'}" strokeWidth="2" animationDuration=".5s"></p-progressSpinner> -->

<ng-template #datasetsDailogBox>
  <div class="modal-header">
    <div class="modal-title pull-left" style="font-size: 15px;font-weight: 600;">
      <span style="padding-left: 5px;">Subscribe DataSets</span>
    </div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="background: #f9f9f9;">
    <!-- <div class="form-group row" style="margin-bottom: 0.2rem;">
			<label class="col-md-4 col-form-label">Journey Name<span style="color: red">*</span></label>
			<div class="col-md-8">
				<input type="text" [(ngModel)]="journey_name" name="journey_name" class="form-control" placeholder="Journey Name">
			</div>
    </div> -->
    <div class="row">
      <div class="col">
        <label class="col-form-label">Selected Datasets</label>
      </div>
      <div class="col" style="text-align: right;">
        <label class="checkbox-inline" style="padding-right: 13px;">
          <input type="checkbox" [(ngModel)]="filter_cs" (click)="filterDatass()" style=" position: relative;
            top: 2px;">Select All</label>
        <label>Total Selected: {{smsEmail_Dailog.length}}</label>
      </div>
    </div>
    <div class="form-group row" style="margin: 0px;">
      <div class="col" style="background: #fff">
        <div class="row datasetsdiv_commonHidden">
          <div class="card afterDatasetsHidden">
            <div class="card-body afterDatasetsHiddenBG" style="padding-right: 8px !important;">
              <div class="row Datasets_bgcolor">
                <div *ngFor="let item of smsEmail_selectedDailog, let i=index" style="padding-left:5px !important;padding-right:5px !important;margin-left: -7px;">
                  <div class="card card-block card-widget subcards" (click)="selectsubcribe_dialog(item,i)">
                    <div id={{item.type+item.datasetid+item.type}} class="subscribeDataBG">
                      <p style="margin-bottom: 0px;overflow:hidden;white-space:nowrap;text-overflow: ellipsis;width: 90px;">
                        <i id={{item.type+i+item.type}} class="fa fa-mobile font_classMobileB" style="font-size: 16px;"></i>
                        {{item.name}}
                      </p>
                      <p style="text-align: left;margin-bottom: 0px;font-size: 13px;">{{item.count}}</p>
                      <div class="row">
                        <div class="col" style="padding: 0;text-align: center;">
                          M: {{item.maleCount}}
                        </div>
                        <div class="col" style="padding: 0;text-align: center;">
                          F: {{item.femaleCount}}
                        </div>
                      </div>
                    </div>
                    <div id={{item.datasetid+i+item.type+i}} class="card-footer subcardfooter">
                      <div class="row">
                        <div class="col-4">
                          <i id={{item.datasetid+item.type+i+item.type}} class="fa fa-check-circle font_check_classtransparent"></i>
                        </div>
                        <div class="col-4">
                          <p class=''></p>
                        </div>
                        <div class="col-4">
                          <p class=''>₹</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="form-group row" style="margin-bottom: 0.2rem;">
      <label class="col-md-3 col-form-label" style="display: inline-flex;">Wallet Balance:
        <p style="color: green;padding-left: 5px;">₹ {{wallet_details}}</p>
      </label>
      <label class="col-md-3 col-form-label">Pricing:</label>
      <div class="col-md-3">
        <input type="text" [(ngModel)]="packName" placeholder="Enter Package Name" style="border: none;margin-top: 4px;" >
      </div>
      <div class="col" style="text-align: right;padding-top: 10px;">
        <button (click)="buySelectedDatasets()" style="border-radius: 4px;background: #20a8d8;border: none;color: #fff;">
          <i class="fa fa-shopping-cart"></i> Subscribe </button>
        <button (click)="canceldialog()" style="border-radius: 4px;background: red;border: none;color: #fff;margin-left: 7px;">
          <i class="fa fa-times"></i> Cancel </button>
      </div>
    </div>
  </div>
</ng-template>