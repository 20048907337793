<div class="registermanageroutemodalcontainer">
  <div class="modal-header">
    <h4 class="modal-title pull-left" style="font-size: 15px;">Route Request</h4>
    <button type="button" class="close pull-right" style="padding: 16px 20px" aria-label="Close" (click)="hideModal()"> <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <div class="form-group row" style="margin-bottom: 3px;">
          <label class="col-md-3 col-form-label label_style">Requesting For
          </label>
          <div class="col-md-9">
          <!-- <select class="form-control" [(ngModel)]="route_status" style="padding-left: 1px">
            <option value="" disabled selected hidden>Select Status</option>
            <option *ngFor="let route of routes_status" [value]="route.name">{{route.name}}</option>
            <option [value]="route_status">{{route_status}}</option>  
          </select> -->
          <div style="margin-top: 7px;">{{route_status}}</div>
          </div>
        </div>
    <div class="form-group row" style="margin-bottom: 3px;">
      <label class="col-md-3 col-form-label label_style" for="route_desc">Description
      </label>
      <div class="col-md-9" >
        <textarea [(ngModel)]="route_description" id="route_desc" class="form-control" style="height: 100px;padding-left: 5px;"
          placeholder="Please Enter Description"> </textarea>
      </div>
    </div>
    <div class="form-group row" style="margin-bottom: 3px;">
        <label class="col-md-3 col-form-label label_style">
        </label>
        <div class="col-md-9" style="text-align: center;">
            <button type="button"  class="btn btn-primary submit_btnstyle" (click)="submitroute()">Submit</button>
        </div>
      </div>
  </div>
</div>