import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { DialogComponent } from 'ngx-bootstrap-modal/index';
import { DialogService } from 'ngx-bootstrap-modal/components/dialog.service';

export interface AudienceModel {
  title: string;
  message: string;
}

@Component({
  selector: 'app-audiencemodal',
  templateUrl: './audiencemodal.component.html',
  styleUrls: ['./audiencemodal.component.scss']
})

export class AudiencemodalComponent extends DialogComponent<AudienceModel, boolean> implements AudienceModel, OnInit {
  title: string;
  message: any;
  public obj: any;
  public myid: any;
  myaudienceData: any;
  constructor(dialogService: DialogService, private route: Router) {
    super(dialogService);
  }



  ngOnInit() {
    this.route.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        const myobj: any = {
          'offertype': ''
        };
        this.close(myobj);
      }

    });
  }

  closeModal() {
    // const data = this.audiencemodalData.closeModal();
    this.close();
  }


  audienceDataobj(audienceData) {
    console.log(audienceData);
    (<any>window).audienceDataForCells = audienceData;
  }
}
