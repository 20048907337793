<div   tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class=" modal-dialog" style="max-width:30%;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title pull-left" style="font-size: 15px;font-weight: 600;">
          <span style="padding-left: 5px;">Verify OTP</span>
        </div>
        <button type="button" class="close pull-right my_closex" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true" style="font-size: 21px;">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="background-color: #f4f4f4;">
        <p style="margin-bottom: 0.3rem;">Please Enter OTP</p>
        <div>
          <input type="text" style="width: 23%;" class="form-control" style="border-radius: 5px;" [(ngModel)]="otpNumber" placeholder="Enter OTP Number" maxlength="4">
        </div>
        <div><b>Note:</b> Please check your respected mail / mobilenumber for verification OTP.</div>
        <div class="row" style="margin-top: 9px;">
          <div class="col" style="text-align: center;">
            <button type="button" class="btn btn-default btn-xl"  style="margin-right: 8px;" (click)="submitBtn(otpNumber)">Submit</button>
            <button type="button" class="btn btn-default btn-xl signup_btn" (click)='resendOtp()'>Resend OTP</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>