import { NgModule } from '@angular/core';
import { AdminfundsComponent } from './adminfunds.component';
import { AdminfundsRoutingModule } from './adminfunds-routing.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { DataTableModule } from 'angular2-datatable';
// import { ModalModule } from 'ngx-bootstrap/modal';
@NgModule({
  imports: [
    AdminfundsRoutingModule,
    CommonModule,
    FormsModule,
    TabsModule,
    DataTableModule,
    // ModalModule
  ],
  declarations: [ AdminfundsComponent ],
  entryComponents: [AdminfundsComponent],
  exports: [AdminfundsComponent]
})
export class AdminfundsModule { }
