import { Component, OnInit } from '@angular/core';
import { DialogComponent } from 'ngx-bootstrap-modal/index';
import { DialogService } from 'ngx-bootstrap-modal/components/dialog.service';
import { Time } from '@angular/common';
import { DatePipe } from '@angular/common';
import { timer } from 'rxjs';
import { Router, Event, NavigationEnd } from '@angular/router';

export interface ConfirmModel {
  title: string;
  message: string;
}

@Component({
  selector: 'app-wait',
  templateUrl: './wait.component.html',
  styleUrls: ['./wait.component.scss'],
  providers: [DatePipe]
})
export class WaitComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel, OnInit {
  title: string;
  message: any;
  time_zone: any;
  start_time: Date = new Date();
  end_time: Date = new Date();
  obj: any;
  days = [{ day: 'sun', background: '#e4e7ea' }, { day: 'mon', background: '#e4e7ea', color: 'black' }, { day: 'tue', background: '#e4e7ea', color: 'black' }, { day: 'wed', background: '#e4e7ea', color: 'black' },
  { day: 'thu', background: '#e4e7ea', color: 'black' }, { day: 'fri', background: '#e4e7ea', color: 'black' }, { day: 'sat', background: '#e4e7ea', color: 'black' }];
  selecteddays: any = [];
  settings = {
    bigBanner: true,
    timePicker: true,
    // format: 'yyyy-MM-dd HH:mm:ss',
    format: 'dd-MM-yyyy h:mm a',
    defaultOpen: false
  };
  constructor(dialogService: DialogService, private datePipe: DatePipe, private route: Router) {
    super(dialogService);

  }

  ngOnInit() {
    this.route.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        const empty = [];
        const closeobj: any = {
          'totalWait': empty
        };
        this.close(closeobj);
      }

    });

    console.log(this.obj);
    if (this.obj === undefined) {

    } else {
      this.start_time = this.obj.start_Time;
      this.end_time = this.obj.end_Time;
    }




  }

  closeModal() {
    const myschecule = this.datePipe.transform(this.end_time, 'yyyy-MM-dd HH:mm:ss');
    const start_Time: any = this.datePipe.transform(this.start_time, 'dd');
    const end_Time: any = this.datePipe.transform(this.end_time, 'dd');
    const hours: any = this.datePipe.transform(this.end_time, 'HH');
    const mins: any = this.datePipe.transform(this.end_time, 'mm');
    const nofDays = end_Time - start_Time;
    const custom_wait = [];
    custom_wait.push({ 'Days': nofDays, 'Hours': hours, 'Minutes': mins });

    this.obj = {
      'start_Time': this.start_time,
      'end_Time': this.end_time,
      'totalWait': custom_wait,
      'scheduleon': myschecule,
      'selected_Days': this.selecteddays
    };
    // localStorage.setItem('obj_value', JSON.stringify(this.obj));
    console.log(this.obj);
    this.close(this.obj);
  }

  checkday(selecteditem, indexvalue) {

    if (selecteditem.day === 'sun') {
      if (selecteditem.background === '#e4e7ea') {
        console.log(selecteditem.background);
        this.days[indexvalue].background = '#73818f';
        this.days[indexvalue].color = 'white';
        console.log(this.days[indexvalue].day);
        console.log(this.days[indexvalue].background);
        this.selecteddays.push(selecteditem);
        console.log(this.selecteddays);
      } else {
        this.days[indexvalue].background = '#e4e7ea';
        this.days[indexvalue].color = 'black';

        for (let i = 0; i < this.selecteddays.length; i++) {
          if (this.selecteddays[i].day === selecteditem.day) {
            this.selecteddays.splice(i, 1);
          }
        }

        console.log(this.selecteddays);
      }
    }
    if (selecteditem.day === 'mon') {
      if (selecteditem.background === '#e4e7ea') {
        console.log(selecteditem.background);
        this.days[indexvalue].background = '#73818f';
        this.days[indexvalue].color = 'white';
        console.log(this.days[indexvalue].day);
        console.log(this.days[indexvalue].background);
        this.selecteddays.push(selecteditem);
        console.log(this.selecteddays);
      } else {
        this.days[indexvalue].background = '#e4e7ea';
        this.days[indexvalue].color = 'black';
        for (let i = 0; i < this.selecteddays.length; i++) {
          if (this.selecteddays[i].day === selecteditem.day) {
            this.selecteddays.splice(i, 1);
          }
        }

        console.log(this.selecteddays);
      }
    }

    if (selecteditem.day === 'tue') {
      if (selecteditem.background === '#e4e7ea') {
        console.log(selecteditem.background);
        this.days[indexvalue].background = '#73818f';
        this.days[indexvalue].color = 'white';
        console.log(this.days[indexvalue].day);
        console.log(this.days[indexvalue].background);
        this.selecteddays.push(selecteditem);
        console.log(this.selecteddays);
      } else {
        this.days[indexvalue].background = '#e4e7ea';
        this.days[indexvalue].color = 'black';
        for (let i = 0; i < this.selecteddays.length; i++) {
          if (this.selecteddays[i].day === selecteditem.day) {
            this.selecteddays.splice(i, 1);
          }
        }

        console.log(this.selecteddays);
      }
    }

    if (selecteditem.day === 'wed') {
      if (selecteditem.background === '#e4e7ea') {
        console.log(selecteditem.background);
        this.days[indexvalue].background = '#73818f';
        this.days[indexvalue].color = 'white';
        console.log(this.days[indexvalue].day);
        console.log(this.days[indexvalue].background);
        this.selecteddays.push(selecteditem);
        console.log(this.selecteddays);
      } else {
        this.days[indexvalue].background = '#e4e7ea';
        this.days[indexvalue].color = 'black';
        for (let i = 0; i < this.selecteddays.length; i++) {
          if (this.selecteddays[i].day === selecteditem.day) {
            this.selecteddays.splice(i, 1);
          }
        }

        console.log(this.selecteddays);
      }
    }

    if (selecteditem.day === 'thu') {
      if (selecteditem.background === '#e4e7ea') {
        console.log(selecteditem.background);
        this.days[indexvalue].background = '#73818f';
        this.days[indexvalue].color = 'white';
        console.log(this.days[indexvalue].day);
        console.log(this.days[indexvalue].background);
        this.selecteddays.push(selecteditem);
        console.log(this.selecteddays);
      } else {
        this.days[indexvalue].background = '#e4e7ea';
        this.days[indexvalue].color = 'black';
        for (let i = 0; i < this.selecteddays.length; i++) {
          if (this.selecteddays[i].day === selecteditem.day) {
            this.selecteddays.splice(i, 1);
          }
        }

        console.log(this.selecteddays);
      }
    }

    if (selecteditem.day === 'fri') {
      if (selecteditem.background === '#e4e7ea') {
        console.log(selecteditem.background);
        this.days[indexvalue].background = '#73818f';
        this.days[indexvalue].color = 'white';
        console.log(this.days[indexvalue].day);
        console.log(this.days[indexvalue].background);
        this.selecteddays.push(selecteditem);
        console.log(this.selecteddays);
      } else {
        this.days[indexvalue].background = '#e4e7ea';
        this.days[indexvalue].color = 'black';
        for (let i = 0; i < this.selecteddays.length; i++) {
          if (this.selecteddays[i].day === selecteditem.day) {
            this.selecteddays.splice(i, 1);
          }
        }

        console.log(this.selecteddays);
      }
    }

    if (selecteditem.day === 'sat') {
      if (selecteditem.background === '#e4e7ea') {
        console.log(selecteditem.background);
        this.days[indexvalue].background = '#73818f';
        this.days[indexvalue].color = 'white';
        console.log(this.days[indexvalue].day);
        console.log(this.days[indexvalue].background);
        this.selecteddays.push(selecteditem);
        console.log(this.selecteddays);
      } else {
        this.days[indexvalue].background = '#e4e7ea';
        this.days[indexvalue].color = 'black';
        for (let i = 0; i < this.selecteddays.length; i++) {
          if (this.selecteddays[i].day === selecteditem.day) {
            this.selecteddays.splice(i, 1);
          }
        }

        console.log(this.selecteddays);
      }
    }

  }

}
