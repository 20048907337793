import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router, NavigationEnd, Event } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import { CitiservicesService } from '../../../../citiservices.service';
@Component({
  selector: 'app-adminfunds',
  templateUrl: './adminfunds.component.html',
  styleUrls: ['./adminfunds.component.scss']
})
export class AdminfundsComponent implements OnInit {
  @Input()
  parentCount: any;
  addfundsData: any = [];
  sum: any;
  admindesc: any;
  credits: any;
  groupData: any = [];
  citiuserid: any;
  userAssignedorg: any;
  constructor(public toastr: ToastrService, private myServ: CitiservicesService, public http: Http, private cdr: ChangeDetectorRef, public bsModalRef: BsModalRef, public router: Router) {
    // this.getadminTransactions();
    if (this.parentCount !== undefined) {

      this.citiuserid = this.parentCount.item.citiUserId;
      console.log(this.citiuserid);
      // console.log('oooooooooooooo' + this.parentCount.groupName);
      // this.grpdesc = this.parentCount.groupDescription;
      // this.grpid = this.parentCount.groupId;
    } else {
      // this.grpname = '';
      // this.grpdesc = '';
    }
    this.sum = 0;
    for (let i = 0; i < this.addfundsData.length; i++) {
      console.log(this.addfundsData[i].amount);
      // sum += this.addfundsData[i].credits;
      this.sum += parseInt(this.addfundsData[i].amount, 10);
    }
    console.log(this.sum);

  }
  ngOnInit() {
    this.getTotalcredits();
    console.log(this.groupData);
    this.citiuserid = this.groupData[0].id.citiUserId;

    console.log(this.citiuserid);
    // this.citiuserid = this.groupData[0].id;
    // console.log();
    this.userAssignedorg = this.groupData[0].id.orgId;

    console.log(this.groupData[0].id);
    console.log(this.userAssignedorg);
    this.getadminTransactions();
    // this. getuserTransactions();
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.bsModalRef.hide();
      }
    });
  }
  rowdata(item, i) {
    console.log(item);
    console.log(i);
    this.credits = item.amount;
    this.admindesc = item.description;
  }
  showSuccess(data) {
    if (this.toastr.currentlyActive === 0) {
      this.toastr.success(data, '', {
        timeOut: 1500,
        positionClass: 'toast-bottom-right'
      });
    }
  }

  showError(data) {
    if (this.toastr.currentlyActive === 0) {
      this.toastr.error(data, '', {
        timeOut: 1500,
        positionClass: 'toast-bottom-right'
      });
    }
  }
  addcredits() {
    this.sum = 0;
      console.log(this.addfundsData.length);
      for (let i = 0; i < this.addfundsData.length; i++) {
        console.log(this.addfundsData[i].credits);

        this.sum += parseInt( this.addfundsData[i].credits, 10);
      }
      console.log(this.sum);
    }
  getadminTransactions() {
    console.log(this.citiuserid);
    this.addfundsData = [];
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    headers.append('X-Apikey', (<any>window).citikey);
    this.http.get((<any>window).citiUrl + 'payments/transactions/history?q=campaigns&startDate=2018-12-01&endDate=2019-1-02&rolename=admin&citiUserId=' + this.citiuserid, { headers: headers })
      .map(res => res.json())
      .subscribe(data => {
        console.log(data);
        if (data.status === 'success') {
          this.addfundsData = data.response;
        } else {
          this.showError('No record found for this user');
        }
      }, error => {
        console.log(JSON.stringify(error.json()));
        console.log(error);

      });

  }
  getTotalcredits() {
    console.log(this.groupData[0].id.orgId);
    this.addfundsData = [];
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    headers.append('X-Apikey', (<any>window).citikey);
    this.http.get((<any>window).citiUrl + 'users/payments?orgId=' + this.groupData[0].id.orgId , { headers: headers })
      .map(res => res.json())
      .subscribe(data => {
        console.log(data);
        if (data.status === 'success') {
          this.addfundsData = data.response;
        } else {
           this.showError(data.message);
        }
          this.showError('No record found for this user');
      }, error => {
        console.log(JSON.stringify(error.json()));
        console.log(error);

      });

  }
  addsubtractCredits() {
      if (this.credits === undefined || this.credits === '') {
        this.showError('Enter credits');
      } else
        if (this.admindesc === undefined || this.admindesc === '') {
          this.showError('Enter description');
        } else {
          // this.savegrouploading = true;
          const body = 'amount=' + this.credits + '&description=' + this.admindesc + '&rolename=admin' + '&citiUserId=' + this.citiuserid ;
          const headers = new Headers();
          headers.append('Content-Type', 'application/x-www-form-urlencoded');
          headers.append('X-Citistar-Token', (<any>window).citiToken);
          headers.append('X-Apikey', (<any>window).citikey);
          this.http.post((<any>window).citiUrl + 'users/credits', body, { headers: headers })
            .map(res => res.json())
            .subscribe(data => {
              console.log(data);
              if (data.status === 'success') {
                this.getadminTransactions();
                // this.changes.emit('group added successfully');
                // this.savegrouploading = false;
                // this.savebtn = true;
                // this.updatebtn = false;
                this.credits = '';
                this.admindesc = '';
                this.showSuccess('Credits Created successfully');
                // this.contactlist.getGroups();

              } else {
     this.showError(data.message);
                this.showError(data.message);

              }
            }, error => {
            });
        }
  }
  closeModal() {
    // this.refresh();
    localStorage.removeItem('contactdetails');
    this.bsModalRef.hide();
  }
}
