<div class="emailsendcampaignsContainer">
	<!-- <div class="row">
		<div class="col"> -->
			<div class="card center myemail_dialog_cls">
				<div class="card-header headbackground">
					<ul class="steps steps-5" style="height:30px;">
						<li id="nav1" style="width: 21%;">
							<a>
								<p id="cam_details" style="font-size: 13px;text-align: center;color:#23282c;margin-top: 12px;margin-top: -2px;" class="textSize">{{campaigndetails_label}}</p>
							</a>
						</li>
						<li id="nav2" style="width: 20%;" *ngIf="journey_email">
							<a>
								<p id="email_recipients" style="font-size: 13px;text-align: center;color:#23282c;margin-top: 12px;margin-top: -2px;" class="textSize">{{recepients_label}}</p>
							</a>
						</li>
						<li id="nav3" style="width: 20%;">
							<a>
								<p id="email_template" style="font-size: 13px;text-align: center;color:#23282c;margin-top: 12px;margin-top: -2px;" class="textSize">{{template_label}}</p>
							</a>
						</li>
						<li id="nav4" style="width: 20%;">
							<a>
								<p id="email_content" style="font-size: 13px;text-align: center;color:#23282c;margin-top: 12px;margin-top: -2px;" class="textSize">{{content_label}}</p>
							</a>
						</li>
						<!-- <li id="nav5" style="width: 16.5%;">
						<a>
							<p id="email_spam" style="font-size: 13px;text-align: center;color:#23282c;margin-top: 12px;margin-top: -2px;" class="textSize">5. Spam Report</p>
						</a>
					</li> -->
						<li id="nav6" style="width: 19%;"  >
							<a>
								<p id="email_presend" style="font-size: 13px;text-align: center;color:#23282c;margin-top: 12px;margin-top: -2px;" class="textSize">{{previewsend_label}}</p>
							</a>
						</li>
					</ul>
				</div>
				<div class="card-body bodybg">
					<div *ngIf="campaign_detailsview" style="margin-top: 10px;">
						<div class="form-group row" style="margin-bottom: 0.2rem;"  *ngIf="journey_email">
							<label class="col-md-3 col-form-label" style="text-align: right;" for="text-input">Campaign Name<span style="color: red">*</span>
							</label>
							<div class="col-md-9">
								<input type="text" id="text-input" [(ngModel)]="emailsendObj.campaignname" name="text-input" class="form-control" style="padding: 10px;width: 88%;margin-left: 6%;" placeholder="Please Enter Campaign Name">
							</div>
						</div>
						<div class="form-group row" style="margin-bottom: 0.2rem;">
							<label class="col-md-3 col-form-label" style="text-align: right;" for="text-input">Sender Name<span style="color: red">*</span>
							</label>
							<div class="col-md-9">
								<input type="text" id="text-input" name="text-input" [(ngModel)]="emailsendObj.sendername" class="form-control" style="padding: 10px;margin-left: 6%;    width: 88%;" placeholder="Please Enter Sender Name">
							</div>
						</div>
						<div class="form-group row" style="margin-bottom: 0.2rem;">
							<label class="col-md-3 col-form-label" style="text-align: right;" for="text-input">Sender Email<span style="color: red">*</span>
							</label>
							<div class="col-md-9">
								<input type="email" id="text-input" [(ngModel)]="emailsendObj.senderemail" name="text-input" class="form-control" style="padding: 10px;width: 88%;margin-left: 6%;" placeholder="Please Enter Sender Email">
							</div>
						</div>
						<div class="form-group row" style="margin-bottom: 0.2rem;">
							<label class="col-md-3 col-form-label" style="text-align: right;margin-left: -6px;" for="text-input">Replier Name</label>
							<div class="col-md-9" style="margin-left: 6px;">
								<input type="text" id="text-input" name="text-input" class="form-control" [(ngModel)]="emailsendObj.replyname" style="padding: 10px;width: 88%;margin-left: 6%;" placeholder="Please Enter Replier Name">
							</div>
						</div>
						<div class="form-group row" style="margin-bottom: 0.2rem;">
							<label class="col-md-3 col-form-label" style="text-align: right;margin-left: -6px;" for="text-input">Reply-to Email</label>
							<div class="col-md-9" style="margin-left: 6px;">
								<input type="email" id="text-input" name="text-input" class="form-control" [(ngModel)]="emailsendObj.reply_email" style="padding: 10px;width: 88%;margin-left: 6%;" placeholder="Please Enter Reply-to Email">
							</div>
						</div>
						<div class="form-group row" style="margin-bottom: 0.2rem;">
							<label class="col-md-3 col-form-label" style="text-align: right;" for="text-input">Subject<span style="color: red">*</span>
							</label>
							<div class="col-md-9" style="display: inherit;">
								<textarea id="textarea-input" cols="4" rows="5" name="textarea-input" [(ngModel)]="emailsendObj.camdetailssubject" class="form-control" style="height: 100px;border:none;width: 88%;margin-left: 6%;" placeholder="Please Enter Subject"></textarea>
							</div>
						</div>
					</div>
					<div *ngIf="email_listview" style="margin-top: -12px;">
						<div class="form-group row" style="margin-bottom: 0.2rem;">
							<label class="col-md-3 col-form-label labelSize" for="text-input">Emails<span style="color: red">*</span>
							</label>
							<div class="col-md-9">
								<textarea type="email" [(ngModel)]="emailsendObj.emails" id="text-input" name="text-input" class="form-control" style="margin-bottom: 6px;width:78%;height: 77px;" placeholder="Please Enter Emails separated with comma"></textarea>
							</div>
						</div>
						<div class="form-group row" style="margin-bottom: 5px;">
							<label class="col-md-3 col-form-label labelSize" for="text-input">From file<span style="color: red"></span>
							</label>
							<div class="col-md-7" style="background: #e7505a;border-radius: 5px;padding-left: 0px !important;padding-right: 0px !important;">
								<input type="file" id="file" accept=".xls,.xlsx,.csv" style="margin-top: 4px;margin-left: 12px;color: white;" (change)="changeemailFile($event)">
								<!-- <button type="button" class="btn  btn-primary uploadBtnStyle" (click)="uploademailFile()"><i style="padding-right: 3px;" class="fas fa-file-upload" aria-hidden="true"></i>Upload</button> -->
							</div>
						</div>
						<div class="form-group row" style="margin-bottom: 10px;">
							<div class="col-md-3"></div>
							<div class="col-md-7" *ngIf="validFormat" style="background: #e2e5e6;"> <span style="padding: 10px;">{{fileName}}(Total
									- {{total}}, Valid - {{valid}}, InValid - {{invalid}},
									DND - {{dnd}})</span>
								<i (click)="closeData()" style="float: right;margin-right: 5px;margin-top: 3px;color:red" class="fa fa-times" aria-hidden="true"></i>
							</div>
						</div>
						<div class="form-group row" style="margin-bottom: 0px;">
							<label class="col-md-3 col-form-label labelSize" for="text-input">From Datasets<span style="color: red"></span>
							</label>
							<div style="background: #e7505a;border-radius: 5px;height: 30px;">
								<button type="button" style="height: 30px;line-height: 0;background: #e7505a;border: none;" class="btn  btn-primary" (click)="openemailDatasets()">Data Sets</button>
							</div>
							<div class="col-md-6" *ngIf="showDatasets" style="margin-left: 6px;">
              <div>


                <div class="card" style="padding-left: 0px !important;margin-bottom: 0px !important">

                  <div class="card-body" style="background: white;padding: 5px 0px 15px 0px !important;height: 150px !important;overflow-y: scroll;">
                    <div class="row" style="margin-left: 6px;margin-right: -23px;">
                      <div class="col-sm-6 col-md-6 col-xs-4 col-lg-4 col-xl-4" *ngFor="let item of datasetsArray"
                        style="padding-left:5px !important;padding-right:5px !important;margin-left: -7px;">
                        <div class="card card-block card-widget" style="padding: 5px;margin-bottom: 0.3rem;background: #e5e5e5;">

                          <div>

                            <p style="margin-bottom: 1px;font-size: 13px;">
                              <i class="fa fa-mobile " style="padding: 4px 3px 2px 2px;color: #e7505a;"></i>
                              <span class="mb-0 fw-400" style="float: right;font-weight: 700;">
                                {{item.item.name}}
                              </span>
                            </p>


                            <p class="text-uppercase " style="text-align: right;margin-bottom: 1px;font-size: 13px;color:black">
                              {{item.item.count}}
                            </p>
                            <p class="text-uppercase " style="text-align: right;margin-bottom: 1px;font-size: 13px;color:black">
                              M: {{item.item.maleCount}} F: {{item.item.femaleCount}}
                            </p>

                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div></div>
						<div class="form-group row">
							<label class="col-md-3 col-form-label labelSize" for="text-input">Groups<span style="color: red"></span>
							</label>
							<div class="col-md-3">
								<select class="form-control emailgroupSelectBoxStyle" [ngModel]="selectedemail" multiple (ngModelChange)="chosenemailData($event)">
									<option *ngFor="let item of emailgroupsArray" style="text-align: center;border-radius: 2px;background: #fff;margin-bottom: 2px;padding: 2px;" [ngValue]="item">{{item.groupName}}</option>
								</select>
							</div>
							<div class="col-md-1" style="margin-top: 35px;">
								<div class="row">
									<div class="col-12">
										<button (click)="emailsassigne()" class="btn  btn-primary" style="margin-bottom: 3px;width: 39px;height: 39px;background: #e7505a;border: none;"><i class="fa fa-angle-double-right"></i>
										</button>
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<button (click)="emailsunassigne()" class="btn  btn-primary" style="margin-bottom: 3px;width: 39px;height: 39px;background: #e7505a;border: none;"><i class="fa fa-angle-double-left"></i>
										</button>
									</div>
								</div>
							</div>
							<div class="col-md-3">
								<select class="form-control emailgroupSelectBoxStyle" [ngModel]="deselectedemail" multiple (ngModelChange)="chosenemailDataToRemove($event)">
									<option *ngFor="let item of emailselectedItems" style="text-align: center;border-radius: 2px;background: #fff;margin-bottom: 2px;padding: 2px;" [ngValue]="item">{{item.groupName}}</option>
								</select>
							</div>
						</div>
						<!-- <div class="form-group row" style="margin-bottom: 0.2rem;">
            <label class="col-md-4 col-form-label" for="text-input">Route<span style="color: red">*</span></label>
            <div class="col-md-6">
                <select class="form-control" [(ngModel)]="select_type">
                    <option value="" >Select Route</option>
                    <option *ngFor="let c of foldertype" [ngValue]="c">{{c.name}}</option>
                    </select>
            </div>
          </div> --></div>
					<div *ngIf="template_view">
						<div class="row" style="margin-left: 0px;">
							<div *ngFor="let item of templateimages">
								<img class="item_image" src={{item.image}}>
							</div>
						</div>
					</div>
					<div *ngIf="emailcontent_view">
						<!-- <div class="form-group row" style="margin-bottom: 0.2rem;">
							<label class="col-md-3 col-form-label" style="text-align: right;" for="text-input">Campaign Name:<span style="color: red">*</span>
							</label>
							<div class="col-md-9">
								<input type="text" id="text-input" [(ngModel)]="emailsendObj.contentcampaignname" name="text-input" class="form-control" style="padding: 10px;width: 88%;margin-left: 6%;" placeholder="Campaign Name">
							</div>
						</div>
						<div class="form-group row" style="margin-bottom: 0.2rem;">
							<label class="col-md-3 col-form-label" style="text-align: right;" for="textarea-input">Description:<span style="color: red">*</span>
							</label>
							<div class="col-md-9" style="display: inherit;">
								<textarea id="textarea-input" cols="4" rows="5" name="textarea-input" [(ngModel)]="emailsendObj.contentdesc" class="form-control" style="height: 100px;border:none;width: 88%;margin-left: 6%;" placeholder="Enter Description"></textarea>
							</div>
						</div> -->
						<div class="form-group row" style="margin-top: 12px;">
							<label class="col-md-3 col-form-label" for="text-input" style="text-align: right;">Email Content:</label>
							<div class="col-md-8" style="margin-left: 4.5%;">
								<ckeditor [(ngModel)]="emailsendObj.ckeditorcontent"  debounce="500"></ckeditor>
							</div>

							<!-- Email Template Builder -->
							<!-- <ip-email-builder>
								<div class="top-actions">
									<button
										mat-button
										(click)="sendTestMail()"
										[disabled]="isLoading | async"
										color="primary"
									>
										Send a test email
									</button>
								</div>
								<div class="after-content-blocks"></div>
								<div class="after-structure-blocks"></div>
							</ip-email-builder> -->
							<!-- Template Builder ends -->
							
						</div>
					</div>
					<div *ngIf="emailpreviewanssend">
						<div class="row">
							<div class="col-md-7">
								<div class="row" style="display: contents;">
									<div class="col" style="display:inline-flex">
										<label style="margin-right: 30px;margin-top: auto;">Schedule On</label>
										<angular2-date-picker 
										[(ngModel)]="schdule_time" 
										(onDateSelect)="onDateSelect($event)"
										  [settings]="settings" style="width: 200px;margin-right: 30px;" >
										</angular2-date-picker>
										<button class="schdulebtn_style" [disabled]="desabledScheduleButton" (click)="schduleddate(schdule_time)">Schedule</button>
									</div>
								</div>
								<!-- <div class="row">
									<div class="col"> -->
								<table class="table table-striped jobtracker" [mfData]="schduledtime" #mf="mfDataTable" [mfRowsOnPage]="10" style="margin-top: 3px !important;margin-bottom: 5px;">
									<thead style="background: #d9e0e6;">
										<tr>
											<th>
												<mfDefaultSorter>Scheduled Time</mfDefaultSorter>
											</th>
											<th>
												<mfDefaultSorter>Delete</mfDefaultSorter>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let item of mf.data;">
											<td class="tabledata_style">{{item}}</td>
											<td class="icons_style"><i style='color: #e7505a;' class='fa fa-trash'></i>
											</td>
									</tbody>
									<tfoot>
										<tr></tr>
									</tfoot>
								</table>
								<!-- </div>
								</div> -->
								<!-- <div style="display:grid;margin-bottom: 0.5rem;">
								<div class="form-check form-check-inline mr-1">
									<input class="form-check-input" type="checkbox" id="inline-checkbox1" value="option1">
									<label class="form-check-label" for="inline-checkbox1">Notify on complete</label>
								  </div>
								  <div class="form-check form-check-inline mr-1">
									<input class="form-check-input" type="checkbox" id="inline-checkbox2" value="option2">
									<label class="form-check-label" for="inline-checkbox2">Notify on reply</label>
								  </div>
								  <div class="form-check form-check-inline mr-1">
									<input class="form-check-input" type="checkbox" id="inline-checkbox3" value="option3">
									<label class="form-check-label" for="inline-checkbox3">Save campaign</label>
								  </div>
								</div> -->
							</div>
							<div class="col-md-5">
								<div class="card" style="width: 100%;margin-top: -19px;margin-left: 2px;border: none;
								background: transparent;">
									<!-- <div class="card-header" style="color: #f2284c;font-weight: 500;">Preview</div> -->
									<div class="card-body" style="padding: 2px;">
										<img src='/assets/images/singlelayerTemplate.png'>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- Only for journey email preivew screen -->
					<div *ngIf="journey_email_preview">
								<div class="card" style="width: 100%;margin-top: -19px;margin-left: 2px;border: none;
								background: transparent;">
									<!-- <div class="card-header" style="color: #f2284c;font-weight: 500;">Preview</div> -->
									<div class="card-body" style="padding: 2px;">
										<img src='/assets/images/singlelayerTemplate.png'>
									</div>
								</div>
					</div>
					<!-- ends here -->
				</div>
					<div class="card-footer myemail_dialog_footer_cls" style="border-top: none;">
						<div class="row">
							<div class="col-12 footercs"> <span  *ngIf="journey_email"><button type="button" class="btn  btn-primary" style="border: none;background: #e4e7ea;box-shadow: none;color: #b03626 !important; margin-right:5px;"
									 (click)="clear()"><i style="padding-right: 3px;" class="fa fa-times" aria-hidden="true"></i>Cancel</button></span>
								<span><button type="button" class="btn btn-primary" style="border: none;background: #e4e7ea;color: green !important;box-shadow: none;margin-right:6px"
									 (click)="previous()"><i style="padding-right: 3px;position: relative;right: 1px;top: 0px;" class=" fas fa-angle-left"
										 aria-hidden="true"></i>Back</button></span>
								<span *ngIf="nextbtn"><button type="button" class="btn btn-primary" style="border: none;background: #e7505a;color: white !important;box-shadow: none"
									 (click)="next(emailsendObj)">Next<i style="padding-right: 3px;position: relative;left: 6px;top: 1px;" class="fas fa-angle-right"
										 aria-hidden="true"></i></button></span>
								<span><button [hidden]="!journey_email_sendBtn"  type="button" class="btn  btn-primary myClick_from_modalBox" style="border: none;background: rgb(231, 80, 90);box-shadow: none;color: white !important;"
									 (click)="send(emailsendObj,schdule_time)"><i style="padding-right: 3px;" class="fas fa fa-check" aria-hidden="true"></i>Send
										Campaign</button></span>
							</div>
						</div>
					</div>
				</div>
			<!--</div>
	 </div>-->
	</div> 
	<div class="sk-double-bounce" style="left: 45% !important;top: 40% !important;position: absolute;" *ngIf="profileloading">
		<div class="sk-child sk-double-bounce1"></div>
		<div class="sk-child sk-double-bounce2"></div>
	</div>