import { Component, OnInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Http, Headers } from '@angular/http';
import { DialogComponent } from 'ngx-bootstrap-modal/index';
import { DialogService } from 'ngx-bootstrap-modal/components/dialog.service';
import { Router, Event, NavigationEnd } from '@angular/router';
export interface ConfirmModel {
  title: string;
  message: string;
}

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel , OnInit {
  title: string;
  message: string;
  product_val = '';
  brands_val = '';
  models_val = '';
  obj: any;
  constructor(private http: Http, dialogService: DialogService, private route: Router) {
    super(dialogService);
  }

  ngOnInit() {
    this.route.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        const myobj: any = {
          'brands': ''
        };
        this.close(myobj);
      }

    });

    if (this.obj === undefined ) {

    } else {
      this.brands_val = this.obj.brands;
      this.product_val = this.obj.product;
      this.models_val = this.obj.models;
    }
  }

  closeModal() {

    this.obj = {
      'brands': this.brands_val,
      'product': this.product_val,
      'models': this.models_val
    };

    this.close(this.obj);
  }

  display() {

    // document.getElementById('DIV1').style.display = "block";
    // document.getElementById('DIV2').style.display = "none";
  }
  display1() {
    // document.getElementById('DIV2').style.display = "block";
    // document.getElementById('DIV1').style.display = "none";
  }

}
