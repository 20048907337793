import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { DialogService, BuiltInOptions } from 'ngx-bootstrap-modal/index';
import 'rxjs/add/operator/map';
import { SignupComponent } from '../signup/signup.component';
import { ForgetComponent } from '../forget/forget.component';
// import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

declare var FB: any;
declare var jsSHA: any;

// @AutoUnsubscribe()
@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  twitterConsumerKey = 'ZHdrNUv4YQ0QGIp3WCgwuefrU';
  twitterCallback = 'https://cloud.citimedia.com/web/callback';
  twitterOauthSecret = '5sCGjYMBSPxqAJZtf5mpULVpUwxf08Tw99qyS6gVNZqvUQaPaP';
  signinObj: any = {};
  loggingin = false;
  siteHostName = window.location.hostname;
  constructor(public http: Http, public router: Router, public toastr: ToastrService, public dialogService: DialogService) {
    this.signinObj.userName = '';
    this.signinObj.pwd = '';
  }

  ngOnInit() {
    const loginFocus = document.getElementById('loginUser').focus();
    document.getElementById('loginState').addEventListener('keypress', (e) => {
      // console.log(e);
      const key = e.which || e.keyCode;
      if (key === 13) { // 13 is enter
        this.submit(this.signinObj);
      }
    });
  }



  submit(obj) {
    this.loggingin = true;
    // console.log(obj.userName);
    if (obj.userName === '' || obj.userName === null || obj.userName === undefined) {
      this.showError('Please Enter Username');
      this.loggingin = false;
    } else if (obj.pwd === '' || obj.pwd === undefined || obj.pwd === null) {
      this.showError('Please Enter Password');
      this.loggingin = false;
    } else {
      const headers = new Headers();
      headers.append('Content-Type', 'application/x-www-form-urlencoded');
      headers.append('X-Apikey', (<any>window).citikey);
      const body = 'userName=' + obj.userName.trim() + '&password=' + obj.pwd.trim();
      // console.log(body);
      // console.log(localStorage.getItem('EtihadSerUrl') + 'users/loginUser?userName=' + obj.userName + '&password=' + obj.pwd );
      this.http.post((<any>window).citiUrl + 'users/login', body, { headers: headers })
        .map(res => res.json())
        .subscribe(data => {
          // console.log('*****', data);
          if (data.status === 'success') {
            // console.log('data success');
            // console.log(data.token);
            (<any>window).citiToken = data.token;
            // console.log((<any>window).citiToken);
            this.showSuccess(data.message);
            this.signinObj = {};
            this.loggingin = false;
            this.router.navigate(['/dashboard'], { skipLocationChange: true });
          } else {
            this.loggingin = false;
            this.showError(data.message);
            this.signinObj.pwd = '';
            // this.signinObj = {};
          }
        }, error => {
          this.loggingin = false;
          this.signinObj.pwd = '';
          this.showError('Server is busy, Please try again');
          // this.signinObj = {};

          // console.log(JSON.stringify(error.json()));
          // console.log(error);

        });
    }
  }

  // Signup component
  signUp() {
    const myvar = this;
    myvar.dialogService.addDialog(SignupComponent, {
      title: 'Confirmation',
      message: 'helloooooo'
    }, { backdrop: 'static' })
      .subscribe((data) => {
        // console.log(data);
      });
  }

  forgotPassword() {
    const myvar = this;
    myvar.dialogService.addDialog(ForgetComponent, {
      title: 'Confirmation',
      message: 'helloooooo'
    });
  }

  loginViaFacebook() {
    // will try to login to fb
    // this.fbLogout();
    FB.login((response) => {
      if (response.authResponse) {
        // console.log(response.authResponse);
        (<any>window).fbCredentials = response.authResponse;
        // console.log('Welcome!  Fetching your information.... ');
        this.saveFbAccess();
        // this.getUserDetailsFb();
      } else {
        // console.log('User cancelled login or did not fully authorize.');
      }
    }, { scope: 'publish_pages,manage_pages,user_posts,user_photos,user_likes,user_friends,user_gender,business_management,email,public_profile,user_events,user_videos,user_birthday,' });
  }

  getUserDetailsFb() {
    const params = { fields: 'id,name,first_name,gender,location,feed{attachments},posts{id,from,name,picture,story,attachments{url,subattachments,title},to{id,name,username,pic},description,message,comments{id,from,can_comment,like_count,message,likes{id,name},attachment,permalink_url},permalink_url,shares,likes{id,name,pic,link}},friendlists{id,name}' };
    // const params = {fields: 'fields=from,picture,created_time,description,full_picture,name,attachments{title,url,description},comments{comment_count,from,id,created_time,message},likes{id,username,pic,name},story,message,shares,sharedposts,icon,link,place,caption,object_id,is_popular'};

    FB.api('/me', 'get', params, (res) => {
      // console.log('FB Login Res', res);
      // console.log(JSON.stringify(res));
      // console.log('Good to see you, ' + res.name + '.');
      this.saveFbAccess();
    });
  }

  saveFbAccess() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    const body = 'oauthType=facebook&oauthToken=' + (<any>window).fbCredentials.accessToken;
    // console.log(body);
    this.http.post((<any>window).citiUrl + 'users/oauth2/login', body, { headers: headers })
      .map(res => res.json())
      .subscribe(data => {
        // console.log('*****', data);
        if (data.status === 'success') {
          this.showSuccess(data.message);
          this.loggingin = false;
          (<any>window).citiToken = data.token;
          // console.log('(<any>window).citiToken', (<any>window).citiToken);
          this.router.navigate(['/dashboard'], { skipLocationChange: true });
        } else {
          this.showError(data.message);
        }
      });
  }

  socialLoginFB() {

    // FB.getLoginStatus(function(response) {
    //   statusChangeCallback(response);
    // });

    // will first check for login status
    if (FB.getLoginStatus() != null) {
      FB.getLoginStatus((response) => {
        // console.log(response);
        if (response.status === 'connected') {
          // console.log('connected');
          // The user is logged in and has authenticated your
          // app, and response.authResponse supplies
          // the user's ID, a valid access token, a signed
          // request, and the time the access token
          // and signed request each expire.
          const uid = response.authResponse.userID;
          const accessToken = response.authResponse.accessToken;
          (<any>window).fbCredentials = response.authResponse;
          // console.log('(<any>window).fbCredentials', (<any>window).fbCredentials);
          this.getUserDetailsFb();
          this.saveFbAccess();
        } else if (response.status === 'authorization_expired') {
          // console.log('authorization_expired');
          // The user has signed into your application with
          // Facebook Login but must go through the login flow
          // again to renew data authorization. You might remind
          // the user they've used Facebook, or hide other options
          // to avoid duplicate account creation, but you should
          // collect a user gesture (e.g. click/touch) to launch the
          // login dialog so popup blocking is not triggered.
          this.loginViaFacebook();

        } else if (response.status === 'not_authorized') {
          // console.log('not_authorized');
          // The user hasn't authorized your application.  They
          // must click the Login button, or you must call FB.login
          // in response to a user gesture, to launch a login dialog.
          this.loginViaFacebook();
        } else {
          // The user isn't logged in to Facebook. You can launch a
          // login dialog with a user gesture, but the user may have
          // to log in to Facebook before authorizing your application.
          this.loginViaFacebook();
        }
      },
        true);
    } else {
      this.loginViaFacebook();
    }


  }

  socialLoginTwitter() {

    // step 1 : get request token

    const oauth_timestamp = Math.round((new Date()).getTime() / 1000.0);

    /* Type 1 Nounce Generation*/

    // const nonceObj = new jsSHA(Math.round((new Date()).getTime() / 1000.0), 'TEXT');
    // console.log('nonceObj', nonceObj);

    /* Type 2 Nounce Generation*/

    // const nounceArray = new Uint32Array(1);
    // const nounceGotData = window.crypto.getRandomValues(nounceArray);
    // const nonceObj = nounceGotData[0];

    /* Type 3 Nounce Generation*/

    const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~';
    const result = [];
    window.crypto.getRandomValues(new Uint8Array(32)).forEach(c =>
      result.push(charset[c % charset.length]));
    const nonceObj = result.join('');
    // console.log('nonceObj', nonceObj);

    /* Type 4 Nounce Generation */

    // const nonceObj = Math.random().toString(36).replace(/[^a-z]/, '').substr(2);;
    // console.log('nonceObj', nonceObj);

    let signatureBaseString = 'POST&' + encodeURIComponent('https://api.twitter.com/oauth/request_token') + '&';
    signatureBaseString += encodeURIComponent('oauth_callback=' + this.twitterCallback + '&');
    signatureBaseString += encodeURIComponent('oauth_consumer_key=' + this.twitterConsumerKey + '&');
    signatureBaseString += encodeURIComponent('oauth_nonce=' + nonceObj + '&');
    signatureBaseString += encodeURIComponent('oauth_signature_method=HMAC-SHA1&');
    signatureBaseString += encodeURIComponent('oauth_timestamp=' + oauth_timestamp + '&');
    signatureBaseString += encodeURIComponent('oauth_version=1.0');

    // console.log('signatureBaseString', signatureBaseString);

    const secret_signing_key = this.twitterOauthSecret + '&';

    // console.log('secret_signing_key', secret_signing_key);

    const shaObj = new jsSHA(signatureBaseString, 'TEXT');
    const signature = shaObj.getHMAC(encodeURIComponent(secret_signing_key) + '&', 'TEXT', 'SHA-1', 'B64');

    const encodedSignature = encodeURIComponent(signature);

    // console.log('encodedSignature', encodedSignature);
    const authData = 'OAuth oauth_consumer_key=' + this.twitterConsumerKey +
      ',oauth_timestamp=' + oauth_timestamp +
      ',oauth_signature_method=' + 'HMAC-SHA1' +
      ',oauth_nonce=' + nonceObj +
      ',oauth_version=' + '1.0' +
      ',oauth_signature=' + encodedSignature +
      ',oauth_callback=' + 'http://localhost/callback';
    // console.log('authData', authData);
    const headers = new Headers();
    headers.append('Content-Type', '"application/json; charset=utf-8"');
    // headers.append('Content-Type', 'application/json');
    headers.append('crossDomain', 'true');
    headers.append('Authorization', authData);
    headers.append('Access-Control-Allow-Credentials', 'true');
    headers.append('Access-Control-Allow-Origin', 'https://cloud.citimedia.com/web/');
    // const body = 'oauth_callback=' + 'http://localhost/callback';
    const body = 'grant_type=client_credentials';
    this.http.post('https://api.twitter.com/oauth/request_token', { headers: headers })
      .map(res => res.json())
      .subscribe(data => {
        // console.log(data);
        if (data.status === '200' || data.status === 200) {
          const oauthTwitterData: any = data.body;
          // console.log(oauthTwitterData.oauth_token + ' || ' + oauthTwitterData.oauth_token_secret + ' || ' + oauthTwitterData.oauth_callback_confirmed);

          // step 2 : authenticate user

          this.authTwitterUser(oauthTwitterData, (authResp) => {
            // console.log('authResp', authResp);
            // here we will get oauth_token and oauth_verifier in callbackurl
            // should fetch them and should verify that the token matches the request token received earlier
            if (authResp.oauth_token === oauthTwitterData.oauth_token) {

              // step 3 : convert request token to access token
              this.converRequestToken(authResp, (convertData) => {
                // console.log('convertData', convertData);
                (<any>window).twitterCredentials = convertData;
                // console.log('(<any>window).twitterCredentials', (<any>window).twitterCredentials);
                this.getTwitterUserDetails();
              });
            }
          });

        }
      });
  }

  converRequestToken(authResp, callback) {
    const oauth_timestamp = Math.round((new Date()).getTime() / 1000.0);
    const nonceObj = new jsSHA(Math.round((new Date()).getTime() / 1000.0), 'TEXT');
    // console.log('nonceObj', nonceObj);
    let signatureBaseString = 'POST&' + encodeURIComponent('https://api.twitter.com/oauth/access_token') + '&';
    signatureBaseString += encodeURIComponent('oauth_callback=' + this.twitterCallback + '&');
    signatureBaseString += encodeURIComponent('oauth_consumer_key=' + this.twitterConsumerKey + '&');
    signatureBaseString += encodeURIComponent('oauth_nonce=' + nonceObj + '&');
    signatureBaseString += encodeURIComponent('oauth_signature_method=HMAC-SHA1&');
    signatureBaseString += encodeURIComponent('oauth_timestamp=' + oauth_timestamp + '&');
    signatureBaseString += encodeURIComponent('oauth_version=1.0');

    // console.log('signatureBaseString', signatureBaseString);

    const secret_signing_key = this.twitterConsumerKey + '&';

    // console.log('secret_signing_key', secret_signing_key);

    const shaObj = new jsSHA(signatureBaseString, 'TEXT');
    const signature = shaObj.getHMAC(encodeURIComponent(secret_signing_key) + '&', 'TEXT', 'SHA-1', 'B64');

    const encodedSignature = encodeURIComponent(signature);

    // console.log('encodedSignature', encodedSignature);

    const authData2 = 'OAuth oauth_consumer_key=' + this.twitterConsumerKey + ',oauth_timestamp=' + oauth_timestamp + ',oauth_signature_method=' + 'HMAC-SHA1' + ',oauth_nonce=' + nonceObj + ',oauth_version=' + '1.0' + ',oauth_signature=' + encodedSignature + ',oauth_token=' + authResp.oauth_token;

    const authheaders = new Headers();
    authheaders.append('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
    authheaders.append('crossDomain', 'true');
    authheaders.append('Authorization', authData2);
    authheaders.append('oauth_verifier', authResp.oauth_verifier);

    const body = 'oauth_verifier=' + authResp.oauth_verifier;
    this.http.post('https://api.twitter.com/oauth/access_token', body, { headers: authheaders })
      .map(res => res.json())
      .subscribe(authRepspData => {
        callback(authRepspData);
      });
  }


  authTwitterUser(oauthTwitterData, callback) {
    // here we will be asked to login to twitter
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
    headers.append('crossDomain', 'true');
    this.http.get('https://api.twitter.com/oauth/authenticate?oauth_token=' + oauthTwitterData.oauth_token, { headers: headers })
      .map(res => res.json())
      .subscribe(resp => {
        // console.log('twitter auth resp', resp);
        callback(resp);
      });
  }


  getTwitterUserDetails() {

    const oauth_timestamp = Math.round((new Date()).getTime() / 1000.0);
    const nonceObj = new jsSHA(Math.round((new Date()).getTime() / 1000.0), 'TEXT');
    // console.log('nonceObj', nonceObj);
    let signatureBaseString = 'POST&' + encodeURIComponent('https://api.twitter.com/1.1/account/verify_credentials.json') + '&';
    signatureBaseString += encodeURIComponent('oauth_callback=' + this.twitterCallback + '&');
    signatureBaseString += encodeURIComponent('oauth_consumer_key=' + this.twitterConsumerKey + '&');
    signatureBaseString += encodeURIComponent('oauth_nonce=' + nonceObj + '&');
    signatureBaseString += encodeURIComponent('oauth_signature_method=HMAC-SHA1&');
    signatureBaseString += encodeURIComponent('oauth_timestamp=' + oauth_timestamp + '&');
    signatureBaseString += encodeURIComponent('oauth_version=1.0');

    // console.log('signatureBaseString', signatureBaseString);

    const secret_signing_key = this.twitterConsumerKey + '&';

    // console.log('secret_signing_key', secret_signing_key);

    const shaObj = new jsSHA(signatureBaseString, 'TEXT');
    const signature = shaObj.getHMAC(encodeURIComponent(secret_signing_key) + '&', 'TEXT', 'SHA-1', 'B64');

    const encodedSignature = encodeURIComponent(signature);

    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
    headers.append('crossDomain', 'true');
    const body = 'oauth_consumer_key=' + this.twitterConsumerKey + '&oauth_nonce=' + nonceObj + '&oauth_signature_method=HMAC-SHA1&oauth_token=' + (<any>window).twitterCredentials.oauth_token + '&oauth_timestamp=' + oauth_timestamp + '&oauth_version=1.0&oauth_signature=' + encodedSignature;
    this.http.get('https://api.twitter.com/1.1/account/verify_credentials.json' + body, { headers: headers })
      .map(res => res.json())
      .subscribe(resp => {
        // console.log('twitter user resp', resp);

      });
  }

  showSuccess(data) {
    if (this.toastr.currentlyActive === 0) {
      this.toastr.success(data, '', {
        timeOut: 1500,
        positionClass: 'toast-bottom-right'
      });
    }
  }

  showError(data) {
    if (this.toastr.currentlyActive === 0) {
      // console.log("active toasts = "+ this.toastr.currentlyActive +" In if");
      this.toastr.error(data, '', {
        timeOut: 1500,
        positionClass: 'toast-bottom-right'
      });
    }
  }

  fbLogout() {
    try {
      if (FB.getAccessToken() != null) {
        FB.logout(function (response) {
          // Person is now logged out
          // console.log(response);
        });
      }
    } catch (err) {
      // console.log(err);
    }

  }

  FacebookNodeLogin() {
    // let webString: any;
    // if (this.siteHostName.includes('citimedia')) {
    //   webString = '/web';
    // } else {
    //   webString = '';
    // }
    window.location.href = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/auth/facebook';
  }

  TwitterNodeLogin() {
    // let webString: any;
    // if (this.siteHostName.includes('citimedia')) {
    //   webString = '/web';
    // } else {
    //   webString = '';
    // }
    window.location.href = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/auth/twitter';
  }
}
