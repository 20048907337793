<div class="modal-content">
  <div class="modal-header">
    <div class="modal-title pull-left list_contacts">
      <span style="padding-left: 5px;">Contact List</span>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true" style="font-size: 21px;">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="padding: 15px;" *ngIf="updateDiv">
      <div class="form-group row" style="margin-bottom: 0.2rem;">
        <div class="col-md-4">
          <input type="text" id="text-input" name="text-input" class="form-control" placeholder="Name" [(ngModel)]="name">
        </div>
        <div class="col-md-4">
          <input type="text" id="text-input" name="text-input" class="form-control" placeholder="Phone Number"
            [(ngModel)]="phnnumber">
        </div>
        <div class="col-md-4">
          <button type="button" class="btn btn-primary update_btn" (click)="updateGroup(item)">
            <i style="padding-right: 3px;" class="fa fa-floppy-o" aria-hidden="true"></i>Update
          </button>
          <button type="button" class="btn btn-primary clear_btn" (click)="clearData()">
            <i style="padding-right: 3px;" class="fa fa-times" aria-hidden="true"></i>Clear
          </button>
        </div>
      </div>
    </div>
    <table class="table table-striped jobtracker" [mfData]="contactdetails" #mf="mfDataTable" [mfRowsOnPage]="5" style="margin-top: 5px !important;">
      <thead style="background: #d9e0e6;">
        <tr>
          <th style="width: 10%">
            <mfDefaultSorter by="Name">Name</mfDefaultSorter>
          </th>

          <th style="width: 10%">
            <mfDefaultSorter by="PhoneNumber">Phone Number</mfDefaultSorter>
          </th>

          <th style="width: 10%">
            <mfDefaultSorter by="Edit">Edit</mfDefaultSorter>
          </th>

          <th style="width: 10%">
            <mfDefaultSorter by="Delete">Delete </mfDefaultSorter>
          </th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of mf.data; let row_no = index">
          <td>{{item.firstName}}</td>
          <td>{{item.phoneNumber}}</td>
          <td>
            <i class='fa fa-edit change_ico' (click)=edit(item)></i>
          </td>
          <td>
            <i class='fa fa-trash change_ico' (click)="deletemethod(row_no)"></i>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="4" style="padding-bottom: 0px;">
            <mfBootstrapPaginator [rowsOnPageSet]="[5,10,25]"></mfBootstrapPaginator>
            <span class="input-group-text viewfooter">{{contactdetails.length}} total</span>
          </td>
        </tr>
      </tfoot>
    </table>
    <div class="sk-double-bounce spinnerStyle" *ngIf="subcontactsloading">
      <div class="sk-child sk-double-bounce1"></div>
      <div class="sk-child sk-double-bounce2"></div>
    </div>
  </div>
</div>