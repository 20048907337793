<div tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
	<div class=" modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<div class="modal-title pull-left" style="font-size: 15px;font-weight: 600;">
					<span style="padding-left: 5px;">Products</span>
				</div>
				<button type="button" class="close pull-right my_closex" aria-label="Close" (click)="closeModal()">
					<span aria-hidden="true" style="font-size: 21px;">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-group row" style="margin-bottom: 0.2rem;">
					<label class="col-md-4 col-form-label" for="text-input">Product Name</label>
					<div class="col-md-6">
						<select class="form-control" [(ngModel)]="product_val">
							<option value="" disabled selected hidden >Select Product</option>
							<option value="Televisions">Televisions</option>
							<option value="Cameras">Cameras</option>
							<option value="Other Products">Other Products</option>
						</select>
					</div>
				</div>
				<div class="form-group row" style="margin-bottom: 0.2rem;">
					<label class="col-md-4 col-form-label" for="text-input">Brands</label>
					<div class="col-md-6">
						<select class="form-control" [(ngModel)]="brands_val">
							<option value="" disabled selected hidden>Select Brand</option>
							<option value="Samsung">Samsung</option>
							<option value="Sony">Sony</option>
							<option value="LG">LG</option>
						</select>
					</div>
				</div>
				<div class="form-group row" style="margin-bottom: 0.2rem;">
					<label class="col-md-4 col-form-label" for="text-input">Model</label>
					<div class="col-md-6">
						<select class="form-control" [(ngModel)]="models_val">
							<option value="" disabled selected hidden>Select Model</option>
							<option value="LCD">LCD</option>
							<option value="LED">LED</option>
							<option value="Curved Televisions">Curved Televisions</option>
						</select>
					</div>
				</div>
			</div>
		</div>
	</div>
