export const navItems = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'fa fa-tachometer'
  },
  {
    name: 'Social Monitor',
    url: '/socialmonitor',
    icon: 'fa fa-users'
  },
  {
    name: 'Ad Manager',
    url: '/admanager',
    icon: 'fa fa-bullhorn',
  },
  {
    name: 'Keyword Analytics',
    url: '/keywordanalytics',
    icon: 'fa fa-bar-chart',
  },
  {
    name: 'Experience Manager',
    url: '/journeybuilder',
    icon: 'fa fa-sitemap'
  },
  {
    name: 'Datasets',
    url: '/datasets',
    icon: 'fa fa-database'
  },
  {
    name: 'Content Manager',
    url: '/contentmanager',
    icon: 'fa fa-files-o'
  },
  {
    name: 'L&T Metro',
    url: '/metro',
    icon: 'fa fa-subway'
  },
  {
    name: 'Admin',
    url: '/admin',
    icon: 'fa fa-user'
  }
];