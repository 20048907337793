import { Component, OnInit, AfterViewInit, TemplateRef, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { DomSanitizer } from '@angular/platform-browser';
import { Http, Response, Headers, Jsonp, URLSearchParams } from '@angular/http';
import 'rxjs/add/operator/map';
import { DialogComponent } from 'ngx-bootstrap-modal/index';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { DialogService } from 'ngx-bootstrap-modal/components/dialog.service';
import { ToastrService } from 'ngx-toastr';
import { CitiservicesService } from '../../../citiservices.service';

// declare var map: any;
declare var google: any;
declare var require: any;

@Component({
  selector: 'app-audience-planner',
  templateUrl: './audience-planner.component.html',
  styleUrls: ['./audience-planner.component.scss']
})
export class AudiencePlannerComponent implements OnInit, OnDestroy {
  @Output() audienceObject = new EventEmitter();
  validFormat: any;
  sendObj: any = {};
  showManualTab: any = true;
  selectedItems: any[] = [];
  selectedToAdd: any[] = [];
  selectedToRemove: any[] = [];
  groupsArray: any = [];
  eveData: any;
  total: any;
  valid: any;
  invalid: any;
  dnd: any;
  fileName: any;
  emailfiledata: any;
  journey_emails_value: any;
  public datasetHeight: any;
  public windowHeight: any = window.innerHeight;
  public mapHeight: any = window.innerHeight * 60 / 100;
  public mapWidth: any = ((window.innerWidth - 250) * 71) / 100;
  public slider: any;
  public searchBox: any;
  public input: any;
  public markers: any[];
  public places: any;
  public bounds: any;
  public icon: any;
  private mapRadius: any = '0';
  public gender: any = 'male';
  public budgetDate: any = { fromDate: '', toDate: '' };
  public agelimit: any = { fromData: '', toData: '' };
  public map: any;
  public searchedPlaces: any = [];
  public searchRadius: any = 5000;
  public cityCircle: any;
  public selectedIndex: any = 0;
  public backupstyleId: any;
  public ageArray: any = [];
  public ageRange: any = { fromAge: 18, toAge: 30 };
  public styleBack = '#f8f8f8';
  public backupLocation: any;
  public obj: any;
  public myid: any;
  public upload_file_numbers = [];
  public upload_file_data = [];
  public roleSelected: any = 'all';
  public roleType: any = 'all';
  public dataSets: any = [];
  public datasetRole: any = [{ roleType: 'Promotional' }, { roleType: 'Transactional' }, { roleType: 'All' }];
  public datasetType: any = [{ type: 'SMS' }, { type: 'Email' }, { type: 'All' }];
  public gotDataSets: any;
  public globalDataSets: any = (<any>window).globalDataSet = [];
  // private apiUrl = 'http://192.168.3.115:8005/datasets/geo?';
  private locationCoords: any;
  private selectedRadius: any;
  public loadSpinner = false;
  public ErrorLoadingData = false;
  public changeHeight = this.windowHeight - (this.windowHeight * 10 / 100) - 10;
  public noDataSetsFound = false;
  public genderSelected: any = 'male';
  fetchingCache = false;
  mysubscribecss = 'customsets_css';
  myfontclass_accordain = 'fa fa-chevron-down';
  subscribeDIV = true;
  customsetsDIV = false;
  myfontclass_custom = 'fa fa-chevron-down';
  datasetsArray = [];
  customPurchased = [];
  subscribePublic = [];
  subscribe_datasetsspinner = false;
  customedatasets_spinner = false;
  subscribePublic_sms = [];
  subscribePublic_email = [];
  customPurchased_sms = [];
  customPurchased_email = [];
  customsetsDIV_sms = true;
  customsetsDIV_email = false;
  subscribeDIV_sms = true;
  subscribeDIV_email = false;
  activeDiv_type = 'sms';
  custompurchaseDataNOsms = false;
  custompurchaseDataNOemail = false;
  subscribeDataNOsms = false;
  subscribeDataNOemail = false;
  smsEmail = [];
  totalDatasets = [];
  mysearchedPlace: any;
  modalRef: BsModalRef;
  smsEmail_selectedDailog = [];
  wallet_details: any;
  filter_cs = false;
  smsEmail_Dailog = [];
  datasets_lengthcustomsms = true;
  datasets_lengthcustomemail = false;
  datasets_lengthsubscribe = true;
  packName: any;
  datasets_lengthsubscribesms = true;
  datasets_lengthsubscribeemail = false;
  columnForMap = 'col-9';
  columnForDatasets = 'col-3';


  constructor(private cdr: ChangeDetectorRef, sanitizer: DomSanitizer, private http: Http, dialogService: DialogService, private toastr: ToastrService, private citiservice: CitiservicesService, private route: Router, private modalService: BsModalService) {
    // super(dialogService);
    // this.map = map;
    // google = google;
    this.selectedRadius = 5;
    for (let i = 18; i < 90; i++) {
      this.ageArray.push(i);
    }
    this.datasetHeight = (window.innerHeight * 70) / 100;
    this.gotDataSets = [];
    this.formdataSets2(this.gotDataSets);
    window.addEventListener('resize', () => {
      // console.log('resized');
      this.changeHeight = this.windowHeight - (this.windowHeight * 10 / 100) - 10;
      // console.log('kjkjkj', this.changeHeight);
    });
  }

  // html = `<span class="btn btn-warning spanStyl" style="">Never trust not sanitized <code>HTML</code>!!!</span>`;
  ngOnDestroy() {
    // console.log(this.searchedPlaces);
    const groupsID = [];
    for (let i = 0; i < this.selectedItems.length; i++) {
      groupsID.push(this.selectedItems[i].groupId);
    }
    const searchMapPlaces: any = [];
    for (let j = 0; j < this.searchedPlaces.length; j++) {
      const placeObj = { place: this.searchedPlaces[j].place, location: this.searchedPlaces[j].location, placeData: this.searchedPlaces[j].placeData, radiusData: this.searchedPlaces[j].radiusData, ageData: this.searchedPlaces[j].ageData, genderData: this.searchedPlaces[j].genderData };
      searchMapPlaces.push(placeObj);
    }
    // console.log(searchMapPlaces);
    this.obj = { data: { searchedPlaces: searchMapPlaces, dataSets: this.smsEmail, ageData: this.ageRange, genderData: this.genderSelected, totalselectedData: this.totalDatasets }, manual: { sms: this.sendObj, uploadfile: this.upload_file_numbers, groups: groupsID, file: this.upload_file_data, email: this.journey_emails_value } };
    this.audienceObject.emit(this.obj);
  }

  ngOnInit() {
    this.route.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        const myobj: any = {
          'offertype': ''
        };
        this.modalRef.hide();
      }

    });

    this.getGroups();
    this.initAutocomplete();
    // console.log(document.getElementById('dataset_sms').classList);
    document.getElementById('dataset_sms').classList.add('button_focused');
    this.obj = (<any>window).audienceData_Journey.obj;
    this.myid = (<any>window).audienceData_Journey.myid;
    // console.log(this.obj);

    if (this.myid === undefined) {
      this.showManualTab = false;
    }
    if (this.obj !== undefined && this.obj.data && this.obj.data.searchedPlaces.length > 0) {
      this.fetchingCache = true;
      (<any>window).globalDataSet = this.globalDataSets = this.obj.data.dataSets;
      const searchedPlacesBackup: any = this.obj.data.searchedPlaces;
      // this.dataSets = this.obj.data.dataSets;
      // this.ageRange = this.obj.data.ageRange;

      setTimeout(() => {
        this.icon = {
          url: 'assets/img/markers/pin.svg',
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          // scaledSize: new google.maps.Size(25, 25)
          scaledSize: new google.maps.Size(35, 35)
        };
        for (let i = 0; i < searchedPlacesBackup.length; i++) {
          this.markers.push(new google.maps.Marker({
            map: this.map,
            icon: this.icon,
            animation: google.maps.Animation.DROP,
            title: searchedPlacesBackup[i].placeData.name,
            position: searchedPlacesBackup[i].placeData.geometry.location
          }));


          this.cityCircle = new google.maps.Circle({
            strokeColor: '#b3c5e9',
            strokeOpacity: 0.5,
            strokeWeight: 1,
            fillColor: 'steelblue',
            fillOpacity: 0.5,
            map: this.map,
            center: searchedPlacesBackup[i].placeData.geometry.location,
            radius: searchedPlacesBackup[i].radiusData.radius
          });
          this.addPlaces(searchedPlacesBackup[i].placeData, { radius: searchedPlacesBackup[i].radiusData.radius, center: searchedPlacesBackup[i].radiusData.center }, this.cityCircle, searchedPlacesBackup[i]);
          this.ageRange = searchedPlacesBackup[i].ageData;
          this.genderSelected = searchedPlacesBackup[i].genderData;
          if (i === searchedPlacesBackup.length - 1) {
            setTimeout(() => {
              // console.log('this.backupstyleId', this.backupstyleId);
              if (this.backupstyleId !== undefined && searchedPlacesBackup.length > 1) {
                document.getElementById(this.backupstyleId).style.background = '#c1c1c1';
              }
              // console.log('++++', searchedPlacesBackup);

              // document.getElementById('place-' + i).style.background = '#03A9F4';
              // this.backupstyleId = 'place-' + i;
              this.searchRadius = searchedPlacesBackup[i].radiusData.radius;
              // console.log('++++', this.searchRadius);
              this.adjustSlider(this.searchRadius);

              this.getIndex(searchedPlacesBackup[i], i);
            }, 1000);
            this.fetchingCache = false;
          }
        }


      }, 2000);


    }
    if (this.obj === undefined) {

    } else {
      this.sendObj.phonenumber = this.obj.manual.sms.phonenumber;
    }
  }

  formdataSets(data) {
    // console.log('looping datasets..');
    if (this.roleSelected.toLowerCase() === 'All'.toLowerCase() && this.roleType.toLowerCase() === 'All'.toLowerCase()) {
      this.dataSets = [];
      this.dataSets = data;
      // console.log(this.dataSets);
    } else if (this.roleSelected.toLowerCase() === 'Promotional'.toLowerCase() && this.roleType.toLowerCase() === 'All'.toLowerCase()) {
      this.dataSets = [];
      // console.log('1');
      for (let i = 0; i < data.length; i++) {
        if (data[i].role.toLowerCase() === this.roleSelected.toLowerCase()) {
          this.dataSets.push(data[i]);
        }
      }
    } else if (this.roleSelected.toLowerCase() === 'Transactional'.toLowerCase() && this.roleType.toLowerCase() === 'All'.toLowerCase()) {
      this.dataSets = [];
      // console.log('2');
      for (let i = 0; i < data.length; i++) {
        if (data[i].role.toLowerCase() === this.roleSelected.toLowerCase()) {
          this.dataSets.push(data[i]);
        }
      }
    } else if (this.roleSelected.toLowerCase() === 'All'.toLowerCase() && this.roleType.toLowerCase() === 'SMS'.toLowerCase()) {
      this.dataSets = [];
      // console.log('3');
      for (let i = 0; i < data.length; i++) {
        if (data[i].type.toLowerCase() === this.roleType.toLowerCase()) {
          this.dataSets.push(data[i]);
        }
      }
    } else if (this.roleSelected.toLowerCase() === 'All'.toLowerCase() && this.roleType.toLowerCase() === 'Email'.toLowerCase()) {
      this.dataSets = [];
      // console.log('4');
      for (let i = 0; i < data.length; i++) {
        if (data[i].type.toLowerCase() === this.roleType.toLowerCase()) {
          this.dataSets.push(data[i]);
        }
      }
    } else {
      // console.log('5');
      this.dataSets = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].role.toLowerCase() === this.roleSelected.toLowerCase() && data[i].type.toLowerCase() === this.roleType.toLowerCase()) {
          this.dataSets.push(data[i]);
        }
      }
    }


  }

  // ngAfterViewInit() {
  //   this.initAutocomplete();
  // }

  getData() {
    const geo_Filters = [];
    const gender = [];
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    // console.log('latitude=' + this.locationCoords.lat + '&longitude=' + this.locationCoords.lng + '&radius=' + this.selectedRadius);
    geo_Filters.push({ 'latitude': this.locationCoords.lat, 'longitude': this.locationCoords.lng, 'radius': this.selectedRadius });
    // gender.push({ 'gender': this.genderSelected });
    // console.log(this.genderSelected);
    // console.log(this.ageRange);
    // console.log((<any>window).citiUrl  + 'datasets/geo?geofilter=' + JSON.stringify(geo_Filters) + '&filter=' + JSON.stringify(gender));
    const urlparams = new URLSearchParams();
    urlparams.append('filter', JSON.stringify(geo_Filters));
    return this.http.get((<any>window).citiUrl + 'datablocks/geo?' + urlparams, { headers: headers })
      .map((res: Response) => res.json());
  }

  getDataSetsViaApi() {
    this.gotDataSets = [];
    this.datasetsArray = [];
    this.dataSets = [];
    this.loadSpinner = true;
    this.ErrorLoadingData = false;
    this.noDataSetsFound = false;
    this.customedatasets_spinner = true;
    this.subscribe_datasetsspinner = true;
    this.getData().subscribe(data => {
      // console.log('|||||||||||||||||||||||||', data);
      console.log(this.searchedPlaces);
      // resizing the column for map after searching results and collapse
      this.columnForMap = 'col-3';
      this.columnForDatasets = 'col-9';
      // end here map
      this.gotDataSets = [];
      this.subscribePublic = [];
      this.customPurchased = [];
      this.subscribePublic_sms = [];
      this.subscribePublic_email = [];
      this.customPurchased_sms = [];
      this.customPurchased_email = [];
      if (data.status === 'success') {

        if (data.response.length) {
          // this.gotDataSets = data.response;
          // this.formdataSets2(this.gotDataSets);
          // console.log(data.response);
          this.customedatasets_spinner = false;
          this.subscribe_datasetsspinner = false;
          this.datasetsArray = data.response;
          for (let d = 0; d < this.datasetsArray.length; d++) {
            if (this.datasetsArray[d].dataBlockStatus === 'public') {
              this.subscribePublic.push(this.datasetsArray[d]);
            } else {
              this.customPurchased.push(this.datasetsArray[d]);

            }
          }

          // for datasets into public
          for (let p = 0; p < this.subscribePublic.length; p++) {
            if (this.subscribePublic[p].type === 'sms') {
              // this.subscribePublic.push(this.datasetsArray[d]);
              this.subscribePublic_sms.push(this.subscribePublic[p]);
            } else {
              this.subscribePublic_email.push(this.subscribePublic[p]);
            }
          }

          // for datasets inot custompurched
          for (let c = 0; c < this.customPurchased.length; c++) {
            if (this.customPurchased[c].type === 'sms') {
              // this.subscribePublic.push(this.datasetsArray[d]);
              this.customPurchased_sms.push(this.customPurchased[c]);
            } else {
              this.customPurchased_email.push(this.customPurchased[c]);
            }
          }

          if (this.subscribePublic_sms.length === 0) {
            this.subscribeDataNOsms = true;
            this.datasets_lengthsubscribesms = false;
          } else if (this.subscribePublic_email.length === 0) {
            this.subscribeDataNOemail = true;
            this.datasets_lengthsubscribeemail = false;
          } else if (this.customPurchased_sms.length === 0) {
            this.custompurchaseDataNOsms = true;
            this.datasets_lengthcustomsms = false;
          } else if (this.customPurchased_email.length === 0) {
            this.custompurchaseDataNOemail = true;
            this.datasets_lengthcustomemail = false;
          }
          // console.log(this.subscribePublic);
          // console.log(this.customPurchased);
          // console.log('++++', this.gotDataSets);
          this.cdr.detectChanges();
        } else {
          this.customedatasets_spinner = false;
          this.subscribe_datasetsspinner = false;
          this.loadSpinner = false;
          this.noDataSetsFound = true;
          this.cdr.detectChanges();
        }

      } else {
          this.customedatasets_spinner = false;
          this.subscribe_datasetsspinner = false;
          this.showError(data.message);
      }
        //else {
//
//        this.gotDataSets = [];
//        this.loadSpinner = false;
//        // console.log('++++', this.gotDataSets);
//        this.cdr.detectChanges();
//        this.noDataSetsFound = true;
//        if (data.message.toLowerCase() === 'token mismatch') {
//          this.citiservice.logout().subscribe(data_logout => {
//            if (data_logout.json().status === 'success') {
//              // console.log('successfully logged out');
//              this.customedatasets_spinner = false;
//              this.subscribe_datasetsspinner = false;
//              this.showError('Session Expired');
//              window.location.reload();
//              // this.router.navigate(['/login'], { skipLocationChange: true});
//              (<any>window).tabs = [];
//              (<any>window).font_awesome = [];
//              (<any>window).citiToken = '';
//              (<any>window).userprofile = [];
//              (<any>window).walletDetails = [];
//            } else {
//              this.showError(data_logout.json().message);
//              window.location.reload();
//            }
//          });
//        } else {
//          this.customedatasets_spinner = false;
//          this.subscribe_datasetsspinner = false;
//          this.showError(data.message);
//        }
//      }
    },
      (err) => {
        // console.log(err);
        // console.log(err.status);
        this.showError('Server is busy, Please try again');
        this.loadSpinner = false;
        this.ErrorLoadingData = true;
      });
  }


  initAutocomplete() {
    this.map = new google.maps.Map((<HTMLInputElement>document.getElementById('map')), {
      center: { lat: 16.7572, lng: 81.6800 },
      zoom: 9,
      mapTypeId: 'roadmap',
      disableDefaultUI: true,
      zoomControl: true,
      // styles: [
      //   {elementType: 'geometry', stylers: [{color: '#607d8b'}]},
      //   {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
      //   {elementType: 'labels.text.fill', stylers: [{color: '#ffffff'}]},
      //   {
      //     featureType: 'administrative.locality',
      //     elementType: 'labels.text.fill',
      //     stylers: [{color: '#ffffff'}]
      //   },
      //   {
      //     featureType: 'poi',
      //     elementType: 'labels.text.fill',
      //     stylers: [{color: '#d59563'}]
      //   },
      //   {
      //     featureType: 'poi.park',
      //     elementType: 'geometry',
      //     stylers: [{color: '#263c3f'}]
      //   },
      //   {
      //     featureType: 'poi.park',
      //     elementType: 'labels.text.fill',
      //     stylers: [{color: '#6b9a76'}]
      //   },
      //   {
      //     featureType: 'road',
      //     elementType: 'geometry',
      //     stylers: [{color: '#38414e'}]
      //   },
      //   {
      //     featureType: 'road',
      //     elementType: 'geometry.stroke',
      //     stylers: [{color: '#212a37'}]
      //   },
      //   {
      //     featureType: 'road',
      //     elementType: 'labels.text.fill',
      //     stylers: [{color: '#9ca5b3'}]
      //   },
      //   {
      //     featureType: 'road.highway',
      //     elementType: 'geometry',
      //     stylers: [{color: '#746855'}]
      //   },
      //   {
      //     featureType: 'road.highway',
      //     elementType: 'geometry.stroke',
      //     stylers: [{color: '#1f2835'}]
      //   },
      //   {
      //     featureType: 'road.highway',
      //     elementType: 'labels.text.fill',
      //     stylers: [{color: '#f3d19c'}]
      //   },
      //   {
      //     featureType: 'transit',
      //     elementType: 'geometry',
      //     stylers: [{color: '#2f3948'}]
      //   },
      //   {
      //     featureType: 'transit.station',
      //     elementType: 'labels.text.fill',
      //     stylers: [{color: '#d59563'}]
      //   },
      //   {
      //     featureType: 'water',
      //     elementType: 'geometry',
      //     stylers: [{color: '#17263c'}]
      //   },
      //   {
      //     featureType: 'water',
      //     elementType: 'labels.text.fill',
      //     stylers: [{color: '#515c6d'}]
      //   },
      //   {
      //     featureType: 'water',
      //     elementType: 'labels.text.stroke',
      //     stylers: [{color: '#17263c'}]
      //   }
      // ]
    });
    const infoWindow = new google.maps.InfoWindow;

    // Try HTML5 geolocation.
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        this.locationCoords = pos;
        if (this.searchedPlaces.length > 0) {
          this.getDataSetsViaApi();
        }
        this.backupLocation = pos;

        this.geocodeLatLng(pos).then((data) => {
          infoWindow.setPosition(pos);
          infoWindow.setContent(data);
          // infoWindow.open(this.map);
          this.map.panTo(pos);
        });

      }, () => {
        this.handleLocationError(true, infoWindow, this.map.getCenter());
      });
    } else {
      // Browser doesn't support Geolocation
      this.handleLocationError(false, infoWindow, this.map.getCenter());
    }


    // Create the search box and link it to the UI element.
    this.input = (<HTMLInputElement>document.getElementById('pac-input'));
    this.searchBox = new google.maps.places.SearchBox(this.input);
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.input);

    // Bias the SearchBox results towards current map's viewport.
    this.map.addListener('bounds_changed', () => {
      this.searchBox.setBounds(this.map.getBounds());
    });

    this.markers = [];
    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    this.searchBox.addListener('places_changed', () => {
      this.places = this.searchBox.getPlaces();

      if (this.places.length === 0) {
        return;
      }

      // Clear out the old markers.
      // this.markers.forEach((marker) => {
      //   marker.setMap(null);
      // });
      // this.markers = [];

      // For each place, get the icon, name and location.
      this.bounds = new google.maps.LatLngBounds();
      this.places.forEach((place) => {
        // this.totalDatasets.push({ place: this.mysearchedPlace, data: this.smsEmail });
        // console.log('||||||||||||||||||||', place.name);
        // this.mysearchedPlace = place.name;
        // this.smsEmail = [];
        if (!place.geometry) {
          // console.log('Returned place contains no geometry');
          return;
        }
        this.icon = {
          url: 'assets/img/markers/pin.svg',
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          // scaledSize: new google.maps.Size(25, 25)
          scaledSize: new google.maps.Size(35, 35)
        };

        // Create a marker for each place.
        this.markers.push(new google.maps.Marker({
          map: this.map,
          icon: this.icon,
          animation: google.maps.Animation.DROP,
          title: place.name,
          position: place.geometry.location
        }));

        this.cityCircle = new google.maps.Circle({
          strokeColor: '#b3c5e9',
          strokeOpacity: 0.5,
          strokeWeight: 1,
          fillColor: 'steelblue',
          fillOpacity: 0.5,
          map: this.map,
          center: place.geometry.location,
          radius: 5000
        });
        this.addPlaces(place, { radius: 5000, center: place.geometry.location }, this.cityCircle);

        // console.log('this.cityCircle', this.cityCircle);
        (<HTMLInputElement>document.getElementById('pac-input')).value = '';
        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          this.bounds.union(place.geometry.viewport);
        } else {
          this.bounds.extend(place.geometry.location);
        }
      });
      // console.log('this.bounds', this.bounds);
      // this.map.panTo(this.bounds);
      this.map.fitBounds(this.bounds);
      this.map.setZoom(9);
      this.searchRadius = 5000;
      this.adjustSlider(this.searchRadius);

    });

    if ((<any>window).savedSearchedPlaces && (<any>window).savedSearchedPlaces.length !== 0) {
      // console.log('|||', (<any>window).savedSearchedPlaces);
      this.searchedPlaces = (<any>window).savedSearchedPlaces;
      // this.changeButtonStyle(this.searchedPlaces.length - 1);
      setTimeout(() => {
        document.getElementById('place-' + (this.searchedPlaces.length - 1)).style.background = '#03A9F4';
        this.backupstyleId = 'place-' + (this.searchedPlaces.length - 1);
        this.searchRadius = this.searchedPlaces[(this.searchedPlaces.length - 1)].radiusData.radius;
        this.selectedIndex = this.searchedPlaces.length - 1;
        this.adjustSlider(this.searchRadius);
        if ((<any>window).mapMarkers.length > 0) {
          // console.log((<any>window).mapMarkers);
          // console.log('this.markers', this.markers);
          this.icon = {
            url: 'assets/img/markers/pin.svg',
            size: new google.maps.Size(71, 71),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(17, 34),
            // scaledSize: new google.maps.Size(25, 25)
            scaledSize: new google.maps.Size(35, 35)
          };
          for (let i = 0; i < (<any>window).mapMarkers.length; i++) {
            this.markers.push(new google.maps.Marker({
              map: this.map,
              icon: this.icon,
              animation: google.maps.Animation.DROP,
              title: (<any>window).mapMarkers[i].title,
              position: (<any>window).mapMarkers[i].position
            }));

            this.cityCircle = new google.maps.Circle({
              strokeColor: '#03a9f4',
              strokeOpacity: 0.5,
              strokeWeight: 1,
              fillColor: '#00bcd4',
              fillOpacity: 0.5,
              map: this.map,
              center: (<any>window).mapMarkers[i].position,
              radius: this.setRadiusObject(this.searchedPlaces[i])
            });
            const myplaces = {
              name: (<any>window).mapMarkers[i].title,
              geometry: { location: (<any>window).mapMarkers[i].position },
              place: this.searchedPlaces[i].placeData,
              radiusData: this.setRadiusObject(this.searchedPlaces[i])
            };
            const placeIndex = i;
            // this.updatePlaces(myplaces, this.cityCircle, placeIndex);
            if (i === (<any>window).mapMarkers.length - 1) {
              this.map.panTo((<any>window).mapMarkers[i].position);
            }
          }
        }
      }, 1000);


    }
  }


  handleLocationError(browserHasGeolocation, infoWindow, pos) {
    infoWindow.setPosition(pos);
    infoWindow.setContent(browserHasGeolocation ?
      'Error: The Geolocation service failed.' :
      'Error: Your browser doesn\'t support geolocation.');
    // infoWindow.open(this.map);
  }

  radiusChage(e) {
    // console.log(e);
    this.searchRadius = e.target.value * 1000;
    this.adjustSlider(this.searchRadius);
    this.changeMapRadius(this.searchRadius);
  }

  adjustSlider(val) {
    val = val / 100000;
    // console.log('val', val);
    document.getElementById('myRange').style.backgroundImage =
      '-webkit-gradient(linear, left top, right top, ' +
      'color-stop(' + val + ', #7eadfb), ' + 'color-stop(' + val + ', #C5C5C5)' + ')';

  }

  changeMapRadius(data) {
    if (this.searchedPlaces.length > 0) {
      // console.log(data);
      // console.log(this.searchedPlaces[this.selectedIndex].radiusData);
      // this.searchedPlaces[this.selectedIndex].radiusData.radius = data;
      // const backUp = this.searchedPlaces[this.selectedIndex].radiusData;
      // this.cityCircle.setMap(null);
      this.searchedPlaces[this.selectedIndex].radiusFunction.setMap(null);
      // const radData = this.setRadiusObject(this.searchedPlaces[this.selectedIndex]);
      // radData.setMap(null);
      this.cdr.detectChanges();
      // console.log(this.setRadiusObject(this.searchedPlaces[this.selectedIndex]));
      // const getLocationPath = this.searchedPlaces.length;
      const getLocationPath = this.selectedIndex;
      const backUpgeodata = this.searchedPlaces[this.selectedIndex].radiusFunction;
      const getLocation = new google.maps.Circle(backUpgeodata).getBounds();
      setTimeout(() => {
        this.cityCircle = new google.maps.Circle({
          strokeColor: '#03a9f4',
          strokeOpacity: 0.5,
          strokeWeight: 1,
          fillColor: '#00bcd4',
          fillOpacity: 0.35,
          map: this.map,
          // center: this.searchedPlaces[getLocationPath - 1].location,
          center: this.searchedPlaces[getLocationPath].location,
          radius: data
        });
        // console.log(getLocation);
        this.searchedPlaces[getLocationPath].radiusData = '';
        this.searchedPlaces[getLocationPath].radiusData = { radius: data, center: this.searchedPlaces[getLocationPath].location };
        this.searchedPlaces[getLocationPath].radiusFunction = '';
        this.searchedPlaces[getLocationPath].radiusFunction = this.cityCircle;
        this.cdr.detectChanges();
        this.selectedRadius = (this.searchedPlaces[getLocationPath].radiusData.radius / 1000);
        this.locationCoords = {
          lat: this.searchedPlaces[getLocationPath].location.lat(),
          lng: this.searchedPlaces[getLocationPath].location.lng()
        };
        this.getDataSetsViaApi();

      }, 100);
    } else {
      this.searchRadius = 5000;
      this.adjustSlider(this.searchRadius);

    }



  }


  addPlaces(place, radiusData, radiusFunction, initData?) {
    let myPlace: any;
    if (initData) {
      this.ageRange = initData.ageData;
      this.genderSelected = initData.genderData;
      myPlace = { place: place.name, location: place.geometry.location, placeData: place, radiusData: radiusData, radiusFunction: radiusFunction, ageData: this.ageRange, genderData: this.genderSelected };
    } else {
      this.ageRange = { fromAge: 18, toAge: 30 };
      this.genderSelected = 'male';
      myPlace = { place: place.name, location: place.geometry.location, placeData: place, radiusData: radiusData, radiusFunction: radiusFunction, ageData: this.ageRange, genderData: this.genderSelected };
    }

    setTimeout(() => {
      this.searchedPlaces.push(myPlace);
      // console.log('|||||', this.searchedPlaces);
      this.cdr.detectChanges();
      this.selectedIndex = this.searchedPlaces.length - 1;
      // console.log('new Index', this.selectedIndex);
      this.changeButtonStyle(this.selectedIndex);
      this.selectedRadius = (this.searchedPlaces[this.selectedIndex].radiusData.radius / 1000);
      this.locationCoords = {
        lat: this.searchedPlaces[this.selectedIndex].location.lat(),
        lng: this.searchedPlaces[this.selectedIndex].location.lng()
      };
      this.getDataSetsViaApi();
    }, 500);
    // console.log('this.searchedPlaces', this.searchedPlaces);
  }

  removePlace(data, index) {
    // console.log('remove place', data);
    // console.log(index);
    // console.log('this.searchedPlaces', this.searchedPlaces);
    // console.log(this.searchedPlaces[index].radiusData);
    this.searchedPlaces[index].radiusFunction.setMap(null);
    this.markers[index].setMap(null);
    this.markers.splice(index, 1);
    this.searchedPlaces.splice(index, 1);
    if (index >= 1) {
      this.backupstyleId = 'place-' + (index - 1);
    }

    if (index === 0) {
      this.backupstyleId = 'place-' + index;
    }

    if (this.searchedPlaces.length === 0) {
      this.dataSets = [];
      this.gotDataSets = [];
      if (document.getElementById('dataset_email') && document.getElementById('dataset_email').classList.contains('button_focused')) {
        document.getElementById('dataset_email').classList.remove('button_focused');
        document.getElementById('dataset_sms').classList.add('button_focused');
      } else {
        if (document.getElementById('dataset_email')) {
          document.getElementById('dataset_sms').classList.add('button_focused');
        }
      }

      this.searchRadius = 5000;
      this.adjustSlider(this.searchRadius);

    }
    this.customPurchased_sms = [];
    this.customPurchased_email = [];
    this.subscribePublic_sms = [];
    this.subscribePublic_email = [];
    console.log(this.searchedPlaces);
    console.log(this.searchedPlaces.length);
    if (this.searchedPlaces.length === 0) {
      console.log(this.searchedPlaces.length);
      this.map.panTo(this.backupLocation);
    } else {
      if (this.searchedPlaces.length >= 1) {
        const coordstoPan = this.searchedPlaces[this.searchedPlaces.length - 1].location;
        this.map.panTo(coordstoPan);
        this.selectedRadius = (this.searchedPlaces[this.searchedPlaces.length - 1].radiusData.radius / 1000);
        console.log(this.searchedPlaces);
        this.locationCoords = {
          lat: this.searchedPlaces[this.searchedPlaces.length - 1].location.lat(),
          lng: this.searchedPlaces[this.searchedPlaces.length - 1].location.lng(),
        };
        this.getDataSetsViaApi();
      }
    }
    setTimeout(() => {
      if (this.searchedPlaces.length >= 1) {
        document.getElementById('place-' + (this.searchedPlaces.length - 1)).style.background = 'rgb(3, 169, 244)';
        // this.backupstyleId = 'place-' + index;
        // this.searchRadius = this.searchedPlaces[index].radiusData.radius;
      }
    }, 500);

    if (this.searchedPlaces.length > 0) {
      this.selectedIndex = this.searchedPlaces.length - 1;
      this.searchRadius = this.searchedPlaces[this.searchedPlaces.length - 1].radiusData.radius;
      this.adjustSlider(this.searchRadius);
    }
  }

  getIndex(data, index) {
    // console.log('lllllllll', data);
    this.dataSets = [];
    this.loadSpinner = true;
    this.selectedIndex = index;
    // console.log(this.selectedIndex);
    // console.log('this.backupstyleId', this.backupstyleId);
    if (this.backupstyleId !== undefined && this.searchedPlaces.length > 1) {
      document.getElementById(this.backupstyleId).style.background = '#c1c1c1';
    }
    if (this.searchedPlaces.length > 1) {
      document.getElementById('place-' + index).style.background = '#03A9F4';
      this.backupstyleId = 'place-' + index;
      this.searchRadius = this.searchedPlaces[index].radiusData.radius;
      this.adjustSlider(this.searchRadius);

    }
    // this.map.setTilt(45);

    this.locationCoords.lat = this.searchedPlaces[index].location.lat();

    this.locationCoords.lng = this.searchedPlaces[index].location.lng();

    this.ageRange = data.ageData;
    this.genderSelected = data.genderData;

    this.selectedRadius = (this.searchedPlaces[index].radiusData.radius / 1000);
    this.getDataSetsViaApi();
    setTimeout(() => {
      this.map.panTo(this.searchedPlaces[index].location);
    }, 400);

  }

  changeButtonStyle(index) {
    // console.log(this.backupstyleId);
    if (this.backupstyleId !== undefined) {
      document.getElementById(this.backupstyleId).style.background = '#c1c1c1';
    }
    if (this.searchedPlaces.length >= 1) {
      document.getElementById('place-' + index).style.background = '#03A9F4';
      this.backupstyleId = 'place-' + index;
      // this.searchRadius = this.searchedPlaces[index].radiusData.radius;
    }
  }

  clearSearch() {
    (<HTMLInputElement>document.getElementById('pac-input')).value = '';
  }

  filterData() {
    // console.log('opening filter');
  }

  getGender(gender) {
    // console.log(gender.target.value);
    this.genderSelected = gender.target.value;
    this.searchedPlaces[this.selectedIndex].genderData = gender.target.value;
    // console.log('genderData Changed', this.searchedPlaces);
    this.getDataSetsViaApi();
  }

  updateAge(ev) {
    // console.log(ev.target.id);
    // console.log(ev.target.value);

    // if (ev.target.id === 'age-from' && ev.target.value !== undefined && ev.target.value < this.ageRange.toAge) {
    //   this.ageRange.fromAge = ev.target.value;
    // } else {
    //   console.log('fromAge is greater than toAge');
    //  this.ageRange.fromAge = 18;
    //  this.ageRange.toAge = 30;
    // }

    // if (ev.target.id === 'age-to' && ev.target.value !== undefined && ev.target.value > this.ageRange.fromAge) {
    //   this.ageRange.toAge = ev.target.value;
    // } else {
    //   console.log('toAge is less than fromAge');
    //   this.ageRange.toAge = 30;
    //   this.ageRange.fromAge = 18;
    //  }


    if (ev.target.id === 'age-from' && ev.target.value !== undefined) {
      if (ev.target.value < this.ageRange.toAge) {
        this.ageRange.fromAge = parseInt(ev.target.value, 10);
        // console.log('this.ageRange', this.ageRange);
        this.searchedPlaces[this.selectedIndex].ageData = this.ageRange;
        // console.log('AgeRange Changed 1', this.searchedPlaces);
        // console.log(this.selectedIndex);
        this.getDataSetsViaApi();
      } else {
        this.ageRange.fromAge = parseInt(ev.target.value, 10);
        this.searchedPlaces[this.selectedIndex].ageData = this.ageRange;
        // console.log('fromAge is greater than toAge');
        this.showError('fromAge should not be greater than toAge');
        // console.log('ageRange', this.ageRange);

      }
    }

    if (ev.target.id === 'age-to' && ev.target.value !== undefined) {
      if (ev.target.value > this.ageRange.fromAge) {
        this.ageRange.toAge = parseInt(ev.target.value, 10);
        // console.log('this.ageRange', this.ageRange);

        this.searchedPlaces[this.selectedIndex].ageData = this.ageRange;
        // console.log('AgeRange Changed 2', this.searchedPlaces);
        // console.log(this.selectedIndex);
        this.getDataSetsViaApi();
      } else {
        this.ageRange.toAge = parseInt(ev.target.value, 10);
        this.searchedPlaces[this.selectedIndex].ageData = this.ageRange;
        // console.log('toAge is less than fromAge');
        this.showError('toAge should not be greater than fromAge');
        // console.log('ageRange', this.ageRange);

      }
    }




  }


  geocodeLatLng(data) {
    const geocoder = new google.maps.Geocoder;
    const promise = new Promise((resolve, reject) => {
      const latlngStr = data;
      const latlng = { lat: parseFloat(latlngStr.lat), lng: parseFloat(latlngStr.lng) };
      geocoder.geocode({ 'location': latlng }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            // console.log('address', results[0].formatted_address);
            resolve(results[0].formatted_address);
          } else {
            // console.log('No results found');
            resolve('no result found');
          }
        } else {
          reject('Geocoder failed due to: ' + status);
        }
      });
    });
    return promise;

  }

  getLocationDetails(item) {
    // console.log('changing radius', item);
    // console.log(item.place + ' - ' + (item.radiusData.radius / 1000) + ' km');
    return item.place + ' - ' + (item.radiusData.radius / 1000) + ' km';


  }

  selectDataSet(event, dataSet, datasetId) {
    // console.log(event.target.checked);
    // console.log('dataset', dataSet);
    // console.log('dataset', datasetId);

    if (event.target.checked !== undefined) {
      if (event.target.checked === true) {
        (<any>window).globalDataSet.push(dataSet);
        document.getElementById(datasetId).style.backgroundColor = '#20a8d8';
        document.getElementById(datasetId).style.color = '#ffffff';
      } else if (event.target.checked === false) {
        (<any>window).globalDataSet.some((item, index) => {
          // console.log((<any>window).globalDataSet[index]);
          if ((<any>window).globalDataSet[index].datasetid === dataSet.datasetid && (<any>window).globalDataSet[index].type === dataSet.type) {
            // found it!
            (<any>window).globalDataSet.splice(index, 1);
          }
        });
        document.getElementById(datasetId).style.backgroundColor = '#f8f8f8';
        document.getElementById(datasetId).style.color = '#000000ab';
      } else {
        // console.log('error getting event');
      }
      // console.log('(<any>window).globalDataSet', (<any>window).globalDataSet);
    } else {
      // console.log('checkbox', 'checkbox-' + dataSet.datasetid);
      // const checkBoxValue = (<HTMLInputElement>document.querySelector('input[name="checkbox-" + dataSet.name]:checked')).value;
      const checkBoxValue = (<HTMLInputElement>document.getElementById('checkbox-' + dataSet.datasetid)).checked;
      // console.log('checkBoxValue', checkBoxValue);
      if (checkBoxValue === true) {
        (<HTMLInputElement>document.getElementById('checkbox-' + dataSet.datasetid)).checked = false;
        (<any>window).globalDataSet.some((item, index) => {
          // console.log((<any>window).globalDataSet[index]);
          if ((<any>window).globalDataSet[index].datasetid === dataSet.datasetid && (<any>window).globalDataSet[index].type === dataSet.type) {
            // found it!
            (<any>window).globalDataSet.splice(index, 1);
          }
        });
        // document.getElementById(datasetId).style.backgroundColor = '#e5e5e5';
        // document.getElementById(datasetId).style.color = '#000000ab';
        document.getElementById(datasetId).removeAttribute('style');
        document.getElementById('fa-' + dataSet.datasetid).removeAttribute('style');
        // if (dataSet.type === 'sms') {
        //   document.getElementById('fasms').removeAttribute('style');
        // }
        // if (dataSet.type === 'email') {
        //   document.getElementById('faemail').removeAttribute('style');
        // }


      } else if (checkBoxValue === false) {
        (<HTMLInputElement>document.getElementById('checkbox-' + dataSet.datasetid)).checked = true;
        (<any>window).globalDataSet.push(dataSet);
        // console.log((<any>window).globalDataSet);
        document.getElementById(datasetId).style.backgroundColor = '#20a8d8';
        document.getElementById(datasetId).style.color = '#ffffff';
        // if (dataSet.type === 'sms') {
        //   document.getElementById('fasms').style.color = 'white';
        // }
        // if (dataSet.type === 'email') {
        //   document.getElementById('faemail').style.color = 'white';
        // }
        document.getElementById('fa-' + dataSet.datasetid).style.color = 'white';
      } else {
        // console.log('error getting event');
      }
    }


  }

  // updaterole(data) {
  //   console.log(data.target.id);
  //   console.log(data.target.value);
  //   this.roleSelected = data.target.value;
  //   this.formdataSets(this.gotDataSets);
  // }

  // updatetype(data) {
  //   console.log(data.target.id);
  //   console.log(data.target.value);
  //   this.roleType = data.target.value;
  //   this.formdataSets(this.gotDataSets);
  // }

  formdataSets2(data) {
    if (document.getElementById('dataset_email') && document.getElementById('dataset_email').classList.contains('button_focused')) {
      document.getElementById('dataset_email').classList.remove('button_focused');
      document.getElementById('dataset_sms').classList.add('button_focused');
    } else {
      if (document.getElementById('dataset_email')) {
        document.getElementById('dataset_sms').classList.add('button_focused');
      }
    }
    this.dataSets = [];
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].type === 'sms') {
          this.dataSets.push(data[i]);
        }
        if (i === data.length - 1) {
          this.loadSpinner = false;
        }
        // console.log('checking selected datasets');
        setTimeout(() => {
          this.selectChecked();
          this.fetchingCache = false;
        }, 2000);
      }
    } else {
      // console.log('No Data Sets available');
      this.loadSpinner = false;
    }


  }

  updatetype(data) {
    // console.log(data.target.id);
    if (data.target.id === 'dataset_sms') {
      this.custompurchaseDataNOemail = false;
      this.subscribeDataNOemail = false;
      if ((this.searchedPlaces.length === 0) && (this.subscribePublic_sms.length === 0)) {
        this.subscribeDataNOsms = false;
      } else if (this.subscribePublic_sms.length === 0) {
        this.subscribeDataNOsms = true;
      }

      if ((this.searchedPlaces.length === 0) && (this.customPurchased_sms.length === 0)) {
        this.custompurchaseDataNOsms = false;
      } else if (this.customPurchased_sms.length === 0) {
        this.custompurchaseDataNOsms = true;
      }
      this.activeDiv_type = 'sms';
      this.customsetsDIV_sms = true;
      this.customsetsDIV_email = false;
      this.subscribeDIV_sms = true;
      this.subscribeDIV_email = false;
      document.getElementById('dataset_email').classList.remove('button_focused');
      document.getElementById('dataset_sms').classList.add('button_focused');
      document.getElementById('dataset_social').classList.remove('button_focused');
      this.dataSets = [];
      for (let i = 0; i < this.gotDataSets.length; i++) {
        if (this.gotDataSets[i].type === 'sms') {
          this.dataSets.push(this.gotDataSets[i]);
          // console.log('i', i);
          // console.log('this.gotDataSets.length - 1', this.gotDataSets.length - 1);

        }
        if (i === (this.gotDataSets.length - 1)) {

          setTimeout(() => {
            this.selectChecked();

          }, 300);
        }
      }
    } else if (data.target.id === 'dataset_email') {
      this.subscribeDataNOsms = false;
      this.custompurchaseDataNOsms = false;
      if ((this.searchedPlaces.length === 0) && (this.subscribePublic_email.length === 0)) {
        this.subscribeDataNOemail = false;
      } else if (this.subscribePublic_email.length === 0) {
        this.subscribeDataNOemail = true;
      }

      if ((this.searchedPlaces.length === 0) && (this.customPurchased_email.length === 0)) {
        this.custompurchaseDataNOemail = false;
      } else if (this.customPurchased_email.length === 0) {
        this.custompurchaseDataNOemail = true;
      }
      this.activeDiv_type = 'email';
      this.customsetsDIV_sms = false;
      this.customsetsDIV_email = true;
      this.subscribeDIV_sms = false;
      this.subscribeDIV_email = true;
      document.getElementById('dataset_sms').classList.remove('button_focused');
      document.getElementById('dataset_email').classList.add('button_focused');
      document.getElementById('dataset_social').classList.remove('button_focused');
      this.dataSets = [];
      for (let i = 0; i < this.gotDataSets.length; i++) {
        if (this.gotDataSets[i].type === 'email') {
          this.dataSets.push(this.gotDataSets[i]);
          // console.log('i', i);
          // console.log('this.gotDataSets.length - 1', this.gotDataSets.length - 1);

        }
        if (i === (this.gotDataSets.length - 1)) {
          setTimeout(() => {
            this.selectChecked();
          }, 300);
        }
      }

    } else if (data.target.id === 'dataset_social') {
      document.getElementById('dataset_sms').classList.remove('button_focused');
      document.getElementById('dataset_email').classList.remove('button_focused');
      document.getElementById('dataset_social').classList.add('button_focused');
      this.dataSets = [];
      for (let i = 0; i < this.gotDataSets.length; i++) {
        if (this.gotDataSets[i].type === 'social') {
          this.dataSets.push(this.gotDataSets[i]);
          // console.log('i', i);
          // console.log('this.gotDataSets.length - 1', this.gotDataSets.length - 1);

        }
        if (i === (this.gotDataSets.length - 1)) {
          setTimeout(() => {
            this.selectChecked();
          }, 300);
        }
      }

    }
  }


  selectChecked() {
    // console.log('....');
    // console.log('dataSets', this.dataSets);
    // console.log('<any>window).globalDataSet', (<any>window).globalDataSet);

    // console.log('globalDataSet', (<any>window).globalDataSet.length);
    if (this.noDataSetsFound === false) {
      for (let j = 0; j < this.dataSets.length; j++) {
        for (let k = 0; k < (<any>window).globalDataSet.length; k++) {
          if ((<any>window).globalDataSet[k].datasetid === this.dataSets[j].datasetid && (<any>window).globalDataSet[k].type === this.dataSets[j].type) {
            // console.log('got', (<any>window).globalDataSet[k].datasetid + '||' + this.dataSets[j].datasetid);
            // console.log('dataset-' + (<any>window).globalDataSet[k].datasetid + '-' + (<any>window).globalDataSet[k].type);
            (<HTMLInputElement>document.getElementById('checkbox-' + (<any>window).globalDataSet[k].datasetid)).checked = true;
            document.getElementById('dataset-' + (<any>window).globalDataSet[k].datasetid + '-' + (<any>window).globalDataSet[k].type).style.backgroundColor = '#20a8d8';
            document.getElementById('dataset-' + (<any>window).globalDataSet[k].datasetid + '-' + (<any>window).globalDataSet[k].type).style.color = '#ffffff';
            document.getElementById('fa-' + (<any>window).globalDataSet[k].datasetid).style.color = 'white';
          }
        }

      }
    }

  }

  showSuccess(data) {
    this.toastr.success(data, '', {
      timeOut: 1000,
      positionClass: 'toast-bottom-right'
    });
  }
  showError(data) {
    this.toastr.error(data, '', {
      timeOut: 1000,
      positionClass: 'toast-bottom-right'
    });
  }

  closeModal() {
    this.cdr.detectChanges();
    // console.log(this.searchedPlaces);
    const groupsID = [];
    for (let i = 0; i < this.selectedItems.length; i++) {
      groupsID.push(this.selectedItems[i].groupId);
    }
    const searchMapPlaces: any = [];
    for (let j = 0; j < this.searchedPlaces.length; j++) {
      const placeObj = { place: this.searchedPlaces[j].place, location: this.searchedPlaces[j].location, placeData: this.searchedPlaces[j].placeData, radiusData: this.searchedPlaces[j].radiusData, ageData: this.searchedPlaces[j].ageData, genderData: this.searchedPlaces[j].genderData };
      searchMapPlaces.push(placeObj);
    }
    // console.log(searchMapPlaces);
    this.obj = { data: { searchedPlaces: searchMapPlaces, dataSets: (<any>window).globalDataSet, ageData: this.ageRange, genderData: this.genderSelected }, manual: { sms: this.sendObj, uploadfile: this.upload_file_numbers, groups: groupsID, file: this.upload_file_data, email: this.journey_emails_value } };
    // const JSON = require('circular-json');
    // const mydata = JSON.stringify(this.obj);
    // const CircularJSON = require('circular-json-es6');
    // const clone: any = CircularJSON.stringify(this.obj);
    // console.log(JSON.stringify(this.obj));
    this.cdr.detectChanges();
    return this.obj;

    // this.close(this.obj);

  }


  // Send campaign
  changeFile(event) {
    this.eveData = event;
    // console.log(this.eveData);
  }

  // uploda file
  uploadFile() {
    // console.log(this.eveData);
    // console.log(this.eveData.target.files[0]);
    this.fileName = this.eveData.target.files[0].name;
    this.sendObj.fileObj = this.eveData.target.files[0];
    // console.log(this.fileName);
    const fd = new FormData();
    fd.append('file', this.eveData.target.files[0], this.eveData.target.files[0].name);
    // console.log('sdasd', fd);

    const headers = new Headers();
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    headers.append('X-Apikey', (<any>window).citikey);
    this.http.post((<any>window).citiUrl + 'campaigns/email/validations', fd, { headers: headers })
      .map(res => res.json())
      .subscribe(data => {
        // console.log(data);
        if (data.status === 'success') {
          this.toastr.success('file upload successfully', '', {
            timeOut: 1000,
            positionClass: 'toast-bottom-right'
          });
          this.validFormat = true;
          this.total = data.total;
          this.valid = data.valid;
          this.invalid = data.invalid;
          this.dnd = data.dnd;
          this.upload_file_numbers = data.nonDndMobileNumbers;
          this.eveData.target.value = '';
          this.upload_file_data = [];
          this.upload_file_data.push({ fileName: data.fileName, filePath: data.filePath, fileType: data.fileType });
        } else {
          // console.log('failed data');
        }
      }, error => {
        // this.showprogress = false;
        // this.snackBar.open('Server error', 'close', {
        //    duration: 3000
        // });
        // console.log(JSON.stringify(error.json()));
        // console.log(error);
      });

  }


  chosenData(group_data) {
    this.selectedToAdd = group_data;
  }

  chosenDataToRemove(group_data) {
    this.selectedToRemove = group_data;
  }

  assigne() {
    // console.log(this.selectedToAdd);
    this.selectedItems = this.selectedItems.concat(this.selectedToAdd);
    this.groupsArray = this.groupsArray.filter(car => {
      return this.selectedItems.indexOf(car) < 0;
    });
    // console.log(this.groupsArray);
    this.selectedToAdd = [];
  }

  unassigne() {
    this.groupsArray = this.groupsArray.concat(this.selectedToRemove);
    this.selectedItems = this.selectedItems.filter(selectedCar => {
      return this.groupsArray.indexOf(selectedCar) < 0;
    });
    this.selectedToRemove = [];
  }

  getGroups() {
    this.citiservice.getGroups().subscribe(data => {

      const group = data.json().response;

      if (data.json().status === 'success') {
        if (data.json().response.length) {
          for (let i = 0; i < group.length; i++) {
            this.groupsArray.push(group[i]);
          }
        } else {

        }
      } else {

      }

    });
  }

  closeData() {
    this.validFormat = false;
  }


  setRadiusObject(obj) {
    // console.log(obj);
    const cityCircle = new google.maps.Circle({
      strokeColor: '#b3c5e9',
      strokeOpacity: 0.5,
      strokeWeight: 1,
      fillColor: 'steelblue',
      fillOpacity: 0.5,
      map: this.map,
      center: obj.radiusData.center,
      radius: obj.radiusData.radius
    });
    return cityCircle;
  }

  selectsubcribe(data, index) {
    // console.log(this.totalDatasets);
    if (document.getElementById(data.datasetid + data.type).getAttribute('class') === 'subscribeDataBG') {
      document.getElementById(data.datasetid + data.type).classList.remove('subscribeDataBG');
      document.getElementById(data.datasetid + data.type).classList.add('subscribeDataBGcolor');
      document.getElementById(data.datasetid + index + data.type).classList.remove('subcardfooter');
      document.getElementById(data.datasetid + index + data.type).classList.add('subcardfootercolor');
      document.getElementById(data.datasetid + data.type + index).classList.add('font_check_class');
      document.getElementById(data.datasetid + data.type + index).classList.remove('font_check_classtransparent');
      document.getElementById(data.type + index).classList.add('font_classMobileW');
      document.getElementById(data.type + index).classList.remove('font_classMobileB');
      this.smsEmail.push({ item: data, item_index: index });

    } else {
      document.getElementById(data.datasetid + data.type).classList.add('subscribeDataBG');
      document.getElementById(data.datasetid + data.type).classList.remove('subscribeDataBGcolor');
      document.getElementById(data.datasetid + index + data.type).classList.add('subcardfooter');
      document.getElementById(data.datasetid + index + data.type).classList.remove('subcardfootercolor');
      document.getElementById(data.datasetid + data.type + index).classList.remove('font_check_class');
      document.getElementById(data.datasetid + data.type + index).classList.add('font_check_classtransparent');
      document.getElementById(data.type + index).classList.add('font_classMobileB');
      document.getElementById(data.type + index).classList.remove('font_classMobileW');
      for (let i = 0; i < this.smsEmail.length; i++) {
        if ((this.smsEmail[i].item.datasetid === data.datasetid) && (this.smsEmail[i].item.type === data.type)) {
          this.smsEmail.splice(i, 1);
        }
      }
      // console.log(this.smsEmail);
    }

    // document.getElementsByClassName('dataset_email').classList.remove('button_focused');
  }

  customsOwn(data, index) {
    // console.log(this.mysearchedPlace);
    // console.log(this.totalDatasets);
    if (document.getElementById(data.datasetid + data.type).getAttribute('class') === 'customDataBG') {
      document.getElementById(data.datasetid + data.type).classList.remove('customDataBG');
      document.getElementById(data.datasetid + data.type).classList.add('customDataBGcolor');
      // document.getElementById(data.datasetid + index).classList.remove('subcardfooter');
      // document.getElementById(data.datasetid + index).classList.add('subcardfootercolor');
      // document.getElementById(data.datasetid + data.datasetid + index).classList.add('font_check_class');
      // document.getElementById(data.datasetid + data.datasetid + index).classList.remove('font_check_classtransparent');
      document.getElementById(data.datasetid + index).classList.add('font_classMobileW');
      document.getElementById(data.datasetid + index).classList.remove('font_classMobileB');
      this.smsEmail.push({ item: data, item_index: index });
    } else {
      document.getElementById(data.datasetid + data.type).classList.add('customDataBG');
      document.getElementById(data.datasetid + data.type).classList.remove('customDataBGcolor');
      // document.getElementById(data.datasetid + index).classList.add('subcardfooter');
      // document.getElementById(data.datasetid + index).classList.remove('subcardfootercolor');
      // document.getElementById(data.datasetid + data.datasetid + index).classList.remove('font_check_class');
      // document.getElementById(data.datasetid + data.datasetid + index).classList.add('font_check_classtransparent');
      document.getElementById(data.datasetid + index).classList.add('font_classMobileB');
      document.getElementById(data.datasetid + index).classList.remove('font_classMobileW');
      for (let i = 0; i < this.smsEmail.length; i++) {
        if ((this.smsEmail[i].item.datasetid === data.datasetid) && (this.smsEmail[i].item.type === data.type)) {
          this.smsEmail.splice(i, 1);
        }
      }
      // console.log(this.smsEmail);
    }

    // document.getElementsByClassName('dataset_email').classList.remove('button_focused');
  }

  // for dialog box datasets
  selectsubcribe_dialog(data, index) {
    // console.log(this.totalDatasets);
    if (document.getElementById(data.type + data.datasetid + data.type).getAttribute('class') === 'subscribeDataBG') {
      document.getElementById(data.type + data.datasetid + data.type).classList.remove('subscribeDataBG');
      document.getElementById(data.type + data.datasetid + data.type).classList.add('subscribeDataBGcolor');
      document.getElementById(data.datasetid + index + data.type + index).classList.remove('subcardfooter');
      document.getElementById(data.datasetid + index + data.type + index).classList.add('subcardfootercolor');
      document.getElementById(data.datasetid + data.type + index + data.type).classList.add('font_check_class');
      document.getElementById(data.datasetid + data.type + index + data.type).classList.remove('font_check_classtransparent');
      document.getElementById(data.type + index + data.type).classList.add('font_classMobileW');
      document.getElementById(data.type + index + data.type).classList.remove('font_classMobileB');
      this.smsEmail_Dailog.push({ item: data, item_index: index });

    } else {
      document.getElementById(data.type + data.datasetid + data.type).classList.add('subscribeDataBG');
      document.getElementById(data.type + data.datasetid + data.type).classList.remove('subscribeDataBGcolor');
      document.getElementById(data.datasetid + index + data.type + index).classList.add('subcardfooter');
      document.getElementById(data.datasetid + index + data.type + index).classList.remove('subcardfootercolor');
      document.getElementById(data.datasetid + data.type + index + data.type).classList.remove('font_check_class');
      document.getElementById(data.datasetid + data.type + index + data.type).classList.add('font_check_classtransparent');
      document.getElementById(data.type + index + data.type).classList.add('font_classMobileB');
      document.getElementById(data.type + index + data.type).classList.remove('font_classMobileW');
      for (let i = 0; i < this.smsEmail_Dailog.length; i++) {
        if ((this.smsEmail_Dailog[i].item.datasetid === data.datasetid) && (this.smsEmail_Dailog[i].item.type === data.type)) {
          this.smsEmail_Dailog.splice(i, 1);
        }
      }
      // console.log(this.smsEmail_Dailog);
    }

    // document.getElementsByClassName('dataset_email').classList.remove('button_focused');
  }


  subscribeset() {
    if (this.activeDiv_type === 'sms') {
      if (this.subscribeDIV_sms) {
        this.myfontclass_accordain = 'fa fa-chevron-right';
        this.subscribeDIV_sms = false;
        if (this.subscribePublic_sms.length) {
          this.datasets_lengthsubscribesms = false;
          this.datasets_lengthsubscribeemail = false;
          this.subscribeDataNOsms = false;
          this.subscribeDataNOemail = false;
        } else {
          this.datasets_lengthsubscribesms = false;
          this.datasets_lengthsubscribeemail = false;
          this.subscribeDataNOsms = false;
          this.subscribeDataNOemail = false;
        }
      } else {
        this.subscribeDIV_sms = true;
        this.myfontclass_accordain = 'fa fa-chevron-down';
        if (this.subscribePublic_sms.length) {
          this.datasets_lengthsubscribesms = false;
          this.datasets_lengthsubscribeemail = false;
          this.subscribeDataNOsms = false;
          this.subscribeDataNOemail = false;
        } else {
          this.datasets_lengthsubscribesms = true;
          this.datasets_lengthsubscribeemail = false;
          this.subscribeDataNOsms = false;
          this.subscribeDataNOemail = false;
        }

      }
    } else {
      if (this.subscribeDIV_email) {
        this.myfontclass_accordain = 'fa fa-chevron-right';
        this.subscribeDIV_email = false;
        if (this.subscribePublic_email.length) {
          this.datasets_lengthsubscribesms = false;
          this.datasets_lengthsubscribeemail = false;
          this.subscribeDataNOsms = false;
          this.subscribeDataNOemail = false;
        } else {
          this.datasets_lengthsubscribesms = false;
          this.datasets_lengthsubscribeemail = false;
          this.subscribeDataNOsms = false;
          this.subscribeDataNOemail = false;
        }

      } else {
        this.datasets_lengthsubscribe = true;
        this.subscribeDIV_email = true;
        this.myfontclass_accordain = 'fa fa-chevron-down';
        if (this.subscribePublic_email.length) {
          this.datasets_lengthsubscribesms = false;
          this.datasets_lengthsubscribeemail = false;
          this.subscribeDataNOsms = false;
          this.subscribeDataNOemail = false;
        } else {
          this.datasets_lengthsubscribesms = false;
          this.datasets_lengthsubscribeemail = true;
          this.subscribeDataNOsms = false;
          this.subscribeDataNOemail = false;
        }
      }
    }

  }

  customsetshow() {
    if (this.activeDiv_type === 'sms') {
      if (this.customsetsDIV_sms) {
        this.myfontclass_custom = 'fa fa-chevron-right';
        this.customsetsDIV_sms = false;
        if (this.customPurchased_sms.length) {
          this.datasets_lengthcustomsms = false;
          this.datasets_lengthcustomemail = false;
          this.custompurchaseDataNOsms = false;
        } else {
          this.datasets_lengthcustomsms = false;
          this.datasets_lengthcustomemail = false;
          this.custompurchaseDataNOsms = false;

        }
      } else {
        this.customsetsDIV_sms = true;
        this.myfontclass_custom = 'fa fa-chevron-down';
        if (this.customPurchased_sms.length) {
          this.datasets_lengthcustomsms = false;
          this.datasets_lengthcustomemail = false;
          this.custompurchaseDataNOsms = false;
        } else {
          this.datasets_lengthcustomsms = false;
          this.datasets_lengthcustomemail = false;
          this.custompurchaseDataNOsms = true;

        }

      }
    } else {
      if (this.customsetsDIV_email) {
        this.myfontclass_custom = 'fa fa-chevron-right';
        this.customsetsDIV_email = false;
        // this.datasets_lengthcustom = false;
        if (this.customPurchased_email.length) {
          this.datasets_lengthcustomsms = false;
          this.datasets_lengthcustomemail = false;
        } else {
          this.datasets_lengthcustomsms = false;
          this.datasets_lengthcustomemail = false;
          this.custompurchaseDataNOemail = false;
        }
      } else {
        this.customsetsDIV_email = true;
        this.myfontclass_custom = 'fa fa-chevron-down';
        if (this.customPurchased_email.length) {
          this.datasets_lengthcustomsms = false;
          this.datasets_lengthcustomemail = false;
        } else {
          this.datasets_lengthcustomsms = false;
          this.datasets_lengthcustomemail = false;
          this.custompurchaseDataNOemail = true;

        }
      }
    }

  }

  buyselectedDatasets(datasetsDailogBox: TemplateRef<any>) {
    this.smsEmail_selectedDailog = [];
    if (this.smsEmail.length) {
      this.modalRef = this.modalService.show(datasetsDailogBox, { class: 'buy_datasets_cssClass' });
      // console.log((<any>window).walletDetails[0].balance);
      this.wallet_details = (<any>window).walletDetails[0].balance;
      for (let j = 0; j < this.smsEmail.length; j++) {
        if (this.smsEmail[j].item.dataBlockStatus === 'public') {
          if ((this.smsEmail[j].item.type === 'sms') || (this.smsEmail[j].item.type === 'email')) {
            // console.log(this.smsEmail[j]);
            this.smsEmail_selectedDailog.push(this.smsEmail[j].item);
          }
        }
      }
      // for (let m = 0; m < this.smsEmail_selectedDailog.length; m++) {
      //   this.selectsubcribe_dialog(this.smsEmail_selectedDailog[m], m);
      // }
    } else {

    }

  }

  filterDatass() {
    // console.log(this.filter_cs);
    if (this.filter_cs) {
      for (let m = 0; m < this.smsEmail_selectedDailog.length; m++) {
        this.selectsubcribe_dialog(this.smsEmail_selectedDailog[m], m);
      }
    } else {
      for (let m = 0; m < this.smsEmail_selectedDailog.length; m++) {
        this.selectsubcribe_dialog(this.smsEmail_selectedDailog[m], m);
      }
    }
  }

  canceldialog() {
    this.modalRef.hide();
  }

  changeemailFile(event) {
    this.emailfiledata = event.target.files[0];
  }

  buySelectedDatasets() {
    if (this.packName === '' || this.packName === undefined) {
      this.showError('Enter Package Name');
    } else {
      const geofilter = [];
      const range = [];
      // console.log(this.smsEmail_Dailog, this.packName, this.searchedPlaces);
      for (let l = 0; l < this.searchedPlaces.length; l++) {
        // console.log(this.searchedPlaces[l].location.lat());
        console.log(this.searchedPlaces[l]);
        geofilter.push({ latitude: this.searchedPlaces[l].location.lat(), longitude: this.searchedPlaces[l].location.lng(), radius: this.searchedPlaces[l].radiusData.radius, gender: this.searchedPlaces[l].genderData, age: [this.searchedPlaces[l].ageData.fromAge, this.searchedPlaces[l].ageData.toAge] });
      }

      for (let s = 0; s < this.smsEmail_Dailog.length; s++) {
        // console.log(this.smsEmail_Dailog[s].item.datasetid);
        range.push({ datasetid: this.smsEmail_Dailog[s].item.datasetid, start: 0, limit: this.smsEmail_Dailog[s].item.count });
      }

      const obj = {
        geofilter: geofilter,
        range: range,
        packageName: this.packName
      };

      // console.log(range, geofilter);
      this.citiservice.selectedDatasetPrice(obj).subscribe(data => {

        console.log(data);
        if (data.status === 'success') {
          this.modalRef.hide();
          this.showSuccess(data.message);
        } else {
//          if (data.message.toLowerCase() === 'token mismatch') {
//            this.citiservice.logout().subscribe(data_logout => {
//              if (data_logout.json().status === 'success') {
//                // console.log('successfully logged out');
//                window.location.reload();
//                // this.router.navigate(['/login'], { skipLocationChange: true});
//                (<any>window).tabs = [];
//                (<any>window).font_awesome = [];
//                (<any>window).citiToken = '';
//                (<any>window).userprofile = [];
//                (<any>window).walletDetails = [];
//
//                this.showError('Session Expired');
//              } else {
//
//                this.showError(data_logout.json().message);
//                window.location.reload();
//              }
//            });
//          } else 
            this.showError(data.message);
          
        }
      },
        error => {

          this.showError(JSON.stringify(error.json()));
          // console.log(JSON.stringify(error.json()));
          // this.spinner_canavas = false
        }
      );
    }

  }


  resizeColumns() {
    this.columnForMap = 'col-9';
    this.columnForDatasets = 'col-3';
  }
}
