import { NgModule } from '@angular/core';

import { EmailsendcampaignsComponent } from './emailsendcampaigns.component';
import { EmailsendcampaignsRoutingModule } from './emailsendcampaigns-routing.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CKEditorModule } from 'ng2-ckeditor';
import { DataTableModule } from 'angular2-datatable';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
// import { IpEmailBuilderModule } from 'ip-email-builder';
@NgModule({
  imports: [
    EmailsendcampaignsRoutingModule,
    TabsModule,
    ModalModule,
    CommonModule,
    AngularDateTimePickerModule,
    FormsModule,
    DataTableModule,
    CKEditorModule,
    // IpEmailBuilderModule.forChild({ xApiKey: 'LZLgKDaxCO1g1t1RYHzr3eXQ6i956RQ6J4bjSM1g' }),
  ],
  declarations: [EmailsendcampaignsComponent],
  exports: [EmailsendcampaignsComponent],
})

export class EmailsendcampaignsModule { }
