
<div tabindex="-1" role="dialog" aria-labelledby="myModalLabel" >
	<div class="modal-dialog modal-lg" role="document">
		<div class="modal-content">
			<div class="modal-header" style='cursor: move;'>
				<div class="modal-title pull-left" style="font-size: 15px;font-weight: 600;">
					<span style="padding-left: 5px;    font-family: roboto;">Add Funds</span>
				</div>
				<button type="button" class="close pull-right my_closex" style='padding: 15px 20px;' aria-label="Close" (click)="closeModal()">
					<span aria-hidden="true" style="font-size: 21px;    font-family: roboto;">&times;</span>
				</button>
			</div>
			<div class="modal-body" style="    padding: 10px 5px 20px 5px;margin-right: 3px;">
	  <div style="text-align: right;">
<form class="form-horizontal"  [formGroup]="addFundsForm" (ngSubmit)="addFundsToUser()">
<fieldset>

<div class="form-group row" style="margin-bottom: 0.2rem;">
<label class="col-md-4 col-form-label" for="text-input">Org id 

<span style="color:red">*</span>
</label>
<div class="col-md-6">
<div style='text-align: left;font-family:Roboto;'>{{addFundsData.id}}</div>
</div>
</div>
<div class="form-group row" style="margin-bottom: 0.2rem;">
<label class="col-md-4 col-form-label" for="text-input">Amount 

<span style="color:red">*</span>
</label>
<div class="col-md-6">
<input type="number" id="paymentAmount" name="text-input" class="form-control" formControlName = "amount" placeholder="Amount" [(ngModel)] = "addFundsData.amount">
</div>
<div *ngIf="addFundsForm.controls.amount.invalid && addFundsForm.controls.amount.touched" style='color: red;overflow: hidden;'>
		Enter valid Amount
</div>
</div>
<div class="form-group row" style="margin-bottom: 0.2rem;">
<label class="col-md-4 col-form-label" for="text-input">Customer name 

<span style="color:red">*</span>
</label>
<div class="col-md-6">
<input type="text" id="customerName" name="text-input" class="form-control" formControlName = "name"  placeholder="Customer Name" [(ngModel)] = "addFundsData.name">
</div>
<div *ngIf="addFundsForm.controls.name.invalid && addFundsForm.controls.name.touched" style='color: red;overflow: hidden;'>
	Enter valid name
</div>
</div>
<div class="form-group row" style="margin-bottom: 0.2rem;">
<label class="col-md-4 col-form-label" for="text-input">Country 

<span style="color:red">*</span>
</label>
<div class="col-md-6">
<input type="text" id="country" name="text-input" class="form-control" formControlName = "country" placeholder="Country" [(ngModel)] = "addFundsData.country">
</div>
<div *ngIf="addFundsForm.controls.country.invalid && addFundsForm.controls.country.touched" style='color: red;overflow: hidden;'>
		Enter valid country
</div>
</div>
<div class="form-group row" style="margin-bottom: 0.2rem;">
<label class="col-md-4 col-form-label" for="text-input">State 
	
	<span style="color:red">*</span>
</label>
<div class="col-md-6">
	<input type="text" id="state" name="text-input" class="form-control" formControlName = "state" placeholder="State" [(ngModel)] = "addFundsData.state">
</div>
<div *ngIf="addFundsForm.controls.state.invalid && addFundsForm.controls.state.touched" style='color: red;overflow: hidden;'>
		Enter valid state
</div>
</div>
<div class="form-group row" style="margin-bottom: 0.2rem;">
<label class="col-md-4 col-form-label" for="text-input">City 

<span style="color:red">*</span>
</label>
<div class="col-md-6">
<input type="text" id="city" name="text-input" class="form-control" formControlName = "city" placeholder="City" [(ngModel)] = "addFundsData.city">
</div>
<div *ngIf="addFundsForm.controls.city.invalid && addFundsForm.controls.city.touched" style='color: red;overflow: hidden;'>
		Enter valid city
</div>
</div>
<div class="form-group row" style="margin-bottom: 0.2rem;">
<label class="col-md-4 col-form-label" for="text-input">Address

<span style="color:red">*</span>
</label>
<div class="col-md-6">
<input type="text" id="address" name="text-input" class="form-control" formControlName = "address" placeholder="Address" [(ngModel)] = "addFundsData.address">
</div>
<div *ngIf="addFundsForm.controls.address.invalid && addFundsForm.controls.address.touched" style='color: red;overflow: hidden;'>
		Enter valid address
</div>
</div>
<div class="form-group row" style="margin-bottom: 0.2rem;">
<label class="col-md-4 col-form-label" for="text-input">Zipcode

<span style="color:red">*</span>
</label>
<div class="col-md-6">
<input type="text" id="zipCode" name="text-input" class="form-control" formControlName = "zipCode" placeholder="Zipcode" [(ngModel)] = "addFundsData.zipCode">
</div>
<div *ngIf="addFundsForm.controls.zipCode.invalid && addFundsForm.controls.zipCode.touched" style='color: red;overflow: hidden;'>
		Enter valid ZipCode
</div>
</div>
<div class="form-group row" style="margin-bottom: 0.2rem;">
<label class="col-md-4 col-form-label" for="text-input">Mobile Number 

<span style="color:red">*</span>
</label>
<div class="col-md-6">
<input type="text" id="mobileNumber" name="text-input" class="form-control" formControlName = "mobileNumber" placeholder="Mobile Number" [(ngModel)] = "addFundsData.mobileNumber" disabled>
</div>
<div *ngIf="addFundsForm.controls.mobileNumber.invalid && addFundsForm.controls.mobileNumber.touched" style='color: red;overflow: hidden;'>
		Enter valid MNumber
</div>
</div>
<div class="form-group row" style="margin-bottom: 0.2rem;">
<label class="col-md-4 col-form-label" for="text-input">Email id

<span style="color:red">*</span>
</label>
<div class="col-md-6">
<input type="text" id="email" name="text-input" class="form-control" formControlName = "email" placeholder="Email Id" [(ngModel)] = "addFundsData.email" disabled>
</div>
<div *ngIf="addFundsForm.controls.email.invalid && addFundsForm.controls.email.touched" style='color: red;overflow: hidden;'>
		Enter valid email
</div>
</div>
<div class="row" style="padding-top: 10px;">
	<div class="col-12" style="text-align: center">
		<span >
			<button type="button" class="btn btn-primary" style="border: none;background: #e4e7ea;color: green !important;box-shadow: none" type="submit" [disabled] = "!addFundsForm.valid" >
				<i  style="padding-right: 3px;" class="fa fa-floppy-o" aria-hidden="true"></i>Proceed to pay
			</button>
		</span>
		
		<span>
			<button type="button" class="btn  btn-primary" style="border: none;background: #e4e7ea;box-shadow: none;color: #b03626 !important; margin-left:5px;" (click)="closeModal()">
				<i style="padding-right: 3px;" class="fa fa-times" aria-hidden="true"></i>Cancel
			</button>
		</span>
	</div>
</div>
</fieldset>
</form>

			</div>
			<p-progressSpinner *ngIf='loggingin' class='prospinner' [style]="{width: '50px', height: '50px'}" strokeWidth="4" animationDuration=".5s"></p-progressSpinner>

			</div>
			<!-- /.modal-content -->
		</div>
		<!-- /.modal-dialog -->
	</div>
	</div>
	<!-- /.modal -->
