import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import {CitiservicesService} from '../../../../../../citiservices.service';

import { Router, NavigationEnd, Event } from '@angular/router';
@Component({
  selector: 'app-viewcontact',
  templateUrl: './viewcontact.component.html',
  styleUrls: ['./viewcontact.component.scss']
})
export class ViewcontactComponent implements OnInit {
  public contactdetails: any = [];
  temp: any;
  id: any;
  updateDiv = false;
  phnnumber: any;
  name: any;
  conid: any;
  groupData: any = [];
  grpId: any;
  subcontactsloading: boolean;
  constructor(private cdr: ChangeDetectorRef, private myServ: CitiservicesService, public bsModalRef: BsModalRef, public router: Router, public http: Http, public toastr: ToastrService) {
    this.temp = this.contactdetails;
  }
  ngOnInit() {
    //console.log(this.groupData);
    this.grpId = this.groupData[0].id.groupId;
    this.getContacts();
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.bsModalRef.hide();
      }
    });
  }
  showSuccess(data) {
    if (this.toastr.currentlyActive === 0) {
      this.toastr.success(data, '', {
        timeOut: 1500,
        positionClass: 'toast-bottom-right'
      });
    }
  }

  showError(data) {
    if (this.toastr.currentlyActive === 0) {
      this.toastr.error(data, '', {
        timeOut: 1500,
        positionClass: 'toast-bottom-right'
      });
    }
  }
  getContacts() {
    this.subcontactsloading = true;
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    headers.append('X-Apikey', (<any>window).citikey);
    this.http.get((<any>window).citiUrl + 'campaigns/sms/contacts?groupId=' + this.groupData[0].id.groupId, { headers: headers })
      .map(res => res.json())
      .subscribe(data => {
        //console.log('*****', data);
        if (data.status === 'success') {
          this.subcontactsloading = false;
          this.contactdetails = this.temp = data.response;
        }
        else {
//          if (data.message.toLowerCase() === 'token mismatch') {
//            this.myServ.logout().subscribe(resp => {
//               if (resp.json().status === 'success') {
//                   window.location.reload();
//                   // this.router.navigate(['/login'], { skipLocationChange: true});
//                   (<any>window).tabs = [];
//                   (<any>window).font_awesome = [];
//                   (<any>window).citiToken = '';
//                   (<any>window).userprofile = [];
//                   (<any>window).walletDetails  = [];
//                   this.toastr.error('Session Expired', '', {
//                       timeOut: 1000,
//                       positionClass: 'toast-bottom-right'
//                   });
//               } else {
//                   this.toastr.error(data.message, '', {
//                   timeOut: 1000,
//                       positionClass: 'toast-bottom-right'
//                   });
//                   window.location.reload();
//               }
//             });
//           }
           this.showError(data.message);
        }
      }, error => {
        this.showError('Server is busy, Please try again');
        //console.log(JSON.stringify(error.json()));
        //console.log(error);
      });
  }
  edit(item) {
    this.updateDiv = true;
    this.name = item.firstName;
    this.phnnumber = item.phoneNumber;
    this.conid = item.id;
  }
  deletemethod(row_no) {
    this.contactdetails.splice(row_no, 1);
  }
  updateGroup(item) {
    // console.log(item);
    // item.firstName = this.name;
    // item.phoneNumber = this.phnnumber;
   // console.log(this.name);
    //console.log(this.phnnumber);
    //console.log(this.conid);
   // console.log(this.groupData[0].id.groupId);
    if (this.name === undefined || this.name === '') {
      this.showError('please enter name');
    } else
      if (this.phnnumber === undefined || this.phnnumber === '') {
        this.showError('please enter phone number');
      } else {
        this.subcontactsloading = true;
        const headers = new Headers();
        const body = '&name=' + this.name + '&phoneNumber=' + this.phnnumber + '&contactId=' + this.conid + '&groupId=' + this.groupData[0].id.groupId;
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('X-Citistar-Token', (<any>window).citiToken);
        headers.append('X-Apikey', (<any>window).citikey);
        this.http.put((<any>window).citiUrl + 'campaigns/sms/contacts', body, { headers: headers })
          .map(res => res.json())
          .subscribe(data => {
            if (data.status === 'success') {
              if (data.status === 'success') {

                this.refresh();
                this.subcontactsloading = false;
                this.showSuccess('Contact updated successfully');
              }
              this.refresh();
              this.name = '';
              this.phnnumber = '';
            } else {
//              if (data.message.toLowerCase() === 'token mismatch') {
//                this.myServ.logout().subscribe(resp => {
//                   if (resp.json().status === 'success') {
//                       window.location.reload();
//                       // this.router.navigate(['/login'], { skipLocationChange: true});
//                       (<any>window).tabs = [];
//                       (<any>window).font_awesome = [];
//                       (<any>window).citiToken = '';
//                       (<any>window).userprofile = [];
//                       (<any>window).walletDetails  = [];
//                       this.toastr.error('Session Expired', '', {
//                           timeOut: 1000,
//                           positionClass: 'toast-bottom-right'
//                       });
//                   } else {
//                       this.toastr.error(data.message, '', {
//                       timeOut: 1000,
//                           positionClass: 'toast-bottom-right'
//                       });
//                       window.location.reload();
//                   }
//                 });
//               }
              this.showError(data.message);
            }
          }, error => {
            this.showError('Server is busy, Please try again');
          });
      }
  }
  closeModal() {
    this.refresh();
    localStorage.removeItem('contactdetails');
    this.bsModalRef.hide();
  }
  clearData() {
    this.name = '';
    this.phnnumber = '';

  }
  refresh() {
    this.cdr.detectChanges();
  }
}
