<div class='ProfileContent '>
    <div class="modal-header">
      <h4 class="modal-title pull-left title_style">Add Network</h4>
      <button type="button" class="close pull-right cross_style" aria-label="Close" (click)="hideModal()"> <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row" style="margin-bottom: 0.5rem">
        <a id="a1">
          <div class="col icon" (click)="twitterfunction()">
            <div class="twitter" style="margin-left: 20px"> <i class="fab fa-twitter fa-2x" style="margin-top: 20px;margin-left: 26px;"></i>
              <div class="twittertextbgcolors">&nbsp;Twitter</div>
            </div>
          </div>
        </a>
        <a>
          <div class="col icon" (click)="facebookfunction()">
            <div class="facebook"> <i class="fa fa-facebook font-icon-button fa-2x" style="margin-top: 20px; margin-left: 28px;"></i>
              <div class="textbgcolors" style="padding: 4px 14px !important;">Facebook</div>
            </div>
          </div>
        </a>
        <a>
          <div class="col icon" (click)="linkedinfunction()">
            <div class="linkedin"> <i class="fab fa-linkedin-in fa-2x" style="margin-top: 20px;margin-left: 26px;"></i>
              <div class="textbgcolors">Linkedin</div>
            </div>
          </div>
        </a>
        <a>
          <div class="col icon" (click)="instagramfunction()">
            <div class="Instagram"> <i class="fab fa-instagram fa-2x" style="margin-top: 20px;margin-left: 26px;"></i>
              <div class="textbgcolors" style="padding-left:11px">Instagram</div>
            </div>
          </div>
        </a>
        <a>
          <div class="col icon" (click)="foursquarefunction()">
            <div class="foursquare"> <i class="fa fa-foursquare fa-2x" style="margin-top: 20px;margin-left: 28px;"></i>
              <div class="textbgcolors" style="padding-left: 8px">Foursquare</div>
            </div>
          </div>
        </a>
        <a>
          <div class="col icon" (click)="pinterestfunction()">
            <div class="Pinterest"> <i class="fab fa-pinterest fa-2x" style="margin-top: 20px;margin-left: 26px;"></i>
              <div class="textbgcolors">Pinterest</div>
            </div>
          </div>
        </a>
        <!-- <a>
          <div class="col icon" (click)="tumblrfunction()">
            <div class="tumbir"> <i class="fab fa-tumblr fa-2x" style="margin-top: 20px;margin-left: 28px;"></i>
              <div class="textbgcolors">&nbsp;Tumblr</div>
            </div>
          </div>
        </a>
        <a>
          <div class="col icon" (click)="youtubefunction()">
            <div class="youtube"> <i class="fa fa-youtube fa-2x" style="margin-top: 20px;margin-left: 28px;"></i>
              <div class="textbgcolors">Youtube</div>
            </div>
          </div>
        </a> -->
        <div class="col">
          <div>
            <div></div>
          </div>
        </div>
      </div>
      <!-- <div class="arrow_box"> -->
      <div style="height: 238px;background-color: rgb(224, 240, 253)">
        <div *ngIf="twitternotification" class="notifications">
          <div class="row" *ngFor="let i of twitter">
            <div class="col">
              <div class="notificationadvice">{{i.title}}</div>
            </div>
          </div>
          <br>
          <div class="row" *ngFor="let i of twitter" style="padding-top: 10px;">
            <div class="col">
              <div class="notificationadvice"> <b>Note:</b>&nbsp;<span style="color: #cc181e">{{i.note}}</span> 
              </div>
            </div>
          </div>
          <br>
          <div class="row" style="padding-top: 10px;">
            <div class="col-md-12 " style="text-align: center">	<span style="padding-left: 5px"> <button type="button" class="btn  btn-primary socialbuttons_Style" style="background-color: #1da1f2" (click)='twitterLoginApi()' [disabled]='checkSocialMediaExistance("twitter")'>ADD TWITTER PROFILE</button></span>
            </div>
          </div>
        </div>
        <div *ngIf="facebooknotification" class="notifications">
          <div class="row" *ngFor="let i of facebook">
            <div class="col">
              <div class="notificationadvice">{{i.title}}</div>
            </div>
          </div>
          <br>
          <div class="row" *ngFor="let i of facebook" style="padding-top: 10px;">
            <div class="col">
              <div class="notificationadvice"><b>Note:</b><span style="color: #cc181e">{{i.note}}</span> 
              </div>
            </div>
          </div>
          <br>
          <div class="row" style="padding-top: 10px;">
            <div class="col-md-12 " style="text-align: center"> <span style="padding-left: 5px"> <button type="button" class="btn  btn-primary socialbuttons_Style" style="background-color:#3b5998;" (click)='facebookLoginApi()' [disabled]='checkSocialMediaExistance("facebook")'>ADD FACEBOOK PROFILE</button></span>
            </div>
          </div>
        </div>
        <div *ngIf="linkedinnotification" class="notifications">
          <div class="row" *ngFor="let i of linkedin">
            <div class="col">
              <div class="notificationadvice">{{i.title}}</div>
            </div>
          </div>
          <br>
          <div class="row" *ngFor="let i of linkedin" style="padding-top: 10px;">
            <div class="col">
              <div class="notificationadvice"> <b>Note:</b>&nbsp;<span style="color:#cc181e">{{i.note}}</span> 
              </div>
            </div>
          </div>
          <br>
          <div class="row" style="padding-top: 10px;">
            <div class="col-md-12 " style="text-align: center"> <span style="padding-left: 5px"> <button type="button" class="btn  btn-primary socialbuttons_Style" style="background-color:#0077b5;" (click)='linkedinLoginApi()' [disabled]='checkSocialMediaExistance("linkedin")'>ADD LINKEDIN PROFILE</button></span>
            </div>
          </div>
        </div>
        <div *ngIf="instagramnotification" class="notifications">
          <div class="row" *ngFor="let i of instagram">
            <div class="col">
              <div class="notificationadvice">{{i.title}}</div>
            </div>
          </div>
          <br>
          <div class="row" *ngFor="let i of instagram" style="padding-top: 10px;">
            <div class="col">
              <div class="notificationadvice"> <b>  Note:</b>&nbsp;<span style="color:#cc181e">{{i.note}}</span> 
              </div>
            </div>
          </div>
          <br>
          <div class="row" style="padding-top: 10px;">
            <div class="col-md-12 " style="text-align: center"> <span style="padding-left: 5px"> <button type="button" class="btn  btn-primary socialbuttons_Style" style="background-color:#517fa4;" (click)='instagramLoginApi()' [disabled]='checkSocialMediaExistance("instagram")'>ADD INSTAGRAM PROFILE</button></span>
            </div>
          </div>
        </div>
        <div *ngIf="foursquarenotification" class="notifications">
          <div class="row" *ngFor="let i of foursquare">
            <div class="col">
              <div class="notificationadvice">{{i.title}}</div>
            </div>
          </div>
          <br>
          <div class="row" *ngFor="let i of foursquare" style="padding-top: 10px;">
            <div class="col">
              <div class="notificationadvice"><b>Note:</b>&nbsp;<span style="color: #cc181e">{{i.note}}</span> 
              </div>
            </div>
          </div>
          <br>
          <div class="row" style="padding-top: 10px;">
            <div class="col-md-12 " style="text-align: center"> <span style="padding-left: 5px"> <button type="button" class="btn  btn-primary socialbuttons_Style" style="background-color:#fe9c2b;" (click)='fourSquareLoginApi()' [disabled]='checkSocialMediaExistance("foursquare")'>ADD FOURSQUARE PROFILE</button></span>
            </div>
          </div>
        </div>
        <div *ngIf="pinterestnotification" class="notifications">
          <div class="row" *ngFor="let i of pinterest">
            <div class="col">
              <div class="notificationadvice">{{i.title}}</div>
            </div>
          </div>
          <br>
          <div class="row" *ngFor="let i of pinterest" style="padding-top: 10px;">
            <div class="col">
              <div class="notificationadvice"> <b>Note:</b>&nbsp;<span style="color:#cc181e">{{i.note}}</span> 
              </div>
            </div>
          </div>
          <br>
          <div class="row" style="padding-top: 10px;">
            <div class="col-md-12 " style="text-align: center"> <span style="padding-left: 5px"> <button type="button" class="btn  btn-primary socialbuttons_Style" style="background-color:#ab171e;" (click)='pinterestLoginApi()' [disabled]='checkSocialMediaExistance("pinterest")'>ADD PINTEREST PROFILE</button></span>
            </div>
          </div>
        </div>
        <div *ngIf="tumblrnotification" class="notifications">
          <div class="row" *ngFor="let i of tumblr">
            <div class="col">
              <div class="notificationadvice">{{i.title}}</div>
            </div>
          </div>
          <br>
          <div class="row" *ngFor="let i of tumblr" style="padding-top: 10px;">
            <div class="col">
              <div class="notificationadvice"> <b>Note:</b>&nbsp;<span style="color: #cc181e">{{i.note}}</span> 
              </div>
            </div>
          </div>
          <br>
          <div class="row" style="padding-top: 10px;">
            <div class="col-md-12 " style="text-align: center"> <span style="padding-left: 5px"> <button type="button" class="btn  btn-primary socialbuttons_Style" style="background-color:#36465d;" (click)='tumblrLoginApi()' [disabled]='checkSocialMediaExistance("tumblr")'>ADD TUMBLR PROFILE</button></span>
            </div>
          </div>
        </div>
        <div *ngIf="youtubenotification" class="notifications">
          <div class="row" *ngFor="let i of youtube">
            <div class="col">
              <div class="notificationadvice">{{i.title}}</div>
            </div>
          </div>
          <br>
          <div class="row" *ngFor="let i of youtube" style="padding-top: 10px;">
            <div class="col">
              <div class="notificationadvice"><b>Note:</b>&nbsp;<span style="color:#cc181e">{{i.note}}</span> 
              </div>
            </div>
          </div>
          <br>
          <div class="row" style="padding-top: 10px;">
            <div class="col-md-12 " style="text-align: center"> <span style="padding-left: 5px"> <button type="button" class="btn  btn-primary socialbuttons_Style" style="background-color:#cc181e;" (click)='youtubeLoginApi()' [disabled]='checkSocialMediaExistance("youtube")'>ADD YOUTUBE PROFILE</button></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>