import { Component, OnInit } from '@angular/core';
import { EmailcampaigndetailsComponent } from './emailcampaigndetails/emailcampaigndetails.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
  providers: [EmailcampaigndetailsComponent, DatePipe]
})
export class EmailComponent implements OnInit {

  public emaildashboard: any = true;
  public emailsendcampaign: any = false;
  public emailcampaigndetails: any = false;
  public emailschedulecampaigns: any = false;
  public emailaddressbook: any = false;
  public emailstatistics: any = false;
  public emailreports: any = false;
  backup = 'emaildashboard';
  emailschduledata: any;
  constructor(public emailcam_details: EmailcampaigndetailsComponent, public router: Router, public route: ActivatedRoute) { }

  ngOnInit() {
    document.getElementById(this.backup).style.background = '#e7505a';
    document.getElementById(this.backup).style.color = '#ffffff';
    document.getElementById(this.backup).style.fontWeight = '500';
    this.route.queryParams.subscribe(queryParams => {
      if (JSON.stringify(queryParams) === '{}') {

      } else {
        document.getElementById(this.backup).removeAttribute('style');
        this.backup = 'emailcampaigndetails';
        document.getElementById(this.backup).style.background = '#e7505a';
        document.getElementById(this.backup).style.color = '#ffffff';
        document.getElementById(this.backup).style.fontWeight = '500';
        this.applyFilter('emailcampaigndetails');
        this.emailschduledata = queryParams;
        this.emailcam_details.getIndividualemailcamopaign(this.emailschduledata);
      }
    });
  }

  applyFilter(data) {
    (<any>window).journey_email_modal_tabs_fields = {
      tab_fields: true
    };
    document.getElementById(this.backup).removeAttribute('style');
    document.getElementById(data).style.background = '#e7505a';
    document.getElementById(data).style.color = '#ffffff';
    document.getElementById(data).style.fontWeight = '500';
    this.backup = data;
    if (data === 'emaildashboard') {
      this.emaildashboard = true;
      this.emailsendcampaign = false;
      this.emailcampaigndetails = false;
      this.emailschedulecampaigns = false;
      this.emailaddressbook = false;
      this.emailstatistics = false;
      this.emailreports = false;
    }
    if (data === 'emailsendcampaign') {
      this.emaildashboard = false;
      this.emailsendcampaign = true;
      this.emailcampaigndetails = false;
      this.emailschedulecampaigns = false;
      this.emailaddressbook = false;
      this.emailstatistics = false;
      this.emailreports = false;
    }
    if (data === 'emailcampaigndetails') {
      this.emaildashboard = false;
      this.emailsendcampaign = false;
      this.emailcampaigndetails = true;
      this.emailschedulecampaigns = false;
      this.emailaddressbook = false;
      this.emailstatistics = false;
      this.emailreports = false;
    }
    if (data === 'emailschedulecampaigns') {
      this.emaildashboard = false;
      this.emailsendcampaign = false;
      this.emailcampaigndetails = false;
      this.emailschedulecampaigns = true;
      this.emailaddressbook = false;
      this.emailstatistics = false;
      this.emailreports = false;
    }
    if (data === 'emailaddressbook') {
      this.emaildashboard = false;
      this.emailsendcampaign = false;
      this.emailcampaigndetails = false;
      this.emailschedulecampaigns = false;
      this.emailaddressbook = true;
      this.emailstatistics = false;
      this.emailreports = false;
    }
    if (data === 'emailstatistics') {
      this.emaildashboard = false;
      this.emailsendcampaign = false;
      this.emailcampaigndetails = false;
      this.emailschedulecampaigns = false;
      this.emailaddressbook = false;
      this.emailstatistics = true;
      this.emailreports = false;
    }
    if (data === 'emailreports') {
      this.emaildashboard = false;
      this.emailsendcampaign = false;
      this.emailcampaigndetails = false;
      this.emailschedulecampaigns = false;
      this.emailaddressbook = false;
      this.emailstatistics = false;
      this.emailreports = true;
    }
  }

  emailschdule(event) {
    this.emailschduledata = event.event;
    console.log(this.emailschduledata);
    this.applyFilter('emailcampaigndetails');
    this.emailcam_details.getIndividualemailcamopaign(this.emailschduledata);
  }


  // emailschduel(queryParams) {
  //   this.emailschduledata = queryParams;
  //   if (this.emailschduledata.campaignid) {
  //     this.applyFilter('emailcampaigndetails');
  //     this.emailcam_details.getIndividualemailcamopaign(this.emailschduledata);
  //   } else {
  //     this.emailcam_details.getIndividualemailcamopaign(this.emailschduledata);
  //   }
  // }


}
