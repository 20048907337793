import { Injectable } from '@angular/core';
import { Http, Headers, } from '@angular/http';
// import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class SocialMediaServiceService {
  fbid = (<any>window).facebookAppId;
  fbseckey = (<any>window).facebookAppSecret;

  constructor(private http: Http, private router: Router) { }

  /* saving facebook token and getting token from api*/


  saveFbAccess(token) {
    console.log('token', token);
    console.log(token.userProfileData);
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    // const body = 'oauthType=facebook&oauthToken=' + token;
    const body = 'socialMediaType=facebook&accessToken=' + token.permenentToken + '&accessTokenSecret=' + '' + '&userName=' + token.userProfileData.name + '&socialId=' + token.userProfileData.id;
    console.log(body);
    return this.http.post((<any>window).citiUrl + 'users/oauth2/login', body, {headers: headers}).map(res => res.json());
  }

  /* saving twitter token and getting token from api*/

  saveTwttrAccess(twitterData) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    const body = 'socialMediaType=twitter&accessToken=' + twitterData.twToken + '&accessTokenSecret=' + twitterData.twTokenSecret + '&userName=' + twitterData.profile.screenName + '&socialId=' + twitterData.profile.id ;
    // console.log(body);
    return this.http.post((<any>window).citiUrl + 'users/oauth2/login', body, {headers: headers}).map(res => res.json());
  }

  /* fb account fetching APi calling  service */

  getFacebookUserAccounts(token) {

    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);

    return this.http.get('https://graph.facebook.com/me/accounts?access_token=' + token , {headers: headers}).map(res => res.json());
  }

  /* fb account fetching APi calling via node */

  getfbUserAccounts(token) {

    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('vApikey', 'citistar-01f25b67974da152f6493qa1sd');
    headers.append('fbtoken', token);
    return this.http.get((<any>window).citiNodeApi + 'fb/getUserAccounts' , {headers: headers}).map(res => res.json());

  }

  /* FB APi to get permenent token */

  getFbPermenentAccessToken(fbcredentials) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);

    return this.http.get('https://graph.facebook.com/oauth/access_token?grant_type=fb_exchange_token& client_id=' + this.fbid + '&client_secret=' + this.fbseckey + '&fb_exchange_token=' + fbcredentials.fbToken , {headers: headers}).map(res => res.json());

  }

  /* Profiles APi to get user social profiles */

 userProfiles(orgId) {
  const headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('X-Apikey', (<any>window).citikey);
  headers.append('X-Citistar-Token', (<any>window).citiToken);
  // console.log((<any>window).citiUrl + 'users/social/profiles?orgId=' + orgId);
  // console.log(headers);
  return this.http.get((<any>window).citiUrl + 'users/social/profiles?orgId=' + orgId, {headers: headers}).map(res => res.json());

 }

 /** Get UserTimeline Twitter**/

 gettwitterUser(twitterToken, twitterTokenSecret) {

  const headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('vApikey', 'citistar-01f25b67974da152f6493qa1sd');
  headers.append('token', twitterToken);
  headers.append('tokensecret', twitterTokenSecret);
  return this.http.get((<any>window).citiNodeApi + 'twitter/getUserTimeline' , {headers: headers}).map(res => res.json());
 }

 /** Get FriendsList Twitter**/

 getTwitterFriendsList(twitterToken, twitterTokenSecret) {
  const headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('vApikey', 'citistar-01f25b67974da152f6493qa1sd');
  headers.append('token', twitterToken);
  headers.append('tokensecret', twitterTokenSecret);
  return this.http.get((<any>window).citiNodeApi + 'twitter/getFollowersList' , {headers: headers}).map(res => res.json());
 }

  /** Get FlowersList Twitter**/

 getTwitterFollowingList(twitterToken, twitterTokenSecret) {
  const headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('vApikey', 'citistar-01f25b67974da152f6493qa1sd');
  headers.append('token', twitterToken);
  headers.append('tokensecret', twitterTokenSecret);
  return this.http.get((<any>window).citiNodeApi + 'twitter/getFollowingList' , {headers: headers}).map(res => res.json());
 }

  /** Get user FavoritesList Twitter**/

 getTwitterFavoritesList(twitterToken, twitterTokenSecret) {
  const headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('vApikey', 'citistar-01f25b67974da152f6493qa1sd');
  headers.append('token', twitterToken);
  headers.append('tokensecret', twitterTokenSecret);
  return this.http.get((<any>window).citiNodeApi + 'twitter/getFavoritesList' , {headers: headers}).map(res => res.json());
 }

  /** Get HomeTimeline Twitter**/

 getTwitterHomeTimeline(twitterToken, twitterTokenSecret) {
  const headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('vApikey', 'citistar-01f25b67974da152f6493qa1sd');
  headers.append('token', twitterToken);
  headers.append('tokensecret', twitterTokenSecret);
  return this.http.get((<any>window).citiNodeApi + 'twitter/getHomeTimeline' , {headers: headers}).map(res => res.json());
 }


 getTwitterUserTimeline(twitterToken, twitterTokenSecret) {
  const headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('vApikey', 'citistar-01f25b67974da152f6493qa1sd');
  headers.append('token', twitterToken);
  headers.append('tokensecret', twitterTokenSecret);
  return this.http.get((<any>window).citiNodeApi + 'twitter/getUserTimeline' , {headers: headers}).map(res => res.json());
 }

  /** tweet in twitter**/


 tweetToTwitter(tweetData, twitterToken, twitterTokenSecret) {
  const headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('vApikey', 'citistar-01f25b67974da152f6493qa1sd');
  headers.append('token', twitterToken);
  headers.append('tokensecret', twitterTokenSecret);
  const body = 'statusTweet=' + tweetData ;

  return this.http.post((<any>window).citiNodeApi + 'twitter/statusUpdate' , body , {headers: headers}).map(res => res.json());
 }

 /** Retweet tweet in twitter**/

 retweetToTwitter(tweetId, twitterToken, twitterTokenSecret) {
  const headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('vApikey', 'citistar-01f25b67974da152f6493qa1sd');
  headers.append('token', twitterToken);
  headers.append('tokensecret', twitterTokenSecret);
  const body = 'tweetId=' + tweetId ;

  return this.http.post((<any>window).citiNodeApi + 'twitter/status/retweet' , body , {headers: headers}).map(res => res.json());
 }

 /** Save Timelines **/

 SaveTimeline(socialId, timeLine, socialMedia, type? ) {
  const headers = new Headers();
  const form = new FormData();
  form.append('socialId', socialId);
  form.append('timeLineInfo', JSON.stringify(timeLine));
  form.append('socialMediaType', socialMedia);
  // headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('X-Apikey', (<any>window).citikey);
  headers.append('X-Citistar-Token', (<any>window).citiToken);
  if(type){
    const body = 'socialId=' + socialId + '&timeLineInfo=' + JSON.stringify(timeLine) + '&socialMediaType=' + socialMedia;
  } else {
    const body = 'socialId=' + socialId + '&timeLineInfo=' + JSON.stringify(timeLine) + '&socialMediaType=' + socialMedia + 'timeLineType=' + type;
  }
  // console.log(body);
  return this.http.post((<any>window).citiUrl + 'social/timeline' , form , {headers: headers}).map(res => res.json());
 }

 myPostsposttobackend(socialId, postsInfo, socialMedia ) {
  const headers = new Headers();
  const form = new FormData();
  form.append('socialId', socialId);
  form.append('postsInfo', JSON.stringify(postsInfo));
  form.append('socialMediaType', socialMedia);
  // headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('X-Apikey', (<any>window).citikey);
  headers.append('X-Citistar-Token', (<any>window).citiToken);
  const body = 'socialId=' + socialId + '&timeLineInfo=' + JSON.stringify(postsInfo) + '&socialMediaType=' + socialMedia;
  // console.log(body);
  return this.http.post((<any>window).citiUrl + 'social/posts' , form , {headers: headers}).map(res => res.json());
 }

 /* Getting saved timeline */
 getSavedTimeline(fbpageId, fbuserId) {
  const headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('X-Apikey', (<any>window).citikey);
  headers.append('X-Citistar-Token', (<any>window).citiToken);
  headers.append('User-Id', fbuserId );
  return this.http.get((<any>window).citiUrl + 'social/pages/' + fbpageId + '?limit=20&offset=0' , {headers: headers}).map(res => res.json());
 }

 /* getting twittertimeline */
 getSavedTwitterhomeTimeline(twitterPageId) {
  const headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('X-Apikey', (<any>window).citikey);
  headers.append('X-Citistar-Token', (<any>window).citiToken);
  // headers.append('User-Id', fbuserId );
  return this.http.get((<any>window).citiUrl + 'twitter/users/' + twitterPageId + '/timelines/public?limit=20', {headers: headers}).map(res => res.json());
 }

  /* getting twittertimeline */
  getSavedTwitterPrivateTimeline(twitterPageId) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey);
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    // headers.append('User-Id', fbuserId );
    return this.http.get((<any>window).citiUrl + 'twitter/users/' + twitterPageId + '/timelines/private?limit=20', {headers: headers}).map(res => res.json());
   }
 /** Save facebook pages**/

 SaveFacebookPages(pageInfo, socialId ) {
  const headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('X-Apikey', (<any>window).citikey);
  headers.append('X-Citistar-Token', (<any>window).citiToken);
  // const body = 'pageInfo=' + JSON.stringify(pageInfo) + '&pageId=' + pageId + '&socialId=' + socialId + '&socialMediaType=facebook';
  pageInfo.some(item => {
    if (item.category.includes('&')) {
      const objData = item.category.replace('&', 'and');
      item.category = objData;
      const objDataName = item.name.replace('&', 'and');
      item.name = objDataName;

      item.category_list.some(subitem => {
        const subObjData = subitem.name.replace('&', 'and');
        subitem.name = subObjData;
      });
    }
  });
  const body = 'pageInfo=' + JSON.stringify(pageInfo) + '&socialId=' + socialId + '&socialMediaType=facebook';
  console.log('body', body);
  return this.http.post((<any>window).citiUrl + 'social/pages/posts' , body , {headers: headers}).map(res => res.json());
 }


 getFbUserDetails(token) {
  const headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('X-Apikey', (<any>window).citikey);

  return this.http.get('https://graph.facebook.com/me?fields=birthday,education,email,favorite_athletes,first_name,gender,last_name,locale,name,timezone,updated_time,verified,location,hometown,religion,languages,about,picture,cover,website,work&access_token=' + token , {headers: headers}).map(res => res.json());
 }

 /********************************** Save Tokens to server Start ***************************************/

 saveTwitterAccess(twitterData) {
  const headers = new Headers();
  // headers.append('Content-Type', 'multipart/form-data');
  headers.append('X-Apikey', (<any>window).citikey);
  headers.append('X-Citistar-Token', (<any>window).citiToken);
  const form: FormData = new FormData();
  form.append('orgId', (<any>window).userprofile[0].orgId);
  form.append('accessToken', twitterData.twToken);
  form.append('accessTokenSecret', twitterData.twTokenSecret);
  form.append('screenName', twitterData.profile.screenName);
  form.append('userName', twitterData.profile.screenName);
  form.append('socialId', twitterData.profile.id);
  form.append('socialMediaType', 'twitter');
  // console.log(form);
  return this.http.post((<any>window).citiUrl + 'users/social/profiles', form, {headers: headers}).map(res => res.json());
}

// saveFacebookAccess(facebookData) {
//   const headers = new Headers();
//   // headers.append('Content-Type', 'multipart/form-data');
//   headers.append('X-Apikey', (<any>window).citikey);
//   headers.append('X-Citistar-Token', (<any>window).citiToken);
//   const form: FormData = new FormData();
//   form.append('orgId', (<any>window).userprofile[0].orgId);
//   form.append('accessToken', facebookData.permenentToken);
//   // console.log(form);
//   return this.http.post((<any>window).citiUrl + 'users/social/profiles/facebook', form, {headers: headers}).map(res => res.json());
// }

saveFacebookAccess(facebookData) {
  console.log(facebookData);
  const headers = new Headers();
  // headers.append('Content-Type', 'multtrripart/form-data');
  headers.append('X-Apikey', (<any>window).citikey);
  headers.append('X-Citistar-Token', (<any>window).citiToken);
  const form: FormData = new FormData();
  form.append('orgId', (<any>window).userprofile[0].orgId);
  form.append('accessToken', facebookData.permenentToken);
  form.append('socialMediaType', 'facebook');
  form.append('socialId', facebookData.userProfileData.id);
  form.append('userName', facebookData.userProfileData.name);
  console.log(form);
  return this.http.post((<any>window).citiUrl + 'users/social/profiles', form, {headers: headers}).map(res => res.json());
}

savePinterestAccess(pinterestData, profileData) {
  const headers = new Headers();
  // headers.append('Content-Type', 'multipart/form-data');
  headers.append('X-Citistar-Token', (<any>window).citiToken);
  headers.append('X-Apikey', (<any>window).citikey);
  const form: FormData = new FormData();

  form.append('socialMediaType', 'pinterest');
  form.append('socialId', profileData.id);
  form.append('userName', profileData.first_name + ' ' + profileData.last_name);
  form.append('orgId', (<any>window).userprofile[0].orgId);
  form.append('accessToken', pinterestData.pinaccessToken);
  // console.log(form);
  return this.http.post((<any>window).citiUrl + 'users/social/profiles', form, {headers: headers}).map(res => res.json());
}

saveInstagramAccess(instagramData, profileData) {
  const headers = new Headers();
  // headers.append('Content-Type', 'multipart/form-data');
  headers.append('X-Citistar-Token', (<any>window).citiToken);
  headers.append('X-Apikey', (<any>window).citikey);
  const form: FormData = new FormData();

  form.append('socialMediaType', 'instagram');
  form.append('socialId', profileData.id);
  form.append('userName', profileData.username);
  form.append('orgId', (<any>window).userprofile[0].orgId);
  form.append('accessToken', instagramData.instaccessToken);
  // console.log(form);
  return this.http.post((<any>window).citiUrl + 'users/social/profiles', form, {headers: headers}).map(res => res.json());
}

saveFourSquareAccess(fourSquareData, profileData) {
  const headers = new Headers();
  console.log(profileData.id);
  // headers.append('Content-Type', 'multipart/form-data');
  headers.append('X-Citistar-Token', (<any>window).citiToken);
  headers.append('X-Apikey', (<any>window).citikey);
  const form: FormData = new FormData();
  form.append('socialMediaType', 'foursquare');
  form.append('socialId', profileData.id);
  form.append('userName', profileData.firstName + ' ' + profileData.lastName );
  form.append('orgId', (<any>window).userprofile[0].orgId);
  form.append('accessToken', fourSquareData.fqaccesstoken);
  console.log(form);
  return this.http.post((<any>window).citiUrl + 'users/social/profiles', form, {headers: headers}).map(res => res.json());
}

saveTumblrAccess(tumblrData) {
  const headers = new Headers();
  // headers.append('Content-Type', 'multipart/form-data');
  headers.append('X-Citistar-Token', (<any>window).citiToken);
  headers.append('X-Apikey', (<any>window).citikey);
  const form: FormData = new FormData();
  form.append('socialMediaType', 'tumblr');
  form.append('socialId', '');
  form.append('userName', '');
  form.append('orgId', (<any>window).userprofile[0].orgId);
  form.append('accessToken', tumblrData.tblrtoken);
  form.append('accessTokenSecret', tumblrData.tblrtokenSecret);
  // console.log(form);
  return this.http.post((<any>window).citiUrl + 'users/social/profiles', form, {headers: headers}).map(res => res.json());
}

saveLinkedInAccess(linkedinData) {
  const headers = new Headers();
  // headers.append('Content-Type', 'multipart/form-data');
  headers.append('X-Citistar-Token', (<any>window).citiToken);
  headers.append('X-Apikey', (<any>window).citikey);
  const form: FormData = new FormData();
  form.append('socialMediaType', 'linkedin');
  form.append('socialId', linkedinData.linkedUserId);
  form.append('userName', linkedinData.linkedUserName);
  form.append('orgId', (<any>window).userprofile[0].orgId);
  form.append('accessToken', linkedinData.linkdtoken);
  form.append('accessTokenSecret', linkedinData.linkdtokenSecret);
  // console.log(form);
  return this.http.post((<any>window).citiUrl + 'users/social/profiles', form, {headers: headers}).map(res => res.json());
}

saveYoutubeAccess(youtubeData) {
  const headers = new Headers();
  // headers.append('Content-Type', 'multipart/form-data');
  headers.append('X-Citistar-Token', (<any>window).citiToken);
  headers.append('X-Apikey', (<any>window).citikey);
  const form: FormData = new FormData();
  form.append('socialMediaType', 'youtube');
  form.append('socialId', '');
  form.append('userName', '');
  form.append('orgId', (<any>window).userprofile[0].orgId);
  form.append('accessToken', youtubeData.ytbaccesstoken);
  form.append('refreshToken', youtubeData.ytbrefreshToken);
  // console.log(form);

  return this.http.post((<any>window).citiUrl + 'users/social/profiles', form, {headers: headers}).map(res => res.json());
}


 /********************************** Save Tokens to server End ***************************************/


/** Get widgets according to id's**/

  getCredentialsFromSocialAccounts(mediaInfo, callback) {
    console.log((<any>window).citiUserSocialProfiles);
    for (let i = 0; i < (<any>window).citiUserSocialProfiles.length; i++) {
    if ((<any>window).citiUserSocialProfiles[i].socialMediaType === mediaInfo.socialMediaType && (<any>window).citiUserSocialProfiles[i].socialId === mediaInfo.id) {
      if ((<any>window).citiUserSocialProfiles[i].pagesInfo && ((<any>window).citiUserSocialProfiles[i].socialMediaType === 'facebook' || (<any>window).citiUserSocialProfiles[i].socialMediaType === 'linkedin')) {
        console.log('media is facebook or linkedin');
        for (let j = 0; j < (<any>window).citiUserSocialProfiles[i].pagesInfo.length; j++) {
          if ((<any>window).citiUserSocialProfiles[i].pagesInfo[j].id === mediaInfo.pageId) {
            if ((<any>window).citiUserSocialProfiles[i].socialMediaType === 'linkedin') {
            const obj: any = {
              token : (<any>window).citiUserSocialProfiles[i].accessToken,
              pageid: (<any>window).citiUserSocialProfiles[i].pagesInfo[j].id,
              id: (<any>window).citiUserSocialProfiles[i].socialId,
              tokenSecret: (<any>window).citiUserSocialProfiles[i].accessTokenSecret,
              refreshToken: ''
            };
            callback(obj);
            } else {
            const obj: any = {
              token : (<any>window).citiUserSocialProfiles[i].pagesInfo[j].access_token,
              pageid: (<any>window).citiUserSocialProfiles[i].pagesInfo[j].id,
              id: (<any>window).citiUserSocialProfiles[i].socialId,
              tokenSecret: '',
              refreshToken: ''
            };
            callback(obj);
            }
            break;

          }
        }
        break;
      } else {
        // console.log('media is other than facebook or linkedin');
        const obj: any = {
          token : (<any>window).citiUserSocialProfiles[i].accessToken,
          id: (<any>window).citiUserSocialProfiles[i].id,
          pageid: '',
          tokenSecret: this.getTokenSecret((<any>window).citiUserSocialProfiles[i]),
          refreshToken: this.getRefreshToken((<any>window).citiUserSocialProfiles[i])
        };
        callback(obj);
      }
      break;
    }
    }
  }

  getTokenSecret(data) {
  if (data.accessTokenSecret && data.accessTokenSecret !== '') {
    return data.accessTokenSecret;
  } else {
    return '';
  }
  }

  getRefreshToken(data) {
  if (data.refreshToken && data.refreshToken !== '') {
    return data.refreshToken;
  } else {
    return '';
  }
  }

  /** facebook message posting **/

  postFacebookmsg(message, fbpageId, fbtoken) {
    const headers = new Headers();
    const body = 'message=' + message;
    return this.http.post('https://graph.facebook.com/' + fbpageId + '/feed?access_token=' + fbtoken , body , {headers: headers});
  }

   /** facebook image posting **/

   postFacebookImage(fd, fbpageId, fbtoken ) {
    const headers = new Headers();
    return this.http.post('https://graph.facebook.com/' + fbpageId + '/photos?access_token=' + fbtoken , fd , {headers: headers}).map(res => res.json());
   }

   /**facebook like**/
   FacebookLike(likeid, fbtoken ) {
   const headers = new Headers();
   return this.http.post('https://graph.facebook.com/' + likeid + '/likes?access_token=' + fbtoken , {headers: headers}).map(res => res.json());
  }

  /** facebook dislike **/

  FacebookDisLike(dislikeid, fbtoken) {
     const headers = new Headers();
     return this.http.delete('https://graph.facebook.com/' + dislikeid + '/likes?access_token=' + fbtoken , {headers: headers}).map(res => res.json());
  }

  /** facebook comments **/

  FacebookpostComments(commentid, fbcommentmsg, fbtoken) {
     const headers = new Headers();
     return this.http.post('https://graph.facebook.com/' + commentid + '/comments?message=' + fbcommentmsg + '&access_token=' + fbtoken , {headers: headers}).map(res => res.json());
  }

  getSocialWidgets(callback) {
    const headers: Headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey );
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    this.http.get((<any>window).citiUrl + 'users/widgets', {headers: headers})
    .map(res => res.json())
    .subscribe(data => {
      console.log(data);
      if (data.status === 'success') {
        if (data.response.length !== 0 && data.response[0].widgetsInfo) {
          (<any>window).socialMonitorTabs = data.response[0].widgetsInfo;
          callback(data.response[0].widgetsInfo);
        }
      } else {
        const responseData = [];
        callback(responseData);
      }

    }, error => {
      console.log(error);

    });
  }

  SaveSocialWidgets(dataResp) {
    const body = 'widgets=' + JSON.stringify(dataResp) ;
    console.log('||||||||||', body);
    const headers: Headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-Apikey', (<any>window).citikey );
    headers.append('X-Citistar-Token', (<any>window).citiToken);
    this.http.post((<any>window).citiUrl + 'users/widgets', body, {headers: headers})
    .map(res => res.json())
    .subscribe(data => {
    console.log(data);
    if (data.status === 'success') {
      console.log(data);
    } else {
      console.log(data.message);
    }
    }, error => {
      console.log(JSON.stringify(error.json()));
      console.log(error);

    });
  }

  getPinterestProfile(token) {
    const headers: Headers = new Headers();
    return this.http.get('https://api.pinterest.com/v1/me/?access_token=' + token , {headers: headers}).map(res => res.json());
  }


  getInstagramProfile(token) {
    const headers: Headers = new Headers();
    return this.http.get('https://api.instagram.com/v1/users/self/?access_token=' + token , {headers: headers}).map(res => res.json());
  }

  getForeSquareProfile(token) {
    const presdate = new Date();

    // to set month string
    let presmonth = presdate.getMonth();
    presmonth = presmonth + 1;
    const presmonthAfter = '0' + presmonth;
    const monthString = presmonthAfter.slice(-2);

    // to set date string
    let presdates: any = presdate.getDate();
    presdates = '0' + presdates;
    const dateString = presdates.slice(-2);

    const vString = presdate.getFullYear() + '' +  monthString + '' + dateString;
    const headers: Headers = new Headers();
    return this.http.get('https://api.foursquare.com/v2/users/self/?oauth_token=' + token + '&v=' + vString , {headers: headers}).map(res => res.json());
  }


  getForesquareCheckins(token) {
    const presdate = new Date();

    // to set month string
    let presmonth = presdate.getMonth();
    presmonth = presmonth + 1;
    const presmonthAfter = '0' + presmonth;
    const monthString = presmonthAfter.slice(-2);

    // to set date string
    let presdates: any = presdate.getDate();
    presdates = '0' + presdates;
    const dateString = presdates.slice(-2);

    const vString = presdate.getFullYear() + '' +  monthString + '' + dateString;
    const headers: Headers = new Headers();
    return this.http.get('https://api.foursquare.com/v2/users/self/checkins/?oauth_token=' + token + '&v=' + vString , {headers: headers}).map(res => res.json());
  }

  getFbFriends(token) {
    const headers: Headers = new Headers();
    return this.http.get('https://graph.facebook.com/v3.3/me/friends?access_token=' + token , {headers: headers}).map(res => res.json());
  }

  getPagefanCount(pageID, token) {
    const headers: Headers = new Headers();
    return this.http.get('https://graph.facebook.com/' + pageID + '?fields=fan_count,country_page_likes&access_token=' + token , {headers: headers}).map(res => res.json());
  }

  getTwitterFollowersIds(twitterUserData) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('vApikey', 'citistar-01f25b67974da152f6493qa1sd');
    headers.append('token', twitterUserData.accessToken);
    headers.append('tokensecret', twitterUserData.accessTokenSecret);
    headers.append('screenname', twitterUserData.userName);
    headers.append('userid', twitterUserData.socialId);
    return this.http.get((<any>window).citiNodeApi + 'twitter/getUserFollowersIds' , {headers: headers}).map(res => res.json());
  }

  getTwitterUserLookUp(twitterUserData) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('vApikey', 'citistar-01f25b67974da152f6493qa1sd');
    headers.append('token', twitterUserData.accessToken);
    headers.append('tokensecret', twitterUserData.accessTokenSecret);
    headers.append('screenname', twitterUserData.userName);
    headers.append('userid', twitterUserData.socialId);
    return this.http.get((<any>window).citiNodeApi + 'twitter/getUserLookup' , {headers: headers}).map(res => res.json());
  }


  setLocalStorage() {
    const tabs = JSON.stringify((<any>window).tabs);
    const font_awesome = JSON.stringify((<any>window).font_awesome);
    const citiToken = JSON.stringify((<any>window).citiToken);
    const userprofile = JSON.stringify((<any>window).userprofile);
    const walletDetails = JSON.stringify((<any>window).walletDetails);
    const fbCredentials = JSON.stringify((<any>window).fbCredentials);
    const twCredentials = JSON.stringify((<any>window).twCredentials);
    const instaCredentials = JSON.stringify((<any>window).instaCredentials);
    const pinterestCredentials = JSON.stringify((<any>window).pinterestCredentials);
    const linkedinCredentials = JSON.stringify((<any>window).linkedinCredentials);
    const foursquareCredentials = JSON.stringify((<any>window).foursquareCredentials);
    const tumblrCredentials = JSON.stringify((<any>window).tumblrCredentials);
    const youtubeCredentials = JSON.stringify((<any>window).youtubeCredentials);

    localStorage.setItem('tabs', tabs);
    localStorage.setItem('font_awesome', font_awesome);
    localStorage.setItem('citiToken', citiToken);
    localStorage.setItem('userprofile', userprofile);
    localStorage.setItem('walletDetails', walletDetails);
    localStorage.setItem('fbCredentials', fbCredentials);
    localStorage.setItem('twCredentials', twCredentials);
    localStorage.setItem('instaCredentials', instaCredentials);
    localStorage.setItem('pinterestCredentials', pinterestCredentials);
    localStorage.setItem('linkedinCredentials', linkedinCredentials);
    localStorage.setItem('foursquareCredentials', foursquareCredentials);
    localStorage.setItem('tumblrCredentials', tumblrCredentials);
    localStorage.setItem('youtubeCredentials', youtubeCredentials);
  }
}
